import { Injectable, PLATFORM_ID, Inject } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { CookieServices } from './cookies.service';
// import "rxjs/add/observable/of";
// import "rxjs/add/operator/share";
// import "rxjs/add/operator/map";

/**
 * General Service
 */
declare let mixpanel: any;
@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  displayname: any;
  metaService: any;

  private controller: AbortController | null = null;
  private streamSubject = new Subject<string>();

  constructor(
    private http: HttpClient,
    private cookieService: CookieServices,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    // this.headers = new HttpHeaders();
  }
  /**
   * getUserHeader returns  headers
   * if logged in returns authorization value else returns empty value
   */
  getUserHeader(option: string = '') {
    const userDetail: any = this.cookieService.getCookie('userDetails');
    let encodedString;
    let base64format;
    if (
      userDetail === undefined ||
      userDetail === '' ||
      userDetail === null ||
      userDetail === false
    ) {
      encodedString = '';
    } else {
      base64format =
        userDetail['user_id'] +
        ':' +
        userDetail['session_id'] +
        ':' +
        userDetail['email'];
      encodedString = 'Basic ' + btoa(base64format);
    }
    //encodedString =
    //  'Basic NTkxYmQ5NjlhMjk2MWMwZGQ0MGQ2Yjk2OlI0MDZlR2ZCTHBCdnhha1BuSkNBNjF2N3g4dWRUelRFOnZpanU1MjdAZ21haWwuY29t';
    const header = {
      headers: new HttpHeaders().set('Authorization', encodedString),
    };
    if (option == 'encoded') {
      return encodedString;
    } else {
      return header;
    }
  }

  getLoggedUser() {
    const userDetail = this.cookieService.getCookie('userDetails');
    if (
      userDetail === undefined ||
      userDetail === '' ||
      userDetail === null ||
      userDetail === false
    ) {
      return false;
    } else {
      return userDetail;
    }
  }

  checkvalidatesubdomain(): Observable<any> {
    const headers: any = this.getUserHeader();
    return this.http.get(
      environment.apiEndpoint + 'users/validateSubDomain',
      headers
    );
  }

  async generate(
    data: any,
    onChunk: (chunk: string) => void,
    onError: (error: any) => void
  ): Promise<void> {
    const url = data.url ? data.url : '';
    const version = data.url ? data.version : 'v1';
    let apiUrl = '';
    if (version == 'v1') {
      apiUrl = environment.apiEndpoint + url;
    } else if (version === 'v2') {
      apiUrl = environment.apiEndpointv2 + url;
    }
    const header: any = this.getUserHeader('encoded');
    console.log('in gene');
    let totalChunk = 0;
    let control = new AbortController();
    const signal = control.signal;
    const request=data.request;
    console.log("DATA DATA");
    console.log(request);
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: header,
        },
        body: JSON.stringify(request),
        signal, // Pass the signal to the fetch request
      });
      if (response.body) {
        console.log('response.body >>>>>>>>>> ');
        console.log(response.body);
        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8');

        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            break;
          }
          // Massage and parse the chunk of data
          const chunk = decoder.decode(value);
          console.log('TOTAL CHUNK: ' + totalChunk);
          totalChunk++;
          console.log(
            'chunk................................................................'
          );
          console.log(chunk);
          onChunk(chunk);
        }
      }
    } catch (error) {
      console.log('ERRRRRR======');
      console.log(error);
      // onError(error);
    } finally {
      // Enable the generate button and disable the stop button
      console.log('FINALLY');
      const done = '{ "done": true }';
      onChunk(done);
      // control = null; // Reset the AbortController instance
    }
  }

  async streamPost(
    data: any,
    onChunk: (chunk: string) => void,
    onError: (error: any) => void
  ): Promise<void> {
    let apiUrl = data.url ? data.url : '';
    console.log('apiUrl');
    console.log(apiUrl);
    const request = data.req;
    console.log('request--------------------------------');
    console.log(request);

    const header: any = this.getUserHeader('encoded');
    console.log('in gene');
    let control = new AbortController();
    const signal = control.signal;
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: header,
        },
        body: JSON.stringify(request),
        signal,
      });
      if (response.body) {
        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8');

        while (true) {
          const { done, value } = await reader.read();
          if (done) {
            break;
          }
          // Massage and parse the chunk of data
          const chunk = decoder.decode(value);
          onChunk(chunk);
        }
      }
    } catch (error) {
      console.log('ERRRRRR');
      console.log(error);
      onError(error);
    } finally {
      // Enable the generate button and disable the stop button
      console.log('FINALLY');

      // control = null; // Reset the AbortController instance
    }
  }

  postMethod(url: string, req: any, version = 'v1'): Observable<any> {
    // console.log("inside the getMethod");
    const headers: any = this.getUserHeader();
    // console.log(headers);
    let apiUrl = '';
    if (version == 'v1') {
      apiUrl = environment.apiEndpoint + url;
    } else if (version === 'v2') {
      apiUrl = environment.apiEndpointv2 + url;
    }

    return this.http.post(apiUrl, req, headers);
  }

  getMethod(url: string, version = 'v1'): Observable<any> {
    console.log('inside the getMethod');
    const headers: any = this.getUserHeader();
    // console.log(headers);
    let apiUrl = '';
    if (version == 'v1') {
      apiUrl = environment.apiEndpoint + url;
    } else if (version === 'v2') {
      apiUrl = environment.apiEndpointv2 + url;
    }

    return this.http.get(apiUrl, headers);
  }

  putMethod(url: string, req: any, version = 'v1'): Observable<any> {
    // console.log("inside the getMethod");
    const headers: any = this.getUserHeader();
    // console.log(headers);
    let apiUrl = '';
    if (version == 'v1') {
      apiUrl = environment.apiEndpoint + url;
    } else if (version === 'v2') {
      apiUrl = environment.apiEndpointv2 + url;
    }

    return this.http.put(apiUrl, req, headers);
  }

  deleteMethod(url: string, version = 'v1'): Observable<any> {
    // console.log("inside the getMethod");
    const headers: any = this.getUserHeader();
    // console.log(headers);
    let apiUrl = '';
    if (version == 'v1') {
      apiUrl = environment.apiEndpoint + url;
    } else if (version === 'v2') {
      apiUrl = environment.apiEndpointv2 + url;
    }

    return this.http.delete(apiUrl, headers);
  }
}
