// pricing
.tripPricing {
    max-width: 90% !important;
}

.trip_price_item_row {
    &.pass_selection {
        background-color: var(--white) !important;
        color: var(--body-text-color) !important;
        border: 1px solid  var(--grey100) ;
        margin-top: 30px;
    }
    &.row_head {
        font-weight: 500;
        font-size: 14px;
        background-color:  var(--body-text-color)   ;
        color: var(--white);
    }
    display: block;
    text-align: left;
    .trip_item {
        display: inline-block;
        &.item_title {
            width: 30%;
        }
    }
    &.row_head .trip_item {
        text-align: center;
        vertical-align: bottom;
    }
    .trip_item.item_desc {
        width: 20%;
    }
    &.row_head .trip_item {
        text-align: center;
        vertical-align: bottom;
    }
    .trip_item.item_local_cost {
        width: 20%;
        border-left: 1px solid  var(--grey100) ;
        height: 57px;
        height: 47px;
        @include media-breakpoint-only(xs) {
            width: 16%;
            font-size: 13px;
        }
    }
    &.row_head .trip_item {
        text-align: center;
        vertical-align: bottom;
    }
    .trip_item {
        display: inline-block;
    }
    .item_local_cost_disp {
        display: inline-block;
        width: 100%;
        border-top: 1px solid  var(--grey100) ;
        .item_local_cost_sub {
            width: 50%;
            border-right: 1px solid  var(--grey100) ;
            float: left;
            @include media-breakpoint-only(xs) {
                height: 25px;
                line-height: 25px;
            }
        }
    }
    .trip_item.price_currency {
        width: 8%;
        text-align: center;
        @include media-breakpoint-only(xs) {
            width: 13%;
        }
    }
    &.row_head .trip_item {
        text-align: center;
        vertical-align: bottom;
    }
    .trip_item {
        display: inline-block;
        &.price_currency .small_section {
            border-bottom: 1px solid  var(--grey100) ;
        }
        &.item_local_cost.border_right {
            border-right: 1px solid  var(--grey100) ;
        }
    }
    // &.row_head {
    //     font-weight: 500;
    //     font-size: 14px;
    //     background-color:   var(--body-text-color) ;
    //     color: var(--white);
    // }
    display: block;
    text-align: left;
    .trip_item.item_local_cost:last-child {
        border-right: 1px solid  var(--grey100) ;
    }
}

.trip_price_holder {
    .trip_price_head {
        border-top: 1px solid  var(--grey100) ;
        border-bottom: 1px solid  var(--grey100) ;
        font-size: 13px;
        font-weight: 500;
        padding: 0px 5px;
        background-color: rgba(243, 209, 194, 0.1);
        text-transform: uppercase;
        line-height: 30px;
        input {
            height: 30px !important;
            line-height: 30px;
            margin-right: 5px;
        }
    }
    .trip_price_item_row {
        .hub_name {
            text-align: center;
            font-size: 14px;
            font-weight: 500;
            padding-bottom: 5px;
        }
        .trip_item:first-child {
            padding-left: 25px;
        }
    }
}

.trip_price_item_row .trip_item.item_title {
    width: 30%;
}

.trip_price_holder .trip_price_item_row .trip_item {
    height: 30px;
    line-height: 30px;
}

.trip_price_item_row .trip_item {
    display: inline-block;
    .trip_item_input {
        width: 85% !important;
        height: inherit !important;
        margin-bottom: 0px !important;
    }
    &.item_cost {
        width: 10%;
    }
    display: inline-block;
    &.item_title {
        width: 30%;
    }
    &.item_desc {
        width: 20%;
    }
}

.trip_price_holder .trip_price_item_row .trip_item:last-child {
    border-right: none;
}

.trip_price_item_row .trip_item.item_action {
    width: 3%;
    @include media-breakpoint-only(xs) {
        width: 6%;
    }
}

.trip_price_holder .trip_price_item_row .trip_item {
    height: 30px;
    line-height: 30px;
}

.trip_price_item_row .trip_item input {
    height: 30px !important;
    line-height: 30px;
    margin-right: 5px;
    @include media-breakpoint-only(xs) {
        font-size: 12px;
    }
}

.trip_price_holder.grand_total {
    background-color: rgba(243, 209, 194, .1);
}

.other_price_details {
    margin-top: 20px;
    font-size: 14px;
    border: 1px solid  var(--grey100) ;
    width: 250px;
    .line_text {
        width: 100px;
        border-right: 1px solid  var(--grey100) ;
    }
    .cost_input {
        width: 100px;
    }
    td {
        padding: 0px 5px !important;
    }
    .line_text {
        width: 100px;
        border-right: 1px solid  var(--grey100) ;
    }
    tr {
        height: 30px;
        border-bottom: 1px solid  var(--grey100) ;
        input {
            width: 70% !important;
            // margin-bottom: 0px;
            height: 30px !important;
            line-height: 30px !important;
            // border-bottom: none;
            margin: 0 auto;
            display: inline-block;
        }
    }
}

.pricing-top-new .selectdiv select {
    height: 25px!important;
    @include media-breakpoint-only(xs) {
        padding: 3px!important;
        min-width: 50px!important;
    }
}

.tripPricing .modal-content {
    overflow: auto!important;
}

.width-pricing {
    min-width: 650px;
}

.row-height-title {
    height: 40px;
    align-items: center;
}

// end pricing