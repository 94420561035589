/* background css */

.background-greylightest {
  background: var(--grey100);
}
.background-primary-text-color {
  background: var(--primary-color);
}
.background-lighter-black {
  background-color: #333;
}

.background-primary-light {
  background: var(--primary-light);
}
.background-secondary-text-color {
  background: var(--secondary-color);
}
.new_secondarycolor2 {
  color: var(--primary-light);
}
.background-red {
  background: var(--error);
}
.new_warningstate {
  color: var(--warning);
}
.medium-icon-size {
  font-size: 16px;
}
.small-icon-size {
  font-size: 12px;
}
.new_activegreen {
  color: var(--success);
}

.new_darkgrey {
  color: var(--body-text-color) !important;
}
.new_activegreen {
  color: var(--success);
}
.small-icon-size {
  font-size: 12px;
}
.success-state {
  color: var(--success);
}
.icon-default-width {
  width: 30px;
  height: 24px;
}
.border-greylighter {
  border: 1px solid var(--grey200);
}
.height-100vh {
  min-height: 100vh;
}
.row-box-shadow {
  box-shadow: 0px 2px 3px var(--card-shadow);
}
.d-flex-direction {
  flex-direction: row;
}
.icon-default-width {
  width: 30px;
  height: 24px;
}
.form-label {
  font-size: 14px;
  line-height: 1.5;
  color: var(--body-text-color);
}

.tick {
  display: inline-block;
  &:after {
    content: "";
    display: block;
    width: 6px;
    height: 12px;
    border: solid var(--success);
    border-width: 0 0.1em 0.1em 0;
    transform: rotate(45deg);
  }
}
.tick-container {
  width: 30px;
  display: inline-block;
  text-align: center;
  height: 34px;
  line-height: 34px;
}
.tick-container-mobile {
  padding-right: 8px;
  width: auto;
  display: inline-block;
  text-align: center;
  height: 13px;
  line-height: auto;
  .cross-symbol::before,
  .cross-symbol::after {
    content: "";
    position: absolute;
    width: 100%;
    top: 62%;
    left: 0;
    margin-top: -1px;
    background: #f44336;
  }
}
.radio-container {
  display: block;
  position: relative;
  padding-left: 35px;
  padding-top: 0px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 14px;
  color: var(--body-text-color);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.margin-top {
  margin-top: 40px;
}
.margin-15 {
  margin: 15px 0px;
}
.lht20 {
  line-height: 20px;
}
.margin-bottom-15 {
  margin-bottom: 15px;
}
/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.check-radiobtn {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: var(--grey200);
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .check-radiobtn {
  background-color: var(--grey200);
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .check-radiobtn {
  background-color: var(--primary-color);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.check-radiobtn:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .check-radiobtn:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .check-radiobtn:after {
  top: 6px;
  left: 6px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: var(--white);
}

.checkmark-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 0px;
  cursor: pointer;
  font-size: 14px;
  color: var(--body-text-color);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: normal;
}

/* Hide the browser's default checkbox */
.checkmark-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmarker {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: var(--grey100);
  border: 1px solid var(--grey200);
}

/* On mouse-over, add a grey background color */
.checkmark-container:hover input ~ .checkmarker {
  background-color: var(--grey200);
  border: 1px solid var(--grey200);
}

/* When the checkbox is checked, add a blue background */
.checkmark-container input:checked ~ .checkmarker {
  background-color: var(--primary-color);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmarker:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkmark-container input:checked ~ .checkmarker:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkmark-container .checkmarker:after {
  left: 7px;
  top: 3px;
  width: 6px;
  height: 11px;
  border: solid var(--white);
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.input-text {
  font-size: 14px;
  color: var(--body-text-color);
}

.icon-size {
  font-size: 10px;
}
.d-display-block {
  display: block;
}
.chevron.bottom.active span {
  transform: rotate(-178deg) !important;
}
.disc-list {
  display: inline-block;
  text-align: left;
}
.disc-list li {
  list-style-type: disc;
  margin-left: 1.1rem;
  padding-left: 5px;
}
.cross-container {
  width: 30px;
  display: inline-block;
  text-align: center;
}

.cross-symbol {
  position: relative;
  display: inline-block;
  width: 12px;
  height: 12px;
  overflow: hidden;
  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0;
    margin-top: -1px;
    background: var(--error);
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }

  &.thin {
    &::before,
    &::after {
      height: 1.2px;
    }
  }
}

@media only screen and (max-width: 1024px) {
}

/* Progress Bar CSs */

.progress {
  display: flex;
  height: 0.5rem;
  overflow: hidden;
  font-size: 0.5rem;
  background-color: var(--grey100);
  border-radius: 4px;
  border: 1px solid var(--grey200);
}
.progress-bar {
  border-radius: 4px !important;
}
.overflow-x-auto {
  overflow-x: auto;
}
.modal-medium {
  max-width: 700px !important;
}
.dropdown-box {
  width: 200px;
  padding: 10px 0px;
  background: var(--white);
  z-index: 9;
  border: 1px solid var(--grey200);
  border-radius: 6px;
  font-size: 15px;
  color: var(--body-text-color);
  top: 25px;
  top: 35px;
  left: 0px;
  ul {
    li {
      line-height: 15px;
      min-height: 20px;
      cursor: pointer;
      a {
        padding: 10px 20px;
        line-height: 22px;
        display: block;
        &:hover {
          background: var(--grey100);
        }
      }
    }
  }
}
.trans-mob-rbar {
  z-index: 1200;
  position: fixed;
  min-height: 50px;
  bottom: -1px;
  line-height: 60px;
  background: var(--grey100);
  .active {
    .active-icon {
      transform: rotate(180deg);
      transition: 300ms all linear;
    }
  }
  .bottom-bar-grey {
    z-index: 10;
    background-color: var(--grey100);
  }
}
.trans-mob-rbar {
  .content-heading {
    line-height: 2rem;
  }
  .small-heading {
    line-height: 1.5rem;
  }
}
@media only screen and (max-width: 1024px) {
  .mob-text-align-left {
    text-align: left !important;
  }
  .mob-label-bottom {
    margin-right: 0px !important;
    margin-bottom: 0.5rem !important;
  }
  .mob-form-tick {
    display: inline;
    width: 30px;
    text-align: center;
    &:after {
      margin-left: 15px;
      content: "";
      display: inline-block;
      width: 6px;
      height: 10px;
      border: solid var(--success);
      border-width: 0 0.1em 0.1em 0;
      transform: rotate(45deg);
    }
  }
  .mob-display-none {
    display: none;
  }

  .otp-box {
    margin-bottom: 10px;
    height: 50px;
    text-align: center;
  }
  .th-mob-rbar {
    position: fixed;
    min-height: 50px;
    bottom: 0px;
    line-height: 50px;
    z-index: 11;
    background: var(--grey100);
    .active {
      .active-icon {
        transform: rotate(180deg);
        transition: 300ms all linear;
      }
    }
  }
  .th-mob-rbar-list {
    position: absolute;
    width: 100%;
    background: var(--white);
    bottom: 49px;
    z-index: 9;
    border-bottom: 1px solid var(--grey200);
  }

  .trans-mob-rbar-list {
    position: absolute;
    width: 100%;
    height: calc(100vh - 47px);
    background: var(--white);
    bottom: 49px;
    z-index: 9;
    border-bottom: 1px solid var(--grey100);
  }
  .th-mob-wrapper {
    padding-bottom: 20px;
  }
}
.transaction-top-arrow {
  position: fixed;
  z-index: 1300;
  text-align: center;
  width: 100%;
  background-color: var(--white);
  border-bottom: 1px solid var(--grey100);
}
.transaction-bottom-bar {
  bottom: 0px;
  position: absolute;
  width: 100%;

  overflow-y: scroll;
  .modal {
    z-index: 1599;
    background: var(--body-text-color) 00069;
  }
}
bs-datepicker-container,
bs-daterangepicker-container {
  z-index: 99999;
}
@media only screen and (max-width: 991px) {
  .d-flex-direction {
    flex-direction: column;
  }
}
@media only screen and (min-width: 575px) and (max-width: 1024px) {
  .th-mob-wrapper {
    width: 500px;
    margin: 0px auto !important;
  }
}
@media only screen and (max-width: 767px) {
  .modal-medium {
    max-width: 500px !important;
    margin: 3rem auto;
  }
}
@media only screen and (max-width: 575px) {
  .modal-medium {
    max-width: 360px !important;
    margin: 3rem auto;
  }
  .mob-flex-col {
    flex-direction: column;
  }
}
@media only screen and (max-width: 360px) {
  .modal-medium {
    max-width: 300px !important;
    margin: 3rem auto;
  }
}
.checkmarker1 {
  position: absolute;
  top: 5px;
  right: 0px;
  height: 25px;
  border-radius: 50%;
  width: 25px;
  background-color: var(--grey100);
  border: 1px solid var(--grey200);
}
.checkmark-container .checkmarker1:after {
  left: 8px;
  top: 4px;
  width: 6px;
  height: 11px;
  border: solid var(--white);
  border-width: 0 1.5px 1.5px 0;
  transform: rotate(45deg);
}
.checkmark-container input:checked ~ .checkmarker1 {
  background-color: var(--primary-color);
}
.checkmarker1:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkmark-container input:checked ~ .checkmarker1:after {
  display: block;
}

.inr-outer {
  display: flex;
  border: 1px solid var(--grey200);
  border-radius: 4px;
  min-width: 80px !important;
  max-width: 150px !important;
}
.input-border {
  padding-left: 10px;
  border: 0px solid transparent;
}
.price-wrapper {
  padding: 0px 5px;
}
.card-box {
  padding: 20px;
  border: 1px solid var(--grey200);
  border-radius: 6px;
}
@media only screen and (max-width: 1024px) {
  .checkmarker1 {
    top: 0px;
  }
}
