
%input-style {
    @include font-height($input-font-size , $input-font-size + 1.6rem);
    @include border-radius(0.4rem);
    display: flex;
    width: 100%;
    align-items: center;
    width: 100%;
    max-width: 100%;
    font-weight: inherit;
    border:$input-border;
    color :var(--body-text-color);
    padding: 0.5rem 1rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
input[type=text],
input[type=url],
input[type=email],
input[type=password],
input[type=number] {
    @extend %input-style;
    height:$input-height;
    &:focus {
        box-shadow: none;
        -webkit-box-shadow: none ;
        outline: none;
        
    }
    @include placeholder-color(var(--placeholder));
}
input:-webkit-autofill {
 background: inherit;
 font-family: inherit;
}
input:autofill { 
  
  background: inherit;
  font-family: inherit;
}
label {
   @include font-height($input-font-size , $input-font-size + 1.6rem);
   @media only screen and (max-width:575px) {
    font-size: 1.2rem;
    line-height: 2rem;
   }
}
select {
    height:$input-height;
    @extend %input-style;
}
textarea {
    @extend %input-style;
    height:auto;
}
.input-field {
    @extend %input-style;
    height:$input-height;
    &:focus {
        box-shadow: none;
        -webkit-box-shadow: none ;
        outline: none;
        
    }
    @include placeholder-color(var(--placeholder)); 
   
}

.radio-item {
    display: inline-block;
    position: relative;
    padding: 0 6px;
    margin: 0px 0 0;
  }
  
  .radio-item input[type='radio'] {
    display: none;
  }
  
  .radio-item label {
    color: #666;
    font-weight: normal;
  }
  
  .radio-item label:before {
    content: " ";
    display: inline-block;
    position: relative;
    top: 5px;
    margin: 0 5px 0 0;
    width: 20px;
    height: 20px;
    border-radius: 11px;
    border: 2px solid var(--primary-color);
    background-color: transparent;
  }
  
  .radio-item input[type=radio]:checked + label:after {
    border-radius: 11px;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 9px;
    left: 10px;
    content: " ";
    display: block;
    background: var(--primary-color);
  }

@media only screen and (max-width:575px) {
    .input-field {
        @include font-height($input-font-size , $input-font-size + 1rem);
    } 
} 
