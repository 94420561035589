<div class="type_data">
  <div class="trip-scrap d-flex px-0 flex-column">
    <div class="d-flex flex-column">
      <div class="col-12 px-0" *ngIf="image != ''">
        <app-image
          *ngIf="image != ''"
          [image]="image"
          [ratio]="'21:9'"
        ></app-image>
      </div>
      <!-- <div class="trip-scrap-more-option">
        <i class="fa fa-ellipsis-v"></i>
      </div> -->
    </div>
    <div class="place_details_holder mt-2">
    <div class="">
      <div class="w-100 d-flex align-items-center justify-content-between">
        <div class="d-flex w-100">
          <div class="scrap-title content-heading ">
              <a
              class="pointer"
              *ngIf="placeUrl != ''"
              [routerLink]="['/scrapinner', placeUrl]"
              target="_blank"
              >
              <i class="fas {{ categoryIcon }} iti-icon-ht mr-2"></i>
              {{ place["name"] }}</a
            >
            <div *ngIf="placeUrl == ''">
              <i class="fas {{ categoryIcon }} iti-icon-ht mr-2"></i>
              {{ place["name"] }}</div>
            
          </div>
        </div>
      </div>
      <div
        *ngIf="place_address"
        class="scrap-iti-details place_address d-flex mt-1 align-items-center"
      >
         {{ place_address }} 
        
      </div>
       
      <div
        class="scrap-iti-details small-heading d-flex mt-1 align-items-center"
      >
        <div class="scrap_arrival" *ngIf="arrivalTime">
          <span class="small-heading mr-2">Arr: </span>
          <span>{{ arrivalTime }}</span>
        </div>
        <div
          class="scrap_duration ml-2 small-heading"
          *ngIf="stayDuration !== '' && category!=='hotel'"
        >
          (<i class="far fa-clock"></i> {{ stayDuration }})
        </div>
        <!-- <div>({{timezone}})</div> -->
      </div>
     
    </div>
    <div *ngIf="reason != ''" class="reason-text">
      <div class="spacer-1"></div>
      <div>{{ reason }}</div>
      
    </div>
    <div *ngIf="placeHighlight !== ''">
      <div class="spacer-1"></div>
      <div class="content-body text-justify" [innerHTML]="placeHighlight"></div>
    </div>
   
    <div *ngIf="tips.length>0"  class="place_tips_detail" [ngClass]="{'showTips':showTips}">
      <div class="spacer-1" style="height:8px"></div>
      <div class="tips_heading primary-text-color" (click)="showTips= !showTips">Tips <i class="fas " [ngClass]="showTips ? 'fa-angle-up':'fa-angle-down'"></i></div>
      <div class="tips_details">
          <ul>
            <li *ngFor="let item of tips">
              {{item}}
            </li>
          </ul>
      </div>
    </div>

    </div>
  </div>
</div>
