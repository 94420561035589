.tb_profile_bgimg {
  position: relative;
  height: 200px;
  padding-top: 50px;
  background-image: url("/assets/images/default-cover-pic.png");
  background-color: var(----grey300);
  background-size: cover;
  background-position: center;
  @include media-breakpoint-only(xs) {
    height: 180px;
  }
  @include media-breakpoint-only(sm) {
    height: 220px;
  }
  @include media-breakpoint-only(md) {
    height: 250px;
  }
}

.profile_showcase_role {
  position: absolute;
  bottom: -12px;
  width: 100%;
  z-index: 1;
}

.edit-profile {
  border-radius: 50% !important;
  color: var(--body-text-color) !important;
  background-color: var(--white) !important;
  border-color: var(--body-text-color) !important;
  border: 1px solid var(--body-text-color) !important;
  padding: 0px !important;
  width: 30px;
  height: 30px;
  line-height: 30px !important;
  text-align: center;
  @include media-breakpoint-down(sm) {
    width: 24px;
    height: 24px;
    line-height: 24px !important;
  }
}

.profile_role {
  font-size: 18px;
  color: var(--secondary-color);
  @include media-breakpoint-only(xs) {
    font-size: 14px;
  }
  @include media-breakpoint-only(sm) {
    font-size: 14px;
  }
}

.profile_detail {
  background-color: var(--white);
}

.profile_detail a,
.profile_detail i,
.profile_detail span {
  color: var(--body-text-color);
  word-break: break-word;
  //white-space: nowrap;;
  // text-overflow: ellipsis;
}

.profile_detail .editable-text input {
  width: 100px;
}

.tb_profile_image {
  position: relative;
  background: var(--white);
  height: 160px;
  width: 160px;
  @include border-radius(50%);
  margin-left: auto;
  margin-right: auto;
  border: 6px solid var(--white);
  z-index: 1;
  margin-top: -80px;
  background-position: center;
  background-size: cover;
  @include media-breakpoint-only(xs) {
    position: absolute;
    background: var(--white);
    height: 100px;
    width: 100px;
    border: 6px solid var(--white);
    margin-top: -72px;
  }
  @include media-breakpoint-only(sm) {
    width: 120px;
    height: 120px;
  }
  @include media-breakpoint-only(md) {
    margin-top: -90px;
  }
  .edit-profile {
    bottom: 8px;
    right: 8px;
    width: 26px;
    height: 26px;
    line-height: 26px !important;
    i {
      font-size: 12px;
    }
    @include media-breakpoint-down(sm) {
      bottom: 8px;
      right: -5px;
      width: 24px;
      height: 24px;
      line-height: 24px !important;
    }
  }
}

.account-outer {
  border: 1px solid var(--grey100);
  @include media-breakpoint-down(sm) {
    border: unset;
  }
}

.tb_profile_headg {
  text-align: center;
  .toggle_permission {
    display: inline-block;
    cursor: pointer;
    line-height: 42px;
  }
}

.tb_profile_username {
  font-size: 20px;
  text-transform: capitalize;
  display: inline-block;
  span {
    color: var(--body-text-color) !important;
  }
}

.tb_profile_account_info {
  text-align: center;
  @include media-breakpoint-only(xs) {
    /*margin-left: 28px;*/
    margin-left: 0px;
  }
}

.tb-userprof-info {
  display: inline-block;
  margin-left: 1px;
  @include media-breakpoint-only(xs) {
    display: inline-block;
    margin-left: 6px;
  }
}

.tb_profile_account_actions {
  margin: 10px 0px;
  display: inline-block;
}

.tb-profile-border {
  border-bottom: 1px solid var(--grey100);
}

.tb-profile-place-info {
  margin: 9px 0px 10px 0px;
  padding: 10px;
  @include media-breakpoint-only(xs) {
    padding: 10px 0px;
  }
  .tb-link-us-new {
    width: 26px;
    height: 26px;
    line-height: 26px;
    background: var(--body-text-color);
    i {
      color: var(--white);
    }
  }
  .edit-state {
    width: 16px;
    height: 16px;
    background-color: var(--white);
    border: 1px solid var(--body-text-color);
    border-radius: 50%;
    bottom: -4px;
    right: -6px;
    text-align: center;
    color: var(--body-text-color);
    i {
      font-size: 7px !important;
      position: absolute;
      right: 3px;
      line-height: 15px;
      color: var(--body-text-color) !important;
    }
  }
}

.tb_profile_follow {
  background-color: var(--white);
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  color: var(--body-text-color);
  font-size: 12px;
  // padding: 0px 10px;
  line-height: 30px;
  margin-bottom: 12px;
}

.tb_profile_location_info {
  font-size: 12px;
  word-wrap: break-word;
  i.fa-map-marker {
    // float: left;
    margin-right: 6px;
    line-height: 18px;
  }
  .phone-rotate {
    transform: rotate(100deg);
  }
}

.tb_profile_links_align a.editable.editable-click,
.tb_profile_links_align a {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 12px;
}

.tb_profile_links_align {
  // width: 50%;
  // float: left;
}

.content_holder {
  background-color: var(--white);
  margin: 10px 0px;
  -webkit-box-shadow: 0 2px 2px 0 var(--card-shadow),
    0 2px 5px 0 var(--card-shadow), 0 0px 1px var(--card-shadow);
  -moz-box-shadow: 0 2px 2px 0 var(--card-shadow),
    0 2px 5px 0 var(--card-shadow), 0 0px 1px var(--card-shadow);
  box-shadow: 0 2px 2px 0 var(--card-shadow) 0 2px 5px 0 var(--card-shadow),
    0 0px 1px var(--card-shadow);
}
.profile-trips {
  margin-bottom: 30px !important;
}
.profile_stats_hold {
  padding: 0 0.75rem;
}

.stacs {
  border-bottom: 2px solid var(--white);
  cursor: pointer;
  text-align: center;
  @include media-breakpoint-only(xs) {
    font-size: 11px;
  }
  .stacs_number.active {
    color: var(--primary-color);
  }
  .stacs_number {
    font-size: 18px;
    color: var(--body-text-color);
  }
  .stacs_name {
    color: var(--body-text-color);
    &.active {
      color: var(--primary-color);
    }
  }
}

.edit_icon_new {
  .edit-profile {
    font-size: 12px;
  }
}

.edit_icon_mob {
  @include media-breakpoint-only(xs) {
    margin: 18px;
  }
}

.edit_icon_new .tb-tripbtn {
  line-height: 24px;
  background-color: transparent;
  color: var(--white);
}

.edit_icon_new .tb-tripbtn {
  @include media-breakpoint-only(xs) {
    line-height: 21px;
    position: relative;
    left: 12px;
    top: 0px;
    height: 25px;
  }
}

.no_trips_new {
  /*background-color: var(--white)9eb;*/
  height: auto;
}

.buttons_new {
  float: left;
  width: 100%;
  text-align: center;
}

.btn_one {
  display: inline-block;
  margin: 0px 10px;
  @include media-breakpoint-only(xs) {
    text-align: center;
  }
}

.btn_one .tb-tripbtn {
  font-size: 17px;
  background-color: #ffb629;
  color: var(--white);
}

.btn_two .tb-tripbtn {
  font-size: 17px;
  background-color: #ffb629;
  color: var(--white);
}

.tb_profile_username {
  @include media-breakpoint-only(xs) {
    width: 110px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.share_size {
  font-size: 17px;
}

.sub-head-new {
  color: var(--body-text-color);
  font-size: 18px;
}

.profile_scraps .exp_holder {
  width: 180px;
}

.profile_scraps .exp_description {
  font-size: 9px;
}

.profile_scraps .tb_scrap_primary-button {
  padding: 0px 2px;
  font-size: 10px;
  line-height: 25px;
}

.tb-profile-bar {
  display: inline-block;
}

.tb-proflocationicon-style {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin: 0px 6px 4px 0px;
  color: var(--body-text-color);
  @include media-breakpoint-only(xs) {
    position: relative;
    display: inline-block;
    cursor: pointer;
    margin: 0px 6px 4px 5px;
    color: #9a9791;
  }
}

.tb-profile-links {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin: 0px 6px 4px 0px;
  color: var(--body-text-color);
}

.tb-profile-userinfo {
  font-size: 19px;
  color: var(--body-text-color);
  margin-bottom: 10px;
  text-align: center;
  @include media-breakpoint-only(xs) {
    font-size: 19px;
    margin-bottom: 10px;
  }
}

.tb-profile-athrdescription {
  font-size: 13px;
  margin-bottom: 10px;
  text-align: center;
  color: var(--body-text-color);
}

.tb-profile-identitiy-info {
  display: inline-block;
  cursor: pointer;
  margin: 0px 8px 4px 0px;
  color: var(--body-text-color);
}

.tb-profile-contactinfo {
  text-align: center;
  background-color: var(--primary-color);
  color: var(--white);
}

.tb-profile-contactalign {
  text-align: center;
  margin: 7px 0px 7px 0px;
}

.tb_profile_editimg {
  float: right;
  top: -48px;
  margin-right: 2px;
  position: relative;
}

.tb_scrap_primary-button {
  border: 1px solid var(--grey100);
  background-color: var(--white);
  border-color: rgb(255, 182, 41);
  color: var(--body-text-color);
  /*   border-radius: 5px;*/
  @include border-radius(5px);
  font-size: 12px;
  padding: 0px 10px;
  line-height: 24px;
  margin-top: 2px;
}

.idprof {
  width: 120px;
  height: 120px;
  position: relative;
  float: left;
}

.expert-outer {
  height: auto;
}

.expert-outer .chip {
  display: inline-block;
  height: 27px;
  font-size: 13px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
  line-height: 27px;
  padding: 0 12px;
  border-radius: 16px;
  background-color: #e4e4e4;
}

.expert-outer .chip i.cls {
  cursor: pointer;
  float: right;
  font-size: 14px;
  line-height: 27px;
  font-weight: 100;
  padding-left: 8px;
}

.close-edit-new {
  font-size: 14px !important;
  @include media-breakpoint-only(xs) {
    font-size: 10px !important;
  }
}

.cover-pic-profile {
  position: absolute;
  top: 10px;
  right: 5px;
  @include media-breakpoint-down(sm) {
    right: 0px;
  }
  .text-col-shadow {
    color: var(--body-text-color);
    text-shadow: 0px 3px 9px rgba(255, 255, 255, 0.8);
  }
}

.icon-width-new {
  width: 12px;
}

.profile-inline-edit {
  #inlineEditWrapper {
    margin-bottom: 5px !important;
    width: 100% !important;
    word-wrap: break-word;
    .c-inline-editor.inlineEditForm {
      width: 100% !important;
    }
    .c-inline-editor {
      line-height: unset !important;
      margin-left: unset !important;
      @include media-breakpoint-only(md) {
        margin-left: 5px !important;
      }
    }
  }
  inline-editor-textarea {
    width: 100% !important;
  }
  inline-editor-text {
    width: 100% !important;
  }
  .form-group input {
    display: block !important;
  }
  .form-inline .input-field {
    width: 100% !important;
  }
}

.yellow {
  background: var(--primary-color);
}

.yellow-text {
  color: var(--primary-color);
}

.pink {
  background: var(--secondary-light);
}

.blue {
  background: var(--blue);
}

.gray-lighter {
  background: #ededed;
}

.dashboard-new .profile_stacs .stacs {
  width: unset;
  position: unset;
  float: none;
}

.width-dashboard-new {
  width: 280px;
  margin: 0 auto;
  padding: 10px 0px;
}

.number-dashboard {
  font-size: 16px;
}

.stacs_number_new {
  font-size: 18px;
  @include media-breakpoint-only(xs) {
    font-size: 14px;
    padding: 10px 5px;
  }
  @include media-breakpoint-only(md) {
    padding: 10px 5px;
  }
}

.profile_stacs .stacs .stacs_number_new.selected-active {
  color: var(--primary-color);
}

.profile_stacs .stacs .stacs_number.active {
  color: var(--primary-color);
}

.profile-name-edit {
  font-size: 14px;
  @include media-breakpoint-only(xs) {
    font-size: 13px;
  }
}

.profile-exp {
  @include media-breakpoint-only(xs) {
    .profile-trips:nth-child(odd) {
      padding-right: 0px !important;
    }
    .profile-trips:nth-child(even) {
      padding-left: 0px !important;
    }
  }
}

.inline-new-outer {
  left: 0px;
  top: 45px;
  z-index: 5;
  background: var(--white);
  padding: 5px;
}

.profile-notrip {
  .no-places-bag {
    background-image: unset;
    padding-top: 10px;
    img {
      height: 150px;
    }
  }
}
.trip_options {
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
  .trip_tab {
    padding: 5px;
    cursor: pointer;
    &.active {
      color: var(--primary-color);
    }
  }
}
