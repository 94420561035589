.activity-content-heading {
    font-weight: 400;
    font-size: 18px;
    line-height: normal;
    color: var(--body-text-color) !important;
    @include media-breakpoint-only(xs) {
        font-size: 15px;
        line-height: 25px;
    }
}
.activity-body-heading {
    font-size: 15px;
    color: var(--body-text-color);
    font-weight: 300;
    line-height: 25px;
    i {
    font-style: normal;
    font-size: 15px;
    color: var(--body-text-color);
    font-weight: 300;
    line-height: 25px; 
    }
    div {
        margin-bottom: 1rem;
    }
     ul {
    font-size: 15px;
    color: var(--body-text-color);
    font-weight: 300;
    line-height: 25px;
    margin-bottom: 1rem;
    li {
    padding-left: 10px;
    margin-left: 15px;
    list-style-type:disc;
    }
    @include media-breakpoint-only(xs){
    font-size: 14px;
    line-height: 24px;
    }
   

    }

    
    b , strong{
    font-weight: 400;
    font-size: 18px;
    line-height: normal;
    color: var(--body-text-color) !important;
    @include media-breakpoint-only(xs) {
        font-size: 15px;
        line-height: 25px;
    }  
    }
}
.activity-list {
    font-size: 15px;
    color: var(--body-text-color);
    font-weight: 300;
    line-height: 25px;
    
    li {
    padding-left: 10px;
    margin-left: 15px;
    list-style-type:disc;
    }
    @include media-breakpoint-only(xs){
    font-size: 14px;
    line-height: 24px;
    }
}

.activity-margin-bottom {
    margin-bottom:1rem;
    ul {
    font-size: 15px;
    color: var(--body-text-color);
    font-weight: 300;
    line-height: 25px;
    margin-bottom: 1rem;
    li {
    padding-left: 10px;
    margin-left: 15px;
    list-style-type:disc;
    }
    @include media-breakpoint-only(xs){
    font-size: 14px;
    line-height: 24px;
    }
}
}
#activity-expect-section {


    ul {
    font-size: 15px;
    color: var(--body-text-color);
    font-weight: 300;
    line-height: 25px;
    margin-bottom: 1rem;  
    @include media-breakpoint-only(xs){
    font-size: 14px;
    line-height: 24px;
    }
    }
}
.activity-option-wrapper {
    border:1px solid var(--grey200);
    border-radius :6px;
    margin-top:30px;
    

}
@media only screen and (max-width:1024px) {
    .activity-option-wrapper { 
        margin-top:20px;
        .bordermid {
            border:0px solid var(--grey200);
            padding-bottom:0px;
            margin-bottom:0px;
        }
    }
    
} 