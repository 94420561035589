import { Component, ElementRef, EventEmitter, inject, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageComponent } from '../image/image.component';
import { GeneralService } from '../../../core/general.service';
import { RouterModule } from '@angular/router';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } 
    from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FilestackService } from '@filestack/angular';
import {AutosizeModule} from 'ngx-autosize';
import { TypingPlaceholderDirective } from '../../../directives/typing-placeholder.directive';
import { ClickOutsideDirective } from '../../../directives/click-outside.directive';
import { environment } from '../../../../environments/environment';
import { SvgIconDirective } from '../../../directives/svg-icon.directive';
@Component({
  selector: 'day-places',
  standalone: true,
  imports: [
    CommonModule, ImageComponent, RouterModule,NgbTooltipModule,TypingPlaceholderDirective,
    ClickOutsideDirective,
    FormsModule,
    AutosizeModule,
    SvgIconDirective
  ],
  providers:[FilestackService],
  templateUrl: './day-places.component.html',
  styleUrls: ['./day-places.component.scss'],
})
export class DayPlacesComponent implements OnInit {
  @Input() place: any;
  @Input() tripUrl: any;
  @Input() editable:boolean=false;
  @Output() updatePlace: EventEmitter<any> = new EventEmitter();

  @ViewChild('textInput') textInput!: ElementRef;

  @ViewChild('editTripTitle', { static: false }) editTripTitle!: TemplateRef<any>;
  
  private modalService = inject(NgbModal);

  private readonly apiKey:string=environment.filestack;
  private filestack:any;
  placeholders:any=["Change place","Change day"];
  arrivalTime: any;
  placeTitle:string='';
  arrivalDateTime: any;
  stayDuration: string = '';
  hub: string = '';
  placeHighlight: string = '';
  locationString: string = '';
  image: string = '';
  category: string = '';
  categoryIcon: string = '';
  placeUrl: string = '';
  rating: any = [];
  reason: string = '';
  place_address: string = '';
  timezone: string = '';
  tips: any = [];
  showTips: boolean=false;
  showEditor:boolean=false;
  showEditMenu:boolean=false;
  parentId:string='';
  enablePlaceEdit:boolean=false;
  showFullText: boolean = false;
  constructor(
    private generalService: GeneralService,
    private sanitizer: DomSanitizer,
    private filestackService: FilestackService
  ) {}

  ngOnInit() {
    this.checks();
    if(this.editable){
      this.filestack=this.filestackService.init(this.apiKey,{
        proxy: true,
      });
    }
    if(this.place['parent']){
      this.parentId=this.place['parent']['_id'];
      this.enablePlaceEdit=true;
    }
  }

  checks() {
    const categoryMaping: any = {
      h: { name: 'hotel', icon: 'hotel' },
      p: { name: 'points-of-interest', icon: 'location' },
      r: { name: 'restaurant', icon: 'restaurant' },
      a: { name: 'activity', icon: 'skiing' },
      t: { name: 'hub', icon: 'destinations' },
      hc: { name: 'hub', icon: 'destinations' },
      fl: { name: 'airport', icon: 'airport' },
      ts: { name: 'airport', icon: 'airport' },
      s: { name: 'shopping', icon: 'shopping-cart' },
      default:{name:'-',icon:'location'}
    };
    this.arrivalTime = this.place.hasOwnProperty('arrival_time')
      ? this.place['arrival_time']
      : '';
    this.arrivalDateTime = this.place.hasOwnProperty('arrival_date_time')
      ? this.place['arrival_date_time']
      : '';

    const stayDuration =
      this.place.hasOwnProperty('duration') && this.place['duration']
        ? this.place['duration']
        : 0;
    if (stayDuration) {
      this.stayDuration = this.secondsToHHMM(stayDuration);
    }
    this.hub = this.place.hasOwnProperty('hub') ? this.place['hub'] : '';
    this.locationString = this.place.hasOwnProperty('locationString')
      ? this.place['locationString']
      : '';

    if (this.place.hasOwnProperty('image')) {
      this.image = this.place['image'];
    }else if (this.place.hasOwnProperty('parent') && this.place['parent']) {
      if (this.place['parent'].hasOwnProperty('experience_media')) {
        const placeImages = this.place['parent']['experience_media'];
        let imgRate = 0;
        let imgPath: string = '';
        placeImages.forEach((img: any) => {
          if (img['active']) {
            if (img['imgRate'] > imgRate) {
              imgRate = img['imgRate'];
              imgPath = img['path'];
            }
          }
        });
        this.image = imgPath;
      }
    }

    this.placeTitle = (this.place['title'])?this.place['title'] : this.place['name'];
    if (
      this.place.hasOwnProperty('place_type') &&
      categoryMaping[this.place['place_type']]
    ) {
      this.category = categoryMaping[this.place['place_type']].name;
      this.categoryIcon = categoryMaping[this.place['place_type']].icon;
    } else {
      this.category = categoryMaping['default'].name;
      this.categoryIcon = categoryMaping['default'].icon;
    }
    this.placeHighlight = this.place.hasOwnProperty('description')
      ? this.stripHtml(this.place['description'])
      : '';

    const rating = this.place.hasOwnProperty('rating')
      ? this.place['rating']
      : 0;
    this.tips = this.place.hasOwnProperty('tips') ? this.place['tips']:[] ;

    this.rating = Array(Math.ceil(rating)).fill(0);
    this.placeUrl =
      this.place.hasOwnProperty('parentURL') && this.category !== 'hotel'
        ? this.place['parentURL']
        : '';

    this.reason = this.place.hasOwnProperty('reason')
      ? this.place['reason'].replace(/['"]+/g, '')
      : '';
    //if(['hotel','points-of-interest','restaurant','activity','shopping'].includes(this.category)){
      this.place_address = this.place.hasOwnProperty('place_address')
      ? this.place['place_address'].replace(/['"]+/g, '')
      : '';
    //}

    this.timezone = this.place.hasOwnProperty('timezone')
      ? this.place['timezone']
      : '';
  }

  secondsToHHMM(seconds: number): string {
    const hours: number = Math.floor(seconds / 3600);
    const minutes: number = Math.floor((seconds % 3600) / 60);
    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')} hrs`;
  }

  get truncatedText(): String {
    if (!this.placeHighlight) return '';
    
    const text = this.placeHighlight.toString();
    if (text.length > 100 && !this.showFullText) {
      return text.substring(0, 100) +'...'; 
    }
    
    return this.placeHighlight;
  }
  
  toggleText() {
    this.showFullText = !this.showFullText;
  }

  getPlaceDetails() {
    const req = {
      requestMessage: 'identifyScrap',
      requestId: '888',
      apiKey: '',
      requestData: [
        {
          name: this.place['name'],
          coordinates: this.locationString,
          hub: this.hub,
        },
      ],
    };
    const url = 'trip/identifyScraps/' + this.tripUrl;
    this.generalService.postMethod(url, req).subscribe(
      (value: any) => {
        console.log(value);
      },
      (error: any) => {
        console.log('there is an error in this 175');
        console.log(error);
      }
    );
  }
  stripHtml(html: string) {
    console.log(' stripHtml');
    console.log(html);

    if (html)
      return html.replace(/<[^>]*>/g, '');
    else return '';
  }

  showPrompt(){
    this.showEditor=!this.showEditor;
    setTimeout(() => this.textInput?.nativeElement?.focus(), 300);
  }
  placeEdit(userForm:any,event:Event){
    event.preventDefault();
    console.log("form submitted");
    console.log(userForm.form.value.user_prompt);
    const user_prompt=userForm.form.value.user_prompt;
    if(user_prompt && user_prompt.length>5){
      console.log(this.place);
      //let editPrompt ='Edit '+this.place['name']+' as per user requirement.\n';
      const editPrompt =userForm.form.value.user_prompt.trim();
      const edit={
        "editType":"custom",
        "detail":this.place,
        "value":editPrompt
      };
      this.updatePlace.emit(edit);
    }
  }

  editTitle(){
    const modalRef=this.modalService
      .open(this.editTripTitle, { ariaLabelledBy: 'modal-basic-title' });
      
      modalRef.result.then(
        (result) => {
         console.log(result.form.value);
         const title=result.form.value.title.trim();
         if(title!=''){
          this.updatePlaceDetails('newTitle',title);
         }
         //console.log(userForm.form.value.user_prompt);
        },
        (reason) => {
          //console.log(`Dismissed ${this.getDismissReason(reason)}`);
        }
      );
  }

  updatePlaceImage(){
    if(this.editable){
      console.log("open File Uploader");
    const parentId=this;
    const options={
      maxFiles: 1,
      accept: ['image/*'],
      fromSources: ['local_file_system'],
      uploadInBackground: false,
      onOpen: () => console.log('opened!'),
      onUploadDone: (res:any) => {
        console.log(res);
        if(res.filesUploaded && res.filesUploaded.length>0){
          const path=res.filesUploaded[0]['handle'];
          this.updatePlaceDetails('newImage',path);
        }
      },

    };
    this.filestack.picker(options).open();
    }
  }

  updatePlaceDetails(keyValue:any,value:any){
    const url='trip/updateTripMetadata/'+this.tripUrl;
    let req={
        "parentId" : this.parentId,
        [keyValue]:value
    };
    this.generalService.postMethod(url,req).subscribe(
      (data)=>{
        if(data['response']=='success' && data['result']=='yes'){
          const result={
            "editType":"trip",
            "detail":'',
            "value":data
          };;
          this.updatePlace.emit(result);
        }
      },
      (error)=>{
        
      }
    );
  }
  
}
