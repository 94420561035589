.time-new {
    height: 40px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.time-scroll {
    height: 320px;
    overflow: auto;
    overflow-x: hidden;
}
.change_day_hidden{
    position: absolute;
    top: 0px;
    opacity: 0;
    width: 100%;
}
.show_full_map_view{
    position: fixed;
    top:0px;
    width:100%;
    height:100vh;
    z-index:10000;
    background:var(--white);
    .map_navigation_bar{
        height:60px;
    }
    .iti_place_holder{
        height: calc(100vh - 60px);
        width:100%;
        .map{
            height:100%;
            width:100%;
        }
    }
}