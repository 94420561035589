.tbMap{
  display: flex;
  padding:8px;
  .map_image{
    position: relative;
    min-width:40px;
    height:30px;
    margin-right: 4px;
  }
  .map_title{
    position: relative;
    flex-grow: 1;
    height:30px;
    overflow: hidden;
  }
}
