.mtb_10 {
  margin: 10px 0px;
}
.no_padding {
  padding: 0px;
}
.pointer {
  cursor: pointer;
}
.fw_l {
  float: left;
  width: 100%;
}
.map_span {
  float: left;
  margin-left: 10px;
}
.space_evenly {
  justify-content: space-evenly !important;
}

.border-right-none {
  border-right: none !important;
}

.border-none {
  border: none !important;
}

.tb-multipleplanrow {
  background: var(--light-cream);
  height: 80px;
  h1 {
    margin-bottom: 6px !important;
    font-size: 22px;
    font-weight: 300;
    color: var(--body-text-color);
    @include media-breakpoint-down(sm) {
      font-size: 14px;
    }
  }
  h2 {
    margin-top: 6px !important;
    font-size: 18px;
    color: var(--body-text-color);
    font-weight: 300;
    @include media-breakpoint-down(sm) {
      font-size: 13px;
    }
  }
  .location_name_holder {
    font-size: 16px;
    color: var(--body-text-color);
    line-height: 80px;
    span {
      text-transform: lowercase;
    }
    @include media-breakpoint-only(xs) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.top_level_menu-holder {
  .tb-multiple-plan-bar-new-holder {
    display: inline-block;
    border: 1px solid var(--grey100);
    margin: 15px 0px 10px 0px;
    height: auto;
    font-size: 0;
  }
  .tb-multiple-plan-bar {
    cursor: pointer;
    text-transform: uppercase;
    font-size: 13px;
    line-height: 9px;
    display: inline-block;
    border-right: 1px solid var(--grey100);
    padding: 14px 50px;
    color: var(--body-text-color);
    a {
      text-decoration: none;
      color: var(--body-text-color);
    }
  }
  .tb-multiple-plan-bar.selected_active {
    background-color: var(--grey100);
    font-weight: 400;
    @include media-breakpoint-only(xs) {
      background: var(--grey100);
    }
  }
}

.horizontal_scroll_holder {
  position: relative;
  overflow-x: scroll;
  overflow-y: visible;
  white-space: nowrap;
  text-align: center;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}

.horizontal_scroll_holder::-webkit-scrollbar {
  display: none;
}

.categories_nav_bar_list {
  float: left;
  .categories_nav_bar_item {
    display: inline-block;
    .restaurant-img {
      color: var(--body-text-color);
      padding-right: 10px;
      margin-right: 10px;
      display: inline-block;
      line-height: 16px;
    }
    .restaurant-img img {
      display: inherit !important;
      width: 16px !important;
      float: left;
      margin-right: 3px;
      margin-top: -4px;
      height: 23px !important;
    }
  }
}

.categories_nav_bar_item .restaurant-img.selected_active {
  color: var(--secondary-light);
}

.tb-mob-map-scrap-view {
  float: right;
  padding: 0px 0px;
  .icons-map-scrap-new {
    position: relative;
    top: -2px;
    height: 23px !important;
  }
  .active.map_scrap_button i {
    color: var(--primary-color);
  }
}
.accordion {
  .tb-mob-map-scrap-view {
    float: right;
    padding: 0px 0px;
  }
}

.map_scrap_button {
  color: var(--body-text-color);
  font-size: 14px;
  cursor: pointer;
  svg {
    color: var(--primary-color);
  }
}

.results_found_row {
  padding: 10px 0px !important;
  border-top: 1px solid var(--grey100);
  .tb-mob-filter-view {
    display: inline-block;
    margin-right: 15px;
    vertical-align: bottom;
    position: relative;
  }
  .filter_button svg {
    color: var(--primary-color);
  }
  .input-tb-categorysearchbar-bar {
    font-size: 22px !important;
    background-color: var(--white);
    .input-field {
      margin-top: 0px !important;
      position: relative;
      .tb-event-searchterm {
        float: left;
        font-size: 13px !important;
        font-weight: 100 !important;
        border-bottom: 1px solid var(--grey100) !important;
        border: none;
      }
    }
    .input-field input {
      margin-bottom: 0px !important;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100% !important;
      padding-right: 22px !important;
    }
  }
}

.angular-input-stars {
  display: inline-block;
  padding: 0;
  margin-left: 0px;
  list-style: none;
  li {
    padding: 0px 0px !important;
    padding: 0px 0px !important;
    font-size: 10px !important;
    width: 15px;
    color: var(--primary-color) !important;
    display: inline-block;
  }
}

.experiences {
  width: 100%;
  text-align: center;
}

.experiences_inline {
  display: block;
}

.exp_holder {
  border-radius: 4px;
  position: relative;
  width: 100%;
  margin: 0px 0px 0px 0px;
  background-color: var(--white);
  display: inline-block;
  opacity: 1;
  text-align: left;
  animation: display 0.2s forwards;

  @include media-breakpoint-only(xs) {
    margin: 0px 0px 0px 0px;
  }
  .board_trip_selection {
    display: block;
    width: 100%;
    text-align: right;
  }
  .scrap_trip_add.add_to_trip {
    color: var(--body-text-color);
  }
  .scrap_trip_add {
    background: var(--white);
    color: var(--secondary-light);
    text-align: center;
    width: 100%;
    font-size: 12px;
    padding: 8px 5px;
    text-transform: uppercase;
    font-weight: normal;
  }
  .score_km_explore {
    height: 28px;
    line-height: 32px;
    font-size: 12px !important;

    @include media-breakpoint-down(sm) {
      font-size: 10px !important;
    }
  }
  .showAvilability {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    color: var(--white);
  }
  .tbevent-date {
    position: absolute;
    width: 100%;
    /* left: 0px; */
    background: #2d98a5cf;
    text-align: center;
    color: var(--white);
    font-size: 15px;
    padding: 0px 11px;
    height: 36px;
    line-height: 0px;
    z-index: 1;
    top: calc(100% - 152px);
    @include media-breakpoint-down(md) {
      font-size: 15px;
      top: calc(100% - 148px) !important;
    }
    @include media-breakpoint-only(xs) {
      font-size: 15px;
      top: calc(100% - 145px) !important;
    }
  }
  .exp_title {
    line-height: 20px;
    height: 25px;
    width: 100% !important;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block !important;
    width: 100% !important;
    text-overflow: ellipsis;
    .truncate {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @include media-breakpoint-only(xs) {
        padding: 0px;
        width: 100%;
      }
    }
    a {
      color: var(--body-text-color) !important;
    }
    .external_link {
      float: left;
      width: 20%;
      color: var(--body-text-color);
      text-align: right;
      font-weight: normal;
      font-size: 12px;
      font-weight: 300;
      .ex_link {
        display: none;
      }
    }
  }
  .check_avil {
    cursor: pointer;
    display: block;
    margin: 0px auto;
  }
  .image_holder {
    position: relative;
    text-align: center;
    z-index: 1;
    width: 100%;
  }
  .icons_holder {
    height: 23px;
    line-height: 23px;
    .hotel_score {
      color: var(----grey300);
    }
    @include media-breakpoint-only(xs) {
      font-size: 14px !important;
    }
  }
  .tb-scraps-weblink {
    line-height: 20px;
    height: 20px;
    @include media-breakpoint-only(xs) {
      line-height: 19px;
      height: 18px;
    }
  }
  .exp-tb-logo {
    height: 15px;
    @include media-breakpoint-only(xs) {
      height: 11px;
    }
  }
}

.exp_title:hover .ex_link1 {
  display: none;
}

.exp_title:hover .external_link .ex_link {
  display: block;
}

.tb-scraps-weblink {
  .tb-scrap-weblink-align {
    line-height: 30px;
  }
  .experience_icons {
    line-height: 30px;
    color: var(----grey300);
    float: right;
    margin-left: 10px;
    .tb-navlocation {
      height: 18px;
    }
  }
}

.shadow-hover {
  position: relative;
  transition: all 0.3s ease;
}

.wh_100 {
  height: 100%;
  width: 100%;
}

.exp_holder_inner {
  width: 100%;
  -webkit-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05),
    0 1px 1px 0 rgba(0, 0, 0, 0.05), 0 0px 1px 0 rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05),
    0 1px 1px 0 rgba(0, 0, 0, 0.05), 0 0px 1px 0 rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05), 0 1px 1px 0 rgba(0, 0, 0, 0.05),
    0 0px 1px 0 rgba(0, 0, 0, 0.05);
}

.truncate_100,
truncate_80 {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate_80 {
  width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.shadow {
  box-shadow: 0 0.1rem 1rem rgba(0, 0, 0, 0.1) !important;
}

.categories_nav_bar .categories_nav_bar_item:last-child .restaurant-img {
  border-right: none;
}
/* NEW ADD TO TRIP */

.add_to_trip_holder_new {
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 1;
  .add_trip_circle {
    text-align: center;
    width: 30px;
    height: 30px;
    line-height: 28px;
    color: var(--white);
    cursor: pointer;
    border-radius: 50%;
    border: 1px solid var(--white);
    transition: 0.1s linear;
    background: rgba(128, 128, 128, 0.5);

    &:hover {
      background: rgba(128, 128, 128, 0.5);
      color: var(--white);

      -ms-transform: scale(1.1);
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
    i {
      font-size: 12px;
    }
    @include media-breakpoint-down(sm) {
      width: 30px;
      height: 30px;
      line-height: normal;
      font-size: 12px;
      display: flex !important;
      align-items: center;
      justify-content: center;
    }
  }
  .add_to_trip_circle {
    // background: var(--white);
    display: block;
    @include media-breakpoint-down(md) {
      display: block;
    }
  }
  .remove_from_trip_circle {
    background: var(--primary-color);

    color: var(--white);
    &:hover {
      background: var(--primary-color);

      color: var(--white);
      transform: scale(1.1);
    }
  }
  .adding_trip {
    background: var(--white);
    color: var(--body-text-color);
    // animation: spin 2s infinite linear;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(1turn);
  }
}

.exp_holder:hover .add_to_trip_circle,
.map_scrap:hover .add_to_trip_circle {
  display: block;
}

.travel-recommend-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 105px;
}

.exp_holder .showAvilability {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  color: var(--white);
  z-index: 2;
}

.exp_holder .showAvilability .avilability_close {
  height: 30px;
  widows: 30px;
  text-align: center;
  line-height: 20px;
  padding: 5px;
  i {
    font-size: 18px;
  }
}

.exp_holder .showAvilability .avilability_holder {
  margin-top: 20px;
}

.exp_holder .showAvilability input.datepicker {
  height: 30px !important;
  line-height: 30px !important;
  width: 48%;
  float: left;
  margin: 0px 1%;
  text-align: center;
}

.exp_holder .showAvilability .latest_price {
  font-size: 12px;
  padding: 5px 0px;
}
.small_rating {
  font-size: 12px;
}
.exp_holder .showAvilability .latest_price span {
  font-size: 14px;
}

.exp_holder .showAvilability .avilability_result {
  font-size: 14px;
  text-transform: uppercase;
  color: #ffb629;
}

.exp_holder .showAvilability.not_avilable {
  font-size: 13px;
}

#boxd {
  position: fixed;
  z-index: 104;
  overflow: auto;
  top: 0px;
  left: -100%;
  width: 100%;
  opacity: 0;
  padding: 20px 0px;
  height: 100%;
  /* background-color: var(--white);*/
  color: var(--body-text-color);
  transition: all 350ms cubic-bezier(0.6, 0.05, 0.28, 0.91);
}

#boxd.active {
  left: 0px;
  opacity: 1;
  z-index: 1050;
}

.mobile-filter-new {
  #items {
    width: 100%;
    margin-left: unset;
    overflow-y: scroll;

    color: var(--white);
  }
}

.new-map-view .explore-text {
  height: 60px;
  @include media-breakpoint-only(xs) {
    height: 65px;
  }
}

.explore-text {
  height: 70px;
  @include media-breakpoint-only(xs) {
    height: 64px;
  }
  h1 {
    margin-bottom: 0px !important;
    font-size: 16px;
    text-align: center;
    font-weight: 300;
    color: var(--body-text-color);
    @include media-breakpoint-only(xs) {
      font-size: 13px;
      text-align: justify;
      padding-bottom: 0px;
      line-height: 17px;
    }
  }
}

.categories-new-view {
  background: var(--white) !important;
  height: 75px;
  @include media-breakpoint-only(xs) {
    height: 55px;
  }
  .map_span {
    padding: 0px 10px 0px 10px;
    margin-left: unset;
  }
  .navbar {
    height: 50px !important;
    background: transparent;
    z-index: 0 !important;
  }
  a#navbarDropdown {
    color: var(--body-text-color);
  }
  a.nav-link {
    color: var(--body-text-color) !important;

    text-transform: uppercase;
    margin: 0px 0px 0px 0px;
    padding: 0px !important;
  }
  a.nav-link.active {
    color: var(--primary-color) !important;
    opacity: 1;
  }
}

.filter-new-holder {
  .filter-new {
    border: 1px solid var(--light-cream);
    font-size: 12px;
    padding: 3px 5px 3px 5px;
    margin: 0px 7px 0px 0px;
    @include media-breakpoint-only(xs) {
      margin: 3px 5px 3px 0px;
    }
    @include media-breakpoint-only(sm) {
      padding: 6px;
      margin: 0px 10px 0px 0px;
    }
  }
}

.border-filter-new {
  border-bottom: 1px solid var(--grey100);
  @media only screen and (max-width: 991px) {
    border-bottom: 0px solid var(--grey100);
  }
}

.sort-filter {
  margin: 0px 10px 0px 0px;
  @include media-breakpoint-only(xs) {
    margin: 8px 8px 8px 0px;
  }
}

.filter-new-holder {
  .filter-new.selected-active {
    background: var(--light-cream);
    a {
      color: var(--body-text-color) !important;
    }
  }
}

.speciality-truncate select {
  width: 115px !important;
  @include media-breakpoint-only(xs) {
    width: 132px !important;
  }
}

.padding-experience {
  @include media-breakpoint-only(xs) {
    padding: 5px !important;
  }
}

.location-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mapmodal-wid {
  max-width: 100% !important;
  .modal-content {
    border-radius: unset;
  }
}

.dropdown-new-select {
  margin: 0px 10px 0px 0px;
  @include media-breakpoint-only(xs) {
    margin: 3px 5px 3px 0;
  }
}

.multi-dropdown {
  margin-right: 10px;
  @include media-breakpoint-only(xs) {
    margin-right: unset;
    display: flex;
    align-items: center;
  }
}

.main-title:after {
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  font: 16px "Consolas", monospace;
  color: var(--body-text-color);
  right: 13px;
  top: 15px;
  padding: 0 0 2px;
  position: absolute;
  pointer-events: none;
  @include media-breakpoint-down(sm) {
    border-top: 0.2em solid;
    border-right: 0.2em solid transparent;
    border-left: 0.2em solid transparent;
  }
}

.down-arrow:after {
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  font: 22px "Consolas", monospace;
  color: var(--body-text-color);
  right: 11px;
  top: 8px;
  padding: 0 0 2px;
  position: absolute;
  pointer-events: none;
  transform: rotate(180deg);
  @include media-breakpoint-down(sm) {
    border-top: 0.2em solid;
    border-right: 0.2em solid transparent;
    border-left: 0.2em solid transparent;
  }
}

// Google like filter
.filter_new {
  margin: 20px 0px 10px !important;
  @include media-breakpoint-down(sm) {
    margin: 10px 0px 0px !important;
  }
  .custom_filter {
    position: relative;
    margin-right: 8px;
    margin-bottom: 8px;
    border-bottom: 5px;
    cursor: pointer;
    color: var(--body-text-color);
    &.active {
      background: var(--grey100);
      border-radius: 6px;
    }
    &.custom_link {
      border: none;
      color: var(--primary-color);
    }
    .custom_filter_item {
      border: 1px solid var(--grey200);
      border-radius: 6px;
      padding: 10px 20px;
      @include media-breakpoint-only(xs) {
        border: 1px solid var(--grey200);
        border-radius: 6px;
        padding: 7px 6px;
      }
    }
    @include media-breakpoint-only(xs) {
      margin-right: 5px;
      margin-bottom: 5px;
    }
  }
  label {
    margin-bottom: 0px;
  }
}
// Filters new ui
.filters_multi_dropdown {
  margin-right: 10px;
  min-width: 150px;
  @include media-breakpoint-down(sm) {
    display: flex;
    align-items: center;
  }
  .dropbtn {
    width: 100%;
    color: var(--body-text-color);
    line-height: 18px;
    padding: 10px 20px;
    border: none;
    border: 1px solid var(--grey200);
    margin-left: 7px;
    border-radius: 0.6rem !important;
    @include media-breakpoint-only(xs) {
      min-width: 122px;
      color: var(--body-text-color);
      line-height: 18px;
      padding: 7px 16px;
      border: none;
      border: 1px solid var(--grey200);
    }
  }
  .dropdown {
    position: relative;
    display: inline-block;
    width: 100%;
    @include media-breakpoint-only(xs) {
      min-width: 122px;
    }
  }
  .dropdown-content {
    position: absolute;
    margin-left: 0.5rem;
    left: 0rem;
    background-color: var(--white);
    min-width: 153px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 8;
    border-radius: 6px;
    @include media-breakpoint-only(xs) {
      margin-left: 7px;
    }
  }
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  .dropdown-content a:hover {
    background-color: var(--white);
  }
  .checkbox-custom {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 20px;
    height: 20px;
    line-height: 19px;
    border-radius: 3px;
    margin-right: 10px;
    border: 1px solid var(--grey200);
    i {
      font-size: 10px;
      padding-top: 2px;
      display: none;
      color: var(--white);
    }
  }
  .checkbox-custom.active {
    background: var(--primary-color);
    border: 1px solid var(--primary-color);
    i {
      display: block;
    }
  }
  .custom-label {
    font-size: 12px;
  }
}

// experience-new
.new-exp-outer {
  animation: display 0.2s forwards;
  box-shadow: var(--card-shadow) 0px 4px 10px 0px;
  z-index: 1;
  img {
    width: 100%;
  }
  @include media-breakpoint-down(sm) {
    margin: 0px 0px 15px 0px;
  }
  .new-exp-des-outer {
    color: var(--body-text-color);
    font-size: 14px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    height: 60px;
    min-height: 60px;
    text-align: center;
    padding: 0px 10px;
    @include media-breakpoint-down(sm) {
      font-size: 12px;
      padding: 10px 10px;
    }
  }
}

.text-col-bag {
  position: relative;
  background-image: url(/assets/images/slides/d2.webp);
  background-position: center;
  background-size: cover;
  padding: 25px 0px;
  &:before {
    position: absolute;
    content: "";
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.9);
  }
  .text-col {
    font-size: 16px;
    font-weight: 500;
    z-index: 1;
    margin-bottom: 20px;
  }
}

.top-exp {
  justify-content: space-between;
  @include media-breakpoint-down(sm) {
    justify-content: center;
  }
}

.expnew-slider {
  .swiper-wrapper {
    padding: 20px 0px 20px 0px;
    display: flex;
    align-items: center;
    @include media-breakpoint-down(sm) {
      padding: 0px;
      align-items: unset;
    }
  }
  .swiper-slide {
    width: 28%;
    @include media-breakpoint-only(xs) {
      width: 68%;
    }
    @include media-breakpoint-only(sm) {
      width: 45%;
    }
    @include media-breakpoint-only(md) {
      width: 35%;
    }
  }
}

.expnew-slider-top-places {
  .swiper-wrapper {
    padding: 0px 0px 20px 0px;
    display: flex;
    align-items: center;
    @include media-breakpoint-down(sm) {
      align-items: unset;
      padding: 0px;
    }
  }
  .swiper-slide {
    width: 20%;
    @include media-breakpoint-only(xs) {
      width: 68%;
    }
    @include media-breakpoint-only(sm) {
      width: 40%;
    }
    @include media-breakpoint-only(md) {
      width: 30%;
    }
  }
}

.exp-video-outer {
  .exp_holder:hover {
    .playbutton {
      display: block;
    }
    .imgnew-hold:before {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
  .exp_holder {
    margin: 0px;
    @include media-breakpoint-down(sm) {
      margin: 0px 0px 15px 0px;
    }
  }
  .playbutton {
    position: absolute;
    top: 46%;
    left: 42%;
    width: 50px;
    height: 50px;
    line-height: 50px;
    background: var(--white);
    border-radius: 50%;
    text-align: center;
    display: none;
    @include media-breakpoint-down(sm) {
      display: block;
      background: var(--body-text-color);
      i {
        color: var(--white);
        font-size: 10px;
      }
    }
  }
}

.modal-lg-video {
  max-width: 800px !important;
  @include media-breakpoint-down(sm) {
    max-width: unset !important;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
}
.exphold-new {
  border-radius: 6px;
  img {
    border-radius: 6px 6px 0px 0px;
  }
}
.exp-box-shadow {
  box-shadow: var(--card-shadow) 0px 4px 10px 0px;
}
.exp_holder {
  img {
    border-radius: 4px 4px 0px 0px;
  }
}

.custom_filter_item-c {
  font-size: 13px;
  border: 1px solid var(--grey200);
  border-radius: 6px;
  padding: 10px 0px 10px 20px;
  @include media-breakpoint-only(xs) {
    font-size: 11px;
    border: 1px solid var(--grey200);
    border-radius: 6px;
    line-height: 20px;
    padding: 6px 6px 6px 6px;
  }
}

.unavl_o {
  opacity: 0.8 !important;
}
.loc_overflow {
  white-space: nowrap;
  overflow: hidden;
  display: inline-block !important;
  width: 100% !important;
  text-overflow: ellipsis;
}

.swiper-button-prev-custom1,
.swiper-container-rtl .swiper-button-next-custom1 {
  left: 10px;
  right: auto;
  padding-right: 2px;
}

.swiper-button-next-custom1,
.swiper-container-rtl .swiper-button-prev-custom1 {
  right: 10px;
  left: auto;
}

.swiper-button-next-custom1,
.swiper-button-prev-custom1 {
  position: absolute;
  top: 48%;
  transform: translateY(-48%);
  margin-top: 0px;
  z-index: 10;
  cursor: pointer;
  color: var(--white);
  font-size: 10px;
  width: 30px;
  height: 30px;
  line-height: 27px;
  border-radius: 50%;
  text-align: center;
  text-shadow: 0 0 12px var(--white);
  border: 1px solid var(--white);
  background: var(--body-text-color) 0006b;
  @include media-breakpoint-only(xs) {
    top: 38%;
  }
}
.swiper-button-next-custom1.swiper-button-disabled,
.swiper-button-prev-custom1.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: auto !important;
  display: none !important;
}

@media only screen and (max-width: 336px) {
  .filter_new {
    margin: 20px 0px 0px !important;
  }
}

.trolley-img {
  width: 25px;
  height: auto;
  margin-right: 6px;
}
