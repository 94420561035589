.bag-new-footer {
    background-color: var(--grey100)  ;
    height: auto;
    padding-top: 4rem;
    padding-bottom: 1rem;
    
}
.footer-top-margin {
    margin-top: 6rem !important;
    @include media-breakpoint-only(xs){
        margin-top: 10rem !important;
    }
}
.logo-partners {
    padding-bottom: 2.5rem;
    display: block !important;
    margin: auto !important;
    width: 50% !important;
}
.social-links{
    justify-content: center;
    @include media-breakpoint-only(xs) {
        justify-content: space-evenly;
    }
}
.logo-center{
    display: block !important;
    margin: auto !important;
}
.pb-50{
    padding-bottom: 5rem;
    @include media-breakpoint-only(xs) {
        padding-bottom: 2.5rem;
    }
}
.flexw50 {
    flex-basis:50%;
}
.footer-new-logo1 {
    text-align: center;
    font-size: 2.1rem;
    color:  var(--grey100) ;
    @include media-breakpoint-only(xs) {
        font-size: 1.8rem;
    }
}

.travel-partners {
    color: var(--white)  ;
    text-align: center;
}

.tb-links-ftr {
    width: 12rem;
    @include media-breakpoint-only(xs) {
        width: 10rem;
    }
}


// .tb-link-hovercol {
//     color: var(--white);
// }
.tb-link-us-new {
    font-size: 1.4rem;
    color: var(--white);
    background: var(--grey300);
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    display: flex;
    text-align: center;
    margin: 0 0.7rem 0 0;
    justify-content: center;
    align-items: center;
  
 
}

.footer-bag {
    border-top:1px solid var(--grey200);
    background-color:   var(--grey100)  ;
    padding-top: 22px;
    padding-bottom: 20px;
    .outline-0 {
            outline : 0px solid transparent;
        &:focus {
            outline : 0px solid transparent;
        }
    }
    @include media-breakpoint-down(md) {
        padding-bottom: 25px;
    }
}

.tb-content-ftr {
    color:  var(--grey100) ;
    float: left;
    height: auto;
    line-height: 40px;
    @include media-breakpoint-only(xs) {
        text-align: center;
        float: none;
    }
}

.tb-contact-us {
    position: relative;
    display: inline-block;
    margin-right: 10px;
    line-height: 24px;
    text-align: center;
    a {
        color: var(--body-text-color) !important;
        font-size: 15px;
        font-weight: 300;
    }
}

.footer-bag-white {
    background-color: var(--white);
}

.footer-partners-white {
    // height: 42px;
    // line-height: 42px;
    margin-top: 30px;
    @include media-breakpoint-only(xs) {
        height: unset;
    }
}

.travel-partners-new-white {
    color: var(--body-text-color);
    font-size: 15px;
    display: inline-block;
    margin-right: 10px;
    font-weight: 500;
    @include media-breakpoint-only(xs) {
        display: block;
        text-align: center;
        margin-bottom: 10px;
    }
}

.travel-partners-new {
    text-align: left;
    display: inline-block;
    @include media-breakpoint-only(xs) {
        text-align: center;
    }
}

.tb-text-legal {
    color: var(--body-text-color) !important;
    font-size: 1.5rem;
    font-weight: 300;
    float: right;
    @include media-breakpoint-only(xs) {
        text-align: center;
        float: none;
        margin-bottom: 2rem;
    }
}

// .footer-bag-iti {
//     @include media-breakpoint-only(xs) {
//         padding-bottom: 42px!important;
//     }
// }

.footer-new-addition {
    a {
        color:  var(--grey200) !important;
        font-size: 1.5rem;
    }
}

.padding-footer-home {
    @include media-breakpoint-only(xs) {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
    }
}

.more-btn{
    // padding-left: 20px !important;
    padding-right: 20px !important;
    font-weight: 300;
    font-size: 15px;
    
}


.footer-continents-padding {
    padding-bottom: 10px;
    padding-top: 10px;
    text-transform: capitalize;

}

.country-color {
    color: var(--body-text-color);
    a::after{
      content:',';
    }
    a:last-child::after{
      content:'';
    }
}

.country-places {
    letter-spacing: 0.5px;
    text-transform: uppercase;
    a {
        text-transform: capitalize;
        color:var(--body-text-color);
    }
    a::after{
      content:',';
    }
    a:last-child::after{
      content:'';
    }
}

.border-footer-new {
    border: 1px solid var(--body-text-color);
}



/* .cookies-box {
    position: fixed;
    text-shadow: 0 0 black;
    bottom: 20px;
    
    border-radius: 6px;
    width: 100%;
    margin: auto;
    left: 50%;
    z-index: 999;
    transform: translateX(-50%);
} */
.contact-page {
    button {
        text-align:right ;
        margin-top:10px;
        @media only screen and (max-width:991px){
            display: block;
             margin: 10px auto 0px auto;
        }
    }

}
.overflow-wrap-anywhere {
    overflow-wrap: anywhere;
    table {
        width: 100%;
    }
}
