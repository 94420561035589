.animation-loading {
    animation-name: placeHolderShimmer;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    background: linear-gradient(90deg, #f6f9fc 8%, #dee3e5 18%, #f6f9fc 33%);
    background-size: 900px 4px;
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0
    }
    to {
        background-position: 468px 0
    }
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0
    }
    to {
        background-position: 468px 0
    }
}

.main_outer {
    width: 100%;
    margin: 5px 0px 35px 0px;
    @include media-breakpoint-down(sm) {
        margin: 0px 0px 15px 0px;
    }
}

.main_img {
    position: relative;
    height: 170px;
    opacity: 0.2;
    position: relative;
    animation-name: placeHolderShimmer;
    transition: all .15s ease-in-out;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    background: -webkit-gradient(linear, left top, right top, color-stop(8%, lightgrey), color-stop(18%, #73787a), color-stop(33%, lightgrey));
    background: linear-gradient(90deg, lightgrey 8%, #73787a 18%, lightgrey 33%);
    background-size: 900px 4px;
    overflow: hidden;
}

.main_description {
    height: auto;
    padding: 10px;
}

.main_title {
    height: 16px;
    margin: 10px 0px;
    width: 80%;
    opacity: 0.2;
    position: relative;
    animation-name: placeHolderShimmer;
    transition: all .15s ease-in-out;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    background: -webkit-gradient(linear, left top, right top, color-stop(8%, lightgrey), color-stop(18%, #73787a), color-stop(33%, lightgrey));
    background: linear-gradient(90deg, lightgrey 8%, #73787a 18%, lightgrey 33%);
    background-size: 900px 4px;
    overflow: hidden;
}

.sub_title {
    height: 16px;
    margin: 10px 0px;
    width: 60%;
    opacity: 0.2;
    position: relative;
    animation-name: placeHolderShimmer;
    transition: all .15s ease-in-out;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    background: -webkit-gradient(linear, left top, right top, color-stop(8%, lightgrey), color-stop(18%, #73787a), color-stop(33%, lightgrey));
    background: linear-gradient(90deg, lightgrey 8%, #73787a 18%, lightgrey 33%);
    background-size: 900px 4px;
    overflow: hidden;
}
.title_description {
    height: 58px;
    margin: 10px 0px 0px 0px;
    width: 100%;
    opacity: 0.2;
    position: relative;
    animation-name: placeHolderShimmer;
    transition: all .15s ease-in-out;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    background: -webkit-gradient(linear, left top, right top, color-stop(8%, lightgrey), color-stop(18%, #73787a), color-stop(33%, lightgrey));
    background: linear-gradient(90deg, lightgrey 8%, #73787a 18%, lightgrey 33%);
    background-size: 900px 4px;
    overflow: hidden;
}

.ready-trips {
    .main_img {
        height: 180px;
        @include media-breakpoint-down(sm) {
            height: 180px;
        }
    }
}

.near-destination {
    .main_img {
        @include media-breakpoint-down(sm) {
            height: 100px;
        }
    }
}