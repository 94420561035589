                .tb-itoutput-bgimg {
                    position: relative;
                    height: 300px;
                    background-size: cover;
                    background-position: center;
                }
                
                .inex_padding {
                    padding: 0px 30px;
                }
                
                .pdf-header-outer {
                    height: auto;
                    margin: -95px 22px 22px 22px;
                    position: relative;
                    box-shadow: 0 1px 1px 0  var(--card-shadow), 0 1px 1px 0  var(--card-shadow), 0 0px 1px 0  var(--card-shadow);
                }
                
                .pdf-header-description {
                    margin: 10px 0px;
                }
                
                .des-pdf {
                    display: flex;
                    align-items: center;
                    font-weight: 300;
                    font-size: 14px;
                    padding: 0px 5px 0px 0px;
                    height: 20px;
                    line-height: 20px;
                }
                
                .des-pdf:last-child::after {
                    content: none;
                }
                
                .des-pdf::after {
                    height: 12px;
                    display: block;
                    width: 1px;
                    background:   var(--body-text-color) ;
                    margin: 0px 3px;
                    content: '';
                }
                
                .pdf-header-description {
                    margin: 10px 0px;
                }
                
                .pdf-header-right-col {
                    background:  var(--secondary-light) !important;
                }
                
                .pdf-header-left {
                    background:  var(--secondary-light)  ;
                    height: auto;
                    padding: 30px !important;
                }
                
                .itinerary-description-output {
                    font-size: 13px;
                    color:   var(--body-text-color) ;
                }
                
                .heading1-pdf {
                    font-weight: 300;
                    font-size: 25px;
                    letter-spacing: .25em;
                    text-transform: capitalize;
                }
                
                .price-pdf-col {
                    color: var(--white);
                    word-wrap: break-word;
                }
                
                .prepared-by-heading {
                    font-size: 16px;
                    font-weight: 300;
                }
                
                .prepared-by-pdf {
                    font-size: 14px;
                    font-weight: 300;
                    .cost-pdf {
                        font-size: 20px;
                        font-weight: 400;
                    }
                }
                
                .output_route {
                    text-align: left;
                    padding-left: 50px !important;
                    margin: 15px 0px 32px 0px;
                }
                
                .output_route .hub_route.place {
                    margin-left: -30px;
                    margin-right: 10px;
                }
                
                .output_route .hub_route {
                    position: relative;
                    display: inline-block;
                    width: 170px;
                    height: 80px;
                    vertical-align: bottom;
                }
                
                .output_route .hub_route .hub_details {
                    padding-left: 22px;
                    font-size: 12px;
                    line-height: 15px;
                }
                
                .pdf_hub_duration {
                    padding-right: 13px;
                    font-size: 14px;
                }
                
                .output_route .hub_route.place {
                    margin-left: -30px;
                    margin-right: 10px;
                }
                
                .output_route .hub_route {
                    position: relative;
                    display: inline-block;
                    width: 190px;
                    height: 80px;
                    vertical-align: bottom;
                }
                
                .output_route .hub_route.mode {
                    width: 32px;
                    z-index: 2;
                }
                
                .vehicle-bad-pdf {
                    width: 32px;
                    height: 32px;
                    position: relative;
                    color: var(--white);
                    text-align: center;
                    line-height: 32px;
                    display: inline-block;
                    background-color:  var(--secondary-light) ;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    -ms-border-radius: 50%;
                    border-radius: 50%;
                }
                
                .output_route .hub_route.place .hub_name_display {
                    background:  var(--secondary-light)  ;
                    border-radius: 50px;
                    line-height: 32px;
                    padding-left: 25px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                   white-space: nowrap;;
                    width: 180px;
                    font-weight: 400;
                }
                
                .output_route .hub_route.place {
                    margin-left: -30px;
                    margin-right: 10px;
                }
                
                .iti-top-holder h5 {
                    font-size: 16px;
                    color: var(--white);
                    padding: 3px 5px;
                    background-color:  var(--secondary-light) ;
                    font-weight: normal;
                }
                
                .itineary_scraps_holder {
                    width: 100% !important;
                }
                
                .arrive-pdf-output1 {
                    display: inline-block;
                    // width: 14%;
                    width: 18%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                   white-space: nowrap;;
                    font-weight: 300;
                    font-size: 14px;
                    letter-spacing: 0.3px;
                    // height: 28px;
                    // line-height: 28px;
                }
                
                .iti-output-outer {
                    padding: 5px 0px 0px 20px;
                }
                
                .trip_story_description {
                    font-size: 15px;
                    word-wrap: break-word;
                }
                
                .iti-output-outer fieldset {
                    border: 1px solid  var(--grey100) ;
                    min-height: 70px;
                    padding: 10px 5px;
                }
                
                fieldset legend {
                    background:  var(--secondary-light) ;
                    color: var(--white);
                    padding: 2px 10px;
                    font-size: 18px;
                    margin-left: 20px;
                    text-transform: capitalize;
                    width: auto;
                }
                
                .iti-pdf fieldset {
                    border: 1px solid  var(--grey100) ;
                    padding: 10px 5px;
                }
                
                .inex_list {
                    padding: 5px 0px;
                    margin-left: 15px;
                }
                
                .allign-new-pdf {
                    // height: 30px;
                    margin-left: 6px;
                    border-bottom: 1px solid  var(--grey100)  ;
                }
                
                .inex {
                    margin-bottom: 10px;
                }
                
                .iti-output-route-outer {
                    margin-right: 10px;
                    display: inline-block;
                    line-height: 30px;
                }
                
                .allign-pdf-line {
                    height: 30px;
                    line-height: 30px;
                    font-size: 16px;
                    font-weight: 300;
                    text-transform: capitalize;
                }
                
                .list-name-pdf {
                    font-weight: 300;
                    font-size: 14px;
                    // height: 30px;
                    line-height: 30px;
                }
                
                .list-name-pdf ul li:before {
                    background-color:   var(--primary-color) ;
                    border-radius: 50%;
                    content: "";
                    display: inline-block;
                    margin-right: 10px;
                    margin-bottom: 2px;
                    height: 6px;
                    width: 6px;
                }
                
                .bag-pdf {
                    background-color:  var(--body-text-color)   ;
                    height: 50px;
                    line-height: 25px;
                }
                
                .total-price-pdf {
                    font-size: 20px;
                    font-weight: 400;
                    text-transform: capitalize;
                    i {
                        font-size: 17px;
                    }
                }
                
                .logo-pdf {
                    margin-left: 40px;
                }
                
                .arrive-pdf-output2 {
                    // width: 25%;
                    width: 39%;
                    display: inline-block;
                    word-wrap: break-word;
                    font-weight: 300;
                    font-size: 14px;
                    letter-spacing: 1px;
                    // height: 28px;
                    // line-height: 28px;
                    img {
                        height: 18px;
                    }
                }
                
                .width_10 {
                    width: 10%;
                }
                
                .width_90 {
                    width: 90%;
                }
                
                .w-24 {
                    width: 25% !important;
                }
                
                .background_last {
                    background-color: rgba(243, 209, 194, 0.5);
                    padding: 0px 8px;
                }
                
                // new
                .iti-output-new-outer {
                    .phone-email {
                        font-size: 14px;
                    }
                    .arr-depart-bag {
                        margin-top: -100px;
                        height: auto;
                        min-height: 200px;
                        background-color: rgba(243, 209, 194, 0.5);
                        padding: 20px 0px;
                    }
                    .arr-pff {
                        color:   var(--body-text-color) ;
                        font-size: 16px;
                        font-weight: 400;
                        margin-bottom: 5px;
                        letter-spacing: 1px;
                    }
                    .logo-name-section {
                        .w-10 {
                            width: 10%!important;
                        }
                        .logo-design {
                            img {
                                width: 80px;
                                height: 80px;
                                border-radius: 50%;
                            }
                        }
                        .agency-name {
                            font-size: 20px;
                            color: var(--primary-color);
                            border-radius: 50%;
                            letter-spacing: 1px;
                        }
                    }
                    .description-pdf {
                        .trip-heading-pdf {
                            font-weight: 500;
                            font-size: 24px;
                            text-transform: capitalize;
                            color: var(--primary-color);
                            margin-bottom: 5px;
                            letter-spacing: 1px;
                        }
                    }
                    .bottom-section-outer {
                        .location {
                            background-color: rgba(243, 209, 194, 0.5);
                            color:   var(--body-text-color) ;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 0px 8px;
                        }
                        .days-heading {
                            height: 40px;
                        }
                        .days-specific {
                            height: 65px;
                            border-bottom: 1px solid var(--grey200);
                            h5 {
                                color:   var(--body-text-color) ;
                                font-weight: 400;
                                font-size: 20px;
                            }
                        }
                        .next-location {
                            font-size: 14px;
                            font-weight: 400;
                            letter-spacing: 1px;
                        }
                        .timings-pdf {
                            .free-time {
                                color:  var(--secondary-light)  ;
                                font-size: 12px;
                            }
                        }
                    }
                    .inclusions {
                        font-size: 20px;
                        font-weight: 400;
                    }
                    .inclusions-details {
                        font-size: 12px;
                        color:   var(--body-text-color) ;
                    }
                    .categories-details {
                        font-weight: 400;
                        font-size: 14px;
                    }
                    .bag-pdf {
                        background-color: rgba(243, 209, 194, 0.5);
                        padding: 0px 40px;
                    }
                }
                
                .iti_wrap {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    margin-left: -15px;
                    margin-right: -15px;
                }
                
                .happy-journey {
                    font-size: 20px;
                    font-weight: 400;
                    letter-spacing: 1px;
                }
                
                .car-size-pdf {
                    margin-right: 6px;
                }
                
                .time {
                    color: var(--primary-color);
                    font-size: 18px;
                    font-weight: 500;
                    width: 14%;
                }
                
                .hotel {
                    color:   var(--body-text-color) ;
                    font-size: 14px;
                    font-weight: 500;
                    text-transform: uppercase;
                    width: 39%;
                    .width_90 {
                        width: 90%!important;
                    }
                }
                
                .spend-time {
                    font-size: 13px;
                    color:   var(--body-text-color) ;
                    width: 22%;
                }
                
                .free {
                    font-size: 13px;
                    color:   var(--body-text-color) ;
                }
                
                .height-image {
                    overflow: hidden;
                }