.hotel-icon {
  font-size: 1.4rem;
  color: var(--primary-color);
  font-weight: 600;
  margin-right: 1rem;
}
.hotelbg {
  background: var(--grey100);
  border-radius: 6px;
}
.cards_accepted::after {
  content: ", ";
}
.cards_accepted:last-child::after {
  content: "";
}
.img_unavlht {
  max-height: 250px !important;
}
.bordermid1 {
  border-right: 1px solid var(--grey200);
}
.h_minner {
  border-bottom: 1px solid var(--grey200);
  &:last-child {
    border-bottom: 0px solid var(--grey200);
  }
  .input-field {
    background: #c1bbbb00;
    box-shadow: none !important;
    border: 1px solid var(--grey200);
    padding: 5px 10px;
    border-radius: 4px;
    width: 100px;
    cursor: pointer;
  }
}

.flex-10 {
  flex-basis: 10%;
}
.flex-15 {
  flex-basis: 15%;
}
.flex-20 {
  flex-basis: 20%;
  .quantity-field {
    border: none;
    width: 30px;
    margin: 0px auto;
    /* display: inline; */
    text-align: center;
    cursor: pointer;
  }
}
.flex-50 {
  flex-basis: 50%;
}
.flex-30 {
  flex-basis: 30%;
}
.flex-25 {
  flex-basis: 25%;
}
.flex-40 {
  flex-basis: 40%;
}
.flex-32 {
  flex-basis: 32%;
}
.flex-70 {
  flex-basis: 70%;
}
.h-caret:before {
  content: "\f0d7";
  position: absolute;
  right: 10px;
  top: 8px;
}
.hotelpopup {
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 48%;
    width: 27px;
    height: 44px;
    margin-top: -22px;
    z-index: 10;
    cursor: pointer;
    background-size: 29px 30px;
    background-position: center;
    background-repeat: no-repeat;
  }
  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    background-image: url(/assets/images/review-prev.png) !important;
    left: 10px;
    right: auto;
  }
  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    background-image: url(/assets/images/review-next.png) !important;
    right: 10px;
    left: auto;
  }
  .swiper-slide {
    source {
      border-radius: 6px;
    }
  }
}
.flex-60 {
  flex-basis: 60%;
}
.flex-80 {
  flex-basis: 80%;
}
.flex-90 {
  flex-basis: 90%;
}
.r-grid {
  border-radius: 6px;
  border: 1px solid var(--grey200);
  margin-bottom: 15px;
}
.r-header {
  border-bottom: 1px solid var(--grey200);
}

.ml-2 {
  margin-left: 2px;
}
.r-list {
  width: 440px;
  margin-left: 30px;
}
.dis-btmborder {
  padding: 10px;
  margin-right: 15px;
  border-bottom: 1px solid var(--grey200);
}
.r_dropdown {
  .selectdiv {
    font-size: 15px !important;
    width: 100%;
    position: relative;
    float: left;
    padding-left: 0px;
    select {
      min-width: 130px;
      font-size: 15px;
      line-height: 22px;
      color: var(--body-text-color) 0006e !important;
    }
  }
  .selectdiv:after {
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    font: 18px "Consolas", monospace;
    color: #5c5c5c;
    right: 14px;
    top: 14px;
    padding: 0 0 2px;
    position: absolute;
    pointer-events: none;
  }
}
@media only screen and (max-width: 1024px) {
  .mob_margin {
    margin: 0px !important;
  }
  .flex-32 {
    width: 100%;
    flex-basis: 100%;
  }
  .flex-80 {
    width: 100%;
    flex-basis: 100%;
  }
  .flex-70 {
    width: 100%;
    flex-basis: 100%;
  }
  .flex-40 {
    width: 100%;
    flex-basis: 100%;
  }
  .hotelpopup {
    .swiper-button-next,
    .swiper-button-prev {
      position: absolute;
      top: 40%;
      width: 20px;
      height: 24px;
      margin-top: 0px;
      transform: scaleX(1.2);
      cursor: pointer;
      background-size: 27px 44px;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  .flex-20 {
    width: 100%;
    flex-basis: 100%;
  }
  .flex-25 {
    width: 100%;
    flex-basis: 100%;
  }
  .flex-30 {
    width: 100%;
    flex-basis: 100%;
  }
  .flex-60 {
    width: 100%;
    flex-basis: 100%;
  }
  .bordermid1 {
    padding-bottom: 0px;
    border-right: none;
    border-bottom: 0px solid var(--grey200);
    margin-bottom: 10px;
  }
  .h_minner {
    .input-field {
      background: #c1bbbb00;
      box-shadow: none !important;
      border: 1px solid var(--grey200);
      padding: 5px 10px;
      border-radius: 4px;
      width: 100%;
      cursor: pointer;
    }
  }

  .flex-15 {
    width: 100%;
    flex-basis: 15%;
  }
  .dis-btmborder {
    padding: 10px 0px;
    margin: 0px 0px 0px 0px;
    border-bottom: 1px solid var(--grey200);
  }
  .m-center {
    text-align: center;
  }

  .mob_top_margin {
    margin-top: 20px;
    min-height: auto !important;
  }
  .r-list {
    width: auto;
    margin-left: 0px;
  }
  .flex-70 {
    .bordermid {
      padding-bottom: 0px !important;
      border-right: none;
      border-bottom: 0px solid var(--grey200);
      margin-bottom: 20px;
    }
  }

  .mob_top10 {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 575px) {
  .m-abs.dropdown-abs {
    margin-top: 5px;
    width: 246px;
    padding: 20px;
    background: var(--white);
    border-radius: 4px;
    position: absolute;
    z-index: 2;
    border: 1px solid var(--grey200);
    .trav_popup {
      align-self: center;
      width: auto !important;
      margin: 0px;
    }
    .flexrow {
      margin-bottom: 10px;
      .flightgrid1 {
        width: 100%;
      }
    }
  }
  .r-grid {
    .padding30 {
      padding: 20px;
    }
  }

  .h_minner {
    .bordermid {
      padding-bottom: 0px;
      border-right: none;
      border-bottom: 0px solid var(--grey200);
      margin-bottom: 10px;
    }
    .input-field {
      background: #c1bbbb00;
      box-shadow: none !important;
      border: 1px solid var(--grey200);
      padding: 5px 10px;
      border-radius: 4px;
      width: 100%;
    }
  }

  .r_dropdown {
    .selectdiv {
      font-size: 15px !important;
      width: 100%;
      position: relative;
      float: left;
      padding-left: 0px;
      select {
        min-width: 130px;
        font-size: 15px !important;
        line-height: 22px !important;
        color: #0000006e !important;
      }
    }
    .selectdiv:after {
      content: "";
      border-top: 0.3em solid;
      border-right: 0.3em solid transparent;
      border-bottom: 0;
      border-left: 0.3em solid transparent;
      font: 18px "Consolas", monospace;
      color: #5c5c5c;
      right: 14px;
      top: 14px;
      padding: 0 0 2px;
      position: absolute;
      pointer-events: none;
    }
  }
}
.more_facilities {
  transition: max-height 0.5s ease;
  overflow: hidden;
  max-height: 0px;
  &.show_more_facilities {
    max-height: 2000px;
  }
}
.dropdown-form {
  .selectdiv {
    width: 100%;
    min-width: 68px !important;
    select {
      border: 1px solid var(--grey200);
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      height: auto !important;
      padding: 0.55rem 0.75rem;
      font-size: 14px !important;
      font-weight: 300;
      line-height: 1.5;

      color: var(--body-text-color);
      width: 100%;
      background-image: none;

      option {
        font-size: 14px !important;
        padding: 5px 10px;
      }
    }
  }
}
.d-flex-50 {
  display: inline-flex;
}
.hotel-fixed-top-bar {
  position: fixed;
  box-shadow: none;
  border-bottom: 1px solid var(--grey100);
  z-index: 99;
  background: var(--white);
  width: 100%;
  top: 100px;
}
.timer-box {
  padding: 3px 0px;
  text-align: center;
  width: 38px;
  background: var(--secondary-color);
  color: var(--white) !important;
  border-radius: 4px;
  margin-left: 5px;
  display: inline-block;
  border: 1.5px solid var(--secondary-color);
}
.timer-box1 {
  padding: 3px 0px;
  text-align: center;
  background: var(--white);
  width: 38px;
  border-radius: 4px;
  margin-left: 5px;
  display: inline-block;
  border: 1.5px solid var(--secondary-color);
}
.desk-hotel-bottom-bar {
  background: var(--white);
  z-index: 9;
  position: fixed;
  z-index: 9;
  bottom: 0px;
  display: block;
  width: 100%;
  text-align: right;
  border-top: 1px solid var(--grey200);
}
@media only screen and (max-width: 991px) {
  .d-flex-50 {
    display: block;
    width: 50%;
  }
}

.hotel-left-panel {
  width: 70%;
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
}
.hotel-right-panel {
  width: 30%;
  @media only screen and (max-width: 1024px) {
    width: 100%;
  }
}

.content-seperator {
  display: inline;
}
.content-seperator :after {
  content: ", ";
}
.content-seperator :last-child:after {
  content: " ";
}

.own_modal {
  .dropdown_list {
    top: 33px !important;
  }
  .message_box_clear {
    top: 7px !important;
  }
}

.rotate-phone {
  transform: rotate(90deg);
}
@media only screen and (max-width: 575px) {
  .mob-hotel-content {
    max-width: 85%;
    width: 100%;
    padding: 0px;
    margin: 0px auto;
  }
  .mob-margin-hotel {
    margin-top: 0px;
  }
}
.h-flex-35 {
  flex-basis: 35%;
}
.h-flex-65 {
  flex-basis: 65%;
}
.amtbrheight {
  height: 50px;
}
