.exp-newbg {
    background: #d8c8c685;
    margin-left: 10px;
    border-radius: 0px 18px 18px 0px;
    padding: 25px;

}
.exp-box {

    border-top: none !important;
    border: 1px solid var(--grey200);
    border-radius: 6px;
    img {
        border-radius: 6px 6px 0px 0px;
    }
}
.exp_bannerimg {
    border-radius: 18px 0px 0px 18px;
}
.masonry {
    -webkit-column-count: 2;
    column-count: 2;
    column-gap: 20px;
}
.exp-ht {
    min-height: 60px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: var(--white)
}
.masonry-items {
    display: inline-block;
    margin: 10px 0px;
    border-radius: 6px;
    background: var(--white);
    width:100%;
    img {
        width: 100%;
        border-radius: 6px 6px 0px 0px;
    }
}

.exp-gallery {
    -webkit-column-count: 2;
    column-count: 2;
    column-gap: 10px;
}

.gallery-items {
    display: inline-block;
    margin: 5px 0px;


    img {
        width: 100%;

    }
}
.exp_content {
        padding: 25px;
        @media only screen and (max-width:575px) {
            padding: 25px 0px;
        }
} 
.exp-content-border {
    border:1px solid var(--grey200);
    @media only screen and (max-width:575px) {
        border:0px solid var(--grey200);
    }
}
.img-radius {
    border-radius: 6px;;
}
.exp-bg {
    padding:10px 0px 20px 0px;
     
    
}
.lightbox {
    display: flex !important;
    flex-direction: column-reverse !important;
}
.gallery-items {
    display: inline-block;
    margin: 5px 0px;
    cursor: pointer;
}
.exp-icon-size{
    height: auto;
    width: 75px;
}
.exp-flex-item {
    width: calc(20% - 20px);
    margin-bottom: 40px;
}
.primary-gradient {
    background: -webkit-linear-gradient(45deg,  var(--secondary-color) , var(--primary-color));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.exp-banner-heading {
    padding: 0rem;
    margin: 0 0 2rem 0;
    font-weight: 700;
    font-size: 5rem;
    line-height: 6rem;

}
.exp-new_greylighter {
    color:#838080;
}
.exp-link {
    li {
        &:last-child {
            &:after {
        content : "  " ;
        margin:0px 10px;
       
        }
     }
         &:after {
        content : " | " ;
        margin:0px 10px;
        color :var(--primary-color);
    }
    }
   
}

@media only screen and (max-width:1024px) {
    .exp-flex-item {
    width: calc(30% - 20px);
    margin-bottom: 30px;
}
    .exp-newbg {
    background: #d8c8c685;
    margin: 20px 0px 0px 0px;
    border-radius: 6px;
    padding: 20px;
}
.masonry {
    -webkit-column-count: 1;
    column-count: 1;
    column-gap: 20px;
}
.exp-gallery {
    -webkit-column-count: 1;
    column-count: 1;
    column-gap: 10px;
}
.exp_bannerimg {
    border-radius: 6px;
}
.swiper-button-next-custom, .swiper-button-prev-custom {
    position: absolute;
    top: 46%;
    margin-top: 0px;
    z-index: 10;
    cursor: pointer;
    color: black;
    font-size: 15px;
    width: 34px;
    height: 34px;
    line-height: 34px;
    border-radius: 50%;
    text-align: center;
    text-shadow: 0 0 12px var(--white)  ;
}
.exp-icon-size {
    height: auto;
    width: 60px;
}
}
@media only screen and (max-width:575px) {
    .exp-banner-heading {
    font-size: 2.5rem;
    line-height: 3rem;
    margin: 0 0 0.5rem 0;
    }
    .exp-flex-item {
    width: calc(50% - 20px);
    margin-bottom: 20px;
}
.exp-icon-size {
    height: auto;
    width: 50px;
}
}

.experience-wrapper{
    
   
    .swiper-slide {
    width: 135px !important;
    display: flex;
    min-height: 130px;
    max-height: 150px;
    background-size: cover;
    background-position: center bottom;
    }
}

.exp-thumbnail-wrapper {
    position: relative;
    border-radius:6px;
    padding: 20px;
    width: 120px;
    min-height: 120px;
    max-height: 150px;
    margin-right: 10px;
    text-align: center;
    transition-duration : 0.5s;
    margin-bottom:20px;
    margin-top:20px;
    border:1px solid  var(--grey100)  ;
    transition: all 0.2s ease-out;
    &:focus {
        border:1px solid  var(--grey100)  ;
    }
    &:hover {
        background-color: none !important;
        border-radius :6px;
        transition: all 0.2s ease-out;
        box-shadow: 0px 4px 8px  var(--card-shadow);
        
        @media only screen and (max-width:575px) {
            box-shadow: none;   
           background-color: none !important;
           
        }
    }
    img {
        margin: 0px auto;
        border-radius: 50%;
        width: 40px;
        height: 38px;
        text-align: center;
    }
    .thumbnail-title-minht {
        min-height: 40px;
        max-height:45px;
    }
}
.justify-content-p {
    text-align: justify !important;
    p{
        text-align: justify !important;
        @media only screen and (max-width:991px) {
            text-align: justify ;
            
        }
    }
    @media only screen and (max-width:991px) {
        text-align: justify ;
        
    }
}
