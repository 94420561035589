a {
  cursor: pointer;
}
a:hover {
  color: unset !important;
}

.img-fluid-blur {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100% !important;
  height: 100%;
  transition: all 0.5 linear;
}

img.imgAnimate {
  opacity: 0;
}

img.imgAnimate.show {
  animation: fadein 0.3s linear;
  animation-fill-mode: forwards;
}
::-webkit-input-placeholder {
  /* Chrome */
  color: var(--body-text-color);
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: var(--body-text-color);
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: var(--body-text-color);
  opacity: 1;
}
:-moz-placeholder {
  /* Firefox 4 - 18 */
  color: var(--body-text-color);
  opacity: 1;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

select {
  background-color: var(--white);
}

.black {
  color: var(--body-text-color) !important;
}

.white {
  color: var(--white) !important;
}

.primary-text {
  color: var(--primary-color) !important;
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: none !important;
}

.add-btn {
  text-transform: uppercase;
  font-size: 13px;
  padding: 10px;
  background: #ee6055;
  color: var(--white);
  border-radius: 0px 0px 6px 6px;
  font-weight: 400;
  &:hover {
    color: var(--white);
  }
}

@font-face {
  font-family: titleFont;
  src: url("/assets/fonts/bebas_neue/BebasNeueRegular.otf");
  src: url("/assets/fonts/bebas_neue/BebasNeueRegular.woff");
}

@font-face {
  font-family: greatvibes;
  src: url("/assets/fonts/greatvibes/GreatVibes-Regular.ttf");
}

@font-face {
  font-family: benton-book;
  src: url("/assets/fonts/benton/bentonbook.otf");
}
@font-face {
  font-family: benton-regular;
  src: url("/assets/fonts/benton/bentonregular.otf");
}

.stickyShadow {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

.pointer {
  cursor: pointer;
}

ul li {
  list-style-type: none;
}

.angular-editor ul li {
  list-style-type: disc !important;
}
.angular-editor ul {
  padding: 10px !important;
  margin-left: 5px !important;
}
.angular-editor ol {
  padding: 10px !important;
  margin-left: 5px !important;
}

.page_list ul li {
  list-style: disc !important;
}
.page_list ul,
.page_list ol {
  margin-left: 15px !important;
}

.dropdown-menu ul li :hover {
  background-color: var(--grey200);
}

.is-sticky {
  background: var(--white);
  z-index: 10;
}

.editable_field,
.primary-text-colorpointer {
  color: var(--primary-color) !important;
  border: none;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
}

.btn-secondary-new {
  margin: 10px;
}

.btn.c-inline-editor span {
  color: var(--white);
}

.btn.btn-danger.c-inline-editor span:before {
  content: "\f00d";
}

.modal-footer {
  justify-content: center;
}

.nav-tabs .nav-link {
  border: none;
}

a {
  color: var(--body-text-color);
  text-decoration: none !important;
}

ul,
ol {
  padding: 0px;
  margin: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

ul,
ol {
  padding: 0px;
  margin: 0px;
}

.clear {
  clear: both;
}

.shadow-hover {
  position: relative;
  transition: all 0.3s ease;
}

.shadow-hover:hover {
  z-index: 7 !important;
}

.headline-home-new {
  margin: 15px 0px 0px 0px;
}
.btn-secondary:hover {
  background-color: var(--secondary-light);
  border-color: var(--secondary-light);
}
.heading_tag_line {
  font-size: 17px;
  color: var(--body-text-color);
  margin-bottom: 10px;
}

.title-col {
  color: var(--body-text-color);
}

.dropdow_content {
  border-radius: 6px;
  right: 0px !important;
  top: 60px !important;
  left: 9%;
  background-color: var(--white);
  margin: 0;
  display: none;
  min-width: 150px;
  max-height: 650px;
  overflow-y: auto;
  opacity: 1;
  position: absolute;
  z-index: 999;
  display: none;
  padding: 10px 0px;
  will-change: width, height;
  ul {
    list-style: none;
    li {
      cursor: pointer;
      :hover {
        background-color: var(--grey100);
      }
      a {
        padding: 10px 20px !important;
        display: block;
      }
      a:not(.text-danger) {
        color: var(--body-text-color) !important;
      }
    }
  }
}

.tb-break-word {
  word-wrap: break-word;
}

.graylight {
  color: var(--grey200) !important;
}

.font-size-mob {
  @include media-breakpoint-only(xs) {
    font-size: 24px;
  }
}

.font-weight-general {
  font-weight: 400 !important;
}
.flex_1 {
  flex-grow: 1;
}
.flex_end {
  align-self: flex-end;
}
.gen_flex {
  display: flex;
  justify-content: space-between;
}

/* INLINE EDITOR CSS */

a.c-inline-editor {
  text-decoration: none;
  color: #428bca;
  border-bottom: dashed 1px #428bca;
  cursor: pointer;
  line-height: 2;
  margin-right: 5px;
  margin-left: 5px;
  display: inline !important;
}

.c-inline-editor.editable-empty,
.c-inline-editor.editable-empty:hover,
.c-inline-editor.editable-empty:focus,
.c-inline-editor.a.editable-empty,
.c-inline-editor.a.editable-empty:hover,
.c-inline-editor.a.editable-empty:focus {
  font-style: italic;
  color: #dd1144;
  text-decoration: none;
}

.c-inline-editor.inlineEditForm {
  display: inline-block;
  white-space: nowrap;

  margin: 0;
  position: absolute;
  width: 200px;
  top: -4px;
  left: 0px;
  background-color: var(--white);
  z-index: 10;
}

#inlineEditWrapper {
  display: inline-block;
  position: relative;
}

.c-inline-editor.inlineEditForm input,
.c-inline-editor.select {
  display: inline;
}

.c-inline-editor.inlineEditForm input {
  width: 125px;
}

.c-inline-editor.inline-editor-button-group {
  display: inline-block;
}

.c-inline-editor.editInvalid {
  color: #a94442;
  margin-bottom: 0;
}

.c-inline-editor.error {
  border-color: #a94442;
}

[hidden].c-inline-editor {
  display: none;
}

.alert-warning {
  color: var(--white) !important;
  background-color: var(--blue) !important;
  font-size: 18px !important;
  text-align: center !important;
  border-color: var(--blue) !important;
  margin-bottom: 0px !important;
  @include media-breakpoint-only(xs) {
    font-size: 14px !important;
  }
}

.no-places-bag {
  background-image: url(/assets/images/no-place-found.png);
  background-position: center;
  background-repeat: no-repeat;
  height: auto;
  font-size: 24px;
  line-height: 24px;
  color: var(--body-text-color);
  font-weight: 500;
  padding-top: 120px;
  @include media-breakpoint-only(xs) {
    padding: 62px;
  }
  &.no-place-itinerary {
    @include media-breakpoint-down(sm) {
      padding: 0px;
      height: 300px;
    }
  }
}

.font-size-label {
  font-size: 14px !important;
}

.card-iti {
  display: flex !important;
  align-items: center;
  min-width: 100%;
  height: 42px;
  background: var(--light-cream);
  overflow-x: scroll;
  white-space: nowrap;
  flex-direction: row !important;
  border-radius: unset !important;
}

.card::-webkit-scrollbar {
  display: none;
}

.card--content {
  min-width: 17%;
  color: var(--body-text-color);
  i {
    font-size: 12px;
  }
}

.mobileDeleteConfirm {
  margin-left: -70px;
  .arrow {
    left: 80% !important;
  }
}

.webDeleteConfirm {
  margin-left: -70px;
  .arrow {
    left: 80% !important;
  }
}

.mobilexpscore {
  .popover-body {
    padding: 0px !important;
    table tr td {
      padding: 5px 8px !important;
    }
  }
  margin-left: 120px !important;
  .arrow {
    left: 8% !important;
  }
}

.scrapinner-pop-mob-timings {
  margin-left: 8px !important;
  z-index: 8 !important;
}

.line-height-submenu {
  line-height: 80px;
}
.bs-datepicker-container {
  padding: 10px;
}

// chat box
.clear-button {
  font-size: 13px;
  padding: 6px 10px;
  cursor: pointer;
}
.chat-img {
  img {
    border-radius: 6px;
  }
}
.tb-chat-bax-new {
  position: fixed;
  width: 350px;
  height: 400px;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  @include media-breakpoint-only(xs) {
    width: 100%;
    height: 100%;
    right: unset;
    bottom: unset;
    bottom: 0;
    padding-top: 0vh;
    background: rgba(0, 0, 0, 0.5);
  }
}

.chat-box-outer {
  background-color: var(--white);
  box-shadow: 0 4px 5px 0 var(--card-shadow), 0 1px 10px 0 var(--card-shadow),
    0 2px 4px -1px var(--card-shadow);
  border-radius: 6px;
  flex-direction: column;
  height: 100%;
}

.chat-box-heading {
  color: var(--body-text-color);
  font-size: 18px;
  font-weight: 400;
  margin: 10px 15px;
  height: auto;

  padding-bottom: 10px;
  border-bottom: 1px solid var(--grey200);
  border-bottom: 1px solid var(--grey200);
  @include media-breakpoint-down(sm) {
    margin-top: 20px;
  }
}

.chat-main-holder {
  position: relative;
  overflow: scroll;
  margin: 10px 0px;
}

.chat-holder {
  padding: 0px 12px;
  width: 100%;
  bottom: 0;
}

.msg-box-inner {
  background: var(--white);
  padding: 10px 10px;
  border-radius: 5px;
  button:focus {
    outline: none !important;
  }
}

.img-circle-chat {
  width: 42px;
  height: 42px;
  float: left;
  position: relative;
  img {
    max-width: 42px;
  }
}

.ques-tb {
  float: left;
  color: var(--white);
  font-size: 15px;
  background: var(--primary-color);
  padding: 8px 15px;
  border-radius: 6px;
  box-shadow: 0 1px 2px var(--card-shadow);
  margin-left: 2px;
  max-width: 78%;
  min-width: 20px;
  line-height: 22px;
  word-wrap: break-word;
  @include media-breakpoint-only(xs) {
    max-width: 82%;
  }
}

.user-reply {
  color: var(--body-text-color);
  background: var(--grey200) !important;
}
.chat_options {
  margin: 10px 0px 10px 42px;
  .option_values {
    background-color: transparent;
    color: var(--body-text-color);
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 0px 5px;
    line-height: 24px;
    cursor: pointer;
    border: 1px solid var(--grey200);
    padding: 5px 15px;
    border-radius: 18px;
  }
}
.payload_holder {
  margin-top: 20px;
}
.chat_scrap {
  width: 50% !important;
  .chat_scrap_holder {
    margin-right: 5px;
    background: var(--white);
    font-size: 12px;
    line-height: 18px;
    .scrap_title {
      width: 95%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  a {
    color: var(--body-text-color);
  }
}

.tb-coversation-outer {
  padding: 5px 0px;
}
.chat-options {
  border-radius: 5px;
  background: var(--white);
  padding: 3px 5px;
}
.tb-welcome {
  height: auto;
  line-height: 30px;
}

.message-box-chat {
  position: relative;
  z-index: 5;
  background: transparent;
  border: none;
  outline: none !important;
  resize: none;
  font-size: 15px;
  min-height: 24px;
  max-height: 30px;
  margin: 0;
  padding-right: 20px;
  width: 265px;
  color: var(--body-text-color);
  font-weight: 300;
  @include media-breakpoint-only(xs) {
    width: 91% !important;
  }
}

.user_enter_text {
  color: var(--body-text-color);
}

.suggestion_text_holder {
  position: absolute;
  top: 0px;
  color: var(--body-text-color);
  left: 0px;
  z-index: 4;
}

.msg-box {
  border-top: 1px solid var(--grey200);
  width: 100%;
  padding: 10px;
  position: relative;
  line-height: 14px;
  position: relative;
  form {
    position: relative;
  }
}

.msg-send-icon {
  position: absolute;
  right: 0px;
  transform: rotate(50deg);
  font-size: 20px;
  color: var(--body-text-color);
  background: none;
  border: none;
  cursor: pointer;
  @include media-breakpoint-only(xs) {
  }
}
.msg-recorder {
  position: absolute;
  top: 0px;
  right: 0;
  font-size: 20px;
  color: var(--body-text-color);
  background: none;
  border: none;
  cursor: pointer;
  .stop_recording {
    color: var(--error);
  }
}
.recording_space {
  position: absolute;
  width: 90%;
  height: 30px;
  background: var(--white);
  z-index: 10;
  color: var(--body-text-color);
}

.cover {
  background: black;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 1001;
  opacity: 50%;
}

.pwa-btn {
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 400;
  background: transparent;
  border: 2px solid var(--white);
  color: var(--white);
  border-radius: 20px;
  padding: 6px 18px;
  transition-duration: 0.5s;
  &:hover {
    text-transform: uppercase;
    color: var(--primary-color)-color !important;
    background: var(--white);
    border: 2px solid var(--white) fff00;
    /* margin-right: 10px; */
    font-size: 15px;
    font-weight: 400;
    padding: 6px 18px;
    border-radius: 20px;
  }
}
.sec-btn {
  text-transform: uppercase;
  color: var(--white);
  background: transparent;
  border: none;
  margin-right: 10px;
  font-size: 15px;
  font-weight: 400;
  padding: 6px 18px;
  border: 2px solid var(--white) fff00;
  transition-duration: 0.5s;
  border-radius: 20px;
}
.sec-btn:hover {
  text-transform: uppercase;
  color: var(--primary-color)-color !important;
  background: var(--white) fff9e;
  border: none;
  margin-right: 10px;
  font-size: 15px;
  font-weight: 400;
  border: 2px solid var(--white) fff00;
  padding: 6px 18px;
  border-radius: 20px;
}
.downloadapp {
  color: var(--primary-color);
  position: absolute !important;
  bottom: 0rem;
  right: 0;
  left: 0;
  background-color: #f5f5f5;
}
.app-bg {
  background: var(--primary-color);
  padding: 15px !important;
  font-size: 18px !important;

  .pwa-btn {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 400;
    background: transparent;
    border: 2px solid var(--white);
    color: var(--white);
    border-radius: 20px;
    padding: 0px 15px;
    /* margin: 0px auto; */
    line-height: 40px;
    &:hover {
      text-transform: uppercase;
      background: var(--white);
      border: 2px solid #ffffff00;
    }
  }
  .sec-btn {
    text-transform: uppercase;
    color: var(--white);
    background: transparent;
    border: none;
    margin-right: 10px;
    font-size: 15px;
    font-weight: 400;
    padding: 0px 20px;
    border: 2px solid #ffffff00;
    transition-duration: 0.5s;
    border-radius: 20px;
    line-height: 40px;
  }
  .sec-btn:hover {
    text-transform: uppercase;
    color: var(--primary-color)-color !important;
    background: #ffffff9e;
    border: none;
    margin-right: 10px;
    font-size: 15px;
    font-weight: 400;
    border: 2px solid #ffffff00;
    padding: 0px 20px;
    border-radius: 20px;
    line-height: 40px;
  }
}
.app-bg:hover {
  background: var(--primary-color)-color !important;
}

.cookies-wrapper {
  box-shadow: 1px 0px 3px var(--card-shadow);
  border-radius: 6px;
  position: fixed;
  width: 700px;
  height: auto;
  left: 1%;
  bottom: 2%;
  background-color: var(--grey100);
  padding-left: 20px;

  color: var(--white);
  font-size: 15px;
  font-weight: 400;
  z-index: 2000;
  padding: 20px;

  @include media-breakpoint-down(lg) {
    width: 100%;
    text-align: center;
    left: 0%;
    bottom: 0%;
    padding: 15px;
    border-radius: 0px;
  }
}

.install-btn {
  box-shadow: 1px 0px 3px var(--card-shadow);
  border-radius: 6px;
  position: fixed;
  width: 400px;
  height: 125px;
  left: 1%;
  bottom: 2%;
  background-color: var(--primary-color);
  padding-left: 20px;
  text-align: center;
  color: var(--white);
  font-size: 15px;
  font-weight: 400;
  z-index: 15;
  padding: 20px;

  @include media-breakpoint-only(xs) {
    box-shadow: 1px 0px 3px var(--card-shadow);
    border-radius: 0px;
    position: fixed;
    width: 100%;
    height: auto;
    left: 0%;
    bottom: 0%;
    background-color: var(--primary-color);
    padding-left: 20px;
    text-align: center;
    color: var(--white);
    font-size: 18px;
    font-weight: 500;
    z-index: 1500;
    padding: 20px;
  }
}

.owl-dt-control-arrow-button[disabled] {
  color: rgba(0, 0, 0, 0.4) !important;
  text-transform: uppercase !important;
  font-size: inherit !important;
  border: none !important;
  padding: 0px !important;
  background: none !important;
  border-radius: 18px !important;
  transition-duration: 0.5s !important;
  font-weight: 400 !important;
  &:hover {
    color: rgba(0, 0, 0, 0.4) !important;
    text-transform: uppercase !important;
    font-size: inherit !important;
    border: none !important;
    padding: 0px !important;
    background: none !important;
    border-radius: 18px !important;
    transition-duration: 0.5s !important;
    font-weight: 400 !important;
  }
}

.chat-close {
  position: fixed;
  bottom: 62px;
  right: 5px;
  cursor: pointer;
  background-color: var(--primary-color);
  width: 40px;
  height: 40px;
  text-align: center;
  vertical-align: middle;
  line-height: 40px;
  border-radius: 50%;
  color: var(--white);
  font-size: 20px;
  z-index: 8;
  @include media-breakpoint-only(xs) {
  }
}

.typing-indicator {
  background-color: #e6e7ed;
  width: auto;
  border-radius: 50px;
  padding: 8px;
  display: table;
  position: relative;
  -webkit-animation: 2s bulge infinite ease-out;
  animation: 2s bulge infinite ease-out;
}

.typing-indicator span {
  height: 8px;
  width: 8px;
  float: left;
  margin: 0 1px;
  background-color: #9e9ea1;
  display: block;
  border-radius: 50%;
  opacity: 0.4;
}

.typing-indicator span:nth-of-type(1) {
  -webkit-animation: 1s blink infinite 0.3333s;
  animation: 1s blink infinite 0.3333s;
}

.typing-indicator span:nth-of-type(2) {
  -webkit-animation: 1s blink infinite 0.6666s;
  animation: 1s blink infinite 0.6666s;
}

.typing-indicator span:nth-of-type(3) {
  -webkit-animation: 1s blink infinite 0.9999s;
  animation: 1s blink infinite 0.9999s;
}

.destination_check {
  .destination_check_loader {
    display: inline-grid;
  }
  .typing-indicator {
    background-color: transparent;
    span {
      background-color: var(--white);
    }
  }
}
@-webkit-keyframes blink {
  50% {
    opacity: 1;
  }
}

@keyframes blink {
  50% {
    opacity: 1;
  }
}

@-webkit-keyframes bulge {
  50% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
}

@keyframes bulge {
  50% {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
}

.help-support-head-line {
  padding: 0px 32px;
  @include media-breakpoint-only(xs) {
    margin: 10px 0px;
    padding: 0px 12px;
  }
}

.support-bag {
  background: var(--light-cream);
}

.width-icon-help {
  width: 6%;
  @include media-breakpoint-only(xs) {
    width: 8%;
  }
}
.login-close {
  position: relative;
  float: right;
  font-size: 2.8rem;
  font-weight: 600;
  text-align: right;
  padding: 1rem 3rem;
  background: var(--white);
  border: none;
  border-radius: 0.6rem;

  color: var(--body-text-color);
  span {
    font-size: 2.8rem;
  }
}
.loginheader {
  padding: 0px 3rem 1rem 3rem;
}
.loginpadding_modal {
  padding: 2rem 3rem 3mrem 3rem;
}
.padding-help-support {
  padding: 1rem 3.5rem;
  input {
    font-size: 1.3rem !important;
  }

  @include media-breakpoint-only(xs) {
    padding: 2rem 1.2rem;
  }
  .card-header.active i {
    transform: rotate(180deg);
  }
  .help-social {
    a {
      color: var(--body-text-color);
    }
  }
}

.font-size-normal {
  font-size: 1.4rem !important;
}

.width-icon-close {
  width: 30px;
  height: 30px;
  font-size: 14px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  color: var(--body-text-color) 000;

  i {
    font-size: 24px;
  }
}

.not-found .no-places-bag {
  padding-top: 60px;
  height: 400px;
}

.sticky-index {
  z-index: 5 !important;
  overflow: unset;
}

.new-scroll ul li {
  background: unset;
  color: var(--white);
}

.new-submenu {
  .map_span {
    padding: 0px;
    margin-left: unset;
  }
  ul li a {
    color: var(--body-text-color) !important;
  }
  a.active {
    font-weight: 400 !important;
  }
  .accordion {
    .card-header {
      background: var(--white) !important;
      padding: 0px !important;
      color: var(--body-text-color) !important;
      border-bottom: 0px solid var(--grey100) !important;
      @include media-breakpoint-only(sm) {
        padding: 0px !important;
      }
      @include media-breakpoint-only(md) {
        padding: 0px !important;
      }
    }
    .card {
      border: unset !important;
    }
  }
  .card-header {
    span {
      text-transform: uppercase;
    }
    i {
      margin-right: unset !important;
    }
  }
}

.markerLabelClass {
  line-height: 20px;
  color: var(--white);
  font-weight: 400;
  -webkit-text-stroke: black;
  font-weight: 400;
  text-shadow: 0px 0px 2px var(--body-text-color);
}

.w-18 {
  width: 18% !important;
}

.font-size-11 {
  font-size: 11px !important;
}

.font-size-12 {
  font-size: 12px !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.border-bottom-gray {
  border-bottom: 1px solid #d0d0d0;
}

.btm5 {
  margin-bottom: 5px;
}
.create-trip-font {
  font-weight: 300;
  font-size: 36px;
  margin-bottom: 20px !important;
  letter-spacing: 0.2em;
  color: var(--primary-color);

  @include media-breakpoint-only(xs) {
    font-size: 17px;
  }
  @include media-breakpoint-only(sm) {
    font-size: 42px;
  }
  @include media-breakpoint-only(md) {
    font-size: 55px;
  }
}

.header-font-white {
  padding: 60px 0px;
  font-weight: 300;
  font-size: 36px;
  color: var(--white) !important;
  letter-spacing: 0.2em;
  display: block;
}
.header-font_nu {
  font-weight: 300;
  font-size: 36px;
  color: var(--body-text-color) !important;
  letter-spacing: 0.2em;
  display: block;
  @include media-breakpoint-only(sm) {
    font-weight: 300;
    font-size: 22px;
    letter-spacing: 0.2em;
  }
  @include media-breakpoint-only(xs) {
    font-weight: 300;
    font-size: 17px;
    letter-spacing: 0.2em;
  }
}
.header-font {
  font-weight: 300;
  font-size: 36px;
  color: var(--body-text-color) !important;
  letter-spacing: 0.2em;
  display: block;
  &:before {
    background-color: var(--secondary-color);
    content: "";
    position: absolute;
    width: 10%;
    height: 4px;
    left: 45%;
    bottom: -28px;
  }
  @include media-breakpoint-only(sm) {
    font-weight: 300;
    font-size: 22px;
    letter-spacing: 0.2em;
    &:before {
      background-color: var(--secondary-color);
      bottom: -20px;
      width: 20%;
      left: 40%;
    }
  }
  @include media-breakpoint-only(xs) {
    font-weight: 300;
    font-size: 17px;
    letter-spacing: 0.2em;
    &:before {
      background-color: var(--secondary-color);
      bottom: -15px;
      width: 20%;
      left: 40%;
      height: 2px;
    }
  }
}
.owl-dt-container-control-button .owl-dt-control-button-content {
  background: var(--grey200);
  height: 100%;
  width: 100%;
  transition: background-color 0.1s linear;
}
.bg-tick {
  background: var(--grey200);
  padding: 1rem;
  font-size: 1.2rem;
  color: var(--success);
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.bg-cross {
  background: var(--grey200);
  padding: 1rem;
  font-size: 1.2rem;
  color: var(--grey400);
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
}

.btn-secondary {
  text-transform: uppercase;
  background: var(--secondary-color);
  border-color: var(--secondary-color);
}

.datePicker-highlight {
  background-color: var(--primary-color) !important;
  color: var(--white) !important;
}

.rowmargin {
  margin: 70px 0px;
  @include media-breakpoint-only(xs) {
    margin: 35px 0px;
  }
}
.abtpleft {
  padding-left: 50px;
  @include media-breakpoint-only(xs) {
    padding-left: 0px;
  }
  @include media-breakpoint-only(sm) {
    padding-left: 0px;
  }
}
.abtp {
  font-size: 16px;
  margin-bottom: 20px;
  text-align: justify;
  color: var(--body-text-color);
  line-height: 30px;
}

.padding-row {
  padding: 20px 0px;
}
.nav-color {
  color: var(--white) !important;
}

.comp-lacation .small-heading {
  @include media-breakpoint-only(xs) {
    font-size: 12px;
  }
}

.img-slider-zoom {
  :hover img {
    -ms-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -o-transform: scale(1.1);
    transform: scale(1.1);
  }
  * {
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
  }
}
.row-padding {
  padding: 20px;
}
.text-overflow-wrap {
  overflow-wrap: break-word;
}
.overflow-hidden {
  overflow: hidden !important;
}

.parent {
  width: 45%;
  margin: 20px;
  height: 300px;
  border: 1px solid blue;
  overflow: hidden;
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.child {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  a {
    color: var(--white) fff !important;
    font-family: sans-serif;
    text-align: center;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 50px;
    cursor: pointer;
    /*text-decoration: none;*/
  }
}

.parent:hover .child,
.parent:focus .child {
  -ms-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
}

.font-size-small-mobile {
  @include media-breakpoint-only(xs) {
    font-size: 10px !important;
  }
}

.carousel-inner .carousel-item {
  transition-duration: 2s !important;
}

.trip_hold {
  @include media-breakpoint-only(xs) {
    .col-12 {
      padding-right: 25px !important;
      padding-left: 25px !important;
    }
  }
}

.padding-zero-mob {
  @include media-breakpoint-only(xs) {
    padding: 0px !important;
  }
  @include media-breakpoint-only(sm) {
    padding: 0px 15px !important;
  }
}

.padding-zero-web {
  padding: 0px !important;
  @include media-breakpoint-down(sm) {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}

.w-90 {
  width: 90% !important;
}

.drop {
  bottom: -63px;
  right: 3%;
  background-color: var(--white);
  margin: 0;
  min-width: 100px;
  max-height: 650px;
  overflow-y: auto;
  position: absolute;
  will-change: width, height;
  overflow: visible;
  z-index: 9;
  ul {
    li {
      line-height: 15px;
      min-height: 20px;
      cursor: pointer;
      &:hover {
        background-color: var(--grey200);
      }
      a {
        padding: 8px 10px !important;
        display: block;
        color: var(--body-text-color) !important;
        font-size: 16px !important;
        line-height: 22px;
      }
    }
  }
}

.dropbtn_aa {
  border: none;
  cursor: pointer;
}

.dropdown_aa {
  position: relative;
  display: inline-block;
}

.dropdown-content_aa {
  display: none;
  top: 45px;
  left: 9%;
  background-color: var(--white);
  margin: 0;
  min-width: 100px;
  max-height: 650px;
  overflow-y: auto;
  position: absolute;
  z-index: 9;
  border-radius: 4px;
  will-change: width, height;
  @media screen and (max-width: 767px) {
    left: unset;
    right: 0% !important;
  }
}

.dropdown-content_aa a {
  padding: 8px 15px !important;
  display: block;
  color: var(--body-text-color) !important;
  font-size: 14px !important;
  line-height: 22px;
  text-decoration: none;
  min-width: 120px;
  text-align: left;
}

.dropdown-content_aa a:hover {
  background-color: var(--grey200);
}

.dropdown_aa:hover .dropdown-content_aa {
  display: block;
}

// dropdown plugin
.multiselect-dropdown .dropdown-btn .dropdown-down {
  border-top: 7px solid var(--body-text-color) !important;
  border-left: 7px solid transparent !important;
  border-right: 7px solid transparent !important;
  margin-left: 10px !important;
  @include media-breakpoint-only(xs) {
    border-top: 5px solid var(--body-text-color) !important;
    border-left: 5px solid transparent !important;
    border-right: 5px solid transparent !important;
  }
}

.multiselect-dropdown .dropdown-btn .dropdown-up {
  border-bottom: 7px solid var(--body-text-color) !important;
  border-left: 7px solid transparent !important;
  border-right: 7px solid transparent !important;
  margin-left: 10px !important;
  @include media-breakpoint-only(xs) {
    border-bottom: 5px solid var(--body-text-color) !important;
    border-left: 5px solid transparent !important;
    border-right: 5px solid transparent !important;
  }
}

.multiselect-dropdown .dropdown-btn {
  line-height: 12px !important;
  font-size: 13px !important;
  font-weight: 100 !important;
  color: var(--body-text-color) !important;
  min-width: 150px !important;
  @include media-breakpoint-only(xs) {
    font-size: 11px !important;
    line-height: 9px !important;
  }
}

.multiselect-item-checkbox input[type="checkbox"] + div:before {
  color: var(--body-text-color) !important;
  border: 1px solid var(--body-text-color) !important;
  border-radius: 5px !important;
  width: 15px !important;
  height: 15px !important;
}

.multiselect-item-checkbox input[type="checkbox"]:checked + div:before {
  background: var(--secondary-light) !important;
  border: 1px solid var(--secondary-light) !important;
}

.multiselect-dropdown .dropdown-btn .selected-item {
  border: 1px solid var(--primary-color) !important;
  background: var(--primary-color) !important;
}

.multiselect-item-checkbox input[type="checkbox"] + div:after {
  border-width: 0 0 1px 1px !important;
}

.dropdown-list .item2 {
  max-height: unset !important;
  overflow: hidden !important;
}

.multiselect-item-checkbox input[type="checkbox"] + div {
  font-size: 11px !important;
}

.dropdown-list {
  z-index: 8 !important;
}

.selectdiv {
  position: relative;
  float: left;
  @include media-breakpoint-only(xs) {
    position: relative;
    float: left;
    padding-left: 0px;
  }
}

.selectdiv:after {
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  font: 16px "Consolas", monospace;
  color: var(--body-text-color);
  right: 13px;
  top: 15px;
  padding: 0 0 2px;
  position: absolute;
  pointer-events: none;
  @include media-breakpoint-only(xs) {
    border-top: 0.2em solid;
    border-right: 0.2em solid transparent;
    border-left: 0.2em solid transparent;
    right: 9px;
    font: 16px "Consolas", monospace;
    color: var(--body-text-color);
    right: 9px;
    top: 15px;
  }
}
.h-label {
  text-align: center;
  @media only screen and (max-width: 1024px) {
    text-align: left;
    margin: 10px 0px;
  }
}
.h-avl-box {
  padding: 20px;
  border-radius: 6px;
  background: var(--grey200) eee6b;
  margin-top: 25px;
  @media only screen and (max-width: 1024px) {
    padding: 15px 25px 25px 25px;
    .flex-20 {
      width: 100%;
      flex-basis: 100%;
    }
    .marginleft15 {
      margin-top: 15px;
    }
  }
}
.selectdiv select {
  border: 1px solid var(--grey200);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: auto !important;
  padding: 10px 30px 10px 20px;
  font-size: inherit !important;
  line-height: 18px;
  color: var(--body-text-color);
  background-color: var(--white) fff;
  background-image: none;
  border-radius: 0.6rem;

  @include media-breakpoint-only(xs) {
    padding: 7px 18px 6px 12px;
    margin-top: 0px !important;
  }
}

.selectdiv select:focus {
  outline: none !important;
}

.image-blur {
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s linear;
  img {
    -webkit-filter: blur(50px);
    filter: blur(50px);
    width: 100%;
    height: 100%;
    opacity: 1;
  }
}

.grecaptcha-badge {
  bottom: 5px !important;
}
.align-self-baseline {
  align-self: baseline;
}
// .login_captcha {
//   overflow: hidden;
// }

.theme-green .bs-datepicker-head {
  background-color: var(--white) !important;
  button {
    color: black !important;
  }
}

.bs-datepicker-head button.next span,
.bs-datepicker-head button.previous span {
  font-size: 28px;
  line-height: 1;
  display: inline-block;
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  color: black;
}
.bs-datepicker-body table td span.selected,
.bs-datepicker-body table td.selected span {
  background-color: var(--primary-color) !important;
}

.bs-datepicker-body table td span.is-other-month {
  display: none;
}

.bs-datepicker-body {
  padding: 10px;
  border-radius: 0 0 3px 3px;
  min-height: 200px !important;
  min-width: 278px !important;
  border: none;
}

.bs-datepicker-body table th {
  font-size: 13px;
  color: gray;
  padding-bottom: 2px;
  font-weight: 300;
  text-align: center;
}
.bs-datepicker-head button.current {
  border-radius: 15px;
  max-width: 155px;
  margin: 0 -11px;
  font-size: 15px !important;
  color: black !important;
}

.bs-datepicker-body table.days span.in-range {
  background-color: var(--primary-light) !important;
}
.bs-datepicker-body table.days span {
  width: 40px;
  color: var(--body-text-color);
  height: 40px;
  font-size: 13px;
  font-weight: 300;
  line-height: 40px;
  border-radius: unset !important;
  border: 1px solid #e8e8e8;
  @include media-breakpoint-only(xs) {
    height: 30px;
    line-height: 30px;
  }
}

.bs-datepicker {
  border-radius: 5px;
}
.bs-datepicker-head {
  min-width: 270px;
  height: 50px;
  padding: 10px;
  border-radius: 3px 3px 0 0;
  text-align: center;
}

.bs-datepicker-body table td span.disabled,
.bs-datepicker-body table td.disabled span {
  color: #c3c3c3;
}

.bs-datepicker-head button[disabled],
.bs-datepicker-head button[disabled]:hover,
.bs-datepicker-head button[disabled]:active {
  /* background: rgba(221, 221, 221, 0.3); */
  color: var(--grey100) !important;
  display: none;
  cursor: not-allowed;
}
.bs-datepicker-head button.next,
.bs-datepicker-head button.previous {
  border-radius: 50%;
  width: 0px;
  height: 30px;
}
.bs-datepicker-head button.next span {
  margin-right: -80px;
}
.bs-datepicker-head button.previous span {
  margin-left: -110px;
}

// .bs-datepicker-head button.previous span{
//     display: none;
// }

.bs-datepicker-body table td.is-highlighted:not(.disabled):not(.selected) span,
.bs-datepicker-body table td span.is-highlighted:not(.disabled):not(.selected) {
  background-color: #b1d7d8;
  transition: 0s;
  border-radius: unset !important;
  border: 1px solid black;
  box-sizing: border-box;
}

// .theme-green .bs-datepicker-body table td span.selected,
// .theme-green .bs-datepicker-body table td.selected span,
// .theme-green .bs-datepicker-body table td span[class*="select-"]:after,
// .theme-green .bs-datepicker-body table td[class*="select-"] span:after {
//     background-color:  var(--secondary-color)  !important;

// }

.pac-container {
  z-index: 100000 !important;
}
.multiple-dest {
  display: inline-block;
  height: 25px;
  font-size: 12px;
  font-weight: 500;
  color: var(--white);
  line-height: 25px;
  padding: 0 12px;
  border-radius: 16px;
  background-color: var(--grey200);
  margin-right: 3px;
  margin-bottom: 10px;
  opacity: 1;
  -webkit-animation: chipAnimate 0.3s forwards;
  animation: chipAnimate 0.3s forwards;
}
.inline-button {
  padding: 0px !important;
  border-radius: 50% !important;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
}

.btn-outline-light {
  &:hover {
    color: var(--white) !important;
    background-color: unset !important;
  }
  &:focus {
    box-shadow: unset !important;
  }
}
.a-color {
  color: var(--primary-color)-color !important;
  &:hover {
    color: var(--primary-color)-color !important;
  }
}
.modal-body video {
  height: 80vh;
  max-width: 350px;
}
.headline-home1 {
  margin: 40px 0px;
}
.modal-video {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  .modal-content {
    border: none;
    background-color: transparent;
  }
  .modal-body {
    padding: 0px;
    text-align: center;
  }
  .video_modal_close {
    position: absolute;
    top: 10px;
    right: 0px;
    font-size: 18px;
    color: var(--white);
    cursor: pointer;
  }
}
.about_main {
  font-size: 14px;
  text-align: center;
  div {
    margin: 5px 0px;
  }
  span {
    margin: 0px 5px;
  }
  .header {
    font-weight: 300;
    font-size: 17px;
    color: var(--body-text-color) !important;
  }
}

.login-eye {
  top: 6px;
  right: 10px;
}
.login-sign-btn {
  display: inline;
  cursor: pointer;
}
.fb-bag {
  background: #3b5998;
  padding: 7px 12px;
  text-align: center;
  color: var(--white);
  border-radius: 50%;
  border: none;
}
.gmail-bag {
  width: 31px;
  height: 31px;
}
.checkbox_th {
  width: 20px !important;
  height: 20px !important;
  margin-right: 10px;
}

.defaultht {
  padding-top: 105% !important;
  padding-bottom: 20px !important;
}
.fa-times-circle {
  float: right;
  cursor: pointer;
  color: var(--white);
  padding: 9px 6px 0px 6px;
  font-size: 14px;
}
.dropdown {
  .fa-ellipsis-v {
    padding: 23px 26px;
    @include media-breakpoint-only(xs) {
      padding: 12px 0px 12px 30px;
    }
  }
}
.drop_down {
  .fa-ellipsis-v {
    padding: 20px;
  }
}
.text-truncate {
  text-align: left;
  overflow: hidden !important;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.kmspan {
  margin-right: 10px;
}

.edit-profile {
  margin-top: 20px;
}
.input-text {
  font-size: 14px;
  line-height: 24px;
  color: var(--body-text-color);
}
.display-content {
  display: contents;
}

@media only screen and (max-width: 575px) {
  .bs-datepicker {
    display: flex;
    align-items: stretch;
    flex-flow: row wrap;
    background: var(--white);
    box-shadow: 0 0 10px 0 var(--card-shadow);
    position: relative;
    z-index: 1;
  }
}
.myd-none {
  display: none;
  color: var(--success);
}
.container {
  max-width: 85% !important;
}
@media only screen and (min-width: 1200px) {
  .container {
    max-width: 85%;
  }
}

@media only screen and (max-width: 550px) {
  .container {
    width: 100% !important;
    padding-right: 0px;
    padding-left: 0px;
    margin-right: auto;
    margin-left: auto;
  }
}

/* new color codes */

.pr-3 {
  padding-right: 1rem;
}
.new_secondarycolor2 {
  color: var(--primary-light) !important;
}
.new_secondarycolor1 {
  color: var(--grey200) !important;
}
.new_secondarycolor3 {
  color: var(--secondary-light) !important;
}

.list-group-flush .list-group-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
  line-height: 30px;
}
.filtermob {
  margin-bottom: 8px;
  @include media-breakpoint-only(xs) {
    margin-bottom: 10px;
  }
}
.w_50 {
  flex-basis: 50%;
}
.theme-green .bs-datepicker-body table td.week span {
  color: var(--primary-color);
}

@media only screen and (max-width: 1500px) {
  .widget_overflow {
    overflow: hidden;
    white-space: nowrap;
    width: 112px;
    text-overflow: ellipsis;
  }
  .widgetoverflow1 {
    overflow: hidden;
    width: 94px;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.tb-privacy-headg {
  text-decoration: none !important;
}

@media (max-width: 575.98px) {
  .map_single_view .width-scrap-mob-list {
    width: 100% !important;
    margin: 0px 0px 20px 0px !important;
  }
}

.swiper-button-disabled {
  display: none;
}
.noScroll {
  overflow: hidden;
}

.trip_creation_search {
  .tb_search_icon {
    display: none !important;
  }
}

.toast-container .ngx-toastr {
  font-weight: 300 !important;
  color: var(--body-text-color) !important;
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 20px;
  line-height: 26px;
  width: 300px;
  background: none;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 0px;
  box-shadow: none;
  font-size: 15px;
}

.toast-container .ngx-toastr .toast-close-button {
  position: relative;
  right: -12px;
  top: -20px;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: inherit;
  text-shadow: 0 1px 0 var(--white);
  opacity: 0.5;
  background: transparent;
  border: 0;
  padding: 0;
  span {
    font-size: 20px;
  }
}
.margin-row-last {
  margin-bottom: 70px;
}
.opacity-5 {
  opacity: 0.5;
}
.opacity-7 {
  opacity: 0.7;
}

// Sweet alert
.swal2-popup .swal2-styled:focus {
  box-shadow: none !important;
}
.swal2-styled.swal2-confirm {
  text-transform: uppercase;
  font-size: 13px !important;
  border: 2px solid var(--secondary-color) !important;
  padding: 6px 15px !important;
  background: var(--secondary-color) !important;
  color: var(--white) !important;
  border-radius: 18px !important;
  transition-duration: 0.5s !important;
  font-weight: 400 !important;
  margin: 3px !important;
}

.swal2-styled.swal2-confirm:hover {
  border: 2px solid var(--secondary-color) !important;
  background: var(--white) !important;
  color: var(--secondary-color) !important;
  font-weight: 400 !important;
}

.swal2-styled.swal2-cancel {
  background: transparent !important;
  /* display: inline!important; */
  text-align: center !important;
  padding: 6px 15px !important;
  text-transform: uppercase !important;
  border-radius: 18px !important;
  transition-duration: 0.5s !important;
  font-size: 13px !important;
  font-weight: 300 !important;
  border: 2px solid transparent !important;
  transition-duration: 0.5s !important;
  margin: 3px !important;
  color: var(--body-text-color) !important;
  &:hover {
    background: #f87b7b99 !important;
    color: var(--white) !important;
  }
}

.swal2-title {
  position: relative;
  max-width: 100%;
  margin: 0 0 0.4em;
  padding: 0;
  color: #595959;
  text-align: center;
  text-transform: none;
  word-wrap: break-word;
  font-size: 15px !important;
  font-weight: 300 !important;
}

.swal2-popup {
  width: 500px !important;
  padding: 20px;
  margin-bottom: 400px !important;
  @include media-breakpoint-only(xs) {
    margin-bottom: 60% !important;
  }
}

.swal2-actions {
  flex-direction: row-reverse !important;
}

.extra-small-text {
  font-size: 11px;
  font-weight: 300;
  line-height: 18px;
}

.sec-small-heading {
  font-size: 9px;
  font-weight: 300;
  line-height: 15px;
}
.lightbox .lb-image {
  height: auto;
  max-width: inherit;
  max-height: none;
  border-radius: 3px;
  margin: 4px !important;
  border: 4px solid var(--white);
}

//Book Modal

.book-modal-content {
  width: 600px;
  @include media-breakpoint-only(xs) {
    width: unset;
  }
}

.header-font-dash {
  background-color: #ee6055;
  position: absolute;
  width: 10%;
  height: 4px;
}

.book-modal-padding {
  padding: 25px;
}

.contact-text-area {
  height: 200px;
  @include media-breakpoint-down(sm) {
    height: 80px;
  }
}
.contact-image {
  padding: 60px;
  @include media-breakpoint-down(sm) {
    padding: 20px;
  }
  padding-top: 0px;
}
.contact-address {
  padding-right: 15px;
  @include media-breakpoint-down(sm) {
    padding: 0px;
  }
}
.team_member {
  background-color: var(--white);
  justify-content: center;
  .team_image img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
  }
}
.chevron::before {
  border-style: solid;
  border-width: 0.09em 0.09em 0 0;
  content: "";
  display: inline-block;
  height: 0.5em;
  left: 0em;
  position: relative;
  top: 0.4em !important;
  transform: rotate(-45deg);
  vertical-align: top;
  width: 0.5em;
}

.chevron.right:before {
  left: 0;
  transform: rotate(45deg);
}

.chevron.bottom:before {
  top: 0;
  transform: rotate(135deg);
}

.chevron.left:before {
  left: 0.25em;
  transform: rotate(-135deg);
}

.chevron.bottom.activeAccord {
  transform: rotate(180deg);
  line-height: 12px;
}
.chevron.bottom.activePoiAccord {
  transform: rotate(180deg);
  line-height: 12px;
}
.chevron-medium::before {
  border-style: solid;
  border-width: 0.07em 0.07em 0 0;
  content: "";
  display: inline-block;
  height: 0.8em;
  left: 0em;
  position: relative;
  top: 0.4em;
  transform: rotate(-45deg);
  vertical-align: top;
  width: 0.8em;
}

.chevron-medium.right:before {
  left: 0;
  transform: rotate(45deg);
}

.chevron-medium.bottom:before {
  top: 0;
  transform: rotate(135deg);
  transition: 0.5s ease-in-out;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: var(--white);
  background-clip: border-box;
  border: none;
  border-radius: 6px;
}
.chevron-medium.left:before {
  left: 0.25em;
  transform: rotate(-135deg);
}
.new_greylightest {
  color: var(--grey100);
}
.new_greylighter {
  color: var(--grey200);
}
.gark-dark {
  color: #5c5c5c !important;
}

.white-label-heading {
  font-size: 40px;
  font-weight: 400;
  @include media-breakpoint-down(lg) {
    font-size: 30px;
  }
}

.wl-row-ht {
  height: 90vh;
  @include media-breakpoint-down(lg) {
    height: 100vh;
  }
}
.wl-wrap {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);

  @include media-breakpoint-down(lg) {
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
    width: 100%;
  }
}
.border-right-lighter-grey {
  border-right: 1px solid var(--grey200);
  @include media-breakpoint-down(lg) {
    border-right: 0px solid var(--grey200);
    border-bottom: 1px solid var(--grey200);
  }
}
.custom-locality-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 600px;
  pointer-events: auto;
  background-color: var(--white);
  background-clip: padding-box;
  border-radius: 0.3rem;
  outline: 0;
  padding: 25px;
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}
.overflow-hidden {
  overflow: hidden;
}

@page {
  size: auto !important;
}

@media print {
  @page {
    size: auto !important;
  }
}

.print_footer {
  position: fixed;
  bottom: 10px;
  right: 10px;
}
.print_page_break {
  page-break-inside: avoid;
}

.anchor-button {
  border: 0px solid transparent;
  background: var(--white);
  text-transform: capitalize !important;
  color: var(--primary-color)-color !important;
  font-size: 13px !important;
  font-weight: 300 !important;
  &:hover {
    text-transform: capitalize !important;
    border: 0px solid transparent !important;
    color: var(--primary-color)-color !important;
    background: transparent !important;
    font-size: 13px !important;
    font-weight: 300 !important;
  }
  &:disabled {
    font-size: 13px !important;
    padding: 0px !important;
    &:hover {
      border: 0px solid transparent !important;
      color: var(--primary-color)-color !important;
      background: transparent !important;
      font-size: 13px !important;
      font-weight: 300 !important;
      text-transform: capitalize !important;
      padding: 0px !important;
    }
  }
}

.paddingr-7 {
  padding-right: 7px;
}

.img-border-radius {
  img {
    border-radius: 6px 0px 0px 6px;
    @media only screen and (max-width: 991px) {
      border-radius: 6px 6px 0px 0px;
    }
  }
}
.text-align-last {
  text-align-last: center;
}
#wh-widget-send-button {
  bottom: 45px !important;
  z-index: 15 !important;
}
.loading-text {
  text-align: center;
  .flight_loading {
    width: 50%;
    @include media-breakpoint-down(sm) {
      width: 100%;
    }
    -webkit-animation: spin 4s linear infinite;
    -moz-animation: spin 4s linear infinite;
    animation: spin 4s linear infinite;
  }
}
.loading-text {
  @include media-breakpoint-down(sm) {
    left: 20% !important;
    transform: translate(-12%, -95%) !important;
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.whatsapp_bottom {
  position: fixed;
  width: 40px;
  height: 40px;
  bottom: 58px;
  right: 16px;
  background-color: #25d366;
  color: var(--white);
  border-radius: 50px;
  text-align: center;
  font-size: 29px;
  box-shadow: 2px 2px 3px var(--card-shadow);
  z-index: 10;
  .fab,
  .far {
    font-weight: 400;
    color: var(--white);
    font-size: 28px;
    margin-top: 6px;
  }
}

.my-float {
  margin-top: 16px;
}
:focus {
  outline: none;
}

// DYNAMIC SWIPER CSS
.swiper-container-horizontal
  > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic
  .swiper-pagination-bullet {
  transition: 0.2s transform, 0.2s left;
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
  transform: scale(0.33);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
  transform: scale(0.66);
}

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
  transform: scale(0.66);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
  transform: scale(0.33);
}
.test-msg-wrapper {
  position: absolute;
  top: 0px;
  z-index: 20;
  width: 100%;
  display: flex;
  height: 50px;
  align-items: center;

  @media only screen and (max-width: 991px) {
    height: auto;
    top: 92px;
    padding: 10px;
    align-items: center;
    z-index: 2;
  }
}

.sticky_hotel_right {
  position: -webkit-sticky;
  position: sticky;
  top: 100px;
}
.gm-style-iw-t {
  button {
    background: none;
    display: block;
    border: 0px;
    margin: 0px;
    padding: 0px 4px 0px 1px;
    text-transform: none;
    appearance: none;
    position: absolute;
    cursor: pointer;
    user-select: none;
    top: -6px;
    right: -4px;
    width: 30px;
    height: 30px;
  }
}

.pagination {
  margin-top: 40px;
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem;
}
.page-link {
  font-weight: 500;
  width: 30px;
  height: 30px;
  font-size: 13px;
  line-height: 15px;
  position: relative;
  display: flex;
  margin: 0px 5px;
  align-items: center;
  justify-content: center;
  color: var(--primary-color);

  background-color: var(--white);
  border: none;
  &:hover {
    z-index: 2;
    color: var(--body-text-color);
    text-decoration: none;
    background-color: transparent;
    border-color: transparent;
  }
  &:focus {
    box-shadow: none;
  }
}

.page-item.active .page-link {
  z-index: 1;
  color: var(--white);
  background: var(--primary-color);
  border-color: var(--primary-color);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 31px;
  text-align: center;
  margin: 0px 5px;
  padding: 0px;
  &:hover {
    color: var(--white) !important;
  }
}

.page-item:first-child .page-link {
  background: var(--white);
  width: 30px;
  height: 30px;
  margin-left: 0;
  border-radius: 50%;
  margin-right: 5px;
  border: 1px solid var(--grey200);
  padding: 8px 9px;
}
.page-item:last-child .page-link {
  background: var(--white);
  width: 30px;
  height: 30px;
  margin-left: 0;
  border-radius: 50%;
  margin-right: 5px;
  border: 1px solid var(--grey200);
  padding: 8px 10px;
  margin-left: 5px;
}

.modal {
  z-index: 89999;
  background: #00000069;
}

.icon-disabled {
  cursor: default;
  .fas {
    color: var(--grey200);
  }
}
.owl-dt-timer-content .owl-dt-timer-input {
  font-size: 1.8em !important;
}
.owl-dt-control-arrow-button svg {
  width: 70% !important;
  height: 70% !important;
}

.special-source-datepicker {
  left: 110px !important;
}

.image_curve {
  border-radius: 9px;
  overflow: hidden;
}

.nomargin {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.cdk-overlay-pane {
  top: calc(50vh - 68px) !important;
  left: calc(50vw - 125px) !important;
}
.iti-icon-ht-experience {
  color: var(--secondary-color);
  font-size: 1.4rem;
}
