export const environment = {
  production: true,
  apiEndpoint: 'https://dev.tripsica.com/api/v1/tvb/',
  apiEndpointv2: 'https://dev.tripsica.com/api/v2/tvb/',
  aiApiEndpint: 'https://components.tripsicax.com/api-auto/v1/',
  createEditableTrip: true,
  defaultImages: {
    hotel: 'cMtvOObHSPKTEhiIxGWs',
    poi: 'Y7JMMwxNRWelMxWvOsUj',
    activity: 'Tj4pBEqS1uBLvloXVpGQ',
    all: '04dw0imTTqRupPiod98v',
  },
  filestack:'AMWLTZ7TPGdKVmXWkeY9Az',
  svgIconsPath:'https://dev.tripsica.com/itinerary/',
};
