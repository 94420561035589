// map-right-view
.hidden_scraps {
  height: 500px;
  overflow-y: scroll;
  position: relative;
  @include media-breakpoint-down(xs) {
  }
}

.map_scrap_display {
  width: 100%;
  height: 500px;
  @include media-breakpoint-down(sm) {
    height: 300px;
  }
}

.hidden_scraps_new {
  height: calc(100vh - 141px);
  overflow-y: scroll;
  position: relative;
  @include media-breakpoint-down(xs) {
    overflow-x: scroll;
    overflow-y: visible;
    white-space: nowrap;
    height: auto;
  }
}

.map_scrap_display_new {
  width: 100%;
  height: calc(100vh - 200px);
  @include media-breakpoint-only(xs) {
    height: calc(100vh - 336px);
  }
  @include media-breakpoint-only(sm) {
    height: calc(100vh - 100px);
  }
}

.map_scrap_display_new_place {
  width: 100%;
  height: calc(100vh - 141px);
  @include media-breakpoint-down(sm) {
    height: 300px;
  }
}

.hidden_scraps.map_view {
  @include media-breakpoint-down(sm) {
    overflow-x: scroll;
    overflow-y: visible;
    white-space: nowrap;
    height: auto;
    margin-top: 15px;
    .map_scrap {
      width: 275px;
      margin-right: 15px;
      margin-bottom: unset;
    }
  }
}

.map_scrap {
  margin-bottom: 10px;
  width: 280px;
  display: inline-block;
  background-color: var(--white);
  box-shadow: var(--card-shadow) 0 4px 10px 0;
  .add_remove_trip {
    height: 18px;
    width: 100%;
    background-color: var(--white);
    font-size: 10px;
    text-align: center;
    line-height: 18px;
    text-transform: uppercase;
    color: var(--body-text-color);
  }
  // .tb-scraps-weblink {
  //     line-height: 18px;
  // }
  .scrap_trip_add.add_to_trip {
    color: var(--body-text-color);
  }
  .scrap_trip_add {
    background: var(--white);
    color: var(--secondary-light);
    text-align: center;
    width: 100%;
    height: 18px;
    font-size: 11px;
    text-transform: uppercase;
    font-weight: normal;
  }
  .exp_scrap_pic {
    position: relative;
    min-width: 110px;
    // background-image: url(/assets/images/iti-scrap-image.jpg)
  }
  .iti_scrap_detail {
    // min-width: 163px;
    position: relative;
    width: calc(100% - 111px);
  }
  .iti_scrap_title_icon {
    height: 22px;
    img {
      height: 14px;
    }
    @include media-breakpoint-only(xs) {
      img {
        height: 11px;
      }
    }
    // line-height: 30px;
    // margin-left: 5px;
  }
  .iti_scrap_title {
    position: relative;
    // width: 100%;
    color: var(--body-text-color) !important;
    font-weight: 300;
    font-size: 16px;
    @include media-breakpoint-only(xs) {
      font-weight: 500;
      font-size: 10px;
    }
  }
  .truncate_iti {
    width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .iti_scrap_direction {
    position: relative;
    float: right;
    .tb-navlocation {
      height: 18px;
    }
  }
  .iti_scrap_rating {
    // float: left;
    font-size: 11px;
    // margin-left: 5px;
    line-height: 26px;
    ul {
      margin-bottom: 0px;
    }
  }
  .iti_scrap_locality {
    // float: right;
    color: var(--body-text-color);
    font-size: 11px;
  }
  .font-size-small {
    font-size: 10px !important;
  }
}

// end
// end
.circle {
  border-radius: 50%;
}

// .tb_profile_follow_new {
//     background-color:   var(--primary-color) ;
//     border: 1px solid   var(--primary-color) ;
//     color: var(--white);
//     font-size: 12px;
//     line-height: 30px;
//     border: none;
// }
.trip_number {
  font-size: 18px;
  color: var(--body-text-color);
}

.font-size-close-map {
  font-size: 18px;
}

.map_scrap_scroll {
  justify-content: space-around;
  flex-wrap: wrap;
  @include media-breakpoint-down(sm) {
    flex-wrap: unset;
    justify-content: unset;
    padding: 15px 0px;
  }
}

.mobile-map {
  width: 23%;
  margin: 0 1% 30px 1%;
  border-radius: 6px;
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
  @include media-breakpoint-down(md) {
    .mobile-map {
      width: 100%;
    }
  }
}

.list_scrap_scroll {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @include media-breakpoint-only(xs) {
    justify-content: center;
  }
}

.inMapView {
  width: 50%;
  @include media-breakpoint-down(sm) {
    width: 100%;
    height: unset;
  }
  .list_scrap_scroll {
    height: calc(100vh - 211px);
    overflow-y: scroll;
    position: relative;
    @include media-breakpoint-down(sm) {
      width: 100%;
      height: unset;
    }
  }
  .mobile-map {
    margin: 0px auto 30px auto;
    width: 46%;
  }
  .main_outer {
    width: 250px;
  }
}

.inplaceMapview {
  width: 50%;
  height: calc(100vh - 211px);
  overflow-y: scroll;
  position: relative;
  @include media-breakpoint-down(sm) {
    width: 100%;
    height: unset;
    display: flex;
  }
  .mobile-map {
    width: 250px;
  }
}

.map_single_view {
  display: flex;
  @include media-breakpoint-down(sm) {
    display: block;
  }
}

.map_mob-scroll {
  @include media-breakpoint-down(sm) {
    height: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: left !important;
    padding: 10px 0px;
    overflow-y: scroll;
    position: relative;
  }
  .mobile-map {
    @include media-breakpoint-down(sm) {
      margin: 0px 10px 0px 0px;
      width: 200px;
    }
    .main_outer {
      @include media-breakpoint-down(sm) {
        width: 200px;
      }
    }
    .exp_holder {
      @include media-breakpoint-down(sm) {
        width: 200px !important;
        margin: unset !important;
      }
    }
  }
}

.w-100-new {
  width: 100%;
}

.places-added-map_scrap {
  display: flex;
  @include media-breakpoint-down(sm) {
    display: block;
  }
  .map_scrap_display_new {
    @include media-breakpoint-only(xs) {
      height: calc(100vh - 225px);
    }
    @include media-breakpoint-only(sm) {
      height: calc(100vh - 100px);
    }
  }
}

.justify-custom {
  justify-content: center;
}

.align-custom {
  @include media-breakpoint-down(sm) {
    align-items: center;
    justify-content: center !important;
  }
}

.mob-none {
  @include media-breakpoint-down(sm) {
    display: none;
  }
}

.width-scrap-mob-list {
  @include media-breakpoint-only(xs) {
    width: 100%;
  }
  @include media-breakpoint-only(sm) {
    width: 45%;
  }
}

.width-scrap-mob-map {
  @include media-breakpoint-down(sm) {
    width: 280px;
  }
}

@media only screen and (min-width: 575px) and (max-width: 991px) {
  .mobile-map {
    width: 48%;
    margin: 0 1% 30px 1%;
  }
  #map_view_holder .mobile-map {
    width: 100%;
    margin: 20px 20px 0px 20px;
  }
}
