// contact-us
.contact-us-outer {
    .input-field  {
        font-size: 13px!important;
    }
}

.btn-black {
    background: var(--body-text-color)!important;
    border-color: var(--body-text-color)!important;
}

.btn-black:hover {
    color: var(--white)!important;
}

.contact-radio .btn-new-contact-us.selected-active {
    background:  var(--secondary-light)  ;
    color: var(--white);
    padding: 5px 70px;
    border: 1px solid  var(--secondary-light)  ;
    border-radius:6px;
}

.btn-new-contact-us {
    color:   var(--body-text-color) ;
    border: 1px solid  var(--secondary-light)  ;
    padding: 5px 60px;
    background: transparent;
    font-weight: 300;
    cursor: pointer;
    border-radius:6px;
}

.btn-new-contact-us:focus {
    outline: none !important;
}

// end
// content
.content-create {
    width: 50%;
    @include media-breakpoint-only(xs) {
        width: 100%;
    }
}

.new-content .new_input_home {
    margin: 10px;
}

.c-radio {
    display: block;
    
    position: relative;
    padding-left: 27px;
    margin-bottom: 0px;
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding-right: 20px;
}

/* Hide the browser's default radio button */
.c-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 1px;
    left: 1px;
    height: 18px;
    width: 18px;
    background-color:  var(--grey200) ;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.c-radio:hover input ~ .checkmark {
  background-color: var(--grey200);
}

/* When the radio button is checked, add a blue background */
.c-radio input:checked ~ .checkmark {
  background-color: var(--primary-color);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.c-radio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.c-radio .checkmark:after {
    top: 5px;
    left: 5px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: var(--white)  ;
}
.social-cicon {
    font-size: 15px;
    color: var(--white);
    background:var(--primary-color);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    line-height: 40px !important;
    text-align: center;
    margin: 10px 10px 0px 0px;
}
@media only screen and (max-width:1024px){
    .btm10 {
    margin-bottom:10px !important;
}
}
