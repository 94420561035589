@import url("https://fonts.googleapis.com/css?family=Great+Vibes&display=swap");

.custom_nav {
  .nav-item {
    line-height: 8rem;
    transition-property: background;
    transition-duration: 0.7s;
    transition-timing-function: linear;
    &:hover {
      background: var(--white);
    }
    &:focus {
      outline: none !important;
    }
  }
  .nav-item a {
    padding: 0px 1.5rem;
  }
  .nav-item .special_nav_a {
    padding: 0px 0px !important;
  }
}

.navbar-brand:hover,
.navbar-brand:focus {
  outline: none !important;
}
.header_logo {
  height: 55px !important;
}
.navbar {
  height: 8rem;
  padding: 0rem !important;

  z-index: 1000;
  @include media-breakpoint-only(xs) {
    height: 5rem;
  }
  @include media-breakpoint-only(sm) {
    height: 5rem;
  }
  @include media-breakpoint-only(md) {
    height: 5rem;
  }
}

.navbar-background {
  background-color: var(--body-background) !important;
  box-shadow: 0px 0px 3px var(--grey200);
}

.nav-home {
  background-color: var(--body-background) !important;
  z-index: 999;

  .input-group {
    .fa-times {
      @include media-breakpoint-down(md) {
        color: var(--grey100) !important;
      }
    }
  }
  @include media-breakpoint-down(md) {
    position: relative !important;
  }
}

.nav-mob-height {
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.active_trip_menu {
  text-decoration: none;
  color: var(--white) !important;
  &:hover {
    text-decoration: none;
    color: var(--white);
  }
}
.book_dropdown {
  top: 8.1rem;
  background: var(--white);
  min-width: 12rem;
  max-width: 22rem;
  border-radius: 0.6rem;
  z-index: 99;
  padding: 1rem 0rem;
  white-space: nowrap;
  ul li {
    padding: 1rem 2rem !important;
    display: block;

    &:hover {
      background-color: var(--grey100);
    }
  }
}

.menu_buttons {
  padding: 0rem 1rem;
}

.my_trips_list {
  cursor: pointer;
  position: relative;
  display: flex;
  padding: 0rem 0rem;
  align-items: center;
  width: 100%;
  min-height: 4rem;
  @include media-breakpoint-only(md) {
    min-width: 17rem;
  }
  @include media-breakpoint-down(sm) {
  }
  .trips-dropdown-height {
    @include media-breakpoint-only(xs) {
      max-height: 18rem;
      overflow-y: auto;
    }
    .w-60 {
      width: 60%;
    }
    .w-40 {
      width: 40%;
    }
    .tripnew-active {
      height: 1.8rem;
      font-size: 1rem;
      font-weight: 500;

      line-height: 1.8rem;
      padding: 0 0.8rem;
      border-radius: 0.6rem;
      background-color: var(--grey100);
      opacity: 1;
      &.active {
        background-color: var(--primary-color) !important;
      }
      &.active-goto {
        background-color: var(--primary-color) !important;
      }
    }
  }
  :focus {
    outline: unset !important;
  }
  &.active .my_trips_web {
    opacity: 1;
    z-index: 9;
    visibility: visible;
    transform: translateY(0%);
    transition-delay: 0s, 0s, 0.3s;
  }
  &.active .active-icon {
    transform: rotate(180deg);
    transition: 300ms all linear;
  }
  .active_trip {
    max-width: 19rem;
    padding-left: 0rem;
    @media screen and (min-width: 767px) and (max-width: 1328px) {
      max-width: 13rem;
    }
  }
  i {
    font-size: 1.2rem;
  }

  @include media-breakpoint-only(xs) {
    min-width: 12rem;
  }
}
.my_trips {
  padding: 1.5rem !important;
  position: absolute;
  width: 100%;
  max-height: 56vh;
  overflow: auto;
  background: var(--white);

  left: 0rem;

  padding: 0rem;
  cursor: pointer;
  z-index: 9;
  transition: all 0.3s ease-in-out;
  @include media-breakpoint-down(md) {
    bottom: 12rem;
  }
  @include media-breakpoint-only(xs) {
    bottom: 12.8rem;
  }
}
.mytripwrapper {
  background: var(--grey100);
  border-radius: 3rem;
  padding: 0px 1rem;
  padding-right: 0rem;
  margin-right: 5rem;
  margin-left: 2rem;
}
.planebg {
  background-color: var(--secondary-color);
  color: var(--white);
  border-radius: 50%;
  width: 3.5rem;
  height: 3.5rem;
  line-height: 3.3rem;
  text-align: center;
  font-size: 1.6rem;
  margin: 0.3rem;
}

.current_trip {
  background: var(--grey100);

  padding: 0px 1rem;
  line-height: 2rem;
  font-size: 1.1rem;
  margin-top: 0.5rem;
  border-radius: 0.3rem;
}

.mytripborder {
  border-top: 1px solid var(--input-border);
  padding-top: 20px !important;
}

.trip_name {
  .current_trip {
    margin-right: 0.5rem;
    line-height: 1.9rem;
  }
  @media only screen and (max-width: 575px) {
    padding: 0px 0.9rem;
  }
}
.trip_name.active {
  color: var(--primary-color);
}
.my_trip_head {
  font-weight: 500;
}

.my_trips_web {
  border-radius: 0.6rem !important;
  position: absolute;
  width: 32rem;
  max-height: 40.5rem;
  overflow: auto;
  background: var(--white);

  top: 6rem;
  right: -2.5rem;
  line-height: 2.8rem;
  padding: 1rem 2rem;
  cursor: pointer;

  -webkit-transform: translateY(-2em);
  transform: translateY(-2em);
  visibility: hidden;
  opacity: 0;
  z-index: 9;
  transition: 0.6s ease-in-out;
  .trip_link {
    line-height: 3rem;
    margin-left: 0.5rem;
  }
}

.tb-trip-bar {
  position: relative;
  width: 100%;
  height: 4rem;
  line-height: 4rem;
  margin-bottom: 0px;
  background-color: var(--body-text-color);
  top: 0px;
  z-index: 11;
}

.tb-mytripposition {
  position: relative;
  text-align: right;
  height: 4rem;
}

.tb-mytripinner {
  position: relative;
  display: block;
  float: left;
  font-size: 1.3rem;
  cursor: pointer;
  text-transform: uppercase;
  color: var(--white);
}

.triplist_dropdown {
  line-height: 4rem;
  height: 4rem;
  .mytrip_drp {
    color: var(--white);
  }
  .mytrip_drp::after {
    display: none;
  }
}

.mytrip_option_holder {
  position: relative;
  display: inline-block;
  @include media-breakpoint-only(xs) {
    width: 100%;
  }
}

.count {
  position: absolute;
  left: 1.2rem;
  top: -0.2rem;
  width: 2.7rem;
}

.img-cart {
  position: relative;
  top: -0.6rem;
}

.tb-notrips {
  line-height: 2.6rem;
  text-align: center;
  font-weight: bold;
  color: var(--white);
  display: flex !important;
  margin: 0.5rem 0rem 0rem 0.8rem;
}

.dropdown-new {
  left: -12.6rem !important;
  top: 5rem;
  padding: 0rem;
  a {
    text-transform: unset;
    padding: 0.4rem;
  }
}

.dropdown-item-new {
  height: 3.5rem;
  line-height: 2.5rem;
  padding: 0rem;
}

.tb-bar-searchButton {
  color: var(--primary-color) !important;
}

.nav-fixed-inner {
  .input-group {
    border-bottom: 0rem solid var(--grey100) !important;
  }
}

.tb-searchbarwrap {
  width: 36rem;
  z-index: 999;
  position: relative;
  background: var(--body-background);

  border-radius: 0.6rem;
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
  @include media-breakpoint-only(md) {
    width: 100%;
  }
  input {
    border: unset !important;
    background-color: transparent !important;
    border-radius: unset !important;
    @media only screen and (max-width: 575px) {
      font-size: 1.3rem !important;
    }
  }
  input::-webkit-input-placeholder {
    color: var(--grey400) !important;
  }

  .autocomplete-container {
    box-shadow: none;
    height: auto;
  }
}
@include media-breakpoint-down(md) {
  .is-sticky .tb_search_icon {
    background: var(--white);
  }
}

.mob-inner-search {
  .input-group {
    border-bottom: 0px solid var(--grey100) !important;
  }
  input {
    border: unset !important;

    background-color: var(--white) !important;
    border-radius: unset !important;
  }
  input::-webkit-input-placeholder {
    color: var(--grey400) !important;
  }
  .tb-bar-searchButton-home {
    padding: 0px 8px 0px 0px;
  }

  .input-field {
    font-size: 1.3rem !important;
  }
}

.tb-search-mob-index {
  z-index: 11 !important;
}

.tb-tripbtn {
  float: right;
}

#btn {
  cursor: pointer;
  transition: left 500ms cubic-bezier(0.6, 0.05, 0.28, 0.91);
  div {
    width: 2.2rem;
    height: 2rem;
    margin-bottom: 6px;
    background-color: var(--body-text-color);
    transition: transform 500ms cubic-bezier(0.6, 0.05, 0.28, 0.91);
  }
}

#btn.active {
  #top {
    transform: translateY(1rem) rotate(-135deg);
  }
  #middle {
    opacity: 0;
    transform: rotate(135deg);
  }
  #bottom {
    transform: translateY(-1rem) rotate(-45deg);
  }
}

#box {
  position: fixed;
  z-index: 104;
  overflow: auto;
  top: 0px;
  right: -100%;
  background: rgb(0, 0, 0 / 0.6);
  width: 100%;
  opacity: 0;
  padding: 20px 0px;
  height: 100%;
  /* background-color: var(--white);*/

  transition: all 350ms cubic-bezier(0.6, 0.05, 0.28, 0.91);
}

#box.active {
  right: 0px;
  opacity: 1;
  z-index: 1050;
}

#page-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.mob-sub-item {
  background: var(--grey100);
  padding-left: 2rem;
}

#title {
  color: var(--white);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2em;
}

#items {
  position: absolute;
  background: var(--white);
  width: 80%;
  height: 100%;
  min-height: 100%;
  margin-left: 20%;
  top: 0px;
  border-radius: 0.6rem 0rem 0.6rem 0rem;
  @include media-breakpoint-only(sm) {
    margin-left: 50%;
    width: 50%;
  }
  @include media-breakpoint-only(md) {
    margin-left: 50%;
  }
  ul {
    list-style-type: none !important;
    li {
      line-height: 4.8rem;
      a {
        height: 4.8rem;
        line-height: 4.8rem;
        @media only screen and (max-width: 991px) {
          display: block;
        }
        @include media-breakpoint-only(md) {
          font-size: 1.6rem;
        }
      }
    }
  }
  .bookitem {
    cursor: pointer;
    padding: 0rem;
    transition: all 250ms;

    &:hover {
      &:first-child {
        border-radius: 0.6rem 0rem 0rem 0rem;
      }
    }
  }
  .item {
    position: relative;
    cursor: pointer;

    padding: 0px 2rem;
    transition: all 250ms;

    &:hover {
      background-color: var(--grey200);
      &:first-child {
        border-radius: 0rem 0rem 0rem 0rem;
      }
    }
  }
}

#btn,
#btn * {
  will-change: transform;
}

#box {
  will-change: transform, opacity;
}

.item-outer {
  height: 100%;
  min-height: 100%;
  width: 100%;
  background: var(--body-text-color);
  top: 0;
  position: absolute;
}

.test_here {
  background: transparent !important;
  border: none !important;
}

.forgot-pwd {
  margin: 1rem 0rem;
}

.profile-home-img {
  border-radius: 50%;
  width: 3.8rem;
  height: 3.8rem;
  line-height: 3.3rem;
  overflow: hidden;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  @include media-breakpoint-only(xs) {
    width: 2.5rem;
    height: 2.5rem;
    background-color: var(--grey300);
    cursor: default;
    line-height: 1rem;
  }
}
.mob_cross {
  font-size: 1.5rem;
  font-weight: 600;
}

.profile-mobile {
  // border-bottom: 0.1rem solid var(--grey300);
  padding: 0px 2rem 2rem 2rem;
  .profile-home-text {
    a {
      font-size: 1.6rem;
    }
    @include media-breakpoint-only(md) {
      font-size: 1.8rem;
    }
  }
}

.trip_exp_count_mob {
  font-size: 1.8rem;
  min-width: 4.2rem;
  height: 3.9rem;
  line-height: 3.9rem;
  text-align: center;
  border-left: 0.1rem solid var(--grey300);
}

.splNotify {
  .tooltip-arrow.arrow:before {
    border-bottom-color: var(--primary-color);
    @include media-breakpoint-down(lg) {
      border-top-color: var(--primary-color);
    }
  }
  &.tooltip.show {
    padding-left: 1rem;
    padding-right: 1rem;
    opacity: 1;
    font-size: 1.2rem;
  }
  .tooltip-inner {
    font-size: $body-heading-size;
    background: var(--primary-color);
    padding: 0.5rem 1.2rem;
    max-width: auto !important;
    @include media-breakpoint-only(xs) {
      font-size: $body-heading-size;
      padding: 0.5rem 1.2rem;
    }
  }
  &.tooltip .arrow {
    display: none !important;
  }
}

.font-size-mob-nav {
  @include media-breakpoint-only(xs) {
    font-size: 1.1rem !important;
  }
}

.header-tb {
  .modal-title .nav-item a {
    color: var(--body-text-color);
    text-align: center;
  }
  .modal-title .nav-item.selected-active a {
    color: var(--primary-color);
    padding-bottom: 1.5rem;
    border-bottom: 0.4rem solid var(--primary-color);
  }
  .modal-content {
    background-color: var(--white);
  }
}
.forgot_password a {
  color: var(--primary-color) !important;
}

.bag-search-mob {
  height: auto;
  background: var(--white);
  @include media-breakpoint-down(md) {
    .input-field :focus {
      box-shadow: unset !important;
    }
    .input-field {
      font-size: 1.3rem !important;
    }
    .input-group {
      height: 4.2rem;
      line-height: 4.2rem;
    }
    input::-webkit-input-placeholder {
      color: var(--grey400) !important;
    }
  }
}

.search-close-new {
  width: 3.3rem;

  text-align: center;
}

.width-100 {
  width: 100%;
}
.trip-header {
  max-width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @include media-breakpoint-only(md) {
    max-width: 80%;
  }
}

.disablesticky {
  position: relative !important;
}

.icon-width-dropdown {
  width: 2.4rem;
}
.mob_trip_shadow {
  box-shadow: 0.1rem -0.1rem 0.5rem var(--grey300);
}
.create-trip-form .collapse_header {
  border-bottom: none;
  margin: 0.8rem 0rem 1.2rem 0rem !important;
}

.headline2 {
  font-size: 2.9rem;

  position: relative;
  font-weight: 300;
  margin: 3rem 0 0 0;
  span {
    font-weight: 500;
  }
  @include media-breakpoint-only(sm) {
    font-size: 2.5rem;
  }
}

.z-idex-mob-search {
  z-index: 9 !important;
}

.nav-login-icon {
  i {
    font-size: 1.8rem;
    vertical-align: middle;
  }
}
.tb_autocomplete .tb_search_icon {
  padding: 2.4rem 0;
  border-bottom: 0rem solid var(--body-text-color);
  @include media-breakpoint-only(xs) {
    padding: 0rem;
    border-bottom: 0rem solid var(--grey100);
  }
}
.tb_autocomplete .search_input {
  flex: 1 1 auto;
  border-bottom: 0rem solid var(--grey100);
}
.trip-route-bottom {
  z-index: 11;
  bottom: 0rem;
  height: 5rem;
  background-color: var(--grey100);
  border-top: 1px solid var(--grey300);
  @include media-breakpoint-only(md) {
    height: 4rem;
  }
  .my_trips_list .my_trips {
    transform: translateY(-2em);
    top: unset;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    position: absolute;
  }
  .my_trips_list .active_trip {
    max-width: 17.5rem;
  }
  .my_trips_list {
    width: 100%;
    @include media-breakpoint-only(md) {
      width: 100%;
    }
  }
  .go-to-trip-mob {
    min-width: 3.5rem;
    min-height: 3.5rem;
    // border: 0.16rem solid var(--body-text-color);
    background-color: var(--secondary-color);
    border-radius: 50%;
    text-align: center;
    line-height: 3.5rem;
    margin-right: 0.5rem;
  }

  .go_to_trip_plane {
    line-height: 3rem;
  }
}
.plane-rotate {
  font-size: 1.6rem;
  transform: rotate(-45deg);
  margin-left: 0.4rem;
}

.custom-width-createtrip {
  max-width: 64rem !important;
  @include media-breakpoint-down(sm) {
    max-width: unset !important;
    overflow-y: auto !important;
    overflow-x: hidden;
  }
}

.mob-sticky-white.is-sticky {
  background: var(--white);
  border-radius: 0px !important;
  // border-bottom: 1px solid  var(--grey100)  ;
  .tb-bar-searchButton-home {
    padding: 0rem 8rem 0rem 0rem !important;
  }

  .input-field {
    font-size: 1.3rem !important;
  }
  input::placeholder {
    color: var(--grey400) !important;
  }
  .input-group {
    border-bottom: 0px solid var(--grey100) !important;
  }
}
.navbar-nav .nav-item {
  margin: 0rem 0.8rem;
  position: relative;
}
.curr_loc {
  display: inline !important;
  float: left;
  vertical-align: unset;
  width: 18px;
  margin-right: 1.5rem;
}

.near_me_holder {
  position: absolute;
  width: 100%;
  padding-left: 1rem;

  z-index: 10;
  .near_me {
    background: var(--white);
    padding: 0.5rem 1rem;
    cursor: pointer;
    &:hover {
      background: var(--grey300);
    }
  }
}
.mobtripborder {
  border-top: 0.1rem solid var(--grey300);
  margin-top: 1rem !important;
  padding-bottom: 1rem !important;
}

@media only screen and (max-width: 991px) {
  .tb-searchbarwrap {
    width: 100%;
    z-index: 999;
    position: relative;
    background: var(--white);
    border-radius: 0.4rem;
    border-bottom: 0.1rem solid var(--grey100);
  }
  .header_logo {
    height: 3rem !important;
    padding-left: 0.5rem;
  }
  .tb_search_icon img {
    width: 1.8rem;
    height: 1.6rem;
  }
}
.cross-icon-ht {
  min-width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.std-icon-ht {
  min-width: 3.8rem;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.std-icon-size {
  font-size: 1.5rem;
  img {
    fill: var(--grey300) !important;
  }
  &.color_white {
    color: var(--white);
  }
}
.header-container {
  max-width: 95% !important;
  margin: 0rem auto;
  width: 100% !important;
}

//new css

.my_trips_list-dm {
  input[type="radio"] {
    appearance: none;
    margin-right: 10px;
    margin-left: 5px !important;
    width: 1.5rem;
    height: 1.5rem;
    background: var(--grey200);
    box-shadow: inset 0 0 0 0.3em var(--white), 0 0 0 0.14em;
    border-radius: 50%;
    transition: 0.2s;
    cursor: pointer;
    color: var(--grey200);
    vertical-align: middle;
  }
  input[type="radio"]:checked {
    appearance: none;
    color: var(--primary-color) !important;
    background: var(--primary-color) !important;
    margin-right: 10px;
    margin-left: 5px !important;
    width: 1.5rem;
    height: 1.5rem;
  }
  //   input[type="radio"]:checked {
  //     background: -webkit-linear-gradient(360deg, #01a99c 10%, #0698b1 90%) !important;
  //     box-shadow: inset 0 0 0 .4em var(--white)  , 0 0 0 .3em #56be8e !;
  //   }

  .mob-trip-arrow.active .active-icon {
    transform: rotate(180deg);
    transition: 300ms all linear;
  }
  .web-arrow-size {
    margin-left: 10px;
    width: 20px;
    height: 100%;
    text-align: center;
  }
  @media only screen and (max-width: 575px) {
    // .mob-trip-fixed {
    //     left:50%;
    //     transform: translateX(-50%);
    //     position: fixed;
    //     bottom: 49px;
    //     min-height: 79px;
    //     background: var(--white)fff;
    //     border-top: 1px solid #d6d6d6;
    //   }
    .my_trips_list-dm input {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin-left: 2px !important;
      width: 16px;
      height: 16px;
      background: var(--grey200);
      box-shadow: inset 0 0 0 0.3em var(--white), 0 0 0 0.14em;
      border-radius: 50%;
      transition: 0.2s;
      cursor: pointer;
      color: var(--grey300);
      vertical-align: middle;
    }
    .trip_name {
      padding: 0px;
    }
  }
}
.arrow-flight {
  line-height: 31px;
  font-size: 13px;
  transform: rotate(-180deg);
}
@media only screen and (max-width: 991px) {
  .mobtripborder {
    min-height: 80px;
    width: 100%;
    bottom: 40px;
    z-index: 9;
    border-top: 1px solid var(--grey200);
    margin-top: 0px !important;
    padding-bottom: 10px !important;
    position: fixed;
    background: var(--white);
  }
}

@media only screen and (max-width: 575px) {
  .mobtripborder {
    min-height: 80px;
    width: 100%;
    bottom: 49px;
    z-index: 9;
    border-top: 1px solid var(--grey200);
    margin-top: 10px !important;
    padding-bottom: 10px !important;
    position: fixed;
    background: var(--white);
  }

  .trip-route-bottom .go_to_trip_plane {
    max-width: 162px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: var(--body-text-color);
    line-height: 30px;
    overflow-wrap: anywhere;
    display: inline-flex;
  }
}

.trip-name-length {
  display: flex;
  overflow-wrap: anywhere;
}
.trip-name-excerpt {
  display: inline-block;
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mobile_side_menu {
  overflow: auto;
  height: 100%;
  background: var(--white);
  font-weight: 600;
}
.searchbox-border {
  background: var(--grey100);
  .tb_search_icon {
    padding: 20px 0px;
  }
}
.trolley-img {
  width: 25px;
  height: auto;
  margin-right: 6px;
}
@media only screen and (max-width: 991px) {
  .trolley-img {
    width: 20px;
    height: auto;
    margin-right: 0px;
  }
}
@media only screen and (max-width: 360px) {
  .trip-route-bottom .go_to_trip_plane {
    max-width: 168px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: var(--body-text-color);
    line-height: 30px;
  }
}
@media only screen and (min-width: 991px) {
  .explore-cross-icon {
    height: 3.4rem !important;
    background: transparent;
    border-radius: 0rem 0.6rem 0.6rem 0rem;

    width: 3.4rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .home-desktopbg {
    .explore-cross-icon {
      height: 40px !important;
      background: var(--white);
      border-radius: 6px;
    }
  }
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .tb-searchbarwrap {
    width: 28rem;
  }
  .categories-new-view a.nav-link {
    font-size: 12px !important;
  }
  .categories-new-view a.nav-link span {
    font-size: 12px !important;
  }
  .categories-new-view .map_span {
    padding: 0px 0px 0px 10px;
    margin-left: unset;
  }
}
