/* Home Page Banner Scss */
.home-bootstrap-slider {
  position: relative;
  height: 70vh;
  .swiper-slide {
    background-repeat: no-repeat !important;
    height: 100%;
    background-size: cover;
    background-position: center bottom;
  }

  .slider__image1 {
    background-image: url("/assets/images/slides/home-1.jpg");
    background-image: url("/assets/images/slides/h-1.webp");
    @include media-breakpoint-only(xs) {
      background-image: url("/assets/images/slides/mob-2.jpg");
      background-image: url("/assets/images/slides/mob-home2.webp");
    }
  }

  .slider__image2 {
    background-image: url("/assets/images/slides/home-2.jpg");
    background-image: url("/assets/images/slides/home-2.webp");
    @include media-breakpoint-only(xs) {
      background-image: url("/assets/images/slides/mob-1.jpg");
      background-image: url("/assets/images/slides/mob-1.webp");
    }
  }

  .slider__image3 {
    background-image: url("/assets/images/slides/home-3.jpg");
    background-image: url("/assets/images/slides/h-3.webp");
    @include media-breakpoint-only(xs) {
      background-image: url("/assets/images/slides/mob-3.jpg");
      background-image: url("/assets/images/slides/mbh-3.webp");
    }
  }

  .carousel__caption {
    @include position(absolute, 30%, 0, 0, 0);
    text-align: left;
    padding: 0 4rem;

    @include media-breakpoint-only(md) {
      @include position(absolute, 42%, 0, 0, 0);
      text-align: center;
    }
    @include media-breakpoint-only(sm) {
      @include position(absolute, 40%, 0, 0, 0);
      text-align: center;
      padding: 2rem;
    }
    @include media-breakpoint-only(xs) {
      @include position(absolute, 66%, 0, 0, 0);
      text-align: center;
      padding: 2rem;
    }
  }

  .swiper-pagination {
    @include position(absolute, null, 1.5rem, null);
    width: 100%;
    text-align: center;
    z-index: 10;
  }
  .swiper-pagination-bullet {
    @include opacity(0.4);
    @include border-radius(100%);
    @include width-height(1rem, 1rem);
    display: inline-block;
    margin-right: 0.8rem;
    background: var(--white);
  }
  .swiper-pagination-bullet-active {
    color: var(--white);
    opacity: 1 !important;
  }
  @include media-breakpoint-only(xs) {
    height: 48rem;
  }
  @include media-breakpoint-only(sm) {
    height: 40rem;
  }
  @include media-breakpoint-only(md) {
    height: 48rem;
  }
}
