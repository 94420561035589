 
%default-badge  {
    // @include font-height( $badge-font-size,  $badge-font-size - 0);
    @include border-radius(0.4rem);
    text-transform: uppercase;
    padding: 6px;
    font-weight: 400;
    color:var(--white);
    letter-spacing: $letter-spacing;
}
.confirm-badge {
    @extend %default-badge;
    background: $primary-badge-color1;
}
.cancel-badge {
    @extend %default-badge;
    background: $primary-badge-color2;
}
.approve-badge {
    @extend %default-badge;
    background: $secondary-badge-color1;

}
.pending-badge {
    @extend %default-badge;
    background: $warning-badge-color;
}
