/*places added to trip*/

$arrow-size: 10px;
$arrow-border: 1px;
$arrow-color: var(--primary-color);
.trip_name_bgimage {
  position: relative;
  background-size: cover;
  background-position: bottom;
  padding: 20px 0px;
  background-image: url(/assets/images/iti_top_bg.jpg);
  .tb-tripname-alignment {
    margin-bottom: 0px !important;
  }
  .tb_profile_position {
    margin-bottom: 0px !important;
  }
  .tb_profile_tripname {
    position: relative;
    line-height: 31px;
    margin-top: 5px;
    font-size: 24px;
    color: var(--primary-color);
    height: 60px;
    text-transform: uppercase;
  }
}

.edit-trip-icon {
  color: var(--white);
  font-size: 15px;
}

.tb_profile_access_new {
  display: block;
}

.tb_profile_access {
  margin-left: -4px;
  padding-bottom: 5px;
  @include media-breakpoint-only(xs) {
    text-align: center;
  }
  .tb_iti_profile {
    position: relative;
    display: inline-block;
    margin-left: 0px;
    cursor: pointer;
    color: var(--grey100);
    padding: 0px 6px;
    text-transform: uppercase;
  }
}

.tb_iti_profile {
  .tb-user-img {
    width: 35px;
    height: 35px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    background-color: var(--white);
    .tb-user-imgsize {
      height: 40px;
      width: 40px;
    }
  }
}

.prepared-for-btn {
  display: inline-block;

  @include media-breakpoint-only(xs) {
    display: block;
    float: none;
    margin-top: 15px;
  }
}

.exp_score_holder {
  position: fixed;
  right: 20px;
  top: 39px;
  z-index: 1000;
}

.exp_score {
  width: 32px;
  height: 32px;
  font-weight: 300;
  background-color: var(--primary-color);
  color: var(--white) fff;
  text-align: center;
  font-size: 13px;
  border-radius: 6px;
  cursor: pointer;
  line-height: 32px;
  margin: 0px 0px 0px 5px;
}

.trip_hub_name_holder {
  background: var(--white);
  .places-added-hubs-new {
    display: inline-block;
    .hub_name {
      line-height: 16px;
      font-size: 16px;
      padding-right: 10px;
      margin-right: 10px;
    }
  }
  .horizontal_scroll_holder {
    position: relative;
    overflow-x: scroll;
    overflow-y: visible;
    white-space: nowrap;
    text-align: left;
  }
}
.ptb-20 {
  padding: 20px 0px;
}
.places-added-iti {
  border-bottom: 1px solid var(--primary-color);
}

.hub_add_places {
  margin: 20px 0px;
}

.trip_hubs_name {
  position: relative;
  padding-right: 20px;
  font-size: 16px;
  color: var(--body-text-color);
  margin-right: -4px;
  display: inline-block;
  font-weight: 300;
  @include media-breakpoint-only(xs) {
    font-size: 14px;
  }
}

.trip_scrap_reco {
  line-height: 24px;
  font-size: 12px;
  float: right;
  font-weight: bold;
  text-transform: uppercase;
  a {
    color: var(--body-text-color);
  }
}

.trip_route_bg {
  padding: 20px;
  position: relative;
  background-image: url("/assets/images/route_bg.jpg");
  background-position: top;
  background-size: cover;
  &:before {
    position: absolute;
    content: "";
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.9);
  }
  input[type="checkbox"] {
    background: var(--grey200);
    border: 1px solid var(--grey200);
    box-sizing: border-box;
    padding: 0;
    width: 20px !important;
    height: 20px !important;
    vertical-align: middle !important;
  }
  input[type="checkbox"][checked="checked"] {
    background: var(--primary-color)-color !important;
    color: var(--white) !important;
  }
}

.iti_background {
  position: relative;
  text-align: center;
  font-size: 38px;
  display: flex;
  align-items: center;
  color: var(--primary-color);
  font-family: "Great Vibes", "cursive";
}

.iti_day_item {
  color: var(--secondary-light);
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
  width: 35px;
  height: 35px;
  text-align: center;
  border: 1px solid var(--secondary-light);
  @include media-breakpoint-only(xs) {
    width: 25px;
    height: 32px;
    margin-right: 6px;
  }
  &.active {
    background-color: var(--secondary-light);
    color: var(--white);
  }
  .iti_month {
    text-transform: uppercase;
    padding-top: 1px;
    font-size: 11px;
    @include media-breakpoint-only(xs) {
      padding-top: 0px;
      font-size: 10px;
    }
  }
  .iti_date {
    font-size: 16px;
    line-height: 14px;
    @include media-breakpoint-only(xs) {
      font-size: 12px;
      line-height: 15px;
    }
  }
}

.type_data {
  &.open {
    border-top: 3px solid var(--success);
  }
  &.closed {
    border-top: 3px solid var(--error);
  }
}

// mobile map view & web scrap view
@mixin itineraryScrapView() {
  -ms-flex-direction: column !important;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  flex-direction: column !important;
  .iti-scrap-holder {
    width: 100%;
    padding: 0px 0px;
    margin-top: 15px;
  }
  .itinerary_type:before {
    width: 100%;
    height: 4px;
    top: 14px;
  }
  .scrapCollapseButton {
    display: none;
  }
}
/* NEW ITI CSS */

// map & scraps both in mobile & tab
.map_scrap_view .itinerary_holder {
  .iti_place_holder {
    height: 500px;
    overflow: auto;
    transition: all 0.5s ease;
    .map {
      height: 500px;
    }
    .place {
      padding: 20px 15px 20px 0px;
      // margin: 0px 0px 10px 0px;
      @include media-breakpoint-down(sm) {
        padding: unset;
        margin: unset;
      }
    }
  }
  @include media-breakpoint-only(xs) {
    .iti_place_holder {
      overflow-x: scroll;
      overflow-y: visible;
      white-space: nowrap;
      text-align: center;
      // padding-top: 10px;
      // min-height: auto;
      height: auto;
      &.iti_google_map,
      &.iti_google_map .map {
        height: calc(100vh - 180px);
        margin-top: 12px;
      }
    }
    .itinerary_type {
      .type {
        display: block;
        margin: 0px 10px 10px;
      }
      .type_data {
        .dur-distance {
          .colon-itinerary::after {
            content: "";
            margin: 0px;
          }
        }
      }
      &.free_time .type {
        display: none;
      }
    }
    @include itineraryScrapView();
    .iti_types {
      display: inline-block;
      vertical-align: top;
      position: relative;
    }
    .itinerary_type.place {
      .type {
        display: none;
      }
      .type_data {
        //width: 300px;
        padding-right: 0px;
      }
    }
  }
  @include media-breakpoint-only(sm) {
    .iti_place_holder {
      overflow-x: scroll;
      overflow-y: visible;
      white-space: nowrap;
      text-align: center;
      // padding-top: 10px;
      // min-height: auto;
      height: auto;
      &.iti_google_map,
      &.iti_google_map .map {
        height: calc(100vh - 100px);
        margin-top: 12px;
      }
    }
    .itinerary_type {
      .type {
        display: block;
        margin: 0px 10px 10px;
      }
      .type_data {
        .dur-distance {
          .colon-itinerary::after {
            content: "";
            margin: 0px;
          }
        }
      }
      &.free_time .type {
        display: none;
      }
    }
    @include itineraryScrapView();
    .iti_types {
      display: inline-block;
      vertical-align: top;
      position: relative;
    }
    .itinerary_type.place {
      .type {
        display: none;
      }
      .type_data {
        //width: 300px;
        padding-right: 0px;
      }
    }
  }
}
/* LIST VIEW IN DESKTOP */

@include media-breakpoint-up("md") {
  .list_scrap_view {
    .itinerary_type {
      .type_data {
        .dur-distance {
          .colon-itinerary::after {
            content: "";
            margin: 0px;
          }
        }
      }
    }
    @include itineraryScrapView();
    .iti_types {
      display: block;
      vertical-align: top;
      position: relative;
    }
    .itinerary_type.place .type_data {
      // width: 330px;
      padding-right: 0px;
      min-height: auto;
    }
    .iti_place_holder {
      margin: 0px 30px;
    }

    // .scrap_description_enter {
    //     position: absolute;
    // }
    /* CURVES */
    // end curve
    .iti_types:before {
      content: " ";
      width: 0px;
    }
    .iti_types.end .line {
      position: absolute;
      width: 10px;
      height: 4px;
      background: var(--grey100);
      right: -10px;
      top: 228px;
    }
    .iti_types.end:before {
      content: " ";
      position: absolute;
      width: 40px;
      height: 218px;
      right: -50px;
      border-radius: 0 40px 40px 0;
      border: 4px solid var(--grey100);
      border-left: none;
      top: 14px;
    }
    .iti_types.start:before {
      content: " ";
      position: absolute;
      width: 20px;
      height: 55px;
      left: -5px;
      border-radius: 40px 0px 0px 40px;
      border: 4px solid var(--grey100);
      border-right: none;
      top: -37px;
    }
  }
}

.iti_place_holder .iti_types:last-child .itinerary_type:before {
  content: none;
}

.itinerary_type {
  position: relative;
  &.day_change {
    line-height: 40px;
    .type_holder_day {
      text-align: left;
      width: 100%;
      text-align: center;
      display: inline-block;
      z-index: 5;
      position: relative;
      vertical-align: top;

      span {
        position: relative;
        z-index: 1;
        padding: 0px 0px 0px 0px;
        background: var(--white);
        text-transform: uppercase;
        color: var(--secondary-color);
        font-size: 18px;
        font-weight: 400;
      }
      .myspan {
        padding-left: 10px;
      }
      .myspan1 {
        padding-right: 10px;
      }
      .myspan2 {
        padding-right: 10px;
      }
    }
    .type_holder {
      background: var(--blue);
      width: 40px;
      height: 40px;
    }
  }
  &.hub_mode {
    .type_holder {
      border: 1px solid var(--body-text-color);
      color: var(--body-text-color);
      background: var(--white);
    }
  }
  &.place {
    .type_holder {
      background: var(--grey200);
      width: 15px;
      margin-right: 0px;
    }
    .type_data {
      max-width: 100%;
      width: 100%;
      @include media-breakpoint-only(xs) {
        max-width: 100%;
        width: 100%;
      }
    }
  }
  &.reference {
    .type_holder {
      background: var(--body-text-color);
      color: var(--white);
    }
  }
  &.free_time {
    .type {
      margin-bottom: 0px;
      width: auto;
    }
    .type_holder {
      background: transparent;
      width: 15px;
      margin-right: 0px;
      border: none;
    }
  }
}

.itinerary_type.local_commute_hold {
  padding: 10px 0px 10px 0px;
  .show_commute {
    cursor: pointer;
  }
  .show_commute:hover {
    .local_commute_type {
      display: block;
    }
    .type_holder {
      background-color: var(--body-text-color);
      color: var(--white);
    }
  }
  .local_commute_type {
    position: absolute;
    width: 70px;
    left: 12px;
    display: none;
    top: 0px;
  }
}

//route-section
.route-outer {
  height: auto;
}

.route-section-holder {
  position: relative;
  margin: 30px 0px;
  -webkit-transition: all 0.4s ease 0s;
  -moz-transition: all 0.4s ease 0s;
  -ms-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
}

.route-section-holder:before {
  content: "";
  width: 2px;
  height: calc(100% - 60px);
  background: var(--secondary-light);
  position: absolute;
  top: 0;
  left: 50%;
}

.route-section-holder .timeline {
  /* margin-bottom: 50px;*/
  position: relative;
  position: relative;
  margin-bottom: 1.5rem;
  // @include media-breakpoint-only(xs) {
  //     margin-bottom: 1rem;
  // }
}

.route-section-holder .timeline:before,
.route-section-holder .timeline:after {
  content: "";
  display: block;
  width: 100%;
  clear: both;
}

.route-section-holder .timeline-icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: var(--white);
  border: 1px solid var(--body-text-color);
  // overflow: hidden;
  margin-left: -13px;
  position: absolute;
  top: 0;
  left: 50%;
  text-align: center;
  &.place {
    background: var(--body-text-color);
    color: var(--white);
    border: none;
  }
  i {
    font-size: 15px;
    line-height: 30px;
  }
  .trans_editable-mode {
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: var(--white);
    border: 1px solid var(--body-text-color);
    border-radius: 50%;
    bottom: -4px;
    right: -6px;
    text-align: center;
    color: var(--body-text-color);
    i {
      font-size: 8px;
      position: absolute;
      right: 3px;
      line-height: 15px;
    }
  }
}

.route-section-holder .timeline-content {
  width: 45%;
  padding: 7px 10px;
  border-radius: 5px;
  background: var(--white);
  border: 1px solid var(--secondary-light);
  // -webkit-box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.1);
  // -moz-box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.1);
  // -ms-box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.1);
  // box-shadow: 0px 0px 10px 4px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -ms-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    right: $arrow-size;
    width: $arrow-size;
    height: $arrow-size;
    transform-origin: 50% 50%;
    transform: translate(0, -($arrow-size / 2)) rotate(45deg);
    box-shadow: inset -#{$arrow-border} $arrow-border 0 0 $arrow-color;
    border-radius: 0 $arrow-size * 0.15 0 0;
    right: -6px;
    top: 15px;
    background: var(--white);
    @include media-breakpoint-down(sm) {
      left: -6px;
      transform: translate(0, -($arrow-size / 2)) rotate(225deg);
    }
  }
}

.route-section-holder {
  font-size: 12px;
  width: 60%;
  margin: 0px auto;
  @include media-breakpoint-only(xs) {
    width: 95%;
  }
  @include media-breakpoint-only(sm) {
    width: 72%;
  }
  .route-description {
    color: var(--body-text-color);
  }
  .route-title {
    font-size: 15px;
    color: var(--body-text-color);
    @include media-breakpoint-only(xs) {
      font-size: 15px;
    }
    &.hub-name {
      font-weight: 300;
    }
  }
}

.route-section-holder .read-more:hover {
  top: 2px;
  left: 2px;
  box-shadow: none;
}

.route-section-holder .timeline-content.right {
  float: right;
}

.route-section-holder .timeline-content.right:before {
  content: "";
  transform-origin: 50% 50%;
  transform: translate(0, -($arrow-size / 2)) rotate(225deg);
  left: -6px;
  top: 15px;
}

.route-section-holder .timeline-content.left:before {
  content: "";
  transform-origin: 50% 50%;
  transform: translate(0, -($arrow-size / 2)) rotate(45deg);
  left: -6px;
  top: 15px;
}

@media only screen and (max-width: 990px) {
  .route-section-holder .timeline-content::before {
    top: 16px;
  }
}

@include media-breakpoint-only(xs) {
  .route-section-holder {
    margin: 0px 11px;
  }
  .route-section-holder:before {
    left: 0;
    height: calc(100% - 66px);
  }
  .route-section-holder .timeline-content {
    width: 90%;
    float: right;
  }
  .route-section-holder .timeline-icon {
    left: 0;
  }
}

@media only screen and (max-width: 479px) {
  .route-section-holder .timeline-content {
    width: 90%;
    padding: 10px 10px;
  }
}

.iti-places-outer {
  width: 80%;
  margin: 0 auto;
  @include media-breakpoint-only(xs) {
    width: 100%;
  }
}

.iti-scrap-holder {
  position: relative;
  width: 35%;
  padding: 0px 20px;
  transition: all 0.5s ease;
  @include media-breakpoint-only(md) {
    width: 50%;
  }
  @include media-breakpoint-only(lg) {
    width: 45%;
  }
  &.scrapCollapse {
    width: 40px;
    .iti_place_holder {
      opacity: 0;
    }
  }
  .scrapCollapseButton {
    position: absolute;
    left: -45px;
    width: 35px;
    height: 35px;
    font-size: 18px;
    line-height: 35px;
    text-align: center;
    margin: 8px 0px 0px 0px;
    // z-index: 100;
    cursor: pointer;
    background: var(--white);
    i {
      transition: all 1s ease;
    }
    .scrapCollapseIcon {
      transform: rotate(180deg);
    }
  }
}

.trip-scrap {
  position: relative;
  background: var(--white);
  margin-bottom: 30px;
  // box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 10px 0px;
  .trip-scrap-pic {
    position: relative;
    margin-right: 5px;
    min-width: 90px;
    max-width: 90px;
    // background-image: url(/assets/images/iti-scrap-image.jpg);
    img {
      border-radius: 6px;
    }
    @include media-breakpoint-only(xs) {
      img {
        border-radius: 6px;
        max-width: 100px !important;
      }
    }
  }

  .confirm-btn-container button {
    background: none !important;
    border: none !important ;
    transition-duration: 0.5s;
  }
  .confirm-btn-container button:hover {
    background: var(--grey200) !important;
    border: none !important ;
  }
  .trip-scrap-detail {
    padding: 0px 10px;

    .scrap-rating {
      color: var(--primary-color);
    }
    .scrap_arrival,
    .scrap_duration {
      // height: 20px;
      // line-height: 20px;
      // line-height: 30px;
      i {
        font-size: 13px;
      }
      .hourglass {
        font-size: 11px !important;
      }
    }
  }
  .scrap_arrival .time-itinerary-scrap.markRed i {
    color: var(--error);
  }
  .trip-scrap-more-option {
    position: absolute;
    right: 0px;
    min-width: 34px;
    min-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .more_option_click {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: flex-start;
      cursor: pointer;
      text-align: right;
      div {
        line-height: 8px;
        font-size: 30px;
      }
    }
    .more_dropdown {
      position: absolute;
      min-width: 140px;
      font-size: 14px;
      background: var(--white);
      color: var(--body-text-color);
      right: 0px;
      top: 50px;
      display: block;
      z-index: 5;

      @include media-breakpoint-down(sm) {
        right: 1px;
        top: 50px;
      }
      .option {
        position: relative;
        padding: 8px 15px 0px;
        cursor: pointer;
        white-space: nowrap;
        &:hover {
          color: var(--body-text-color);
        }
        &:last-child {
          padding: 8px 15px;
        }
      }
      .next_level_menu {
        position: absolute;
        bottom: 0;
        left: -100px;
        width: 100px;
        background: var(--white);
        display: none;
        white-space: pre-wrap;
      }
      .next_level_show:hover {
        .next_level_menu {
          display: block;
        }
      }
    }
  }
  // .scrap_arrival {
  //     float: left;
  //     margin-right: 13px;
  // }
  // .scrap_duration {
  //     float: right;
  // }
  .pinned_place {
    color: var(--secondary-color);
    font-size: 1.4rem;
    i {
      transform: rotate(-45deg);
    }
    @include media-breakpoint-only(xs) {
      font-size: 14px;
    }
  }
}

.scrap_description_enter {
  color: var(--body-text-color);
  // border: 1px solid  var(--secondary-light)  ;

  word-wrap: break-word;
  text-align: justify;
  padding: 0px 0px;
  border-radius: 3px;
  color: var(--body-text-color);
  white-space: normal;
  margin-bottom: 20px !important;

  overflow-wrap: anywhere;
  textarea {
    padding: 10px;
    margin-top: 20px;
    border-radius: 6px;

    resize: vertical;
    border: 1px solid var(--grey200);
    :focus {
      padding: 10px;
      border-radius: 6px;
      outline-color: var(--primary-color);
      outline-style: solid;
    }
  }

  @include media-breakpoint-only(xs) {
    width: 100%;
  }
  button {
    &:focus {
      outline: unset !important;
      box-shadow: unset !important;
    }
  }
}

// new places added
.bag-new-iti {
  background-image: url("/assets/images/route_bg.jpg");
  height: 320px;
  background-size: cover;
  background-position: bottom;
  @include media-breakpoint-only(xs) {
    height: 260px;
    background-image: unset;
    background-size: cover !important;
    background-position: center !important;
  }
}
.myrow {
  display: flex;
  flex-wrap: wrap;
  margin-right: 0px;
  margin-left: 0px;
  margin-left: 0px;
}

.places-added-iti-holder {
  background: var(--grey200) deb;
  height: 80px;
  line-height: 80px;
}

.exp-popup {
  .popover-body {
    padding: 0px !important;
  }
  .popover {
    border-radius: 5px !important;
    box-shadow: 0 3px 0 rgba(0, 0, 0, 0.1) !important;
    background: var(--white) !important;
  }
  a {
    color: var(--body-text-color);
  }
}

.profile-places-added {
  width: 34px;
  height: 34px;
  line-height: 34px;
  background: var(--white);
  border-radius: 50%;
  overflow: hidden;
  vertical-align: middle;
  @include media-breakpoint-only(xs) {
    width: 30px;
    height: 30px;
    line-height: 30px;
    margin-right: 8px;
  }
  @include media-breakpoint-only(md) {
    width: 34px;
    height: 34px;
    line-height: 34px;
  }
}
.trip-color {
  color: var(--white);
  &:hover {
    color: var(--white) !important;
  }
}

.trip-height {
  height: auto;
  padding: 0px 0px 0px 0px;

  align-items: center;
  @include media-breakpoint-only(xs) {
    height: auto;
    padding: 0px 0px 0px 0px;
  }
  .list-icon-width {
    @include media-breakpoint-down(sm) {
      width: 15px;
      text-align: center;
    }
  }
  .location-name-places-added {
    color: var(--body-text-color);
    font-size: 20px;
    width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    a {
      color: var(--body-text-color);
    }
    @include media-breakpoint-only(xs) {
      font-size: 16px;
      width: 60%;
      flex-wrap: wrap;
      height: 50px;
    }
    @include media-breakpoint-only(sm) {
      font-size: 18px;
      max-width: 160;
    }
  }
  .tripby-name {
    color: var(--body-text-color);
    font-size: 14px;
  }
  .dropdown {
    position: relative;
    display: inline-block;
    cursor: pointer;
    .div_hover {
      color: var(--body-text-color);
      font-size: 14px;
      i {
        color: var(--body-text-color);
      }
    }
    .w-28 {
      width: 28px;
    }
    .mr-custom {
      margin-right: 12px;
    }
    .dropdown-content_aa {
      left: -100px;
      top: -10px;
    }
  }
  .dropdown:hover {
    background-color: none;
    position: relative;
    display: inline-block;
    cursor: pointer;
    .div_hover {
      color: var(--body-text-color);
      font-size: 14px;
      i {
        color: var(--body-text-color);
      }
    }
    .w-28 {
      width: 28px;
    }
    .mr-custom {
      margin-right: 12px;
    }
    .dropdown-content_aa {
      left: -100px;
      top: -10px;
    }
  }
  .dropdown-content {
    display: none;
    position: absolute;
    right: 0px;
    top: 45px;
    border-radius: 6px;
    background-color: var(--white);
    min-width: 180px;

    z-index: 10;
    .div_hover:first-child:hover {
      border-radius: 6px 6px 0px 0px;
    }
    .div_hover:last-child:hover {
      border-radius: 0px 0px 6px 6px;
    }
    @include media-breakpoint-only(xs) {
      top: 44px;
    }
  }
  .dropdown-content a {
    color: black;
    text-decoration: none;
    display: block;
  }
  .dropdown-content .div_hover:hover {
    background-color: var(--grey200);
  }
}

.trip-scrap .trip-scrap-detail .scrap-rating {
  float: left;
  font-size: 11px;
  line-height: 22px;
  height: 26px;
  @include media-breakpoint-only(xs) {
    height: 16px;
    line-height: 12px;
    font-size: 10px;
  }
}

.trip-scrap .trip-scrap-detail .scrap-locality {
  float: right;
  line-height: 26px;
  @include media-breakpoint-only(xs) {
    width: 18px;
    text-align: center;
  }
}

.scrap-locality span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 80px;
}

@include media-breakpoint-down(sm) {
  list_scrap_view {
    margin-top: 20px;
  }
  .list_scrap_view .itinerary_holder {
    .iti-scrap-holder {
      width: 100%;
      padding: 0px 0px;
    }
  }
  .trip-scrap .trip-scrap-pic {
    min-width: 100px;
  }

  .trip-scrap .trip-scrap-detail {
    // width: 100px;
    padding: 0px 5px;
  }

  .trip-scrap .scrap-iti-details .scrap_arrival {
    float: left;
    margin-left: 0px;
    margin-right: 0px;
  }

  .trip-scrap .trip-scrap-more-option {
    width: 7px !important;
  }
  .trip-scrap .trip-scrap-more-option .more_option_holder {
    transform: translateX(calc(100% - 20px));
  }
  .trip-scrap .trip-scrap-more-option .more_option_click {
    i {
      color: var(--body-text-color);
    }
    width: 0px !important;
    // line-height: 85px !important;
    // height: 85px;
  }
}

.iti-footer {
  background-image: url(/assets/images/hands.jpg);
  background-position: center;
  background-size: cover;
  .card-body {
    margin-left: 20%;
  }
  .new-footer-itinerary {
    background: rgba(255, 255, 255, 0.7);
    padding: 40px 5px;
    margin: 39px 0px;
  }
  .create-trip-iti-new {
    font-size: 42px;
    line-height: 44px;
  }
}

.iti-image-text {
  display: inline-block;
  color: var(--white);
  font-size: 20px;
  padding: 18px;
  background: rgba(58, 66, 67, 0.7);
  @include media-breakpoint-only(xs) {
    display: block;
    padding: 8px;
  }
}

.not-added-scrap {
  color: var(--blue);
  font-size: 16px;
  @include media-breakpoint-only(xs) {
    font-size: 14px;
  }
}

// .mob-icons-iti {
// line-height: 60px;
// height: 42px;
// text-align: center;
// box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 2px 5px 0 rgba(0, 0, 0, 0.12), 0 0px 1px rgba(0, 0, 0, 0.1);
// }
.not-added {
  .places_added_scraps .exp_holder .tbevent-date {
    top: calc(100% - 151px);
    @include media-breakpoint-only(xs) {
      top: calc(100% - 133px);
    }
    @include media-breakpoint-only(sm) {
      top: calc(100% - 173px);
    }
  }
}

.places_added_scraps {
  .exp_holder {
    // .image_holder {
    //     min-height: 166px;
    //     background:  var(--grey100) ;
    // }
    @include media-breakpoint-only(xs) {
      width: 100%;
      // margin: 5px 5px 5px 0px;
      // &:nth-child(odd) {
      //     margin-right: 2%;
      // }
      .image_holder {
        min-height: 75px;
      }
      .tb-scrap-locatnplaceinfo i {
        color: var(--body-text-color) !important;
      }
    }

    .small-heading {
      @include media-breakpoint-only(xs) {
        font-size: 1rem;
      }
    }
    .exp_title {
      @include media-breakpoint-only(xs) {
        padding: 0px;
      }
    }
    .external_link {
      @include media-breakpoint-only(xs) {
        font-size: 8px;
      }
    }
    .icons_holder {
      @include media-breakpoint-only(xs) {
        margin: 0px;
      }
    }
    .truncate_100 {
      @include media-breakpoint-only(xs) {
        width: 50px;
      }
    }
    .tb-scraps-weblink {
      @include media-breakpoint-only(xs) {
        padding: 0px;
      }
    }
  }
  // &.show_cateogry .category_icon {
  //   display: block;
  //   img {
  //     height: 20px;
  //     @include media-breakpoint-only(xs) {
  //       height: 14px;
  //     }
  //   }
  // }
}

// .category_icon {
//   display: none;
// }

.scrap_map_holder {
  height: 500px;
  overflow: auto;
}

.tb_added_itinieary {
  line-height: 12px;
  font-size: 11px;
  color: var(--body-text-color);
  text-align: center;
  border: 1px solid var(--grey100);
  padding: 4px;
  border-radius: 5px;
  @include media-breakpoint-only(xs) {
    font-size: 10px;
    line-height: 12px;
  }
}

.notAddedPlacesHolder {
  width: 300px;
  margin: 0px auto;
  text-align: center;
  @include media-breakpoint-only(xs) {
    text-align: center;
    margin: unset;
  }
}

.not-added-itinerary-scrap-mr .map_scrap {
  margin-right: 20px;
  margin-left: 20px;
  @include media-breakpoint-only(xs) {
    margin-right: 0px;
    margin-left: 0px;
  }
}

.icons-placesadded-new {
  position: relative;
  // height: 40px;
  // line-height: 17px;
  font-size: 10px;
  color: var(--body-text-color);
}

.icons-width-iti {
  height: 17px;
  line-height: 17px;
  padding-top: 1px;
}

.confirm-btn-container {
  margin-top: 10px;
  .btn-default {
    font-size: 1.2rem;
    padding: 0.6rem 1rem;
    border-radius: 0.6rem;
    line-height: normal;
    border: 2px solid var(--white);
    text-transform: uppercase;
    color: var(--body-text-color);
    &:hover {
      color: var(--white);
      text-transform: uppercase;
      background: var(--secondary-color) !important;
      border: 2px solid var(--secondary-color);
    }
  }
}

.confirm-btn-container .btn-danger {
  font-size: 1.2rem;
  background: var(--secondary-color) !important;
  border-color: var(--secondary-color) !important;
  text-transform: uppercase;
  padding: 0.6rem 1rem;
  border-radius: 0.6rem;
  line-height: normal;
  border: 2px solid var(--secondary-color);
}
.confirm-btn-container .btn-danger:hover {
  background: var(--white) !important;
  color: var(--secondary-color) !important;
  text-transform: uppercase;
  border: 2px solid var(--secondary-color);
}

.popover .popover-header {
  padding: 1rem;
  text-align: center !important;
  color: var(--body-text-color) !important;
  font-size: 1.2rem;
  font-family: "Montserrat", sans-serif;
}

.mob-view-drop {
  @include media-breakpoint-only(xs) {
    left: 35px !important;
  }
}

div.striped div.exp-hover:nth-of-type(odd) {
  background-color: var(--light-cream);
}

.striped {
  border: 1px solid var(--light-cream);
  border-radius: 5px;
}

.popover-body {
  font-size: 1.2rem;
  color: var(--body-text-color) !important;
}

// .trip_story_edit a.c-inline-editor{
//   position: absolute!important;
// }
.trip_story_edit .c-inline-editor.inlineEditForm {
  position: relative !important;
  display: block;
  top: -23px;
  width: 100% !important;
}

.trip_story_edit {
  textarea {
    width: 100% !important;
  }
  inline-editor-textarea {
    width: 100% !important;
  }
  #inlineEditWrapper {
    width: 100% !important;
  }
}

.mb-none-mob {
  @include media-breakpoint-only(xs) {
    margin-bottom: unset !important;
  }
}

.share-mobile-new {
  background: var(--white);
  z-index: 5;
  display: block;
  padding: 10px 5px;
  line-height: 18px;
}

.share-mobile-new li {
  padding: 5px 8px;
}

.share-mobile-new li a {
  color: var(--body-text-color);
  font-size: 15px;
}

.iti-place-description #inlineEditWrapper {
  word-wrap: break-word;
  width: 100%;
}

.scrapinner-iti {
  max-width: 80% !important;

  @include media-breakpoint-only(xs) {
    max-width: 100% !important;
  }
}

.iti-place-description textarea {
  width: 100% !important;
}

.iti-place-description .c-inline-editor.inlineEditForm {
  width: 100% !important;
}

.iti-place-description inline-editor-textarea {
  width: 100% !important;
}

.trans_editable {
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: var(--white);
  border: 1px solid var(--body-text-color);
  border-radius: 50%;
  bottom: -4px;
  right: -6px;
  line-height: 15px;
  font-size: 7px;
  text-align: center;
  color: var(--body-text-color);
}
.arrvbg {
  width: 100%;
  background: var(--grey200);
  padding: 8px 15px 13px 5px;
  border-radius: 6px;
}
.iti-place-img {
  position: absolute;
  width: 30px;
  top: -14px;
  right: 0px;
}
.iti-dots {
  font-size: 15px;
  padding: 14px 0px;
  color: var(--body-text-color);
}

// .new_editable_itinerary {
//     padding-left: 7%;
//     @include media-breakpoint-only(xs) {
//         padding-left: 14%;
//     }
// }
.show_new {
  overflow: visible;
  height: auto !important;
}

.show_more_less {
  font-size: 11px;
  line-height: 16px;
  height: 15px;
  max-height: 2000px;
  overflow: hidden;
}

.new-icons-itinerary-hub {
  right: 0;
  bottom: -22px;
  z-index: 1;
  .icons_width_hub {
    width: 25px;
    display: inline-block;
    text-align: center;
    background: var(--secondary-color);
    border-radius: 4px;
    margin-left: 5px;
    i {
      color: var(--white);
    }
    // border: 1px solid black;
  }
  // i {
  //     padding: 5px 10px 5px 10px;
  // }
}

.icons_width_hub:hover {
  background: black;
}

.category-img-iti {
  img {
    height: 18px;
    vertical-align: sub;
  }
  @include media-breakpoint-only(xs) {
    height: 15px;
  }
}

.description-editable-border {
  border: none;
  padding: 0px;
}

.mob-savebuild {
  line-height: 43px;
  z-index: 9;
  bottom: 35px;
  .btn:focus,
  .btn.focus {
    box-shadow: unset !important;
    outline: unset !important;
  }
  .btn {
    font-size: 12px !important;
  }
}

.bg-cancel {
  background: var(--white);
}

.bg-save {
  background: var(--secondary-light);
}

.itinerary-scroll .tb-multiple-plan-bar {
  @include media-breakpoint-only(xs) {
    padding: 12px 22px;
    font-size: 11px;
  }
}

.mob-iti-places-new {
  text-transform: uppercase;
}

.itinerary-mob-tab {
  line-height: 55px;
  font-size: 16px;
  text-align: center;
  color: var(--body-text-color);
  font-weight: 400;
  text-transform: capitalize;
  &:focus {
    outline: unset !important;
  }
  @include media-breakpoint-only(xs) {
    font-size: 14px;
  }
}

.itinerary-mob-tab.w-50.active {
  color: var(--primary-color);
  border-top: 2px solid var(--primary-color);
}

.w50-logout {
  min-width: 50% !important;
}

.w33-iti {
  min-width: 33% !important;
}

.itinerary_type .type_data {
  @include media-breakpoint-only(xs) {
    margin-left: unset;
    font-size: 10px;
  }
}

.days-number {
  font-size: 12px;
  @include media-breakpoint-only(xs) {
    font-size: 10px;
  }
}

.itinerary_type .small-heading {
  @include media-breakpoint-only(xs) {
    font-size: 10px;
  }
  @include media-breakpoint-only(md) {
    font-size: 12px;
  }
}

.map-itinerary-mob .itinerary_type {
  @include media-breakpoint-down(sm) {
    padding: 0px 15px 0px 0px !important;
  }
}

.add-to-iti-new {
  text-transform: uppercase;
  background: var(--secondary-color);
  border: var(--secondary-color);
  color: var(--white);
  border-radius: 5px;
  font-size: 15px;
  padding: 5px 15px;
  margin-top: 15px !important;
  @include media-breakpoint-only(xs) {
    font-size: 15px;
    padding: 5px 15px;
    margin-top: 15px !important;
  }
}

.iti-right-places {
  i {
    color: var(--grey300) !important;
  }
}

.trip-page-mobile-absolute {
  bottom: 0px;
  height: 80px;
  background: rgba(0, 0, 0, 0.6);
  width: 100%;
  padding: 0px 15px;
  @include media-breakpoint-only(xs) {
    height: 56px;
  }
}

.places-added-map-height {
  line-height: 34px;
  @include media-breakpoint-only(xs) {
    line-height: 31px;
  }
}

.datewise-zindex {
  @include media-breakpoint-down(sm) {
    z-index: 8 !important;
  }
}

.z-index-mob-icons {
  z-index: 9 !important;
}

// .border-bottom-dates {
//     border-bottom: 1px solid  var(--grey100) ;
// }
// .iti_places_added_heading {
//     text-align: center;
//     font-size: 42px;
//     color:   var(--primary-color) ;
//     font-family: 'Great Vibes', cursive;
//     @include media-breakpoint-only(xs) {
//         font-size: 36px;
//     }
// }
.trip_name_head {
  font-size: 22px;
  padding: 10px 0px;
}

.trip_slide .swiper-slide {
  width: 33.33% !important;
  @include media-breakpoint-down("sm") {
    width: 80% !important;
  }
}

.trip_tabs {
  a {
    color: var(--body-text-color) !important;
  }
  font-size: 14px !important;
  text-transform: uppercase;
  margin: 0px 22px 0px 0px;
}

.trip_tabs a.active {
  font-weight: 400 !important;
}

.scrap_status_holder {
  position: absolute;
  top: -4px;
  right: -8px;
  display: flex;
  justify-content: center;
  // flex-direction: column;
  height: 100%;
}

.diamond-shape-green {
  height: 16px;
  text-align: center;
  // -webkit-transform: rotate(45deg);
  // transform: rotate(45deg);
  width: 16px;
  border-radius: 50%;
  border: 2px solid var(--white);
  background-color: var(--success);
}

.diamond-shape-red {
  height: 16px;
  text-align: center;
  // -webkit-transform: rotate(45deg);
  // transform: rotate(45deg);
  width: 16px;
  border-radius: 50%;
  border: 2px solid var(--white);
  background-color: var(--error);
}

.item-count {
  color: var(--white);
  display: table-cell;
  height: 24px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  vertical-align: middle;
  width: 24px;
}

.horizontal_day_wise_holder {
  height: auto;
  text-align: left;
  width: 100%;
  padding-bottom: 10px;
  .horizontal_day_wise_scroller {
    overflow-x: scroll;
    overflow-y: visible;
    white-space: nowrap;
    &::-webkit-scrollbar {
      width: 0 !important;
    }
  }
}

.day_vertical_list {
  width: 32.5%;
  display: inline-block;
  vertical-align: top;
  margin-right: 15px;

  // padding: 0px 30px;
  border-radius: 10px;
  @include media-breakpoint-down("sm") {
    width: 100%;
    margin-right: 0px;
    display: block;
    border: none;
    padding: 0px;
  }
  @include media-breakpoint-only(md) {
    width: 50%;
  }
  .extra-padding {
    padding: 0px 20px;
    background: var(--white);
    @include media-breakpoint-down("sm") {
      padding: 0px 20px 0px 15px;
    }
  }
  // .showmore {
  //     height: 28px;
  //     line-height: 34px;
  //     text-align: center;
  //     background-color:   var(--primary-color) ;
  //     width: 100%;
  //     border-bottom-right-radius: 10px;
  //     border-bottom-left-radius: 10px;
  //     bottom: 0px;
  //     z-index: 10;
  //     cursor: pointer;
  //     i {
  //         font-size: 20px;
  //     }
  // }
}

.horizontal_scroll {
  position: absolute;
  width: 25px;
  height: 100%;
  z-index: 8;
  cursor: pointer;
  transition: all 0.5s ease;
  font-size: 20px;

  &.left_scroll {
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0px;

    i {
      font-size: 28px;
      position: absolute;
      top: 33px;
    }
  }
  &.right_scroll {
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0px;
  }
  i {
    font-size: 28px;
    position: absolute;
    top: 33px;
  }
}
// @include media-breakpoint-down(sm) {
//     display: none;
// }

.day_wise_outer {
  height: 65px;
  // background-color:  var(--secondary-light)  ;
  font-size: 38px;
  color: var(--primary-color);
  font-family: "Great Vibes", "cursive";
  .day_wise_one {
    width: 110px;
    .day_wise_two {
      height: 20px;
      background-color: var(--secondary-light);
    }
  }
  .day_wise_three {
    margin-left: -10px;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    background-color: var(--secondary-light);
    font-size: 20px;
    transition: 0.4s all linear;
  }
}

.is-sticky .dayWiseAnimate {
  height: 20px;
  border-radius: 0;
  font-size: 12px;
}

@-webkit-keyframes daywiseAnimation {
  to {
  }
}

.day_wise_outer_onscroll {
  .day_wise_one {
    width: 110px;
    .day_wise_two {
      height: 20px;
      background-color: var(--secondary-light);
    }
  }
  .day_wise_three {
    margin-left: -10px;
    width: 195px;
    height: 55px;
    background-color: var(--secondary-light);
    font-size: 20px;
  }
}

.trip_story {
  margin: 20px 0px;
  display: flex;
  align-items: center;

  textarea {
    padding: 10px;
    margin-top: 20px;
    border-radius: 6px;
    resize: vertical;
    border: 1px solid var(--grey200);
  }
  @include media-breakpoint-down(sm) {
    margin: 15px 0px 12px 0px;
  }
}

.horizontal_day_wise_holder {
  .itinerary_type.day_change .type_holder_day:before {
    display: none;
    @include media-breakpoint-down(sm) {
      display: block;
      &:before {
        position: absolute;
        content: " ";
        height: 3px;
        width: 100%;
        background: var(--primary-color);
        left: 0px;
        top: 50%;
      }
    }
  }
  .itinerary_type.place {
    padding: 20px 0px 20px 0px;
    margin: 0px 0px 0px 0px;
    @include media-breakpoint-only(xs) {
      border-bottom: unset;
      margin: unset;
    }
  }
  .itinerary_type.day_change .type_holder_day {
    border: 2px solid var(--white);
    background: transparent;
    color: var(--body-text-color);
    border-radius: 18px;
    display: inline;
    padding: 20px;
    margin: 0px auto !important;
    span {
      color: var(--body-text-color);
      background: unset;
      @include media-breakpoint-down(sm) {
      }
    }
    @include media-breakpoint-down(sm) {
    }
  }
  .itinerary_type {
    margin-top: 0px;
    text-align: center;
    margin-bottom: 0px;
    box-shadow: none !important;
    @include media-breakpoint-down(sm) {
      padding-right: 15px;
    }
  }
  .iti_types:last-child {
    .itinerary_type.place {
      border-bottom: unset;
    }
  }
}

.day_wise_section {
  @include media-breakpoint-down(sm) {
    display: inline-block;
  }
}

.place_tooltip {
  .tooltip-inner {
    min-width: 100px !important;
  }
}
.experience_score_title {
  line-height: 30px;
  font-size: 30px;
  color: var(--secondary-color);
  text-align: center;
}

.owl-dt-container-buttons {
  display: flex;
  width: 100%;
  height: 2em;
  color: #323941 !important;
}

@media only screen and (max-width: 450px) {
  .location-name-places-added {
    color: var(--body-text-color);
    font-size: 20px;
    width: 75%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    a {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 145px;
      color: var(--body-text-color);
    }
  }
}
@media only screen and (max-width: 991px) {
  .itibg {
    background: transparent;
  }
  .scrap_description_enter {
    margin-bottom: 30px !important;
  }
}
@media only screen and (max-width: 575px) {
  .p_bar15 {
    padding: 20px 0px !important;
  }
  .mob_itip {
    padding: 25px !important;
  }
}

.itinerary-bg {
  position: relative;
  background: var(--grey100);
  padding: 0px 0px 40px 0px;

  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 48%;
    width: 27px;
    height: 44px;
    margin-top: -22px;
    z-index: 10;
    cursor: pointer;
    background-size: 29px 30px;
    background-position: center;
    background-repeat: no-repeat;
  }
  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    background-image: url(/assets/images/review-prev.png) !important;
    left: 5px;
    right: auto;
  }
  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    background-image: url(/assets/images/review-next.png) !important;
    right: 10px;
    left: auto;
  }
  .swipe-button {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 30px;
    text-align: center;
    line-height: 40px;
    font-size: 18px;
    cursor: pointer;
    &.swipe-left {
      left: 10px;
    }
    &.swipe-right {
      right: 10px;
    }
  }
}
.iti-boxradius {
  border-radius: 0px 0px;
}
.iti-boxradius:first-child {
  border-radius: 6px 6px 0px 0px;
}
.iti-boxradius:last-child {
  border-radius: 0px 0px 6px 6px;
}
.c-wrapperbox {
  border-radius: 6px;
  margin-bottom: 30px;
  background-color: var(--grey100);
  &.dotted {
    border: 1px dashed #0000003b;
  }

  @media only screen and (max-width: 575px) {
    .small-heading {
      font-weight: 300;
      font-size: 11px;
      color: var(--body-text-color);
    }
    marginright15 {
      margin-right: 10px;
    }
  }
}

.commute_icon {
  font-size: 16px;
  margin-top:2px;
}
.local_commute_options{
  font-size: 16px;
  display: flex;
    padding: 0px 10px;
    align-items: center;
}
.transit_icon{
  color: var(--grey400);
  border-radius: 6px 0 0 6px;
  display: flex;
  padding: 10px 15px;
  margin-top:2px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.commute_details{
  display:flex;
  padding:5px 10px;
}
.day_divider {
  color: var(--body-text-color) !important;
  padding: 0px 20px !important;
}
.day_content {
  padding: 40px 25px 50px 25px;
}

.itinerary_heading {
  display: inline;
  border: 0.1rem solid var(--body-text-color);
  padding: 1.3rem 2rem !important;
  border-radius: 0.8rem;
  @include media-breakpoint-only(xs) {
    padding: 0.8rem 1.6rem !important;
  }
}
.itinerary-cwrapper {
  padding: 20px;
  background: var(--white);
  border-radius: 6px;
  padding-bottom: 25px;
  @media only screen and (max-width: 991px) {
    .small-heading {
      white-space: normal;
    }
  }
}
.iti-svg-icon{
  display: inline;
  svg{
    width:20px;
    height:20px;
    --highlightcolor:var(--secondary-color);
    fill:var(--secondary-color);
  }
} 
.iti-commute-icon {
  display: inline;
  svg{
    width:20px;
    height:20px;
      fill:var(--grey400);
      --highlightcolor:var(--grey400);
  }
}
.iti-icon-ht {
  font-size: 1.2rem;
  margin-top:7px;
  color: var(--secondary-color);
}
.iti-info-icon {
  font-size: 1.2rem;
  color: var(--grey400);
  cursor: pointer;
  padding:0px 5px;
}
.iti-btm {
  margin-bottom: 1rem;
}
.chevron-down {
  display: none;
}
.sec_bg {
  background: var(--grey100);
  padding: 12px 0px 10px 0px;
}
.day-dropdown {
  background: var(--white);
  display: inline;
  justify-self: center;
  width: 225px;
  margin: 0px auto;
  border-radius: 6px;
  position: absolute;
  z-index: 9;
  top: 54px;
  left: 49%;
  transform: translateX(-50%);
  box-shadow: 2px 0px 8px var(--card-shadow);
  text-align: center !important;

  li {
    padding: 10px 20px;
    text-align: left;
    font-size: 14px;
    color: var(--body-text-color);
    &:hover {
      color: var(--grey200);
    }
    &:active {
      color: var(--primary-color);
    }
  }
}
.itinerary-container {
  max-width: 98%;
  margin: 0px auto;
  @media only screen and (max-width: 1024) {
    .col-md-4 {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
  }
}

@media only screen and (max-width: 575px) {
  .itinerary-cwrapper {
    padding: 20px;
    background: var(--white);
    border-radius: 6px;
    padding-bottom: 20px;
  }
  .itinerary-bg .swiper-button-next,
  .itinerary-bg .swiper-button-prev {
    position: absolute;
    top: 52%;
    width: 20px;
    height: 30px;
    z-index: 10;
    cursor: pointer;
    background-size: 20px 20px;
    background-position: center;
    background-repeat: no-repeat;
  }
  .day_divider {
    color: var(--body-text-color) !important;
    padding: 0px 10px !important;
  }

  .chevron-down {
    display: inline !important;
    font-weight: 600;
    font-size: 11px;
  }

  .mob_trippadding {
    padding: 20px;
  }
  .mob_trippadding1 {
    padding-bottom: 20px;
    padding-right: 20px;
  }
}
.sec_bg.is-sticky {
  background: var(--grey100) !important;
  box-shadow: 1px 0px 1px var(--card-shadow) !important;
}

.trip-scrap .trip-scrap-more-option .more_dropdown:last-child {
  position: absolute;
  min-width: 100px;
  font-size: 14px;
  background: var(--white);
  color: var(--body-text-color);
  top: auto !important;
  right: 0px;
  bottom: 70px;
  display: block;
  z-index: 5;
}
.flex-scroll {
  flex-wrap: nowrap;
  overflow-x: scroll;
  overflow-y: visible;
  white-space: nowrap;
}

.w-32 {
  width: 32%;
  margin: 0px auto;
}

.day-bg {
  background: var(--grey100);
}

@media only screen and (max-width: 1024px) {
  .sec_bg {
    background: var(--grey100);
    padding: 0px 0px 10px 0px;
  }
  .itinerary-container {
    padding-top: 0px;
    max-width: 100%;
    margin: 0px;

    .day-bg {
      padding: 20px 0px;
    }
    .mob-100 {
      flex: 0 0 100% !important;
      max-width: 100% !important;
    }
    .is-sticky {
      padding: 10px 0px;
      background: var(--grey100) !important;
      box-shadow: 1px 0px 2px var(--card-shadow) !important;
    }
  }
}

.notinitinerarys {
  overflow: hidden;
  .swiper-pagination {
    margin-top: 20px;
    position: relative !important;
    transition: 0.3s opacity;
    transform: translate3d(0, 0, 0);
    font-size: 15px;
    float: none;
    text-align: right !important;
  }

  .swiper-pagination-bullet {
    font-size: 15px;
    width: auto;
    line-height: 24px !important;
    height: auto !important;
    border-radius: 100%;
    opacity: 1 !important;
    background: transparent !important;
    &:after {
      content: "\007C" !important;
      margin: 0px 10px 0px 10px !important;
      font-size: 15px !important;
      color: var(--body-text-color) !important;
    }
    &:focus {
      outline: none !important;
    }
  }
  .swiper-pagination-bullet-active {
    color: var(--primary-color);
    &:after {
      color: var(--body-text-color) !important;
    }
  }
  .swiper-container-horizontal
    > .swiper-pagination-bullets
    .swiper-pagination-bullet {
    margin: 0 0px;
  }

  .swiper-pagination-bullet:last-child::after {
    content: " " !important;
  }
  .swiper-button-next-custom,
  .swiper-button-prev-custom {
    position: absolute;
    top: 43%;
    margin-top: 0px;
    z-index: 10;
    cursor: pointer;
    color: black;
    font-size: 15px;
    width: 34px;
    height: 34px;
    line-height: 34px;
    border-radius: 50%;
    text-align: center;
    text-shadow: 0 0 12px var(--white);
  }
}

.width-100 {
  max-width: 100;
}
.trip-detailwrapper {
  background: linear-gradient(45deg, #22222273, #00000082);
  position: absolute;
  bottom: 0px;
  width: 100%;
}
.itinerary-cover {
  background: linear-gradient(180deg, #00000094 0%, #000000a6 74%);
  background-size: cover;
  max-width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  height: 70vh;
}
.mobile_day_wise {
  padding: 0px 15px 20px;
  border-bottom: 1px solid var(--grey200);
  margin-bottom: 20px;
  color: var(--grey200);
  font-size: 15px;
}
.trip-price-wrapper {
  box-shadow: var(--card-shadow) 0 -1px 10px 0;
  padding: 20px;

  border-radius: 6px;
  @include media-breakpoint-only(xs) {
    padding: 15px;
  }
}

.scrap-mytable {
  .modal-content {
    .modal-header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 1rem 0.12rem;
      border-bottom: 1px solid #dee2e6;
      border-top-left-radius: 0.3rem;
      border-top-right-radius: 0.3rem;
      background: var(--grey100) !important;
      border: 0px solid transparent !important;
    }
    .modal-body {
      position: relative;
      flex: 1 1 auto;
      background: var(--grey100) !important;
      padding: 1rem;
    }
  }
}

.day_wise_dates {
  justify-content: center;
  background-color: var(--grey100);
  padding:20px 0;
  @media only screen and (max-width: 991px) {
    padding: 10px 0;
  }
  .day-icon svg{
    width:20px;
    height:20px;
    
  }
}
.scrap-title {
  max-width: 85%;
  display: flex;
    align-items: flex-start;
}
.place_tips_icon{
  color:#F8B240;
  cursor: pointer;
  i{
    font-size:10px
  }
}

.trip-scrap {
  .daywiseimg {
    width: 100%;
    object-fit: cover;
    height: 55px !important;
  }
}

@media only screen and (max-width: 991px) {
  .trip-scrap {
    .daywiseimg {
      min-height: 50px;
      width: 100%;
      object-fit: cover;
    }
  }
}


// CUSTOM TOOLTIP

.custom-tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.custom-tooltip {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position the tooltip above the text */
  left: 50%;
  margin-left: -60px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.custom-tooltip-container:hover .custom-tooltip,
.custom-tooltip-container:focus-within .custom-tooltip {
  visibility: visible;
  opacity: 1;
}

/* Mobile specific styling */
@media (hover: none) and (pointer: coarse) {
  .custom-tooltip-container:active .custom-tooltip {
    visibility: visible;
    opacity: 1;
  }
}

.trip_tags .tags-pill{
  padding: 2px 10px;
    border-radius: 10px;
    margin-right: 5px;
    text-transform: capitalize;
    color:var(--body-text-color);
    background-color: var(--grey100);
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom:5px;
}

/* ACCORDION */

.accordion-item{
  border-radius: 6px;
  border: 1px solid var(--grey200);
  overflow: hidden;
  margin: 10px 0px ;
  transition: all 0.5s;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);
  .accordion-header{
    background: var(--grey100);
    font-weight:bold;
  }
  &:has(.accordion-header.collapsed){
      box-shadow:none;
  }
  .accordion-collapse-button{
    background: transparent;
    border: none;
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content:space-between;
  }
  .accordion-collapse{
    padding:10px;
  }
  .accordion-icon{
    margin-right:10px;
  }
}
