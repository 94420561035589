import { Directive, ElementRef, Input, Output, HostListener, EventEmitter } from '@angular/core';

@Directive({
    selector: '[appClickOutside]',
    standalone: true,
})

export class ClickOutsideDirective {

    constructor(private elementRef: ElementRef) {}

    @Output() clickOutside: EventEmitter<any> = new EventEmitter();

    @HostListener('document:click', ['$event'])
    onClick(event: MouseEvent): void {
        const targetElement = event.target as HTMLElement;
        const clickedInside = this.elementRef.nativeElement.contains(targetElement);
        console.log("native element");
        console.log(this.elementRef.nativeElement);
        console.log(" targetElement targetElement ");
        console.log(targetElement);
        
        if (!clickedInside) {
            this.clickOutside.emit(null);
        }
    }
    
}