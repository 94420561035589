svg{
 --highlightcolor:var(--primary-color);
 fill:var(--grey400);
}
.days-container {
  display: flex;
  overflow-x: auto;
  -ms-overflow-style: none; // IE and Edge
  scrollbar-width: none; // Firefox

  &::-webkit-scrollbar {
    display: none; // Chrome, Safari, Opera
  }

  .days-holder {
    flex-basis: calc(33.333% - 20px);
    min-height: 100px;
    margin:0px  10px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
  }
}

.itinerary-cwrapper {
  width: 100%;
}
// Media query for mobile devices
@media (max-width: 600px) {
  .days-container {
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .days-holder {
    flex-basis: 100%; // Full width for mobile view
    margin-left: 10px;
    margin-right: 10px;
  }
}
.rounded-6 {
  border-radius: 6px;
}
.iti-more-options {
  padding: 10px;
  text-align: center;
  cursor: pointer;
  z-index: 2;
  position: relative;
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
  .modal-body {
    padding: 0px !important;
  }
  .modal-content {
    height: 100%;
  }
  day-map {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}
.map-marker {
  width: 30px;
  height: 30px;
  background-color: var(--secondary-color);
  border-radius: 60px 60px 0px 60px;
  position: relative;
  transform: rotate(45deg);
  .map-marker-content {
    transform: rotate(-45deg);
    width: 100%;
    height: 100%;
    text-align: center;
    line-height: 30px;
    color: #fff;
  }
}

.itinerary_full_loading {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 10000;
  top: calc(100vh - 55%);
  transition: all 1s ease-in-out;

  .loading_update {
    font-size: 24px;
    text-align: center;
    width: 100%;
    transition: all 1s ease-in-out;
    position: absolute;
  }
  .loading_text {
    width: 100%;
    background: rgba(255, 255, 255, 0.7);
    transition: all 1s ease-in;
  }
}

//Floating textbox

@keyframes expandInput {
  0% {
    width: 0;
    opacity: 0;
  }
  50% {
    width: 0; /* No change in width until 50% */
    opacity: 0; /* No change in opacity until 50% */
    padding: 8px 12px;
  }
  100% {
    flex-grow:1; /* Fully expanded */
    opacity: 1; /* Fully visible */
    padding: 8px 12px;
  }
}

@keyframes showMoreOptions {
  0% {
    opacity: 0;
    height:0px;
    width: 0px;
  }
  70% {
    opacity: 0; 
    height:0px;
    width: 0px;
  }
  100% {
    opacity: 1;
    height:auto;
    width: auto;
  }
}

.floating-textbox-container {
  position: fixed;
  bottom: 50px; 
  right: 40px;
  width: 60px;
  max-width: 600px;
  transition: all .3s ease;
  transform-style: preserve-3d;
  @media (max-width: 600px) {
    right:10px;
  }

  &.expanded{
    width:98%;
    @media (max-width: 600px) {
      right:1%;
    }
    .floating-prompt-box .floating-textbox {
      animation: expandInput 0.6s ease forwards;
      font-size:16px;
    }
    .floating-prompt-box::before{
      filter:blur(5px);
    }
    .edit_error_message{
      display: block;
    }
    .additional-options{
      animation: showMoreOptions 0.6s ease forwards;
    }
    
    
  }
  .additional-options{
    display: inline-block;
    opacity: 0;
    height:0px;
    width:0px;
    overflow: hidden;
    margin-left:10px;
  }
}
.floating-prompt-box{
  background: var(--white);
  border-radius: 30px;
  width:100%;
  display: flex;
  align-items: flex-start;
  padding: 10px;
  z-index: 1000;
  transition: all .3s ease;
  transform-style: preserve-3d;
  min-height:60px;
  flex-direction: column;
  &::before{
    content:"";
    position: absolute;
    inset: 0;
    border-radius: inherit;
    transform: translateZ(-1px);
    pointer-events: none;
    transition: filter .5s ease;
    background: linear-gradient(123deg, var(--primary-color),  var(--yellow-color),var(--secondary-color) 95%);
    background-clip: content-box;
    padding: 1px;
    filter:blur(0px);
  }
    .input-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    overflow: hidden;
    .floating-textbox {
      all:unset;
      width:0px;
      opacity: 0;
      border: none;
      outline: none;
      font-size: 1px;
      resize: none;
      line-height: 1.5;
      flex-grow:1;
    }
  }
  .edit_error_message{
    display:none;
  }
  .selected-place{
    background: var(--yellow-color);
    padding: 2px 5px;
    border-radius: 20px;
    font-size: 12px;
    color: #000;
    i{
      cursor: pointer;
    }
  }
}

form {
  display: flex;
  align-items: center;
  width: 100%; // Ensures the form takes full width of the container
}

.send-button {
  position: absolute;
  display: flex;
  right:10px;
  bottom:10px;
  justify-content: center;
  align-items: center;
  padding:0px;
  background-color: var(--primary-color); // Button color
  color: white;
  border: none;
  width: 40px; // Circular size
  height: 40px; // Circular size
  border-radius: 50%; 
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s;
}

.send-button i {
  font-size: 16px; 
}

.send-button:disabled {
  background-color: var(--grey400);
}
