import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
@Directive({
  selector: '[appSvgIcon]',
  standalone: true,
})
export class SvgIconDirective implements OnInit {
  @Input() appSvgIcon!: string; // SVG file path

  private static cache: { [key: string]: SafeHtml } = {}; // Caching for performance

  constructor(private el: ElementRef, private http: HttpClient, private sanitizer: DomSanitizer) {}

  ngOnInit() {
    const path=environment.svgIconsPath+'assets/icons/svg/'+this.appSvgIcon+'.svg';
    if (path) {
      if (SvgIconDirective.cache[path]) {
        this.setSvg(SvgIconDirective.cache[this.appSvgIcon]);
      } else {
        // Fetch SVG file
        this.http.get(path, { responseType: 'text' }).subscribe(svg => {
            SvgIconDirective.cache[this.appSvgIcon] = svg; // Store raw SVG
            this.setSvg(svg);
        });
      }
    }
  }

  private setSvg(svg: any) {
    // Sanitize and insert the SVG properly
    const sanitizedSvg = this.sanitizer.bypassSecurityTrustHtml(svg);
    this.el.nativeElement.innerHTML = ''; // Clear previous content
    this.el.nativeElement.insertAdjacentHTML('beforeend', svg); // Inject sanitized SVG
  }
}
