.t-profileimg {
    display: inline-flex;
    flex-basis: 20%;
    img {
    border: 1px solid #5c5c5c59;
    border-radius: 50%; 
    }
}
.background-black{
    background-color :var(--body-text-color);

}
.background-new_graydarker {
    background-color:   var(--body-text-color) ;
}
.t-profilecontent {
    flex-basis: 80%;
}
.w-20 {
    width:20%;
}
.t-profilebg {
    background: linear-gradient(to right, rgb(40, 48, 72), rgb(133, 147, 152));
    padding: 30px 20px;
    .content-heading{
        color:var(--white) !important;
    }
}
.marginright25 {
    margin-right: 25px;
}
.margintop25 {
    margin-top:25px;
}
.profile_icon {
    font-size: 14px;
    background: var(--white);
    color: #283048d6;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    line-height: 35px !important;
    text-align: center;
    margin: 10px 10px 0px 0px;
}

.t-plist a{
    text-align: left;
    color: var(--body-text-color);
    padding: 10px 15px 10px 0px;

    &.active {
        color :  var(--primary-color)-color!important;
        font-weight:400;
    }

    &.focus {
        color :  var(--primary-color)-color!important;
    }
}
.profilepic-container {
    position: relative;
    display: inline-flex;
    
    
}
.profile-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
    width: 100%;
    border-radius: 50%;
  opacity: 0;
  transition: .5s ease;
 background-color: var(--body-text-color)00047;
}
.profilepic-container:hover .profile-overlay {
  opacity: 1;
}
.profile-edit-text {
   color: var(--white)  ;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 5px 10px;
    text-transform: uppercase;
}

.editform {
    .selectdiv select {
    border: 1px solid var(--grey200);;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 7px 20px 7px 20px;
    font-size: 15px;
    line-height: 18px;
    color: var(--body-text-color);
    background-color: var(--white)fff;
    background-image: none;
    width: 100%;
    min-width: 50px !important;
    font-weight: 300 !important;
}
.selectdiv:after {
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
    font: 18px "Consolas", monospace;
    color:  var(--body-text-color);
    right: 10px;
    top: 13px;
    padding: 0 0 2px;
    position: absolute;
    pointer-events: none;
}
.input-field {
    background: var(--white);
    box-shadow: none !important;
    border: 1px solid var(--grey200);;
    padding: 10px 20px;
    border-radius: 4px;
    width: 100%;
    line-height: 20px !important;
}
}
.greyout {
        padding: 10px 20px;
    opacity: 0.5;
    background:  var(--grey200) eeec2;

    border-radius: 6px;
}
.editfield {
padding:10px  20px;

}

.editfield:hover {
   background:  var(--grey200) eee6b;
    border-radius: 6px
}
.editbox {
        padding: 10px 20px;
    border: 1px solid var(--grey200);;
    border-radius: 6px;
    font-weight: 300;
    margin-bottom: 10px;
}
.curved-box {
    border-radius:12px;
    border:1px solid var(--grey200);;
    transition-duration: 0.5s;
}
.curved-box:hover {
       border:1px solid var(--primary-color);
}
.profileborder {
    border: 1px solid var(--grey200);;
    border-radius: 4px;
}
.padding15 {
    padding: 15px;
}



.menu-btn {
    text-transform: capitalize;
    font-size: 14px;
    border: 2px solid var(--primary-color);
    padding: 6px 15px;
    background: var(--white);
    color: var(--primary-color);
    border-radius: 18px;
    transition-duration: 0.5s;
    font-weight: 400;
    margin: 3px;
    &:hover{
        border: 2px solid var(--primary-color);
        background:var(--primary-color);
        color:var(--white)  !important;
        font-weight:400;
    }
    &:disabled{
        &:hover{
            border: 2px solid  var(--primary-color)-color!important;
            background: var(--white) !important;
            color:  var(--primary-color)-color!important;
    
        }
      
    }
}




.confirmbtn {
    text-transform: uppercase;
    font-size: 9px;
    line-height: 6px;
    padding: 8px;
    background:var(--primary-color);
    color:var(--white) !important;
    border-radius: 4px;
    transition-duration: 0.5s;
    font-weight: 400;
    &:hover{
        color : var(--white);
    }
}

.cancelbtn {
     text-transform: uppercase;
    font-size: 9px;
    line-height: 6px;
    padding: 8px;

    background:  var(--secondary-color) ;
    color:var(--white) !important;
    border-radius: 4px;
    transition-duration: 0.5s;
    font-weight: 400;
    &:hover{
        color : var(--white);
    }
}
.approvebtn {
     text-transform: uppercase;
    font-size: 9px;
    line-height: 6px;
    padding: 8px;
    background:var(--primary-light);
    color:var(--white) !important;
     border-radius: 4px;
    transition-duration: 0.5s;
    font-weight: 400;
    &:hover{
        color : var(--white);
    }
}

.pendingbtn{
    text-transform: uppercase;
    font-size: 9px;
    line-height: 6px;
    padding: 8px;
    background: var(--warning)  ;
    color:var(--white) !important;
    border-radius: 4px;
    transition-duration: 0.5s;
    font-weight: 400;
    &:hover{
        color : var(--white);
    }
}




.togglebtn {
    border-radius: 6px;;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 13px;
    text-transform: uppercase;
    background: var(--white) ;
    color:var(--body-text-color) ;
    border: 1px solid  var(--grey200) ;
    
    padding: 10px 20px;
  

}
.bg-primary1-alt {
    background : var(--primary-color);
    color :var(--white);
}
.profile-btngrp {

    .btn-group>.btn:focus, .btn-group>.btn:active,
.btn-group>.btn.active, .btn-group-vertical>.btn:focus,
 .btn-group-vertical>.btn:active, .btn-group-vertical>.btn.active{
    z-index: 1;
    color:var(--white);
    background: var(--primary-color);
    box-shadow: none;
}

.selectdiv select {
    border: 1px solid var(--grey200);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 220px;
    padding: 10px 20px;
    font-size: 15px;
    line-height: 18px;
    color:  var(--body-text-color);
    background-color: var(--white);
    background-image: none;

}
}
.p-reviewstar {
   font-size:10px;
   width:15px;
}
.profile-review-img {
      max-height: 100px;
    border-radius: 50%;
    border: 1px solid var(--grey200);
}
.reviewstar {
    font-size: 10px;
    color:var(--primary-color);
    margin: 10px 2px;
}
.textareabox {
    background:  var(--grey200) ;
    border-radius: 6px;
    padding: 30px;
}
.faq-border {
    padding: 25px;
    border: 1px solid var(--grey200);
    border-radius: 6px;
}
.sqbox {
    height: 18px;
    width: 18px;
    border-radius: 2px;
    border:1px solid var(--primary-color);
    margin-right :5px;
}
.primary1bg {
    background: var(--primary-color);
}
.trip-tag {
    border-radius: 6px;
    border: 1px solid var(--grey200);
    padding: 6px 15px;
    &.active{
      background-color:  var(--grey200) ;
    }
}
.editprofile {
    width: 520px;
.selectdiv select {
    border: 1px solid var(--grey200) ;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    min-width: 150px;
    height: auto !important;
    padding: 5px 30px 5px 10px;
    font-size: 15px;
    line-height: 22px;
    font-weight: 300;
    color:  var(--body-text-color);
    background-color:var(--white);
    background-image: none;
}
.selectdiv:after {
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent;
    font: 18px "Consolas", monospace;
    color:  var(--body-text-color);
    right: 14px;
    top: 13px;
    padding: 0 0 2px;
    position: absolute;
    pointer-events: none;
}
}
.profile-logo img{
    float: right !important;
    max-height: 60px;
    width:auto;


}
.maxht400 {
    max-height:400px;

}
.booking-divider {
    // position: relative;
    &:after {
    content: "";
    position: absolute;
    background:var(--grey200);
    width: 1px;
    height: 45px;
     top: 50%;
    transform: translateY(-50%);
    
    
}
}


@media only screen and (max-width:1024px) {
.marginright25 {
    margin-right: 0px;

}
.booking-divider:after {
    content: "";
    position: absolute;
    background: transparent;
    width: 0px;
    height: 40px;
    top: 50%;
    transform: translateY(-50%);
}
.edit_mbtm {
margin-bottom: 10px !important;
}
.mob-marginbottom25 {
   margin-bottom: 25px;
}
.t-profilebg {
    .content-heading {
    color:  var(--white) !important;
}
.pcurv-radius {
    border: 1px solid transparent;
    padding: 6px 12px;
    border-radius: 18px;
}
.profile_icon {
    font-size: 12px;
    background: var(--white);
    color: #283048d6;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    line-height: 30px !important;
    text-align: center;
    margin: 5px 5px 0px 0px;
}
}

.profile-logo img{
    float: none !important;
    max-height: 40px;
    margin-top: 10px;
}
.maxht400 {
    max-height: 290px;
}
.faq-border {
    padding: 15px;
    border: 1px solid var(--grey200);
    border-radius: 6px;
    margin-bottom: 20px !important;
}
.textareabox {
    background:  var(--grey100)  ;
    border-radius: 6px;
    padding: 30px;
    margin-top: 15px;
    margin-bottom: 40px;
}
.profile-btngrp {
    .selectdiv {
    position: relative;
    float: left;
    padding-left: 0px;
    margin-top: 10px;

    select {
    border: 1px solid var(--grey200);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 250px;
    padding: 10px 20px;
    font-size: 14px !important;
    line-height: 18px;
    color:  var(--body-text-color);
    background-color: var(--white)fff;
    background-image: none;
}
 }
.selectdiv:after {
    border-top: .2em solid;
    border-right: .2em solid transparent;
    border-left: .2em solid transparent;
    right: 9px;
    font: 20px "Consolas", monospace;
    color:  var(--body-text-color);
    right: 10px;
    top: 16px;
}


}
.profileborder {
    margin-bottom: 15px;
    .bordermid {
    padding-bottom: 0px;
    border-right: none;
    border-bottom: 0px solid var(--grey200);
    margin-bottom: 10px;
}
.valign {
    align-self: flex-start;
}
}
.mob_bpadding {
    padding: 0px  !important;
}
 .editprofile {
     .selectdiv {
        width: 100%
     }

 }
 .profile-review-img {
    max-height: 85px;
    border-radius: 50%;
    border: 1px solid var(--grey200);
}

.t-profileimg {
   flex-basis: 30%;
    img {
     border: 1px solid #5c5c5c59;
    border-radius: 50%;
    max-height: 160px;
    width:auto;   
    margin-right: 0px;

    }
}
.t-profilecontent {
    flex-basis: 70%;
    margin-left: 20px;
}
.t-profilebg {
    padding: 20px 0px;
    .content-heading {
        color:  var(--white) !important;
    }
}
.mob_labelalign{
    text-align: left !important;
    margin: 0px !important;
}
}


@media only screen and (max-width:575px) {
    .editprofile {
    text-align: center;
    width: 100%;
    margin: 0px auto;
    .selectdiv {
    position: relative;
    float: left;
    padding-left: 0px;
    width: 100%;
    select {
    border: 1px solid var(--grey200) ;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    min-width: auto;
    height: auto !important;
    padding: 5px 30px 5px 10px;
    font-size: 15px !important;
    line-height: 24px;
    font-weight: 300;
    color:  var(--body-text-color);
    background-color: var(--white)fff;
    background-image: none;
    width: 100%;
}
}
}
.confirmbtn {
    text-transform: uppercase;
    font-size: 9px;
    line-height: 6px;
    padding: 6px;
    background: var(--primary-color);
    color: var(--white);
    border-radius: 4px;
    transition-duration: 0.5s;
    font-weight: 400;
}

.editform .selectdiv select {
   
    padding: 8px 7px 6px 8px;
    
}

}
.anchor-white {
    color: var(--white) !important;
    &:hover {
        color:var(--white) !important;
    }
}
