
.trans_body {
  font-size: 15px;
  font-weight: 300;
  line-height: 26px;
  color: var(--body-text-color);
  &.trip_path::after{
    content: " - ";
  }
  &.trip_path:last-child::after{
    content: "";
  }
}

.transpadding {
  box-shadow: 2px 4px 3px  var(--grey200) ;
  padding: 20px;
  justify-content: space-between !important;
  a {

    font-size: 18px;
    font-weight: 400;
    color: var(--body-text-color);
    &:hover {
       color: var(--body-text-color) !important;
    }
  }
  i {
     margin-right: 5px;
    font-size: 15px;
    font-weight: 600;
  }
}
.trans_descriptive {
  font-size: 13px;
  font-weight: 300;
  color: var(--body-text-color);
  margin: 0px !important;
   &.trip_path::after{
    content: " - ";
  }
  &.trip_path:last-child::after{
    content: "";
  }
}


.grayband {
  padding: 20px;
  background:  var(--grey200) eee7d;
}
.traveller_count_display span::after{
  content:', ',
}
.traveller_count_display span:last-child::after{
  content:'',
}
.flightpadding {
    padding: 25px;
    border: 1px solid #ededed;
    border-radius: 6px;
}
.mytextoverflow {

    max-width: 220px;

}

.file-upload-input{
    position: relative;
    overflow: hidden;
    width: 25px;
    cursor: pointer;
    text-align: center;
    margin: 0px auto;
}
.leftbar{
        width:10%;
        flex-grow:1;
        min-height: 100%;
        box-shadow: 2px 0px 2px  var(--grey200) ;
}
.header_popup {
  
  font-weight: 600;
  font-size: 22px;
  opacity: 0.8;
}
button:focus {
    outline: 1px dotted;
    outline: 0px auto -webkit-focus-ring-color;
}

.flightgrid1 {
  flex-grow: 1;
}
.leftbar {
  width: 13%;
  flex-grow: 1;

}
.midbar {
  width: 72%;
  flex-grow: 2;
}
.rightbar {
  flex-grow: 1;
  width: 28%;
  max-width:28%;
 
   
.margin-rbtn {
  margin-bottom:10px;
}
.margin_tstatus {
    margin: 40px 0px 25px 0px !important;
}
}
.flightgrid2 {
  flex-grow: 2;
}

.rbarinner {
    width: 52%;
    padding-right: 15px;
    flex-grow: 1;
    text-align: left;
}

.rbarinner1 {
    width: 48%;
    
    flex-grow: 1;
    text-align: right;
}

.width-32 {
    width: 32%;
    margin:auto;
}
.modal-container {
  .bs-datepicker {
    display: flex;
    align-items: stretch;
    flex-flow: row wrap;
    background: var(--white);
    box-shadow: 0 0 10px 0  var(--card-shadow);
    position: relative;
    z-index: 1;
    left: 0px;
}
}

.trav_popup {
  align-self: center;
  width: 145px;
  margin:0px;
  text-align: left;
}
.innerflightwrap {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  .col {
    flex: 1;
    padding: 0px;
    text-align: center;
  }
  .fa-plane {
    color: #b7b7b7;
    font-size: 18px;
    transform: rotate(-38deg);
    -webkit-transform: rotate(-38deg);
    margin: 0px 14px;
  }
  .mydots {
    transform: rotate(90deg);
    margin: 8px 0px;
    font-size: 12px;
    color: #b7b7b7;
  }
}
.web_ml38 {
  margin-left: 38px;
}
.flighticon {
  font-size: 22px;
  padding: 0px 15px;
}
.uploadicon {
  font-size: 18px;
  padding: 0px 6px;
}
.pdfcolor {
  color:  var(--error)   ;
}
.plr {
  margin: 0px 20px;
}

.applybtn {
  background: transparent;
  display: inline;
  text-align: center;
  padding: 10px 20px;
  text-transform: uppercase;
  border: none;
  border-radius: 4px;
  transition-duration: 0.5s;
  font-size: 14px;
  font-weight: 300;
  &.hover {
    background:  var(--grey200) ;
  }
}
.lightgrey {
  color:#dfdfdf;
}

.travellerbox {
  background:transparent;
  box-shadow: none !important;
  border: 1px solid var(--grey200) ;
  padding: 5px 10px;
  border-radius: 4px;
  width: 100%;
  font-size:14px;
  text-align:left;
  cursor: pointer;
  i {
    cursor:pointer;
    float: right;
    padding: 4px 0px;
  }
}
.header_popup {
     .close {
        color: var(--body-text-color);
    font-size: 25px !important;
    font-weight: 500;
    opacity: 1;
     }
}
.modal-header{
     .close {
        color: var(--body-text-color);
    font-size: 25px !important;
    font-weight: 500;
    opacity: 1;
     }
}
.fixed-bg {
      background:var(--primary-color);
      color:var(--white) !important;
    }
.warningicon {
  display: block !important;
  font-size: 55px;
  margin: 20px;
  color: #ffc107;
}
.mtop20 {
  margin-top: 20px !important;
}
.mleft20 {
  margin-left: 20px;
}
.successicon {
  font-size: 75px;
  text-align: center !important;
  display: block !important;
  color:  var(--success) ;
}
.successmsg {
  font-weight: 300;
  font-size: 27px;
  text-align: center;
  line-height: 34px;
  margin: 20px 0px 0px 0px;
  text-transform: uppercase;
  color: var(--body-text-color);
}
.flayoutwrapper {
  flex-flow: row wrap;
  display: flex;
  .innertcol1 {
    display: flex;
    flex-flow: column wrap;
    padding: 30px 15px;

    .cols {
      flex-grow:1;
      padding: 10px 0px;
      color:  var(--body-text-color) ;
      :hover {
        color: var(--primary-color);
        cursor: pointer;
      }
      &.active {
        color: var(--primary-color);
        cursor: pointer;
      }
    }
  }
}
.flayoutwrapper1 {
  flex-flow: row wrap;
  display: flex;
  align-items: center;
}
.width-10 {
  width: 10%;
}

.width-20 {
  width: 20%;
}
.trans_title {
    font-size: 30px;
    font-weight: 300;
}
._100 {
  flex: 0 0 100%;
}
.span100 {
  width:100%;
}
.flayoutwrappermid {
  flex-flow: row wrap;
  display: flex;
  align-items: center;
}

._10 {
  padding: 0px 10px;
  width: 100px;
}

.custom-switch .custom-control-label::after {
  top: calc(0.15625rem + 4px);
  left: calc(-2.25rem + 1px);
  width: 13px;
  height: 13px;
  background-color: #adb5bd;
  border-radius: 50%;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.custom-switch .custom-control-label::before {
  left: -2.35rem;
  width: 2.2rem;
  pointer-events: all;
  border-radius: 1.5rem;
}

.custom-control-label::before {
  position: absolute;
  top: 0.22rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1.2rem;
  pointer-events: none;
  content: "";
  background-color: var(--white);
  border: #adb5bd solid 1px;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: var(--white);
  transform: translateX(0.95rem);
}
// .custom-control-input:checked ~ .custom-control-label::before {
//     color: var(--white);
//     border-color: #ccc;
//     background-color: #ccc;
// }
.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: var(--white);
    background-color: var(--white);
    border-color:  var(--grey200) ded;
}
.custom-control-input:checked~.custom-control-label::before {
    color: var(--white);
    border-color: var(--primary-color);
    background-color:var(--primary-color);
}
.selfalign{
  align-self: center;
}
.custom-switch {
  padding-left: 2.25rem;
  padding-top: 0px;
}
.includes_check {
  width: 20px;
  height: 18px;
}
.padding50 {
  padding-top: 50px;
}
.textbox-width {
    overflow: hidden;
    width: 68px;
    border-radius: 4px;
    border: 1px solid #d1d0d0;
    height: 32px !important;
    padding: 5px;
    text-align: center;
    color: var(--body-text-color);
    font-weight: 400;
    text-overflow: ellipsis;
    background :transparent;
}
.trans-arrow {
  width: 16px;
  margin-right: 10px;
}
.transml30 {margin-left:30px;}
.p_right5 {
  padding-right: 5px;
}

.rightbg {
    background: #f9f9f9;
}
.padding_20 {
  padding: 20px;
}
.policy-list {
  display: inline;
  li{
    display: inline;
    div {


      &:first-child {
      &:before {
        content:' ';
      }
    }
    &:before {
      content:' ';
    }
    &:after {

      content:'\007C';
      padding: 0px 10px;
      color:var(--primary-color);
    }
    &:last-child {
      &:after {
        content:' ';
      }
     
    }
    }
    
    

  }
}
.fw_normal {
  font-weight: 500;
}
.rbar-mtop {
  margin-top: 50px !important;
}
.rbar-mtop30 {
  margin-top: 30px !important;
}

.input-field1 {
  font-weight: 300;
  background:transparent;
  box-shadow: none !important;
  border: 1px solid var(--grey300) ;
  padding: 5px 10px;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
  text-align: right !important;
  font-size:14px;
}
.trav-width {
  width: 22% !important;
}
.input-field:focus {
  outline: none;
}
.transmleft {
  margin-left: 10px;
}
.b-gender {
  flex: 1;
}
.b-fname {
  flex: 2;
}
.b-lname {
  flex: 2;
}
.b-passno {
  flex: 5;
}
//dropdown for no of travellers css
.dropdown-abs {
  margin-top: 5px;
  width: 320px;
  padding: 20px;
  background: var(--white);
  border-radius: 4px;
  position: absolute;
  z-index: 2;
  border: 1px solid  var(--grey200) ;
  i {
    cursor: pointer;
  }

  input,
  textarea {
    border: 1px solid var(--grey200);
    box-sizing: border-box;
    margin: 0;
    outline: none;
    padding: 5px 10px !important;

  }

  input[type="button"] {
    -webkit-appearance: button;
    cursor: pointer;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  .input-group {
    clear: both;
    margin: 15px 0;
    position: relative;
    cursor: pointer;
  }

  .input-group input[type="button"] {
    background-color: var(--body-text-color);
    width: 26px;
    height: 26px;
    transition: all 300ms ease;
    color: var(--white);
    border-radius: 50%;
    border: none;
  }

  .input-group .button-minus,
  .input-group .button-plus {
    font-weight: bold;
    height: 38px;
    padding: 0;
    width: 38px;
    position: relative;
  }

  .quantity-field {
    border: none;
   width: 60px;
    margin: 0px auto;
    /* display: inline; */
    text-align: center;
    cursor: pointer;
    font-weight:300;
    font-size:15px;
  }

  input[type="number"] {
    -moz-appearance: textfield;
    -webkit-appearance: none;
  }
}
.inc_decicon {
    font-weight: 600;
    background: none;
    font-size: 10px;
    line-height: 9px;
    padding: 8px;
    color: var(--primary-color);
    border-radius: 50%;
    border: 1px solid var(--primary-color);
}
.tnamewidth {
    align-self: center;
    width: 100px;
     margin:0px;
}
.outergrid2 {
    flex-grow: 2;
}
.modalpadding {
  padding:25px ;
}
.ngx-checkbox {
  display: block;
  cursor: pointer;
  text-align: center;
  border: 1px solid #d1d0d0;
  width: 20px !important;
  height: 20px !important;
  line-height: 20px !important;
  margin: 0px auto;
  :focus {
      box-shadow: none !important;
     outline: none !important;
    }

.checkbox-no-checked {
    background:var(--white);
    box-shadow: none;
    border-radius:2px;

     }

}
.checkbox-checked {
    background: pink;
     color:var(--white) !important;;
     border-radius: 2px;
     border:none !important;
     .fa-check{
      color: var(--white) !important;
     }
    }
input:focus {
  outline: none !important;
}


.control {
    font-size: 18px;
    position: relative;
    display: block;
    margin-bottom: 22px;
    padding-left: 60px;
    cursor: pointer;
}

.control input {
position: absolute;
z-index: -1;
opacity: 0;
}

.control__indicator {
    position: absolute;
    top: 0px;
    left: 40%;
    width: 25px;
    height: 25px;
    background: #e6e6e6;
}

.control--radio .control__indicator {
border-radius: 50%;
}

.control:hover input ~ .control__indicator,
.control input:focus ~ .control__indicator {
background: #ccc;
}

.control input:checked ~ .control__indicator {
background:var(--primary-color);

}

.control:hover input:not([disabled]):checked ~ .control__indicator,
.control input:checked:focus ~ .control__indicator {
background:var(--primary-color);

}

.control input:disabled ~ .control__indicator {
pointer-events: none;
opacity: .6;
background: #e6e6e6;
}

.control__indicator:after {
position: absolute;
display: none;
content: '';
}

.control input:checked ~ .control__indicator:after {
display: block;
}
.footer_d-block {
  display:block;
}

.control--checkbox .control__indicator:after {
  top: 4px;
  left: 9px;
  width: 7px;
  height: 13px;
  transform: rotate(49deg);
  border: solid var(--white);
  border-width: 0 2px 2px 0;
}
.d_arrow {

  font-size: 14px;
  font-weight: 600;
  color: var(--body-text-color);

}
.d_arrow:before {
  content: "\f107";
  font-size: 17px;
  color: var(--body-text-color);

}
 .arr-active {
  transform: rotate(180deg);
}
._70 {
  flex-basis :70%
}
._30 {
  flex-basis:30%;
}

.darrow_pos {
  text-align: CENTER;
  margin: 12px auto 0px auto;
}
.trans_footer {
  position: absolute;
  width: 100%;
  max-height: 400px;
  overflow: auto;
  background: var(--white);
  color: var(--body-text-color);
  bottom: 45px;
  left: 0px;
  line-height: 30px;
  padding: 6px 15px;
  z-index: 9;
  transition: all 0.3s ease-in-out;
  box-shadow: 2px 0px 3px  var(--card-shadow);
}
.bgbtm {
    z-index: 11;
    border-top: 0px solid #f3f2f2;
    background: var(--white);
    height: 50px;
    bottom: 0px;
    padding: 0px 0px;
    line-height: 50px;
    @media only screen and (max-width:450px) {
      .content-heading {
      font-size: 16px;
      line-height: 49px;
      font-weight: 400;
      color: var(--body-text-color);
  }
    }

}
.disp_tblock {
  display:none !important;
}
.disp_tblock1 {
  display:none !important;
}
.disp_topbar {
  display:block;
}
.arrowtoggle {
  display:none !important;
}
.mb_mob5 {
  margin-bottom: 5px;
}
@media only screen and (max-width:1200px) {
  .rightbg {
    background: var(--white);
}
  .flayoutwrapper  {

    .innertcol1 {
        display: flex;
        flex-direction: row;
        padding: 10px;

        align-items: center;
        // overflow-x: scroll !important;
        flex-wrap: nowrap;
        .cols {
        flex-grow: 1;
        padding: 10px 20px;
        }
    }
}
.custom-switch .custom-control-label::before {
  left: -1.35rem;
  width: 2.2rem;
  pointer-events: all;
  border-radius: 1.5rem;
}
.custom-switch .custom-control-label::after {
  top: calc(0.15625rem + 4px);
  left: calc(-1.25rem + 1px);
  width: 13px;
  height: 13px;
  background-color: #adb5bd;
  border-radius: 50%;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.cols .fa-plus-circle{
  font-size: 31px;
  color: var(--primary-color);
}
.arrowtoggle {
  display:block !important;
}
.disp_tblock {
  display:block !important;
}
.disp_tblock1 {
  display:flex !important;
}
.disp_topbar {
  display:none;
}

.rbar_footer_fixed {
  position: fixed;
  bottom: 40px;
  max-height: 450px;
  overflow-y: scroll;
  z-index: 7;
}

.bgmobrow {
  background:  var(--grey200) ;
  padding: 20px 0px;
}
.outergrid2 {
    flex-grow: 2;
    width: 100%;
}
.leftbar {
  width: 100%;
  flex-grow: 1;
  overflow-x: auto;
}
._10 {
    flex-grow: 1;
    padding: 20px 0px ;
    align-items: center;
    .flayoutwrapper {
    flex-flow: row wrap;
    display: flex;
    align-items: center;
    
    .padding50 {
        padding-top: 0px;
        .textbox-width {
        width: 100px;
        border-radius: 4px;
        border: 1px solid #d1d0d0;
        height: 28px !important;
        }
    }
}
.successicon {
  font-size: 50px;
  text-align: center !important;
  display: block !important;
  color: #979797;
}
.successmsg {
  font-weight: 300;
  font-size: 20px;
  text-align: center;
  line-height: 34px;
  margin: 20px 0px 0px 0px;
  text-transform: uppercase;
  color: var(--body-text-color);
}
}

.custom-switch {
 padding:0px;
}
.width10 {
  min-width:80px;
}
.midbar {
  width: 100%;
  flex-grow: 1;
  .ml-3 {
    margin: 25px !important ;
  }
}
.rightbar {
    flex-grow: 1;
    max-width: 100%;
    width:100%;

    .primary-button {
      display:block !important;
      margin: 0px auto !important;
      text-align: center;
      color: var(--white) !important;
      padding: 6px 15px !important;
      background: #EE6055 !important;
      text-transform: uppercase;
      border-radius: 18px;
      font-weight: 300;
      border: none !important;
      display: inline-block;
      margin: 5px;
      font-size: 13px;
      max-width: 50% !important;
      width: 100% !important;
      margin-bottom: 10px !important;
  }
    .flayoutwrapper1{
      text-align: center;
      margin: 0px auto;
      width: 100%;
      .rbarinner {
        width: 50%;
        flex-grow: 1;
        text-align: left;
        margin-bottom: 5px;
    }
    .rbarinner1 {
      width: 50%;
      padding-right: 0px;
      flex-grow: 1;
      text-align: right;
      margin-bottom: 5px;
     
  }
 
.margin_tstatus {
    margin: 40px 0px 40px 0px !important;
}


}


}
.control__indicator {
    position: absolute;
    top: 0px;
    left: 40%;
    width: 20px;
    height: 20px;
    background: #e6e6e6;
}
.trip-height .location-name-places-added {
  color: var(--body-text-color);
  font-size: 20px;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
}
.mleft20 {
  margin-left: 0px;
}

.control__indicator {
  position: absolute;
  padding: 0px 0px;
  top: 4px;
  left: 47%;
  width: 25px;
  height: 25px;
/* height: 19px; */
background: #e6e6e6;
}

._70 {
flex-basis: 100%;
}
._30 {
flex-basis: 100%;
}


}



@media only screen and (max-width:991px)  {
  .mob-trans100 {
    width:100%;
    .primary-button {
      color: var(--white);
      padding: 6px 15px;
      background:  var(--secondary-color)  !important;
      text-transform: uppercase;
      border-radius: 18px;
      font-weight: 400;
      border: none !important;
     
      display: block;
      text-align: center;
      margin: 15px 0px 0px 0px;
      font-size: 13px;
  }
  }
  .t-fullwidth {
    width:100% !important;
    text-align:center !important;
  }
  .successmsg {
    font-weight: 300;
    font-size: 15px;
    text-align: center;
    line-height: 34px;
    margin: 20px 0px 0px 0px;
    text-transform: uppercase;
    color: var(--body-text-color);
}

.successicon {
    font-size: 50px;
    text-align: center !important;
    display: block !important;
    color: #979797;
}

  .mob-transl50 {
    width:50%;
    text-align:left !important;
    margin-top:10px;
  }
  .mob-transr50 {
    width:50%;
    text-align:right !important;
    margin-top:10px;
  }
  .mytextoverflow {
    max-width: 100%;
}
  ._10 {
    .textbox-width {
      overflow: hidden;
      width: 90px;
      border-radius: 4px;
      border: 1px solid #d1d0d0;
      height: 32px !important;
      padding: 5px;
      text-align: center;
      color: var(--body-text-color);
      font-weight: 400;
      text-overflow: ellipsis;
      background: transparent;
  }
  }
  .transpadding {
    box-shadow: 2px 4px 3px  var(--grey200) e;
    padding: 15px;
    justify-content: space-between !important;
}
  .transpadding a {
    font-size: 14px;
    font-weight: 400;
    color: var(--body-text-color);
}

.trav_popup {
    align-self: center;
    width: auto;
    margin: 0px;
}

}

@media only screen and (max-width:450px) {
  .flayoutwrapper {
    .innertcol1{
          height: 75px;
      padding: 0px;
      .cols {
        flex-grow: 1;
        padding: 0px;
    }
    }
  }
  .modalpadding {
    .rbarinner {
      width: 100%;
      padding-right: 15px;
      flex-grow: 1;
      text-align: left;
  }
  }
  .mytextoverflow{
    max-width: 90%;
  }
  .add-item {
    font-size: 29px !important;
    font-weight: 300 !important;
    background:  var(--grey200) ;
    padding: 15px 20px !important;
}
  .flightpadding {
    padding: 15px;
    border: 1px solid #ededed;
    border-radius: 6px;
}
.disp_lbar {
  display:block !important;
}
.hide_lbar {
  display: none;
}
.trans_body {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  color: var(--body-text-color);
}
.trans_descriptive {
  font-size: 11px;
  font-weight: 300;
  color: var(--body-text-color);
  margin: 0px !important;
}
.content-heading {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: var(--body-text-color);
}
.trans_mbtm {
  margin-bottom: 0px;
}
.trans_title {
  font-size: 22px;
  font-weight: 300;
}

.innerflightwrap {
  .fa-plane {
    color: #b7b7b7;
    font-size: 11px;
    transform: rotate(-38deg);
    -webkit-transform: rotate(-38deg);
    margin: 0px 6px;
  }
  .mydots {
    transform: rotate(90deg);
    margin: 4px 3px 0px 0px;
    font-size: 7px;
    color: #b7b7b7;
}
}

.fl_inline {
  display:inline;
  margin-right:10px;
}
.m_tb {
  margin: 10px 0px !important;
}
.rightbar {
  flex-grow: 1;
  max-width: 100%;
  .primary-button {
    color: var(--white);
    padding: 6px 15px !important;
    background:  var(--secondary-color)  !important;
    text-transform: uppercase;
    border-radius: 18px;
    font-weight: 400;
    border: none !important;
    width: 100% !important;
    max-width: 100% !important;
    display: block;
    text-align: center;
    margin: 15px 0px 0px 0px;
    font-size: 13px;
    margin-bottom: 10px !important;
}
  .flayoutwrapper1{
    text-align: center;
    margin: 0px auto;
    width: 100%;
    .rbarinner {
      width: 50%;
      flex-grow: 1;
      text-align: left;
      margin-bottom: 5px;
  }
  .rbarinner1 {
    width: 50%;
    padding-right: 0px;
    flex-grow: 1;
    text-align:right;
    margin-bottom: 5px;
}
.trans_descriptive {
  margin-top:0px !important;
  .mtop20 {
    margin-top: 20px !important;
}
}

}
.margin_tstatus {
    margin: 40px 0px 40px 0px !important;
}


  


}
.rbar-mtop {
  margin-top: 20px !important;
}
.travellerbox i {
  cursor: pointer;
  float: right;
  padding: 3px 0px;
}
.flayoutwrappermid {
  .headline-home1 {
    margin:20px 0px !important;
  }
}
.control__indicator {
  position: absolute;
  padding: 0px 0px;
  top: 4px;
  left: 41%;
  width: 25px;
  height: 25px;
  /* height: 19px; */
  background: #e6e6e6;
}
.bgmobrow {
  background:  var(--grey200) ;
  padding: 15px 0px;
}



}
.add-inclusions-icon {
  margin-left: 0px;
  margin-top: -8px;
  cursor: pointer;
  vertical-align: middle;
  text-align: center;
  display: inline-block;
  width: 34px;
  height: 34px;
  color: #5c5c5c;
  line-height: 31px;
  font-size: 24px;
  font-weight: 300;
  border-radius: 50%;
  border: 1.8px solid #EE6055;
  @media only screen and (max-width:575px) {
    width: 30px;
    height: 30px;
    color: #5c5c5c;
    line-height: 28px;
    font-size: 22px;
    margin-top : -3px;
  }
}
.popup-header-spacing {
  padding: 12px 15px 0px 0px;
}
.trans-arrow-iti {
  width: 16px;
  margin-right: 10px;
  margin-bottom: 6px;
}
.ellipsis-icon-size {
  width: 35px;
  height: 35px;
  float: right;
  line-height: 35px;
  vertical-align: middle;
  text-align: center;
  display: inline-block;
}
.price-sticky {
  position: sticky;
  top: 100px;
}
.status_update{
  width:150px;
  @media only screen and (max-width:575px) {
    width:50%;
  }
}
.booked_checkbox{
  width:25px;
  height: 25px;
}