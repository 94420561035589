<div class="type_data">
  <div class="trip-scrap d-flex px-0 flex-column">
    <div class="d-flex flex-column">
      <div class="col-12 px-0" *ngIf="image != ''">
        <a class="pointer" 
        *ngIf="placeUrl != ''"
        [routerLink]="['/scrapinner', placeUrl]"
        target="_blank">
        <app-image
          *ngIf="image != ''"
          [image]="image"
          [ratio]="'21:9'"
        ></app-image>
        </a>
        <app-image
          *ngIf="image != '' && placeUrl==''"
          [image]="image"
          [ratio]="'21:9'"
        ></app-image>
      </div>
      <!-- <div class="trip-scrap-more-option">
        <i class="fa fa-ellipsis-v"></i>
      </div> -->
    </div>
    <div class="place_details_holder mt-2">
    <div class="">
      <div class="w-100 d-flex align-items-center justify-content-between">
        <div class="d-flex w-100 justify-content-between">
          <div class="scrap-title content-heading ">
              <a
              class="pointer"
              *ngIf="placeUrl != ''"
              [routerLink]="['/scrapinner', placeUrl]"
              target="_blank"
              >
              <div [appSvgIcon]="categoryIcon" class="iti-svg-icon"></div>
              <!-- <i class="fas {{ categoryIcon }} iti-icon-ht mr-2"></i> -->
              {{ placeTitle }}</a
            >
            <div *ngIf="placeUrl == ''">
              <!-- <i class="fas {{ categoryIcon }} iti-icon-ht mr-2"></i> -->
              <div [appSvgIcon]="categoryIcon" class="iti-svg-icon"></div>
              {{ placeTitle }}</div>
            
          </div>
          <div *ngIf="category!=='hub' && category!='airport' && editable" class="place-edit align-items-center" >
            <!-- PLACE EDIT PROMPT -->
            <div appClickOutside
            (clickOutside)="showEditor=false">
              <div *ngIf="category!=='hub' && category!='airport' && editable"  class="place-edit-menu-icon" (click)="showPrompt()">
                <i class="fa-solid {{(!showEditor)?'fa-pen':'fa-xmark'}}"></i>
              </div>
              <div class="place-edit-options" *ngIf="showEditor">
                <div class="place-edit-inline" [ngClass]="{'show-inline-editor': showEditor}" >
                  <form #userPrompt = "ngForm" (ngSubmit)="placeEdit(userPrompt,$event)">
                    <textarea type="text" #textInput name="user_prompt"
                    required
                    ngModel
                    autosize
                    [minRows]="1"
                    [maxRows]="4"
                     [typingPlaceholder]="placeholders" class="place-edit-text">
                     </textarea>
                    <button type="submit" class="place-edit-button"><i class="fa-solid fa-angle-right"></i></button>
                  </form>
                </div>
              </div>
            </div>

            <!-- 3 dots -->
            
            <div appClickOutside
            (clickOutside)="showEditMenu=false" *ngIf="enablePlaceEdit">
              <div (click)="showEditMenu=!showEditMenu" 
              class="place-edit-menu-icon">
                <i class="fa-solid fa-ellipsis-vertical"></i>
              </div>
              <div class="place-edit-dropdown shadow" *ngIf="showEditMenu">
                <ul> 
                  <li (click)="editTitle()">Edit Title</li>
                  <li (click)="updatePlaceImage()">Edit Image</li>
                </ul>
              </div>
            </div>
          
            
          </div>
        </div>
      </div>
      <div
        *ngIf="place_address"
        class="scrap-iti-details place_address d-flex mt-1 align-items-center"
      >
         {{ place_address }} 
        
      </div>
       
      <div
        class="scrap-iti-details small-heading d-flex mt-1 align-items-center"
      >
        <div class="scrap_arrival" *ngIf="arrivalTime">
          <span class="small-heading mr-2">Arr: </span>
          <span>{{ arrivalTime }}</span>
        </div>
        <div
          class="scrap_duration ml-2 small-heading"
          *ngIf="stayDuration !== '' && category!=='hotel'"
        >
          (<i class="far fa-clock"></i> {{ stayDuration }})
        </div>
        <!-- <div>({{timezone}})</div> -->
      </div>
     
    </div>
    <div *ngIf="reason != ''" class="content-body">
      <div class="spacer-1"></div>
      <div>{{ reason }}</div>
      
    </div>
    <div *ngIf="placeHighlight !== ''">
      <div class="spacer-1"></div>
      <div class="reason-text text-justify">
        <span [innerHTML]="truncatedText"></span>
        <span class="reason-text show_more" *ngIf="placeHighlight.toString().length > 100" (click)="toggleText()">
          <i class="fa-solid {{ showFullText ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i>
        </span>
      </div>
    </div>
   
    <div *ngIf="tips.length>0"  class="place_tips_detail" [ngClass]="{'showTips':showTips}">
      <div class="spacer-1" style="height:8px"></div>
      <div class="tips_heading primary-text-color" (click)="showTips= !showTips">Tips <i class="fas " [ngClass]="showTips ? 'fa-angle-up':'fa-angle-down'"></i></div>
      <div class="tips_details">
          <ul>
            <li *ngFor="let item of tips">
              {{item}}
            </li>
          </ul>
      </div>
    </div>

    </div>
  </div>
</div>

<ng-template #editTripTitle let-modal>
  <div class="modal-body">
    <div>Edit title of {{place["name"]}}</div>
    <form #placeTitleEdit = "ngForm">
      <input name="title" ngModel class="input-field" required=""/>
    </form>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn secondary-button"
      (click)="modal.close('no')"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn primary-button"
      (click)="modal.close(placeTitleEdit)"
    >
      Save
    </button>
  </div>
</ng-template>