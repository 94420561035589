@import "../components/v1/variables-tb1.0";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

.container-fluid-print {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.page_break {
  page-break-after: always;
  font-size: 1px;
  height: 1px;
  width: 100%;
}

// A4 landscape size
.one_sheet {
  display: flex;
  height: 825px;
  width: 1200px;
  flex-direction: column;
  position: relative;
}
.sheet_direction {
  display: flex;
  width: 1200px;
  flex-direction: column;
  position: relative;
}
.one_sheet_width {
  width: 1200px;
}
.destination-background {
  background: var(--primary-light-opacity);
}
.first_sheet {
  flex-flow: column;
  align-items: center;
  justify-content: center;
  align-content: flex-end;
  background-image: url("https://process.filestackapi.com/AMWLTZ7TPGdKVmXWkeY9Az/resize=h:1281,w:1920,a:center,f:crop/compress/sTosCSnySOS0FHVhclyJ");
  background-size: cover;
  height: 825px !important;
  .cover_title_holder {
    flex: 1 1;
    align-items: center;
    justify-content: center;
    display: flex;
    color: var(--white);
    text-align: center;
    width: 100%;
    height: 100%;
  }
  .copyright span,
  .copyright a {
    flex: 0 auto;
    color: var(--body-text-color);
    background: var(--white);
    padding: 10px 20px;
    font-size: 13px;
    display: block;
    width: 100%;
  }

  .cover_title_holder {
    .cover_title {
      width: 58%;
      padding: 50px 0px 50px;
      background: var(--white);
      border-radius: 6px;
    }
    .tb_presents {
      font-size: 16px;
      line-height: 30px;
      margin: 10px 0px;

      color: var(--body-text-color);
      font-weight: 300 !important;
    }
    .place_name {
      font-size: 70px;
      line-height: 70px;
      color: var(--body-text-color);

      font-family: var(--font-family);
      margin-bottom: 10px;
    }
    .destination_guide {
      font-size: 25px;

      line-height: 30px;
      color: var(--body-text-color);
    }
  }
}
.must_sheet {
  background: var(--white);
  position: relative;
  &::after {
    position: absolute;
    width: 50%;
    top: 0px;
    left: 0px;
    content: " ";
    height: 100%;
    background: var(--grey100);
    z-index: 1;
  }
}
.rest_wrapper {
  padding: 40px;
}
.destination_page_title {
  color: var(--primary-color);
  font-size: 60px;
  height: 60px;
  line-height: 60px;
  width: 1200px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  z-index: 2;
  margin-top: 20px;
  text-align: center;

  :nth-child(2) {
    flex: 1;
  }
  :first-child {
    flex: 1;
    padding-right: 10px;
    text-align: right;
  }
}
.must_places_holder {
  display: flex;
  z-index: 2;
  padding: 20px;
  height: 632px;
  flex-direction: row;
  .scrap {
    width: 50%;
    padding: 0px 40px;
    display: flex;
    flex-direction: column;
    align-self: stretch;
  }
  &.four_places .scrap {
    padding: 0px 10px;
  }
  .copyright {
    flex: 0 auto;
    color: var(--body-text-color);
    background: transparent;
    padding: 10px 0px;
    font-size: 13px;
    display: block;
    width: 100%;
  }
}

.copyright {
  flex: 0 auto;
  color: var(--body-text-color);
  background: var(--white);
  padding: 10px 0px;
  font-size: 14px;
  display: block;
  width: 100%;
}

.scrap {
  .scrap_title {
    color: var(--body-text-color);

    font-size: 25px;
    line-height: 35px;
    margin-bottom: 5px;
  }
  .scrap_description {
    color: var(--body-text-color);

    font-size: 14px;
    line-height: 24px;
    padding-bottom: 10px;
  }
  .more_info {
    color: var(--body-text-color);
    line-height: 25px;
    margin-bottom: 10px;
    font-size: 16px;
  }
  .more_info_heading {
    padding-right: 20px;
    font-weight: 400;
  }
  .scrap_tags {
    font-size: 16px;
    color: var(--primary-color);
    text-transform: uppercase;
    margin-bottom: 15px;

    span {
      padding-right: 20px;
    }
  }
  .scrap_holder {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}
.other_attraction_text {
  background-color: var(--grey100);
  padding: 20px;

  flex: 1;
}

.page_without_image {
  &.restaurant_page {
    background-image: url("https://cdn.pixabay.com/photo/2017/11/26/00/09/vegetables-2977888_960_720.jpg");
  }
  &.shopping_page {
    background-image: url("https://cdn.pixabay.com/photo/2014/01/31/15/02/scarf-255515_960_720.jpg");
  }
  &.activity_page {
    background-image: url("https://cdn.pixabay.com/photo/2015/09/02/12/50/hiker-918704_960_720.jpg");
  }
  background-size: cover;
  align-items: center;
  &::before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: " ";
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.8);
  }

  .res_center_align {
    width: 80%;
    flex: 1;
    justify-content: center;
    display: flex;
    align-items: center;
  }
  .res_holder {
    width: 100%;
    z-index: 2;
    .category_type {
      display: flex;
      flex-direction: row;
      .category_name {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        text-align: center;
        background-color: var(--primary-color);
        color: var(--body-text-color);
        font-size: 23px;
        margin-right: 20px;
        line-height: 160px;
      }
      .category_places {
        flex: 1;
        background-color: var(--white);
        font-size: 25px;
        padding: 10px;
        span {
          margin-right: 5px;
          &::after {
            content: ",";
          }
          &:last-child::after {
            content: "";
          }
        }
      }
    }
  }
}

.rest_title {
  color: var(--body-text-color);

  font-size: 25px;
  line-height: 35px;
  padding-bottom: 0px;
  font-weight: 300;
}
.rest-width-72 {
  flex-basis: 72%;
  min-height: 100%;
}
.rest-width-25 {
  flex-basis: 25%;
  min-height: 100%;
}
.rest_mbtm {
  margin-bottom: 37px;
}

.rest_description {
  color: var(--body-text-color);

  font-size: 16px;
  line-height: 25px;
  font-weight: 300;
}
.rest_desc_holder {
  margin-left: 25px;
  padding: 20px;
}
.rest_tags {
  font-size: 16px;
  color: var(--primary-color);
  text-transform: uppercase;
  margin-top: 5px;
}

.rest-img {
  object-fit: cover;
  width: 100%;
  max-height: 100%;
}
.rest-grid-padding {
  padding: 0px 10px;
}
.shop-width {
  width: 23%;
  margin: 0px 10px;
}
.dfont-weight-400 {
  font-weight: 400 !important;
}
.iframe_container {
  position: relative;
  overflow: hidden;
  height: 100vh;
}
.iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
.print-title {
  color: var(--body-text-color);
  font-size: 45px;
  font-weight: 700;
}
.print-subtitle {
  color: var(--body-text-color);
  font-size: 45px;
}
.print-body {
  color: var(--body-text-color);
  font-size: 37px;
  font-weight: 300;
}
.print-description {
  color: var(--body-text-color);
  font-size: 37px;
  font-weight: 300;
  line-height: 45px;
}
.print-description_detail {
  color: var(--body-text-color);
  font-size: 27px;
}
.print-italic {
  font-style: italic;
}
.print-title-heading {
  font-weight: 500;
  color: var(--body-text-color);

  position: relative;
  font-size: 37px;
  font-weight: 300;
  color: var(--body-text-color);
  margin-bottom: 45px;
  &:after {
    background-color: var(--secondary-color);
    bottom: -15px;
    content: "";
    left: 0;
    height: 4px;
    position: absolute;
    width: 65px;
  }
}
.print-bold {
  font-weight: 600;
}
.ul-disc-type {
  li {
    list-style-type: none;
  }
}
.page-break-before {
  page-break-before: always;
}
.print-day-image {
  .copyright {
    padding: 10px 0px 30px 0px;
  }
  .itinerary-items {
    display: inline-block;
    margin: 20px 0px 0px 0px;
    width: 100%;
  }
  img {
    margin-bottom: 0px;
    margin-top: 10px;
  }
}

.owner_image {
  position: absolute;
  bottom: 5px;
  right: 0px;
}
