

/* HUB ROUTE CSS  */

.modal-dialog.hubModes {
    max-width: 90%;
    margin: 0 auto;
    .modal-content {
        padding-bottom: 20px;
    }
}

.hubModeHolder.active:before {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.95);
    z-index: 10000;
    content: "Getting details please wait";
    text-align: center;
    padding-top: 20px;
    font-size: 18px;
    font-weight: 500;
}

.source_destination_holder {
    position: relative;
    text-align: left;
    margin: 10px 0px;
}

.source_destination {
    display: inline-block;
    text-align: center;
    font-size: 16px;
    vertical-align: top;
    color: var(--body-text-color);
    .hub_route_info {
        font-size: 13px;
        color:   var(--body-text-color) ;
    }
}

.source_destination.mode_holder {
    margin: 0px 15px;
    .hub_mode {
        color: var(--primary-color);
        border-radius: 50%;
        margin: 0 auto;
    }
}

.route_options {
    background-color: var(--secondary-light)  ;
    color: var(--white);
    text-align: center;
    .route_option {
        width: 50%;
        height: 35px;
        line-height: 30px;
        cursor: pointer;
        border-bottom: 4px solid  var(--secondary-light)  ;
        @include media-breakpoint-only(xs) {}
        &:hover {
            background-color:  var(--secondary-light)  ;
            border-bottom: 4px solid  var(--secondary-light)  ;
        }
        &.active {
            border-bottom: 4px solid var(--primary-color);
        }
    }
}


/**/

.travel-ways-holder {
    height: auto;
}

.travel-ways {
    position: relative;
    width: 100%;
    height: 54px;
    margin-top: 18px;
    background-color: var(--white);
    box-shadow: 0 4px 8px 0  var(--card-shadow), 5px 6px 20px 0  var(--card-shadow);
    z-index: 2;
    &.selected {
        z-index: 4;
        box-shadow: -9px 0px 11px  var(--card-shadow), -9px 0px 11px  var(--card-shadow);
    }
    &.travel_flight {
        cursor: pointer
    }
    &.travel_flight:before {
        position: absolute;
        width: 30px;
        content: ">";
        height: 54px;
        right: 0px;
        text-align: center;
        line-height: 54px;
        font-weight: bold;
        font-size: 20px;
    }
}

.travel-ways-holder {
    .travel-ways.start .travelmap_icon:before {
        content: none;
    }
    .travel-ways.end .travelmap_icon:after {
        content: none;
    }
}

.place-travel-map {
    color: var(--body-text-color);
    font-size: 14px;
    font-weight: bold;
}

.inner_mode {
    margin-top: 18px;
    text-align: center;
}

.travelmap_icon_holder {
    width: 40px;
    height: 54px;
    line-height: 38px;
    float: left;
    text-align: center;
    color: var(--primary-color);
}

.travelmap_icon_holder_new {
    color: var(--primary-color);
    width: 100%;
}

.travelmap_icon_holder_new .description-sub_travel_map i {
    color: var(--primary-color);
}

.right_holder {
    width: calc(100% - 45px);
    float: left;
    padding-left: 8px;
    padding-top: 5px;
}

.inner_transport {
    display: inline-block;
}

.inner_transport_text.flight {
    vertical-align: bottom;
}

.inner_transport_text {
    width: 100px;
    font-size: 13px;
    div {
        font-weight: 500;
    }
}

.inner_transport.flight_more {
    width: 40px;
    font-weight: bold;
    font-size: 25px;
}

.inner_transport.inner_transport_holder {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color:  var(--secondary-light)  ;
    color: var(--white);
    font-size: 17px;
    line-height: 40px;
    text-align: center;
}

.travel_flight .inner_transport_holder,
.travel_flight .flight_more {
    cursor: pointer;
}

.description-sub_travel_map {
    font-size: 13px;
    display: inline-block;
    margin-right: 5px;
}

.date-icon-travel-map {
    color:   var(--body-text-color) ;
    font-size: 13px;
}

.icon-heading-travel-map {
    height: 35px;
    line-height: 35px;
    display: inline-block;
    width: 190px;
    text-align: center;
    cursor: pointer;
    &:hover {
        background-color: #790023;
    }
    &.active {
        border-bottom: 5px solid #790023;
    }
    @include media-breakpoint-down(xs) {
        width: 150px;
    }
}

.mobile_flight_list {
    position: absolute;
    z-index: 10;
    background: var(--white);
    width: 100%;
    padding: 10px;
    font-size: 12px;
    .mobile_flight_close_btn {
        position: absolute;
        right: 10px;
        z-index: 11;
        background:   var(--primary-color) ;
        border-radius: 50%;
        line-height: 25px;
        width: 25px;
        text-align: center;
    }
}

.trip-plan-white {
    color: var(--white)  ;
}

.travelmap_button {
    border: 1px solid   var(--primary-color) ;
    color: var(--body-text-color);
    font-size: 14px;
    padding: 0px 35px;
    display: inline-block;
    margin: 10px;
    border-radius: 5px;
    background-color: var(--white) !important;
}

.travel_map_btn_holder {
    text-align: center;
}

.ngdialog-theme-default.hub_route {
    padding-bottom: 10px !important;
}

.ngdialog-theme-default.hub_route .ngdialog-content {
    width: 95% !important;
    padding: 0px !important;
    height: 100%;
    overflow: auto;
    @include media-breakpoint-down(xs) {
        width: 100% !important;
    }
}

.more_detail_selection {
    min-height: 200px;
    max-height: 500px;
    overflow: auto;
    background: var(--white);
    box-shadow: 0 2px 5px 0  var(--card-shadow), 0 2px 10px 0  var(--card-shadow);
    position: relative;
    z-index: 3;
    margin-top: 18px;
    .mbView{
        @include media-breakpoint-down(xs) {
            display: none;
        }
    }
    .flight_list {
        width: 100%;
        td{
            font-weight: 300;
            font-size: 15px;
            color:   var(--body-text-color)  !important;
            @include media-breakpoint-only(xs) {
                font-size: 13px;
                line-height: 25px;
            }

            border-radius: 0px !important;
            padding: 10px 5px !important;
        }
        th {
            font-weight: 300;
            font-size: 18px;
            color:  var(--primary-color)-color!important;
            @include media-breakpoint-only(xs) {
                font-size: 15px;
                line-height: 25px;
            }

            border-radius: 0px !important;
            padding: 10px 5px !important;
        }
        tr td {
            cursor: pointer
        }
        tr:hover {
            background-color:  var(--light-cream)  ;
        }
        .selected {
            background:  var(--secondary-light)  ;
            color: var(--white);
            &:hover {
                background-color:  var(--secondary-light)  !important;
            }
        }
    }
}
.p_bar15 {
    padding: 15px 0px 15px 0px !important;
}
.route_selection .no-padding-left {
    @include media-breakpoint-down(xs) {
        padding-left: 0.75rem !important;
    }
}

.route_selection .no-padding-right {
    @include media-breakpoint-down(xs) {
        padding-right: 0.75rem !important;
    }
}

.route_action_buttons {
    margin-top: 20px;
    text-align: center;
}