/* You can add global styles to this file, and also import other style files */
@import "../../../assets/css/bootstrap.min.css";
@import "../../../../node_modules/bootstrap/scss/functions";
@import "../../../../node_modules/bootstrap/scss/variables";
@import "../../../../node_modules/bootstrap/scss/mixins/breakpoints";
@import "../../../assets/scss/main.scss";
@import "../../../assets/fonts/fontawesome-6.6.0-web/css/all.min.css";

@import "./itinerary.custom.scss";
.cdk-overlay-connected-position-bounding-box {
  background: rgba(0, 0, 0, 0.5);
}
// .cdk-overlay-pane {
//   left: 40%;
//   top: 40% ;
// }
@media only screen and (max-width: 575px) {
  .cdk-overlay-pane {
    left: 14% !important;
    top: 40% !important;
    right: 14% !important;
  }
}
.vh-25 {
  height: 25vh;
}
.toast-container .ngx-toastr .toast-title {
  font-weight: 300;
}

.ngx-toastr {
  text-align: left;
  background-color: var(--white) !important;
}
.ngx-toastr.toast-success {
  text-align: left;
  background-color: var(--success) !important;
  border: 1px solid var(--success) !important;
  color: var(--white) !important;
}
.ngx-toastr.toast-error {
  text-align: left;
  background-color: var(--error) !important;
  border: 1px solid var(--error) !important;
  color: var(--white) !important;
}

/*
body {
	-youbkit-touch-callout: none; 
	-youbkit-user-select: none;   
	-moz-user-select: none;	   
	-ms-user-select: none;		
	user-select: none;			
}
*/
.was-validated .form-control:valid,
.form-control.is-valid {
  border-color: none !important;
  padding-right: calc(1.5em + 0.75rem);
  background-image: none !important;
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.1875rem);
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
/* Colors */

:root {
  --primary-color: #16a7df;
  --secondary-color: #ee6055;
  --primary-light: #f2fcff;
  --secondary-light: #f3a5a2;
  
  --primary-lighter: #{mix(white, #0099d9, 80%)};
  --primary-transparent: #{rgba(#0099d9, 0.1)};
  --secondary-lighter: #{lighten(#ee6055, 80%)};
  --secondary-transparent: #{rgba(#ee6055, 0.1)};

  --yellow-color:#fffae8;
  --white: #fff;
  --black: #000;
  --grey100: #f5f5f5;
  --grey200: #ddd;
  --grey300: #c0c0c0;
  --grey400: #595959;
  --grey500: #444;
  --blue: #008ad1;
  --warning: #ecc53c;
  --success: #008000;
  --error: #f44336;
  --input-border: var(--grey200);
  --placeholder: var(--grey300);
  --body-text-color: #2c2c2c;
  --heading-text-color: #000;
  --body-background: #fff;
  --light-cream: #f3d2c31a;
  --light-green: #c2d8b9;
  --font-family:"Montserrat";
}

* {
  box-sizing: border-box;
}

.primary-text-color {
  color: var(--primary-color) !important;
}
.secondary-text-color {
  color: var(--secondary-color);
}
.body-text-color {
  color: var(--body-text-color);
}
.text-white {
  color: var(--white);
}
.background-grey-100 {
  background: var(--grey100);
}
.background-grey-200 {
  background: var(--grey200);
}
.background-grey-300 {
  background: var(--grey300);
}
.success-color {
  color: var(--success);
}
.color-grey-300 {
  color: var(--grey300);
}
.background-primary-light {
  background: var(--primary-light);
}

.login_captcha.modal-dialog {
  max-width: 80rem;
  transform: translate(0, 10%) !important;
}

.login-sign-section {
  border-bottom: 0.4rem solid var(--primary-color);
  .close-modal-default {
    // position: absolute;
    right: -45px;
    padding: 0;
    background: transparent;
    color: #fff;
    .fa-times-circle {
      float: none;
      padding: 0rem;
      font-size: inherit;
    }
  }
  .header-tb {
    .loginheader {
      h1 {
        font-size: 4rem;
        color: $black;
        font-weight: 600;
      }
      h3 {
        color: $black;
      }
    }
  }
}
.login-cred {
  .login-cred-details {
    .input-field {
      border-bottom: 1 solid var(--grey200);
      background-color: var(--grey100);
      border-right: 1px solid var(--grey100);
    }
    .form-group {
      .login-eye {
        top: 2rem;
      }
    }
  }
  .new-user {
    position: relative;
    /* width: 100%; */
    /* bottom: -35px; */
    top: 60px;
  }
}
.signup-form {
  .login-eye {
    top: 0.6rem !important;
  }
}
// .signup-image-sec {
//   background-image: url('/assets/images/login-signup/web-login-webp.webp');
//     /* object-fit: fill; */
//   background-repeat: no-repeat;
//   background-attachment: fixed;
//   background-size: cover;
//   height: 53rem;
// }

@media only screen and (min-width: 575px) and (max-width: 1605px) {
  .login_captcha.modal-dialog {
    max-width: 68rem;
    transform: translate(0, 5%) !important;
  }
  .new-user {
    top: 10px !important;
  }
  // .signup-image-sec {
  //   background-image: url('/assets/images/login-signup/mobile-login-webp.webp');
  // }
}
@media only screen and (min-width: 575px) {
  .signup-image-sec {
    .web {
      display: block;
    }
    .mobile {
      display: none;
    }
  }
  .web.close-button {
    display: inline-block;
  }
  .mobile.close-button {
    display: none;
  }
}
@media only screen and (max-height: 667px) {
  .login_captcha.modal-dialog {
    height: 95vh !important;
  }
}
@media only screen and (max-width: 574px) {
  .signup-image-sec {
    .web {
      display: none;
    }
    .mobile {
      display: block;
    }
  }
  .login-cred .new-user {
    top: 0;
    font-size: 1.5rem;
    font-weight: 600;
  }
  .login_captcha.modal-dialog {
    max-width: 80rem;
    // transform: none !important;
    top: 50%;
    transform: translateY(-50%) !important;
    margin: 0 !important;
    padding: 1.5rem !important;
    // height: 90vh;
    .modal-content {
      border-radius: 0;
    }
  }
  .login-sign-section {
    border: none !important;
    height: 78vh;
    .web.close-button {
      display: none;
    }
    .mobile.close-button {
      display: inline-block;
      position: absolute;
      right: 0.5rem;
      top: 0.5rem;
      .close-modal-default {
        right: 0;
        .fa-times-circle {
          color: var(--black);
        }
      }
    }
    .tab-content {
      .modal-body {
        padding: 0rem 2rem !important;
      }
    }
  }
  .signup-image-sec {
    border-bottom: 0.4rem solid var(--primary-color);
    position: relative;
    height: 36vh;
    &.signup {
      height: 29vh;
    }
  }
  .loginheader {
    padding-top: 2rem !important;
    h1 {
      font-size: 3rem !important;
    }
  }
  .forgot_password a {
    font-size: 1.5rem;
    font-weight: 600;
  }
}
.btn {
  font-size: 1.4rem;
}
.bs-datepicker .bs-media-container {
  flex-direction: column;
}

/* Slider CSS START */

.image-slider {
  position: relative;
  overflow: hidden;
  border-radius:10px;
  overflow: hidden;
 
  .slider-wrapper {
    display: flex;
    height:60vh;
    min-height:400px;
    @media (max-width: 769px) {
      min-height:300px;
      height:45vh;
    }
    transition: transform 0.3s ease-in-out;
    @media (max-width: 768px) {
      flex-wrap: nowrap;
    }
  }

  .slide {
    position: absolute;
    display: inline-block; 
    width: 100%;
    flex-shrink: 0;
    height:100%;
    top:0px;
    left:0px;
    background-position: center;
    background-size:cover;
    transition: all 0.3s ease-in-out;
    &.slide-background{
      background: url('https://tripsica.com/assets/images/balloon.png') center no-repeat;
    }

    img {
      width: 100%;
      height: auto;
      display: block;
    }
    &.active {
      opacity: 1;
    }
    &.fade-out {
      opacity: 0;
    }
  }

  .dots {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 10px;

    .dot {
      
      width: 8px;
      height: 8px;
      background-color: var(--white);
      opacity: 0.6;
      border-radius: 50%;
      cursor: pointer;
      transition: background-color 0.3s;

      &.active {
        opacity: 1;
      }
    }
  }
  .swiper-button{
    position: absolute;
    top: 48%;
    transform: translateY(-48%);
    margin-top: 0;
    z-index: 10;
    cursor: pointer;
    color: var(--white);
    font-size: 10px;
    width: 30px;
    height: 30px;
    line-height: 27px;
    border-radius: 50%;
    text-align: center;
    text-shadow: 0 0 12px var(--white);
    border: 1px solid var(--white);
    background: var(--body-text-color) 6b;
    &.swiper-left{left: 10px;
      right: auto;
      padding-right: 2px;
    }
    &.swiper-right{
      right: 10px;
      left: auto;
    }
  }
}

.slide {
  .image-more-options {
    position: absolute;
    top: 10px;
    right: 10px;
    width:25px;
    height:25px;
    z-index: 10;

    .more-options-icon {
      cursor: pointer;
      background-color: rgba(255, 255, 255, 0.6);
      border-radius: 50%;
      padding: 5px;
      color: var(--black);
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: rgba(255, 255, 255, 0.8);
      }
    }

    .more-options-menu {
      position: absolute;
      top: 30px; // Position below the icon
      right: 0;
      background-color: white;
      border-radius: 5px;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
      z-index: 15;
      width: 150px;
      overflow: hidden;
      ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
          padding: 10px;
          cursor: pointer;
          font-size: 14px;
          color: #333;
          border-bottom: 1px solid #eee;

          &:hover {
            background-color: #f5f5f5;
          }

          &:last-child {
            border-bottom: none;
          }
        }
      }
    }
  }
}

/*Slider CSS END */

/* LOADING ANIMATION */
.loading_animation {
  position: fixed;
  text-align: center;
  top: calc(100vh - 70%);
  width: 100%;
  z-index: 100000;
  transition: all 1s linear;
  &.move_top {
    top: 80px !important;
    @media (max-width: 600px) {
      top: 60px !important;
    }
    .itinerary_image {
      display: none;
    }
  }
}
.road {
  width: 100%;
  height: 20px;
  background-color: #555;
  position: relative;
  overflow: hidden;
}

.car {
  position: absolute;
  bottom: 0;
  left: -50px;
  font-size: 2em;
  z-index: 1;
  animation: drive 5s linear infinite;
  transform: scaleX(-1); /* Flip the car emoji to face right */
}

@keyframes drive {
  0% {
    left: -50px;
  }
  100% {
    left: 100%;
  }
}

.clouds {
  position: absolute;
  bottom: 70px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 2;

  .cloud:nth-child(1) {
    left: 10%;
    animation-delay: 0s;
  }

  .cloud:nth-child(2) {
    left: 30%;
    animation-delay: 0s;
  }

  .cloud:nth-child(3) {
    left: 50%;
    animation-delay: 0s;
  }

  .cloud {
    font-size: 2em;
    position: absolute;
    animation: moveClouds 20s linear infinite;
  }
  .cloud:nth-child(4) {
    left: 70%;
    animation-delay: 0s;
  }
}

@keyframes moveClouds {
  0% {
    left: -10%;
  }
  100% {
    left: 110%;
  }
}

.footer-loading-text {
  position: absolute;
  //bottom: 20px;
  height: 50px;
  width: 100%;
  text-align: center;
  font-size: 2em;
  line-height: 50px;
  color: #333;
  background: linear-gradient(
    90deg, 
    rgba(255, 255, 255, 0) 0%,
   rgba(194, 236, 255,1) 20%, 
   rgba(194, 236, 255,1) 80%, 
   rgba(255, 255, 255, 0) 100%
   );
}

.history_list{
  padding:5px 10px;
  background-color: var(--white);
  text-align: left;
  left: 0px;
  .history_time{
    color: var(--grey400);
    font-size: 1.1rem;
  }
  li{
    cursor: pointer;
    &:hover{
      background-color: var(--grey100);
    }
  }
}

.sticky-title{
  width:100%;
  background-color: var(--white);
  &.title-stick{
    box-shadow:0px 2px 3px rgba(0,0,0,0.1);
    .underlined-heading{
      font-size: 2rem;
      line-height: 2rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-top:1.5rem;
      margin-bottom:1.5rem;
    }
  }
  .underlined-heading{
    transition: all 0.3s ease-in-out;
  }
}
.day_wise_dates.dayStickyCss{
  padding:8px 0px;
}

.licence-details{
  position: absolute;
  // background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.5) 85%);
  bottom: 0;
  left: 0;
  min-height: 30px;
  right: 0;
  padding: 0px 15px;
  a{
      position: absolute;
      bottom:5px;
      color: var(--grey200);
  }
  
}
.attributes-container{
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding:10px;
  padding-bottom:0px;
  border-radius: 6px;
  border:1px solid var(--grey200);
  @media (max-width: 600px) {
    gap:0px;
    padding-top:20px;
  }
}
.attribute-holder{
  display: flex;
  flex-direction: row;
  margin-bottom:10px;
  width: calc(25% - 10px);
  @media (max-width: 600px) {
    width:100%;
    margin-bottom:20px;
  }
  .attribute-icon{
    display: flex;
    align-items: center;
    margin-right: 10px;
    min-width:40px;
    text-align: center;
    .svg-icon svg{
      width:30px;
      height: 30px;
    }
  }
  .attribute-heading{
    text-transform: capitalize;
  }
  .attibute-details{
    display: flex;
    align-items:center;
  }
}