.itinerary-masonry {
  -webkit-column-count: 2;
  column-count: 2;
  column-gap: 10px;
}

.itinerary-items {
  display: inline-block;
  margin: 0px 0px 5px 0px;
  width: 100%;
}

.down-arrow-box {
  display: inline-block !important;
  background: var(--white);
  padding: 7px;
  padding-right: 0px;

  text-align: center;
  width: 40px;

  height: 40px;
  border-radius: 50%;
  img {
    width: 19px;
    height: auto;
  }
}

.icon-min-width {
  display: inline-block;
  width: 24px;
  height: 24px;
  font-size: 15px;
}
.w-95 {
  width: 95%;
}
.trip-banner-dropdown {
  position: relative;
}
.itinerary-tab {
  padding: 20px 45px;
  background: var(--white);
  border: 1px solid var(--grey200);
  border-radius: 6px;
  &:hover {
    background: var(--primary-color)-color !important;
    color: var(--white) !important;
    border: 1px solid var(--primary-color);
  }
  @include media-breakpoint-down(sm) {
    padding: 10px 25px;
  }
}

.smile-icon {
  max-height: 65px;
  width: auto;
}
@media only screen and (max-width: 991px) {
  .mobile-itinerary-dropdown {
    right: 15px;
    bottom: 14%;
    z-index: 5;
  }
}
@media only screen and (max-width: 575px) {
  .mobile-itinerary-dropdown {
    right: 15px;
    bottom: 54px;
    z-index: 5;
  }
  .itinerary-mobile-swiper {
    .swiper-container {
      position: relative;
      .swiper-pagination {
        position: absolute;
        width: 100%;
        text-align: center;
        bottom: 62px !important;
        z-index: 10;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
      }
      .swiper-pagination-bullet {
        width: 8px !important;
        height: 8px !important;
        margin-right: 7px;
        display: inline-block;
        border-radius: 100%;
        opacity: 0.6;
        transform: scale(1) !important;
        background: var(--white) fff !important;
      }

      @media only screen and (max-width: 575px) {
        .swiper-pagination-bullets-dynamic
          .swiper-pagination-bullet-active-prev {
          transform: scale(1);
          width: 8px !important;
          height: 8px !important;
        }
        .swiper-pagination-bullets-dynamic
          .swiper-pagination-bullet-active-next {
          transform: scale(1);
          width: 8px !important;
          height: 8px !important;
        }
        .swiper-pagination-bullets-dynamic
          .swiper-pagination-bullet-active-prev-prev {
          transform: scale(1);
          width: 8px !important;
          height: 8px !important;
        }
        .swiper-pagination-bullets-dynamic
          .swiper-pagination-bullet-active-next-next {
          transform: scale(1);
          width: 8px !important;
          height: 8px !important;
        }
      }
    }
    @media only screen and (max-width: 575px) {
      .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
        transform: scale(1) !important;
      }
      .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
        transform: scale(1) !important;
      }
      .swiper-pagination-bullets-dynamic
        .swiper-pagination-bullet-active-prev-prev {
        transform: scale(1) !important;
      }
      .swiper-pagination-bullets-dynamic
        .swiper-pagination-bullet-active-next-next {
        transform: scale(1) !important;
      }
    }
  }
}
@media only screen and (max-width: 350px) {
  .mobile-itinerary-dropdown {
    right: 15px;
    bottom: 55px;
    z-index: 5;
  }
  .itinerary-mobile-swiper {
    .swiper-container {
      position: relative;
      .swiper-pagination {
        position: absolute;
        width: 100%;
        text-align: center;
        bottom: 82px;
        z-index: 10;
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
      }
      .swiper-pagination-bullet {
        width: 8px !important;
        height: 8px !important;
        margin-right: 7px;
        display: inline-block;
        border-radius: 100%;
        opacity: 0.6;
        transform: scale(1) !important;
        background: var(--white) fff !important;
      }

      @media only screen and (max-width: 575px) {
        .swiper-pagination-bullets-dynamic
          .swiper-pagination-bullet-active-prev {
          transform: scale(1);
          width: 8px !important;
          height: 8px !important;
        }
        .swiper-pagination-bullets-dynamic
          .swiper-pagination-bullet-active-next {
          transform: scale(1);
          width: 8px !important;
          height: 8px !important;
        }
        .swiper-pagination-bullets-dynamic
          .swiper-pagination-bullet-active-prev-prev {
          transform: scale(1);
          width: 8px !important;
          height: 8px !important;
        }
        .swiper-pagination-bullets-dynamic
          .swiper-pagination-bullet-active-next-next {
          transform: scale(1);
          width: 8px !important;
          height: 8px !important;
        }
      }
    }
    @media only screen and (max-width: 575px) {
      .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
        transform: scale(1) !important;
      }
      .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
        transform: scale(1) !important;
      }
      .swiper-pagination-bullets-dynamic
        .swiper-pagination-bullet-active-prev-prev {
        transform: scale(1) !important;
      }
      .swiper-pagination-bullets-dynamic
        .swiper-pagination-bullet-active-next-next {
        transform: scale(1) !important;
      }
    }
  }
}

.summary-wrapper {
  display: inline-flex;
  min-width: 100px;
  padding: 0px 10px;
  border-left: 4px solid var(--secondary-color);
}
.summary-icon-size {
  margin: 0px 5px;
  width: auto;
  height: 15px;
}
.summary-icon-size1 {
  width: auto;
  height: 15px;
}

.list-type {
  list-style-type: disc;
}
.cross-icon-ht {
  .img-icon {
    width: 19px;
    height: auto;
  }
}
@media only screen and (max-width: 991px) {
  .summary-wrapper {
    display: inline-flex;

    margin-bottom: 10px;
    padding: 0px 10px;
    border-left: 4px solid var(--secondary-color);
  }
}
@media only screen and (max-width: 575px) {
  .summary-wrapper {
    display: inline-flex;

    margin-bottom: 10px;
    padding: 0px 10px;
    border-left: 4px solid var(--secondary-color);
  }
}
.summary-popup {
  padding: 0px 30px 30px 30px;
}
.hide-arrow {
  margin-right: 5px;
}
.arrow-right-display {
  svg {
    margin: 0px 5px;
    overflow: hidden;
    vertical-align: middle;
  }
  li:last-child {
    .hide-arrow {
      display: none !important;
    }
  }
}

.valign-btm {
  vertical-align: text-bottom;
  line-height: 25px;
}
@media only screen and (max-width: 767px) {
  .valign-btm {
    vertical-align: middle;
    line-height: unset;
  }
}
.trip-details-ul {
  li {
    &:after {
      content: "|";
      padding: 0px 5px;
    }
    &:last-child {
      &:after {
        content: "";
        padding: 0px 5px;
      }
    }
  }
}
.note-ht {
  margin-bottom: 50px;
  height: 0px;
  textarea {
    margin: 0px;
  }
}
.note-ht-auto {
  height: auto;
}
.trip-trolley {
  width: 110px;
  height: auto;
}

@media only screen and (max-width: 991px) {
  .note-ht {
    margin-bottom: 30px;
    height: 0px;
    textarea {
      margin: 0px;
    }
  }
  .trip-trolley {
    width: 60px;
    height: auto;
  }
}
