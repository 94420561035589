import { Directive, ElementRef, Input, Renderer2, HostListener, AfterViewInit, AfterContentInit } from '@angular/core';

@Directive({
  selector: '[appSticky]',
  standalone: true,
})
export class StickyDirective implements AfterContentInit {
  @Input() spacer!: HTMLElement; // Reference to the spacer element
  @Input() stickyClass?: string; // Optional class for styling when sticky
  @Input() stickyTop: number = 0; // Offset from top
  @Input() boundarySelector?: string; // Selector for the boundary element
  @Input() isSticky: boolean = true; // Enable or disable sticky behavior

  private elementHeight: number = 0;
  private elementOriginalOffsetTop: number = 0;
  private boundaryBottom: number = Infinity;
  private boundaryElement: HTMLElement | null = null;
  private isCurrentlySticky: boolean = false;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterContentInit() {
    setTimeout(() => {
      this.calculateHeight();
      this.calculateBoundary();
    });
  }

  private calcTopOffset(): number {
    try {
      const rect = this.spacer.getBoundingClientRect();//this.el.nativeElement.getBoundingClientRect();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      return rect.top + scrollTop;
    } catch (e) {
      return 0;
    }
  }

  private calculateHeight() {
    this.elementHeight = this.el.nativeElement.offsetHeight;
    this.elementOriginalOffsetTop = this.calcTopOffset();
  }

  private calculateBoundary() {
    if (this.boundarySelector) {
      this.boundaryElement = document.querySelector(this.boundarySelector);
      if (this.boundaryElement) {
        const rect = this.boundaryElement.getBoundingClientRect();
        this.boundaryBottom = rect.top + window.scrollY + rect.height;
      }
    }
  }

  @HostListener('window:scroll', [])
  onScroll() {
    if (!this.isSticky) {
      this.removeSticky();
      return;
    }
    if (!this.elementHeight) {
      this.calculateHeight();
    }
    if (this.boundarySelector && this.boundaryBottom === Infinity) {
      this.calculateBoundary();
    }

    const elementOriginalOffsetTop = this.calcTopOffset();
    const scrollTop = window.scrollY;
    const elementBottom = scrollTop + this.elementHeight;

    if (scrollTop >= elementOriginalOffsetTop - this.stickyTop && elementBottom <= this.boundaryBottom) {
      this.makeSticky();
    } else if (elementBottom > this.boundaryBottom) {
      this.fixToBoundary();
    } else {
      this.removeSticky();
    }
  }

  private makeSticky() {
    if (!this.isCurrentlySticky) {
      const element = this.el.nativeElement;
      
      if (this.spacer) {
        this.renderer.setStyle(this.spacer, 'height', `${this.elementHeight}px`);
      }
      
      this.renderer.setStyle(element, 'position', 'fixed');
      this.renderer.setStyle(element, 'top', `${this.stickyTop}px`);
      this.renderer.setStyle(element, 'width', '100%');
      this.renderer.setStyle(element, 'z-index', '1000');
      this.renderer.setStyle(element, 'transition', 'all 0.3s ease-in-out');

      if (this.stickyClass) {
        this.renderer.addClass(element, this.stickyClass);
      }
      this.isCurrentlySticky = true;
    }
  }

  private fixToBoundary() {
    if (this.isCurrentlySticky) {
      const element = this.el.nativeElement;
      const boundaryRect = this.boundaryElement?.getBoundingClientRect();
      if (boundaryRect) {
        const newTop = boundaryRect.bottom - this.elementHeight;
        this.renderer.setStyle(element, 'position', 'absolute');
        this.renderer.setStyle(element, 'top', `${newTop}px`);
      }
    }
  }

  private removeSticky() {
    if (this.isCurrentlySticky) {
      const element = this.el.nativeElement;
      
      if (this.spacer) {
        this.renderer.setStyle(this.spacer, 'height', '0px');
      }
      
      this.renderer.setStyle(element, 'position', '');
      this.renderer.setStyle(element, 'top', '');
      this.renderer.setStyle(element, 'width', '');
      this.renderer.setStyle(element, 'z-index', '');
      this.renderer.setStyle(element, 'transition', 'all 0.3s ease-in-out');

      if (this.stickyClass) {
        this.renderer.removeClass(element, this.stickyClass);
      }
      this.isCurrentlySticky = false;
    }
  }
}
