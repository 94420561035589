.new-sort-top-followers {
    border-bottom: 1px solid  var(--grey100) ;
    float: left;
    width: 100%;
    @include media-breakpoint-only(xs) {
        border-bottom: unset;
    }
}

.new-top-users {
    margin-top: 10px;
    @include media-breakpoint-only(xs) {
        margin-top: 0px;
    }
    .tb_profile_bgimg_new {
        position: relative;
        height: 125px;
        padding-top: 50px;
        // background-color:   var(--blue) ;
        background-size: cover;
        background-position: center;
    }
    .profile_detail_new {
        position: relative;
    }
    .tb_profile_image_new {
        position: relative;
        background: var(--white);
        height: 100px;
        width: 100px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        border-radius: 50%;
        overflow: hidden;
        z-index: 1;
        margin-top: -60px;
        margin-left: 5%;
        border: 4px solid var(--white)  ;
        .tb_profile_person_image_new {
            height: 100px;
            width: 100px;
        }
    }
    .follow_new_top_users {
        position: absolute;
        right: 10px;
        top: 70px;
    }
    .tb-profile-newbg_new {
        margin-top: 5px;
        border-bottom: 1px solid  var(--grey100) ;
        height: 30px;
    }
    .tb_profile_headg_new {
        text-align: left;
        display: inline-block;
        padding: 0px 14px 0px 14px;
        height: 22px;
        line-height: 22px;
        overflow: hidden;
        text-overflow: ellipsis;
       white-space: nowrap;;
        width: 170px;
        font-size: 16px;
    }
    .top-users-trip {
        font-size: 14px;
        width: 50%;
        padding-top: 5px;
        color:   var(--body-text-color) ;
        text-align: center;
        font-weight: 300;
        .font_awsm_clr {
            color: var(--primary-color);
        }
    }
}

.tb_profile_links_align {
    overflow: hidden;
    text-overflow: ellipsis;
   white-space: nowrap;;
}

.top-users-submenu li {
    a.white.active {
        font-weight: 400!important;
    }
}

a.nav-link.topusers-new-view.active {
    font-weight: 400!important;
}
.accordion>.card .card-header {
    margin-bottom: 0px;
}

@media only screen and (max-width:575px) {
    
.new-submenu .card-body {
    flex: 1 1 auto;
    padding: 0px;
    padding-bottom: 10px;
    box-shadow: none;
    border-bottom: 0px solid var(--grey200);
    border-radius:6px;
}
}