//border-radius mixin

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
}
/*@include border-radius(10px 5px 20px 10px);*/
//end
//@mixin box-shadow($top, $left, $blur, $color, $inset:"") {

      //-webkit-box-shadow:$top $left $blur  $color #{$inset};
     // -moz-box-shadow:$top $left $blur  $color #{$inset};
     // box-shadow:$top $left $blur $color #{$inset};
    //}
//@include box-shadow(inset 0,5px, 10px, rgba(var(--body-text-color)-rgb,0.2));
@mixin box_shadow($shadow...) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}
/*@include box_shadow(2px 2px 2px rgba(#ccc, .8), -2px -2px 2px rgba(#ccc, 0.8)) ;*/


//box end
@mixin box($width, $height, $background) {
    width: $width;
    height: $height;
    background-color: $background;
}
//end box

//small screen
@mixin small() {
   /* @media only screen and (max-width:650px)*/@media (min-width: 320px) and (max-width: 480px) {
        @content;
    }
}
@mixin small-landscape() {
   /* @media only screen and (max-width:650px) and (orientation: landscape)*/@media (min-width: 481px) and (max-width: 767px) {
        @content;
    }
}

//end small screen

//medium screen
@mixin medium() {
  /*@media only screen and (max-width:850px)*/@media (min-width: 768px) and (max-width: 1024px) {
    @content;
  }
}
@mixin medium-landscape(){
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape){
    @content;
  }
}




@include medium {
  // css code for medium screens go here
}
//end medium  screen


//back ground color with opacity
@mixin background-opacity($color, $opacity) {
    background: $color; /* The Fallback */
    background: rgba($color, $opacity);
}

@mixin color-rgba($color, $opacity) {
  color: rgba($color, $opacity);
}

@mixin no-scrollbar(){
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
}
/*element {
     @include background-opacity(#333, 0.5);
     @include color-rgba()
}*/
//end


//tablet

$tablet-width: 768px;
$desktop-width: 1024px;

@mixin tablet {
  @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@include tablet {
    // css code for small screens go here
}

//end of tablet

//button mixin
@mixin button($color) {
	background-color: $color;
	border: 1px solid mix(black, $color, 25%);
	border-radius: 5px;
	padding: .25em .5em;
	
	&:hover {
		cursor: pointer;
		background-color: mix(black, $color, 15%);
		border-color: mix(black, $color, 40%);
	}
}

/*
.button {
	@include button(#b4d455);
}
*/
//end button


@mixin linx ($link, $visit, $hover, $active) {
  a {
    color: $link;
    &:visited {
      color: $visit;
    }
    &:hover {
      color: $hover;   
    }
    &:active {
      color: $active;
    }
  }
}


@mixin headline ($color, $size) {
  color: $color;
  font-size: $size;
}

/*h1 {
  @include headline(green, 12px);
}*/

@mixin transition-delay($delay...) {
    -moz-transition-delay:    $delay;
    -o-transition-delay:      $delay;
    -webkit-transition-delay: $delay;
    transition-delay:         $delay;
}



//transition property
@mixin transition-timing-function($timing...) {
    -moz-transition-timing-function:    $timing;
    -o-transition-timing-function:      $timing;
    -webkit-transition-timing-function: $timing;
    transition-timing-function:         $timing;
}

@mixin transition-duration($duration...) {
    -moz-transition-property:    $duration;
    -o-transition-property:      $duration;
    -webkit-transition-property: $duration;
    transition-property:         $duration;
}
@mixin transition-property($property...) {
    -moz-transition-property:    $property;
    -o-transition-property:      $property;
    -webkit-transition-property: $property;
    transition-property:         $property;
}
@mixin transition($transition...) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}

//end of transition property


// Animations
@mixin animation($animation) {
  -webkit-animation: $animation;
       -o-animation: $animation;
          animation: $animation;
}
@mixin animation-name($name) {
  -webkit-animation-name: $name;
          animation-name: $name;
}
@mixin animation-duration($duration) {
  -webkit-animation-duration: $duration;
          animation-duration: $duration;
}
@mixin animation-timing-function($timing-function) {
  -webkit-animation-timing-function: $timing-function;
          animation-timing-function: $timing-function;
}
@mixin animation-delay($delay) {
  -webkit-animation-delay: $delay;
          animation-delay: $delay;
}
@mixin animation-iteration-count($iteration-count) {
  -webkit-animation-iteration-count: $iteration-count;
          animation-iteration-count: $iteration-count;
}
@mixin animation-direction($direction) {
  -webkit-animation-direction: $direction;
          animation-direction: $direction;
}
@mixin animation-fill-mode($fill-mode) {
  -webkit-animation-fill-mode: $fill-mode;
          animation-fill-mode: $fill-mode;
}
