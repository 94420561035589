.mytruncate {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: inherit;
}
.add-note-container {
  width: 360px;
  margin: 0px auto;
}
.change-arrow {
  margin: 12px 0px;
  font-size: 10px;
  z-index: 9;
  transition: all 500ms;
}
.routeFixTop {
  position: fixed;
  z-index: 1000;
  background: var(--white);
  top: 0px;
  width: 100%;
  overflow-y: scroll;
  height: 100vh;
}
.margintopbottom {
  margin: 25px 0px 25px 0px;
  @media screen and (max-width: 575px) {
    margin: 15px 0px 15px 0px;
  }
}
.loc_marker {
  color: #aaa;
  font-size: 16px;
  background: var(--white);
  z-index: 9;

  &:last-child {
    margin-top: 0px;
  }
}

.return-trip-select {
  .selectidv {
    select {
      border: 1px solid var(--grey200);
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      height: auto !important;
      padding: 0.375rem 0.75rem;
      font-size: 0.875rem;
      font-weight: 300;
      line-height: 1.5;
      /* padding: 5px 30px 5px 10px; */
      /* font-size: 14px; */
      /* line-height: 18px; */
      color: var(--body-text-color);
      width: 100%;
      background-image: none;
    }
  }
}

.arrowDisable {
  color: var(--grey200);
  cursor: auto !important;
}
.line {
  border-top: 1px solid var(--secondary-color);
  padding-top: 5px;
  padding-bottom: 5px;
  margin: 5px auto 0;
  width: 100%;
  position: relative;
}

.line:before {
  content: "";
  width: 6px;
  height: 6px;
  background: var(--secondary-color);
  border-radius: 5px;
  position: absolute;
  top: -4px;
  left: 0px;
}
.line:after {
  content: "";
  width: 6px;
  height: 6px;
  background: var(--secondary-color);
  border-radius: 5px;
  position: absolute;
  top: -4px;
  right: 0px;
}
.add-note-btn-wrap {
  width: 360px !important;
  margin: 0px auto;
}
.add-note {
  resize: none !important;
  overflow: hidden;
  background: var(--white) !important;
  margin-bottom: 15px;
  width: 100%;
  min-height: auto;
  padding: 0px 0px;
  transition-duration: 0.5s;

  ::-webkit-input-placeholder {
    color: var(--body-text-color) !important;
  }

  .edit_trip_description {
    cursor: pointer;
  }
}
.add-note::placeholder {
  color: var(--body-text-color) !important;
}

.iconwrapper {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 0.2px;
    left: 5px;
    width: 0px;
    height: 100%;
    border: none !important;
    border-left: 1.2px dashed #0000003b !important;
  }
}
.arrow_marker {
  font-size: 10px;
  color: var(--body-text-color);
  padding: 0px 1px;
}
.circleicon {
  font-size: 10px;
  color: #aaa;
  z-index: 9;
  background: var(--white);
}
.circlewrapper {
  position: relative;
  padding: 0px 1px;
  z-index: 1;
  &:before {
    content: "";
    position: absolute;
    left: 5px;
    width: 0px;
    height: 100%;
    border: none !important;
    border-left: 1.2px dashed var(--grey200) !important;
  }
}
.arrowrapper {
  position: relative;
  padding: 0px 1px 0px 1px;
  min-height: 25px;
  &:before {
    content: "";
    position: absolute;
    left: 5px;
    width: 0px;
    height: 100%;
    border: none !important;
    border-left: 1.2px dashed var(--grey200) !important;
  }

  i.active {
    transform: rotate(180deg);
  }
}
.dashedbox {
  border: 1px dashed #0000003b;
  padding: 20px;
  border-radius: 6px;

  transition: 0.5s ease-in-out;
  &:hover {
    outline: none;
    border: 1px solid #0000003b;
  }
  &.active {
    background-color: var(--grey100);
  }
}
.marginbottom10 {
  margin-bottom: 10px;
}
.margin-top-10 {
  margin-top: 10px;
}
.margin-top-5 {
  margin-top: 5px;
}
.display-inline-grid {
  display: inline-grid;
}
.rw-20 {
  flex-basis: 20%;
}
.iconsize {
  font-size: 10px;
  padding: 2px;
  cursor: pointer;
}
.mob-accord-arrow {
  text-align: center !important;
  background: var(--white);
  margin: 0px auto;
  border-radius: 50%;
  bottom: 0px;
  width: 30px;
  display: flex;
  height: 30px;
  padding: 10px 9px;
  font-size: 10px;
  text-align: center;
  border: 1px solid var(--grey200);
  margin-top: 0px;
  z-index: 999;
  position: absolute;
  left: 46%;
  top: 74px;
}
.route-container {
  max-width: 85%;
  margin: 0px auto;
  background-color: var(--white);
  // transition-property: all;
  // transition-duration: 0.4s ;
  // transition-timing-function: linear;
}
.tb_autocomplete .message_box_clear {
  position: absolute;
  top: 15px;
  right: 0px;
  cursor: pointer;
  color: #00000057 !important;
}
.route-container-fluid {
  max-width: 100% !important;
  .route-left-section {
    border-right: 1px solid var(--grey200);
  }
}
.mob-card-body {
  flex: 1 1 auto;
  padding: 15px;
}
.bidirectionalimg {
  width: 16px;
  height: auto;
}
.mob-commute-dropdown {
  position: absolute;
  z-index: 11;
  padding: 0px;
  width: 100%;
  top: 78px;
  background: var(--white);
}
.mob-card-header {
  padding: 15px;
  margin-bottom: 0;
  background-color: var(--white);
  border-bottom: 1px solid var(--grey200);
}
.route-icon {
  font-size: 16px;
  color: var(--body-text-color);
}
.route-row-bg {
  background: var(--white);
  padding: 20px;
  border-bottom: 1px solid var(--grey200);
  transition: 0.6s ease-in-out;
  &:hover {
    background: var(--grey100);
  }
  &:active {
    background: var(--grey100);
  }
  &.active {
    background: var(--grey100);
  }
}
.new_greylightestbg {
  background: var(--grey100);
}
.rotate45 {
  transform: rotate(-45deg);
}
.route_divider {
  border-left: 1px solid var(--grey200);
}
.route-logo {
  max-height: 28px;
  width: auto;
}
.route-border-bottom {
  border-bottom: 1px solid var(--grey200);
  margin-bottom: 10px;
  padding: 0px 15px 5px 15px;
}
.route-left-section {
  padding: 0px 0px;
}

.route-row-padding {
  padding: 20px;
}
.change-arrow-box {
  text-align: center;
  background: var(--grey100);
  width: 25px;
  vertical-align: middle;
  height: 25px;
  line-height: 21.8px;
  align-self: center;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid var(--grey200) ddd5c;
  .change-arrow {
    margin: 0px;
  }
}
.custom-dropdown {
  border: 1px solid var(--grey200);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 150px !important;
  height: auto !important;
  padding: 5px 30px 5px 20px;
  font-size: 13px;
  width: 100%;
  border-radius: 6px;
  line-height: 24px;
  color: var(--body-text-color);

  &:after {
    position: absolute;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    font: 16px "Consolas", monospace;
    color: var(--body-text-color);
    right: 13px;
    top: 14px;
    padding: 0 0 2px;
    position: absolute;
    pointer-events: none;
  }
}
.custom-dropdown-content {
  position: absolute;
  width: 150px;
  padding: 10px 0px;
  border: 1px solid var(--grey200);
  border-radius: 6px;
  font-size: 13px;
  color: var(--body-text-color);
  line-height: normal;
  transition: 0.6s ease-in-out;
  background: var(--white);
  z-index: 9;
  ul li {
    padding: 10px 20px;
    &:hover {
      background-color: var(--grey100);
    }
  }
}
.myboxshadow {
  box-shadow: -2px 0px 3px var(--card-shadow);
}
i.details-active {
  transform: rotate(180deg);
}
.drive-img-wrapper {
  width: 250px;
  height: 250px;

  margin: 40px auto;
}
.iconwrapper-drive {
  position: relative;
  &:before {
    content: "";
    position: absolute;
    top: 13px;
    left: 2px;
    width: 0px;
    height: 100%;
    border: none !important;
    border-left: 1px dashed var(--secondary-color) !important;
  }
}
.circleicon-drive {
  font-size: 5px;
  color: var(--secondary-color);
  z-index: 9;
  background: var(--white);
}
.route-bottom-bar {
  position: fixed;
  bottom: 0px;
  background: var(--white);
  z-index: 1001;
}
.row-border-bottom {
  border-bottom: 1px solid var(--grey200);
}

.display-mob-route-block {
  display: none;
  position: fixed;
  top: 0px;
  z-index: 1000;
  width: 100%;
  background-color: var(--white);
  height: 100vh;
}
@media only screen and (max-width: 1024px) {
  .mob-route-fixed {
    line-height: 50px;
    height: 50px;
    display: flex;
    justify-content: space-evenly;
    padding: 0px !important;
  }
  .mob-route-button {
    flex-grow: 1;
    border-radius: 0px;
    margin: 0px;
  }
  .add-note {
    margin-top: 0px;
  }
  .route-timeline {
    .rw-20 {
      flex-basis: 100%;
      margin: 3px 0px;
    }
    .flex-20 {
      flex-basis: 20%;
    }
    .flex-80 .flightgrid1 {
      width: 100%;
      margin-top: 0px;
      margin-bottom: 0px;
    }
  }
  .display-mob-route-block {
    display: block;
  }
  .drive_more_option {
    display: none;
  }

  .mob-d-inline-flex {
    display: inline-flex;
  }
}
@media only screen and (max-width: 575px) {
  .add-note {
    background: var(--white) !important;
    color: var(--body-text-color) !important;
    min-height: auto !important;
    max-height: 700px !important;
    width: 100%;
    padding: 0px 0px;
    border-radius: 6px;
    border: 0px solid var(--grey100);
    margin-bottom: 15px;
  }

  .add-note-container {
    width: 85%;
    margin: 0px auto;
  }
  .add-note-btn-wrap {
    width: 85% !important;
    margin: 0px auto;
  }
  .route-container {
    max-width: 100%;
    margin: 0px auto;
  }
  .route-timeline {
    .flex-20 {
      flex-basis: 50%;
    }
    .hubs_hold:last-child .iconwrapper::before {
      content: none;
    }
  }
  .dashedbox {
    padding: 12px;
  }
  .mob-flex-grow {
    flex-grow: 1 !important;
    flex-basis: auto !important;
  }
  .route-border-bottom {
    border-bottom: 1px solid var(--grey200);
    margin-bottom: 10px;
    padding: 0px 0px 5px 0px;
  }
  .route-row-bg {
    padding: 15px;
  }
  .mob-flex-60 {
    flex-basis: 60% !important;
  }
}
.mobile_flight_details {
  overflow: auto;
  height: 100%;
}

.route-timeline {
  .hubs_hold:last-child .iconwrapper::before {
    content: none;
  }
  .message_box_clear {
    top: 7px !important;
  }
  .dropdown_list {
    top: 38px !important;
  }
}
.flexrow-reverse {
  flex-direction: row;
}
@media only screen and (max-width: 1024px) {
  .flexrow-reverse {
    flex-direction: column-reverse;
  }
}

.route_map {
  height: calc(58vh - 100px);
  position: sticky;
  top: 100px;
  #routemap {
    height: 100%;
    margin-left: 45px;
  }
  @media only screen and (max-width: 1024px) {
    width: 100%;
    position: relative;
    top: 0px;
    height: 300px;
    #routemap {
      height: 100%;
      margin-left: 0px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .toggle-height {
    height: auto;
    margin-bottom: 0px !important;
  }
  .mob-top15 {
    margin-top: 30px !important;
  }
}
@media only screen and (max-width: 991px) {
  .mob-top15 {
    margin-top: -15px !important;
  }
}
.flight_icon {
  margin-left: -3px;
  position: relative;
  z-index: 11;
}

.source_cancel {
  font-weight: 300;
  height: 22px;
  p {
    margin: 0px;
    padding: 0px;
    float: right;
    cursor: pointer;
  }
}
