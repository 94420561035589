<div>
  <div class="tb-container">
    <!-- TRIP BANNER -->
    <section
      id="itinerary-banner"
    >
    
    <div class="image-slider"
    (touchstart)="onTouchStart($event)"
  (touchmove)="onTouchMove($event)"
  (touchend)="onTouchEnd($event)"
    >
      <div class="slider-wrapper" >
        <div *ngFor="let image of finalTripImages | slice:0:2;index as i" class="slide" 
        [ngClass]="{'slide-background': image['path']==''}"
        [style.backgroundImage]="'url(' +image['path'] + ')'"
        [ngClass]="{ 'active': i === currentIndex, 'fade-out': i === prevIndex }"
        >
          <div class="image-more-options" *ngIf="editable">
            <div class="more-options-icon" (click)="toggleMoreOptions(i)">
              <i class="fa-solid fa-ellipsis-vertical"></i>
            </div>
            <div class="more-options-menu" *ngIf="activeMenu === i">
              <ul>
                <li (click)="openFileUploader(image, i)">Change Image</li>
                <li (click)="deleteTripImage(deleteImage,i)" *ngIf="image['imageBy']=='user'">Delete Image</li>
              </ul>
            </div>
          </div>
          
          <div class="licence-details" *ngIf="image['imgCredits']!=''">
            <a [href]="image['licence_url']" target="_blank">
               Photo By {{image['imgCredits']}}
            </a>
        </div>
        </div>
      </div>
      
        <div class="swiper-button swiper-left" *ngIf="currentIndex>0" (click)="changeSlide(currentIndex-1)">
          <i class="fas fa-chevron-left"></i>
        </div>
        <div class="swiper-button swiper-right" *ngIf="currentIndex<1" (click)="changeSlide(currentIndex+1)">
          <i class="fas fa-chevron-right"></i>
        </div>
      
      <!-- <div class="dots">
        <div class="left"></div>
        <span
          *ngFor="let image of finalTripImages | slice:0:2; index as i"
          class="dot"
          [class.active]="i === currentIndex"
          (click)="changeSlide(i)"
        ></span>
      </div> -->
    </div>
    </section>
    <!-- TRIP BANNER -->

    <div class="d-flex justify-content-end" *ngIf="!loadingItinerary">
      <div class="iti-more-options" (click)="share()" *ngIf="tripPrivateUrl!=''">
        <i class="fa fa-share-alt"></i><br />
        Share
      </div>
      <div
        class="iti-more-options"
        (click)="updateTripStatus(content)"
        *ngIf="permissions.publish"
      >
        <i class="fa fa-paper-plane"></i><br />
        {{ tripStatus == "public" ? "Unpublish" : "Publish" }}
      </div>
      <div
        class="iti-more-options"
        (click)="deletetrip(deleteContent)"
        *ngIf="editable"
      >
        <i class="fa fa-trash-alt"></i><br />
        Delete
      </div>
    </div>
</div>
      <div #titleStick></div>
      <div class="d-flex sticky-title" appSticky [stickyClass]="'title-stick'" [spacer]="titleStick" [stickyTop]="stickyTop">
        <div class="tb-container">
          <div
        class="underlined-heading line-break mr-3 active underline left"
        *ngIf="tripTitle != ''"
      >
        {{ tripTitle }}
      </div>
        </div>
    </div>

    <div class="tb-container">
    <!-- MORE TRIP DETAILS -->
    <div *ngIf="!loadingItinerary"> 
      <div
        class="w-100 d-flex align-items-center justify-content-start mt-4 ready-days-count"
      >
      <div class="small-heading pr-4">
        <i class="fa-solid fa-calendar-days mr-2"></i>
        <span>{{tripStartDate}}</span>
      </div>
        <div class="small-heading pr-4">
          <i class="fa-regular fa-clock mr-2"></i>
          <span>{{tripDuration}}</span>
        </div>
      </div>
    </div>
    <div *ngIf="tripStory != ''" class="content-body mt-4">
      {{ tripStory }}
    </div>
    <div *ngIf="attributes.length>0">
      <div class="spacer-3"></div>
      <div class="attributes-container">
        <div *ngFor="let item of attributes" class="attribute-holder">
          <div class="attribute-icon">
            <div [appSvgIcon]="attributesIcon[item['name']] || 'menu'" class="svg-icon"></div>
            <!-- <i [ngClass]="attributesIcon[item['name']] || 'fa-solid fa-arrow-right'"></i> -->
          </div>
          <div class="attibute-details">
            <div>
              {{item['description']}}
            </div>
          </div>
        </div>
        <div class="attribute-holder">
          <div class="attribute-icon">
            <div [appSvgIcon]="'speech-bubble'" class="svg-icon"></div>
          </div>
          <div class="attibute-details">
            <div>
              <span *ngFor="let tag of tags; let last = last">
                {{ tag['tag'] }}<span *ngIf="!last">, </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="isAdmin">
      <hr/>
      <div>Only for admins: </div>
      <div>
        <div>History : </div>
        <div class="history_list shadow">
          <ul>
            <li *ngFor="let item of tripHistory" (click)="setPreviousTrip(item)">
              <div class="history_title small-heading">{{item['user_request']}}</div>
              <div class="history_time">{{item['created_on'] | date : "MMM dd - HH:mm" }}</div>
            </li>
            
          </ul>
        </div>
      </div>
      <div *ngIf="prevUserRequest!==''">
        User request : {{prevUserRequest}}
      </div>
      
      <div class="trip_tags" *ngIf="categories.length>0">
        <span *ngFor="let cat of categories" class="tags-pill">{{cat['category_name']}}</span>
      </div>    
      <hr/>
    </div>

    <div class="spacer-3"></div>
    <div
      class="title-heading d-inline-block d-lg-block margin-row"
      style="display: none !important"
    >
      Route Overview
    </div>

    <div
      class="route_overview"
      *ngIf="tripDetails.hubs.length >= 1"
      style="display: none"
    >
      <div class="d-flex flexrow-reverse mb-lg-4 mb-4 align-items-start">
        <div class="flex-40 route-left-section">
          <div class="route-timeline">
            <div *ngFor="let hub of tripDetails.hubs" class="hubs_hold">
              <app-hub *ngIf="hub['hub_type'] == 'hub'" [hub]="hub"></app-hub>
              <app-hub-mode
                *ngIf="hub['hub_type'] == 'mode'"
                [mode]="hub"
              ></app-hub-mode>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Itienrary -->
  <div *ngIf="tripDays.length >= 1">
    <div class="tb-container">
      <div class="title-heading d-inline-block d-lg-block margin-row">
        Day Wise Plan
      </div>
    </div>

    <div class="itinerary-bg">
      <div
        class="swipe-button swipe-left d-lg-block d-xl-block d-none"
        (click)="scrollDays('left')"
      >
        <i class="fa fa-chevron-left"></i>
      </div>
      <div
        class="swipe-button swipe-right d-lg-block d-xl-block d-none"
        (click)="scrollDays('right')"
      >
        <i class="fa fa-chevron-right"></i>
      </div>
      <div class="days-container" #scrollBox>
        <div
          class="days-holder"
          [id]="'daySticky' + day['details']['dayNumber']"
          *ngFor="let day of tripDays; let dayCount = index"
        >
          <!-- Day heading-->
          <div #daySpacerRef></div>
          <div
            appSticky [spacer]="daySpacers?.get(dayCount)?.nativeElement"
            [stickyTop]="dayStickyTop"
            [isSticky]="daySticky"
            [boundarySelector]="'#daySticky' + day['details']['dayNumber']"

            class="text-center d-flex day_wise_dates align-items-center"
            [id]="'dayNumber' + day['details']['dayNumber']"
            #dayElements
          >
            <div
              class="content-heading itinerary_heading"
              (click)="daysDropDownToggle()"
            >
              <span>Day {{ day["details"]["dayNumber"] }}</span>
              <span class="day_divider">|</span>
              <span class="mr-2">
                {{
                day["details"]["date_time"] | date : "MMM dd":'UTC'
              }}
            </span>
              <!-- <span class="mr-1">
                <i class="fa fa-chevron-down mr-1"></i>
              </span> -->
              <div class="day-dropdown" *ngIf="daysDropDown">
                <ul class="">
                  <li
                    *ngFor="let day of tripDays; let i = index"
                    (click)="scrollToDay(day['details']['dayNumber'])"
                  >
                    Day {{ day["details"]["dayNumber"] }}
                  </li>
                </ul>
              </div>
            </div>
            <div
              class="content-body d-block ml-3"
              (click)="showDayMap(dayCount, day['details']['dayNumber'])"
            >
            <div [appSvgIcon]="'map'" class="day-icon"></div>
              
            </div>
          </div>

          <div class="itinerary-cwrapper">
            <div *ngFor="let item of day['days']">
              <!-- REFERENCE BLOCK -->
              <reference-block
                *ngIf="item['type'] == 'reference'"
              ></reference-block>
              <!-- COMMUTE -->
              <app-day-commute
                *ngIf="item['type'] == 'mode'"
                [commute]="item"
              ></app-day-commute>

              <!-- Place -->
              <day-places
                *ngIf="item['type'] == 'place'"
                [place]="item"
                [tripUrl]="tripurl"
                [editable]="editable"
                (updatePlace)="updatePlace($event)"
              ></day-places>

              <!-- FREE TIME -->
              <free-time *ngIf="item['type'] == 'free'"></free-time>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--
  <div class="tb-container">
    <div *ngIf="visaInformation.length>0">
      <div ngbAccordion>
        <div ngbAccordionItem >
          <div ngbAccordionHeader >
            <button ngbAccordionButton class="accordion-collapse-button content-body">
              <div>Visa</div>
            </button>
          </div>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-container *ngFor="let visaInfo of visaInformation">
                <div >
                  {{visaInfo?.country}} : {{visaInfo?.requirement}}
                </div>
              </ng-container>
                
            </div>
          </div>
        </div>
      </div>
      </div>
      <div ngbAccordion *ngIf="emergencyContacts.length>0">
        <div ngbAccordionItem >
          <div ngbAccordionHeader >
            <button ngbAccordionButton class="accordion-collapse-button content-body">
              <div>Emergency Contacts</div>
            </button>
          </div>
          <div ngbAccordionCollapse>
            <div ngbAccordionBody>
              <ng-container *ngFor="let contact of emergencyContacts">
                <div >
                  {{contact?.name}} : {{contact?.phone_number}}
                </div>
              </ng-container>
                
            </div>
          </div>
        </div>
      </div>
      <div ngbAccordion>
      <div ngbAccordionItem *ngFor="let itemDetails of additionalDetails">
        <div ngbAccordionHeader >
          <button ngbAccordionButton class="accordion-collapse-button content-body">
            <div>{{itemDetails['heading']}}</div>
          </button>
        </div>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              <div *ngFor="let item of itemDetails.items">
                {{item}}
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      
      
      </div>
  </div>
</div>
-->

<!-- EDIT BOX -->
<div *ngIf="!loadingItinerary && editable">
  <div class="floating-textbox-container active" 
  appClickOutside
  (clickOutside)="handleOutsideClick()"
  [class.expanded]="isActive"
  (click)="onInsideClick($event)">
    <!-- <div class="additional-options">
      <div class="selected-place">
        The Old House
        <i class="fa-solid fa-xmark"></i>
      </div>
    </div> -->
    
    <div class="floating-prompt-box active">
      <div class="additional-options" *ngIf="selectedPlace!==''">
        <div class="selected-place " > {{selectedPlace}} <i class="fa-solid fa-xmark" (click)="removeSelectedPlace()"></i></div>
      </div>
      <form #userPrompt = "ngForm" (ngSubmit)="editTripForm(userPrompt)">
        <div class="input-wrapper">
          
          <textarea class="floating-textbox" name="userInput" 
          #textInput
          (click)="onInsideClick($event)"
          (input)="onInputChange($event)"
          [typingPlaceholder]="placeholders"
          autocomplete="off" ngModel
          autosize
          [minRows]="1"
          [maxRows]="2">
          </textarea>
          <button class="send-button" [type]="isActive ? 'submit' : 'button'" 
          [disabled]="isActive && editInputText.length <= 10"
          (click)="toggleTextbox($event)">
            <i class="fa-solid" [ngClass]="isActive ? 'fa-angle-right' : 'fa-pen'"></i>
          </button>
        </div>
    </form>
    </div>
  </div>
  
</div>

<!-- LOADING ANIMATION -->
<div
  class="loading_animation {{ loadingText.cssclass }}"
  *ngIf="loadingItinerary"
>
  <img
    src="/assets/images/itinerary_animation.gif"
    width="100px"
    class="itinerary_image"
  />
  <div class="footer-loading-text">{{ loadingText.text }}</div>
</div>

<ng-template #content let-modal>
  <div class="modal-body">
    Do you like to {{ tripStatus == "public" ? "Unpublish" : "Publish" }} this
    trip?
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn secondary-button"
      (click)="modal.close('no')"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn primary-button"
      (click)="modal.close('publish')"
    >
      {{ tripStatus == "public" ? "Unpublish" : "Publish" }}
    </button>
  </div>
</ng-template>

<ng-template #deleteImage let-modal>
  <div class="modal-body">
    This is irrversible process, are you sure you want to delete?
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn secondary-button"
      (click)="modal.close('no')"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn primary-button"
      (click)="modal.close('delete')"
    >
      Delete
    </button>
  </div>
</ng-template>

<ng-template #deleteContent let-modal>
  <div class="modal-body">
    This is irrversible process, are you sure you want to delete?
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn secondary-button"
      (click)="modal.close('no')"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn primary-button"
      (click)="modal.close('delete')"
    >
      Delete
    </button>
  </div>
</ng-template>

<ng-template #editTripConfirm let-modal>
  <div class="modal-body">
    This edit can change your itinerary. Do you want to continue?
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn secondary-button"
      (click)="modal.close('no')"
    >
      No
    </button>
    <button
      type="button"
      class="btn primary-button"
      (click)="modal.close('yes')"
    >
      Yes
    </button>
  </div>
</ng-template>
