import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
  SimpleChanges,
  OnChanges,
  DoCheck,
  ViewChildren,
  QueryList,
  Output,
  EventEmitter,
  inject,
  TemplateRef,
  PLATFORM_ID,
  Inject,
  NgZone,
  Renderer2,
  ChangeDetectorRef,
  HostListener,
} from '@angular/core';
import { GeneralService } from '../../core/general.service';
import { CookieServices } from '../../core/cookies.service';
import { CookieService } from 'ngx-cookie-service';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { RouterModule, ActivatedRoute } from '@angular/router';
import { HubComponent } from './hub/hub.component';
import { HubModeComponent } from './hub-mode/hub-mode.component';
import { DayCommuteComponent } from './day-commute/day-commute.component';
import { ReferenceBlockComponent } from './reference-block/reference-block.component';
import { DayPlacesComponent } from './day-places/day-places.component';
import { FreeTimeComponent } from './free-time/free-time.component';
import { ImageComponent } from './image/image.component';
import { DayMapComponent } from './day-map/day-map.component';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { NotificationEvent } from './library/notifications-events';
import { FilestackService } from '@filestack/angular';
import { NgbAccordionModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } 
    from '@angular/forms';
import { Subject } from 'rxjs';
import {AutosizeModule} from 'ngx-autosize';
import { ClickOutsideDirective } from '../../directives/click-outside.directive';
import { TypingPlaceholderDirective } from '../../directives/typing-placeholder.directive';
import { StickyDirective } from '../../directives/sticky.directive';
import { SvgIconDirective } from '../../directives/svg-icon.directive';
@Component({
  selector: 'app-itinerary',
  standalone: true,
  imports: [
    CommonModule,
    HubComponent,
    HubModeComponent,
    DayCommuteComponent,
    ReferenceBlockComponent,
    DayPlacesComponent,
    FreeTimeComponent,
    RouterModule,
    ImageComponent,
    DayMapComponent,
    FormsModule,
    NgbAccordionModule,
    ClickOutsideDirective,
    TypingPlaceholderDirective,
    StickyDirective,
    SvgIconDirective,
    AutosizeModule
  ],
  templateUrl: './itinerary.component.html',
  styleUrl: './itinerary.component.scss',
  encapsulation: ViewEncapsulation.ShadowDom,
  providers: [GeneralService, CookieServices, CookieService, FilestackService],
})
export class ItineraryComponent implements OnInit, OnChanges {
  @Input() tripurl: string = '';
  @Input() privateurl: string = '';
  @Input() firsttrip: string = '';
  @Output() notification: EventEmitter<any> = new EventEmitter();
  @ViewChild('scrollBox') scrollBox!: ElementRef;

  @ViewChildren('dayElements') dayElements!: QueryList<ElementRef>;

  @ViewChild('textInput') textInput!: ElementRef;
  @ViewChild('floatingButton') floatingButton!: ElementRef;

  @ViewChild('editTripConfirm', { static: false }) editTripConfirm!: TemplateRef<any>;

  @ViewChildren('daySpacerRef') daySpacers!: QueryList<ElementRef>;

  private modalService = inject(NgbModal);

  currentIndex = 0;
  prevIndex=1;
  isMobile = false;
  private startX = 0;
  private endX = 0;
  stickyTop:number=80;
  dayStickyTop:number=0;
  daySticky:boolean=false;
  private monthRef=["Jan","Feb","March","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];

  attributesIcon:any={'destinations-covered':'destination','key-highlights':'hot-air-balloon','accommodation-type':'hotel','transportation-mode':'car','estimated-cost':'money-bag','safety-tips':'shield','cuisine-experience':'service'};
  attributes:any=[];
  selectedPlace: string='';
  showMoreOptions:boolean =false;
  private readonly apiKey:string=environment.filestack;
  private filestack:any;
  showTrip: boolean = false;
  tripTitle: string = '';
  tripStory: string = '';
  prevUserRequest:string='';
  tripDays: any = [];
  obj:any;
  tripDuration: string='';
  tripStartDate:string='';
  months:any=[];
  tripHistory: any = [];
  isAdmin: boolean = false;
  tripDetails: any = { hubs: [], days: [{ days: [], details: {} }] };
  tripGenerated: boolean = false;
  boxWidth: number = 200;
  tripStatus: string = 'private';
  daysDropDown: boolean = false;
  itienraryView: string = 'list';
  editable: boolean = false;
  loadingText: any = { text: 'Loading Itinerary...', cssclass: '' };
  isBrowser: boolean = false;
  notificationStructure: any = {
    type: NotificationEvent,
    message: '',
    result: false,
  };
  editUserPrompt:string='';
  
  oneDay: any = [];
  trip: any;
  tripImages: any[] = [];
  userTripImages:any[]=[];
  finalTripImages:any[]=[];
  loadingItinerary: boolean = true;
  allDaysHolder: any = [];
  tags: any =[];
  categories: any =[];
  visaInformation:any = [];
  emergencyContacts:any = [];
  additionalDetails:any=[];
  private calculateBoxWidthCalled: boolean = false;
  private firstDayNumber: any = null;
  permissions={share:false,publish:false};
  userDetails:any;
  tripPrivateUrl: string='';
  isActive:boolean=false;
  editInputText:string='';

  private destroy$ = new Subject<void>();
  placeholders: string[] = [
    'Change arrival time of a place',
    'Add a breakfast eatery to your trip',
    'Replace existing hotel to your desired one'
  ];
  
  currentPlaceholder = '';
  activeMenu: number | null = null;

  constructor(
    private generalService: GeneralService,
    private el: ElementRef,
    @Inject(PLATFORM_ID) private platformId: Object,
    private filestackService: FilestackService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    console.log('changes data');
    console.log(changes);
    if (changes['tripurl'] && changes['tripurl'].currentValue) {
      if (changes['tripurl'].previousValue != changes['tripurl'].currentValue) {
        console.log('tripurl changed:', this.tripurl);
        console.log('this.firsttrip');
        console.log(this.firsttrip);
        if (this.firsttrip && this.firsttrip == 'first') {
          this.generate(this.tripurl);
        } else {
          this.getTrip(this.tripurl);
        }
      }
    }
    if (changes['privateurl'] && changes['privateurl'].currentValue) {
      if (changes['privateurl'].previousValue != changes['privateurl'].currentValue) {
        console.log('privateurl changed:', this.privateurl);
        this.getTrip(this.privateurl,'private');
      }
    }
  }


  ngOnInit(): void {
    this.isMobile = window.innerWidth <= 768;
    console.log('itinerary loaded  changes');
    console.log(this.tripurl);
    console.log('get Devices...');
    console.log(this.getDeviceType());
    if (isPlatformBrowser(this.platformId)) {
      this.isBrowser = true;
    }
    if(this.privateurl && !this.tripurl){
      console.log("this.privateurl");
      console.log(this.privateurl);
      this.getTrip(this.privateurl,'private');
    }
    
    if (isPlatformBrowser(this.platformId)) {
      //this.addGlobalClickListener();
    }
    this.filestack=this.filestackService.init(this.apiKey,{
      proxy: true,
    });

    this.stickyTop = (this.isMobile)?0:80;
    this.dayStickyTop =45 + this.stickyTop;
    this.daySticky= (this.isMobile) ? true : false;
    this.updateFinalImages();
    
  }
  ngOnDestroy() {
    if (this.unlistenGlobalClick) {
      this.unlistenGlobalClick();
    }
  }
  @HostListener('window:resize', [])
  onResize() {
    this.isMobile = window.innerWidth <= 768;
  }

  private unlistenGlobalClick?: () => void;

  onInsideClick(event: MouseEvent) {
    event.stopPropagation();
  }
  onInputChange(event: Event) {
    this.editInputText = (event.target as HTMLInputElement).value;
    console.log("this.editInputText");
    console.log(this.editInputText);
  }
  /*
  private addGlobalClickListener() {
    this.ngZone.runOutsideAngular(() => {
      this.unlistenGlobalClick = this.renderer.listen('document', 'click', (event: MouseEvent) => {
        this.ngZone.run(() => {
          if (!this.floatingButton || !this.floatingButton.nativeElement) return;

          const containerEl = this.floatingButton.nativeElement;
          // Check if the click is outside the floatingButton element
          if (!containerEl.contains(event.target as Node)) {
            this.handleOutsideClick();
          }
        });
      });
    });
  }
  */

  handleOutsideClick(){
    console.log("CLICKED OUTSIDE OF DIV");
    this.isActive=false;
  }
  changeSlide(index: number) {
    this.prevIndex=this.currentIndex;
    this.currentIndex = index;
  }

  // Touch event handlers
  onTouchStart(event: TouchEvent) {
    this.startX = event.touches[0].clientX;
    
  }

  onTouchMove(event: TouchEvent) {
    this.endX = event.touches[0].clientX;
  }

  onTouchEnd(event: TouchEvent) {
    const SWIPE_THRESHOLD = 50; // Minimum swipe distance to trigger a swipe

    // Ignore if no significant movement happened (e.g., a click)
    if (this.endX === 0 || Math.abs(this.startX - this.endX) < SWIPE_THRESHOLD) {
      return;
    }

    // Prevent swipe detection on "more options" menu
    const targetElement = event.target as HTMLElement;
    if (targetElement.closest('.image-more-options')) {
      return;
    }

    // Determine swipe direction
    if (this.startX - this.endX > SWIPE_THRESHOLD) {
      // Swipe left
      this.nextSlide();
    } else if (this.endX - this.startX > SWIPE_THRESHOLD) {
      // Swipe right
      this.prevSlide();
    }

    // Reset touch coordinates
    this.startX = 0;
    this.endX = 0;
  }

  nextSlide() {
    this.prevIndex = this.currentIndex;
    this.currentIndex = (this.currentIndex ) % 2;
  }

  prevSlide() {
    this.prevIndex = this.currentIndex;
    this.currentIndex =
      (this.currentIndex  + 2) % 2;
  }

  toggleMoreOptions(index: number) {
    if (this.activeMenu === index) {
      this.activeMenu = null; // Close the menu if already open
    } else {
      this.activeMenu = index; // Open the menu for the selected image
    }
  }
  clickOutside(event:any){
    console.log("CLICKED OUTSIDE");
    console.log(event);
  }

  openFileUploader(imgObj:any,index:number){
    if(this.editable){
      this.activeMenu = null;
      console.log("open File Uploader");
    console.log(imgObj);
    console.log(index);
    const options={
      maxFiles: 1,
      accept: ['image/*'],
      fromSources: ['local_file_system'],
      uploadInBackground: false,
      onOpen: () => console.log('opened!'),
      onUploadDone: (res:any) => {
        console.log(res);
        if(res.filesUploaded && res.filesUploaded.length>0){
          const path=res.filesUploaded[0]['handle'];
          this.addTripImage(path,index);
        }
      },

    };
    this.filestack.picker(options).open();
    }
  }

  updateFinalImages() {
    const finalTripImages = [...this.userTripImages, ...this.tripImages];
    if(this.editable){
      while (finalTripImages.length < 2) {
        finalTripImages.push({path:'',imageBy:'trip'});
      }
    }
    const value='https://process.filestackapi.com/AMWLTZ7TPGdKVmXWkeY9Az/compress/resize=h:600,w:1500,a:center,f:crop/';
    this.finalTripImages = finalTripImages.map(image => ({
      ...image,
      path: image.path ? value + image.path : image.path
  }));
  }

  updateUserImages(index: number, image: any) {
    console.log("update user images");
    console.log(index);
    console.log(this.userTripImages.length);
    let userImages=JSON.parse(JSON.stringify(this.userTripImages));
    if (index === 0 && userImages.length > 0) {
      userImages[0] = image;
    } else if (index >= userImages.length) {
      userImages.push(image);
    } else {
      userImages[index] = image;
    }
    return userImages;
  }
  addTripImage(path:string,index:number){
    const tripImages={"path":path,"imageBy":"user"};
    const userTripImages=this.updateUserImages(index,tripImages);
    this.updateTripImages(userTripImages,index);
  }
  

  deleteTripImage(content: TemplateRef<any>,index:number) {
    this.activeMenu = null;
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          
          if (result == 'delete') {
            let userImages = JSON.parse(JSON.stringify(this.userTripImages));
            if (index >= 0 && index < userImages.length) {
                userImages.splice(index, 1);
            }
            this.updateTripImages(userImages,index);
          }
        },
        (reason) => {
          console.log(`Dismissed ${this.getDismissReason(reason)}`);
        }
      );
  }
  updateTripImages(tripImages:any,index:number){
    // const tripImages={"path":path,"imageBy":"user"};
    // this.updateUserImages(index,tripImages);
    const request={
      "requestData": tripImages
  };
    const url='trip/'+this.trip['trip_seo_url']+'/tripImages';
    this.generalService.postMethod(url,request,'v2').subscribe(
      (value: any) => {
          console.log("index====",index);
          console.log(tripImages);
          this.userTripImages=tripImages;
          this.updateFinalImages();
      },
      (error: any) => {
        this.notificationStructure.type = NotificationEvent.General;
              this.notificationStructure.message = 'Image Update Failed';
              this.notificationStructure.result = false;
              this.notification.emit(this.notificationStructure);
        console.log('there is an error in this 437');
        console.log(error);
      }
    )
  }
  checkEditable(currentUserId: string): void {
    this.isAdmin=false;
    const userDetail: any = this.generalService.getLoggedUser();
    if (userDetail) {
      this.userDetails=userDetail;
      if (userDetail['user_id'] && userDetail['user_id'] == currentUserId) {
        this.editable = true;
        if(userDetail['userRole']!='traveller'){
          this.permissions.publish=true;
        }
      }
      if(userDetail['userRole']=='admin') {
        this.permissions.publish=true;
        this.editable = true;
        this.isAdmin=true;
      }

    }
  }
  showDayMap(dayCount: number, dayNumber: string) {
    const modalRef = this.modalService.open(DayMapComponent, {
      fullscreen: true,
    });

    console.log('dayCount');
    console.log(dayCount);
    console.log(this.tripDays[dayCount]);
    modalRef.componentInstance.options = {
      dayNumber: dayNumber,
      dayTrip: this.tripDays[dayCount],
    };
  }
  scrollDays(direction: string) {
    const scroll = direction === 'left' ? this.boxWidth * -1 : this.boxWidth;
    this.scrollBox.nativeElement.scrollBy({ left: scroll, behavior: 'smooth' });
  }

  updateLoadingText(text: string, cssclass: string = '') {
    this.loadingText.text = text;
    this.loadingText.cssclass =
      cssclass == '' ? this.loadingText.cssclass : cssclass;
  }
  getDeviceType(): string {
    if (this.isBrowser) {
      const userAgent = navigator.userAgent.toLowerCase();
      if (/mobile|android|touch|webos|hpwos/i.test(userAgent)) {
        return 'mobile';
      }
      if (/ipad|tablet|playbook|silk|kindle/i.test(userAgent)) {
        return 'tablet';
      }
      return 'desktop';
    } else {
      return 'desktop';
    }
  }
  calculateBoxWidth() {
    if (this.isBrowser) {
      // Assuming all boxes have the same width
      const firstBox: HTMLElement = this.scrollBox.nativeElement
        .children[0] as HTMLElement;
      if (firstBox) {
        const style = window.getComputedStyle(firstBox);
        const margin =
          parseFloat(style.marginLeft) + parseFloat(style.marginRight);
        this.boxWidth = firstBox.offsetWidth + margin;
      }
      console.log('this.boxWidth');
      console.log(this.boxWidth);
    }
  }

  getTripImages(place: any) {
    console.log('getTripImages');
    let image = '';
    let imgRat: number = 0;
    let imgCredits='';let license_url='';
    if (place['place_type'] !== 's') {
      console.log("TRIP IMAGES");
      console.log(place);
      if (place.hasOwnProperty('parent') && place['parent']) {
        if (place['parent'].hasOwnProperty('experience_media') && place['parent']['experience_media']) {
          const placeImages = place['parent']['experience_media'];
          let imgRate = 0;
          let imgPath: string = '';
          let imgCredit:string='';
          let licenseurl:string ='';
          placeImages.forEach((img: any) => {
            console.log('imgRating check');
            console.log(img);
            if (img['active']) {
              if (img['imgRate'] > imgRate) {
                imgRate = img['imgRate'];
                imgPath = img['path'];
                imgCredit=(img['imgCredits'])?img['imgCredits'] : '';
                licenseurl=(img['license_url'])?img['license_url'] : '';
              }
            }
          });
          image = imgPath;
          imgRat = imgRate;
          imgCredits = imgCredit;
          license_url = licenseurl;
        }
      }  
      if (place.hasOwnProperty('image') && image=='') {
        image = place['image'];
      }
    }
    if (image != '') {
      this.tripImages.push({ path: image, imgRate: imgRat,'imageBy':'trip',imgCredits:imgCredits,licenseUrl:license_url });
      this.tripImages.sort((a: any, b: any) => b.imgRate - a.imgRate);
    }
  }

  resetTripVariables() {
    this.trip = null;
    this.tripTitle = '';
    this.tripStory = '';
    this.loadingItinerary = true;
    this.tripDetails = { hubs: [], days: [{ days: [], details: {} }] };
    this.tripDays = [];
    this.allDaysHolder = [];
    this.calculateBoxWidthCalled = false;
    this.firstDayNumber = null;
    this.tripImages = [];
    this.tags=[];
    this.attributes=[];
    this.tripPrivateUrl='';
    this.categories=[];
  }
  async generate(tripurl: string, type:string='create',req:any={}) {
    if (tripurl) {
      this.resetTripVariables();
      console.log('generating...');
      const apiUrl =(type=='create')? 'trip/getInitialTrip/' + tripurl:'trip/editTrip/' + tripurl +'?edit=true' ;

      const data = { url: apiUrl, version: 'v1',request:req };
      let previousDay = -1;
      let currentPos = 0;
      let tripDetails = { days: [] };
      const loadingText= (type=='create')?'Shortlisting Places for your trip...':'Making changes to your trip...';
      this.updateLoadingText(loadingText);
      this.generalService.generate(
        data,
        (chunk) => {
          console.log(
            '================================================CHUNK================================================'
          );
          console.log(chunk.toString());
          const objArray = this.parseChunk(chunk);
          objArray.forEach((obj) => {
            console.log('after obj');
            console.log(obj);
            if (obj.hasOwnProperty('etype')) {
              if (obj.etype === 'DAY') {
                //this.daysFormating(obj);
                const result = this.daysFormatingNew(
                  obj,
                  previousDay,
                  tripDetails,
                  currentPos
                );
                previousDay = result.newPreviousDay;
                tripDetails = result.tripDetails;
                currentPos = result.newCurrentPos;
                console.log('tripDetails>>>>>>>>');
                console.log(tripDetails);
                this.tripDays = tripDetails['days'];
                if (previousDay !== -1) {
                  this.updateLoadingText(
                    'Planning your Day ' + previousDay,
                    'move_top'
                  );
                }
              } else if (obj.etype === 'HUB') {
                this.tripDetails['hubs'].push(obj);
              } else if (obj.etype == 'META') {
                this.updateLoadingText('Planning your trip');
                this.tripTitle = obj.hasOwnProperty('trip_title')
                  ? obj.trip_title
                  : '';
                this.tripStory = obj.hasOwnProperty('trip_story')
                  ? obj.trip_story
                  : '';
              }
            } else if (obj.hasOwnProperty('response')) {
              console.log('inside response');
              this.reRenderItinerary(obj);
            }
            if (obj.hasOwnProperty('done')) {
              this.createEditableTrip(tripurl);

              //this.calculateBoxWidth();
              let createEditTrip = true;
              if (
                typeof this.trip === 'object' &&
                !Array.isArray(this.trip) &&
                this.trip !== null
              ) {
                if (this.trip.hasOwnProperty('trip_title')) {
                  this.tripTitle = this.trip['trip_title'];
                }
                if (this.trip.hasOwnProperty('trip_story')) {
                  this.tripStory = this.trip['trip_story'];
                }
                if (this.trip.hasOwnProperty('trip_details')) {
                  if (
                    this.trip['trip_details'].hasOwnProperty('ai_editable_trip')
                  ) {
                    if (this.trip['trip_details']['ai_editable_trip']) {
                      console.log(
                        'Editable ai trip inside value: ' +
                          this.trip['trip_details']['ai_editable_trip']
                      );
                      createEditTrip = false;
                    }
                  }
                }
              }
              console.log('before createEditableTrip');

              this.notificationStructure.type = NotificationEvent.TripCreation;
              this.notificationStructure.message = 'Trip Created';
              this.notificationStructure.result = true;
              this.notification.emit(this.notificationStructure);
              //this.tripDetails['days'].push(this.oneDay);
              setTimeout(() => {
                console.log('INSIDE DONE....');
                this.updateLoadingText(
                  'Your trip is almost ready... ',
                  'move_top'
                );
              }, 100);
            }
          });
        },
        (error) => console.error('Error:', error)
      );
    }
  }

  getTrip(tripurl: string,tripType: string='') {
    this.resetTripVariables();
    console.log('getting......');
    let apiUrl = 'trip/getInitialTrip/' + tripurl;
    if(tripType=='private') {
      apiUrl='trip/getShareTrip/'+tripurl;
    }
    
    this.generalService.postMethod(apiUrl,{}).subscribe(
      (value: any) => {
        console.log('EDITABLE TRIP');
        console.log(value);
        if (value['result'] == 'yes') {
          this.reRenderItinerary(value);
        } else {
          this.notificationStructure.type = NotificationEvent.TripNotFound;
          this.notificationStructure.message = 'Trip Not Found';
          this.notificationStructure.result = false;
          this.notification.emit(this.notificationStructure);
        }
      },
      (error: any) => {
        this.notificationStructure.type = NotificationEvent.NotAuthorized;
        this.notificationStructure.message = 'Need To Login';
        this.notificationStructure.result = false;
        this.notification.emit(this.notificationStructure);

        console.log('there is an error in this 701');
        console.log(error);
      }
    );
  }
  daysFormatingNew(
    day: any,
    previousDay: number,
    tripDetails: any,
    currentPos: number,
    daysLength: number = 0
  ) {

    console.log("DAY FORMATING")
    console.log(JSON.stringify(day));
    let newPreviousDay = previousDay;
    let newCurrentPos = currentPos;
    if (!tripDetails.hasOwnProperty('transit_list')) {
      tripDetails['transit_list'] = [];
    }
    let dayNumber:any = -1;
    dayNumber = (day && day.hasOwnProperty('day') && day['day'] != null) ? day['day'] : day['dayNumber'];

    if (dayNumber!=-1) {
      console.log('day number', dayNumber);
      console.log('Previous', previousDay);
      if (previousDay !== parseInt(dayNumber)) {
        if (this.firstDayNumber == null) {
          this.firstDayNumber = parseInt(dayNumber);
          previousDay = parseInt(dayNumber);
        } else {
          newCurrentPos++;
        }

        const dayObj = { days: [], details: {} };
        tripDetails.days[newCurrentPos] = dayObj;
      }
      console.log('Current pos : ' + currentPos);
      console.log('newCurrentPos>>>>' + newCurrentPos);
      console.log('tripDetails');
      console.log(tripDetails);

      if (!tripDetails.days[newCurrentPos]) {
        tripDetails.days[newCurrentPos] = { days: [], details: {} };
      } else if (!tripDetails.days[newCurrentPos].hasOwnProperty('details')) {
        tripDetails.days[newCurrentPos] = { days: [], details: {} };
      }
      newPreviousDay = parseInt(dayNumber);
      tripDetails.days[newCurrentPos].details['dayNumber'] = newPreviousDay;
      if (day.hasOwnProperty('arrival_date_time')) {
        tripDetails.days[newCurrentPos].details['date_time'] =
          day['arrival_date_time'];
      }else if (day.hasOwnProperty('arrival_date_time_array')) {
        console.log("DDDDDDD");
        console.log(day);
        console.log(day['arrival_date_time_array']);
        let arrival_date_time = day['arrival_date_time_array'][0].toString().padStart(4, '0') + '-';
        arrival_date_time += (day['arrival_date_time_array'][1]+1).toString().padStart(2, '0') + '-';
        arrival_date_time += day['arrival_date_time_array'][2].toString().padStart(2, '0') + 'T';
        arrival_date_time += day['arrival_date_time_array'][3].toString().padStart(2, '0') + ':';
        arrival_date_time += day['arrival_date_time_array'][4].toString().padStart(2, '0') + ':';
        arrival_date_time += day['arrival_date_time_array'][5].toString().padStart(2, '0') + 'Z';

        tripDetails.days[newCurrentPos].details['date_time'] = arrival_date_time;
      }
    }
    console.log('type : ' + day['type']);
    console.log('current pos : ' + newCurrentPos);
    if (day['type'] == 'place') {
      this.getTripImages(day);
    }
    if (daysLength > 0 && day['type'] == 'mode') {
      const src = this.allDaysHolder[daysLength - 1];
      const dest = this.allDaysHolder[daysLength + 1];
      day['places'] = {
        source: src,
        dest: dest,
      };
      if (day['uniqueId']) {
        const transitDetail = [
          day['uniqueId'],
          src['name'] + ', ' + src['hub'],
          dest['name'] + ', ' + dest['hub'],
          day['data']['transport_mode'],
        ];
        tripDetails['transit_list'].push(transitDetail);
      }
    }
    console.log('newCurrentPos : ' + newCurrentPos);
    console.log(tripDetails);
    console.log('tripDetails >>>>');
    console.log(tripDetails.days[newCurrentPos]);
    if (!tripDetails.days[newCurrentPos]) {
      console.log('days not defined');
      tripDetails.days[newCurrentPos] = { days: [], details: {} };
    }
    console.log(
      'days length =============================' +
        tripDetails.days[0].days.length
    );
    console.log(day);
    if (tripDetails.days[0].days.length == 0 && day['type'] == 'mode') {
    } else {
      tripDetails.days[newCurrentPos].days.push(day);
      console.log('pushed');
    }

    return { newPreviousDay, tripDetails, newCurrentPos };
  }
  reRenderItinerary(obj: any) {
    console.log('reRenderItinerary');
    if (obj.result == 'yes') {
      this.loadingItinerary = false;
      this.obj=obj;
      const tripDetailsObj =
        obj.resultData[0]['trip']['trip_details']['ai_trip'];
        
        this.checkEditable(obj.resultData[0]['trip']['user_id']);
      const tripObj = obj.resultData[0]['trip'];
      if(tripObj.hasOwnProperty('private_trip_url')){
        this.tripPrivateUrl = tripObj.private_trip_url;
      }
      console.log('tripDetails================================');
      console.log(tripDetailsObj);
      this.trip = obj.resultData[0]['trip'];
      if (tripDetailsObj.hasOwnProperty('tripTitle')) {
        this.tripTitle = tripDetailsObj['tripTitle'];
      } else if (this.trip.hasOwnProperty('trip_title')) {
        this.tripTitle = this.trip['trip_title'];
      }
      if (tripDetailsObj.hasOwnProperty('tripStory')) {
        this.tripStory = tripDetailsObj['tripStory'];
      } else if (this.trip.hasOwnProperty('trip_story')) {
        this.tripStory = this.trip['trip_story'];
      }
      this.tripStatus = this.trip.hasOwnProperty('display_status')
        ? this.trip['display_status']
        : 'private';
      let days: any;
      this.tripDetails['hubs'] = tripDetailsObj['hubs'];
      this.firstDayNumber == null;
      let previousDay = -1; // Initialize previousDay appropriately
      let currentPos = 0; // Initialize currentPos appropriately
      let tripDetails = { days: [], transit_list: [] }; // Initialize tripDetails appropriately
      let dayObjLength = 0;
      if (tripDetailsObj.hasOwnProperty('locations')) {
        tripDetailsObj['days'] = tripDetailsObj['locations'];
      }
      this.userTripImages=(this.trip.hasOwnProperty('trip_images'))?this.trip['trip_images']:[];

      this.allDaysHolder = tripDetailsObj['days'];
      tripDetailsObj['days'].forEach((day: any) => {
        console.log('INSIDE DAYS');
        console.log(day);
        console.log(day.length);
        if(day.hasOwnProperty('type')){
          const result = this.daysFormatingNew(
            day,
            previousDay,
            tripDetails,
            currentPos,
            dayObjLength
          );
          previousDay = result.newPreviousDay;
          tripDetails = result.tripDetails;
          currentPos = result.newCurrentPos;
          dayObjLength++;
        }
      });
      console.log('tripDetails== NEW NEW================================');
      console.log(tripDetails);
      this.tripDays = tripDetails['days'];

      this.prepareTripDetails(tripObj);
      //this.getMoreTransitDetails(tripDetails['transit_list']);
      // tripDetails['days'].forEach((day: any) => {
      //   this.daysFormating(day);
      // });
      //this.userTripImages.push(...this.tripImages);
      this.updateFinalImages();
      if(this.isAdmin){
        this.getTripHistory(obj.resultData[0]['trip']['_id']);
      }
    }
  }

  getMoreTransitDetails(transitList: any) {
    console.log('getMoreTransitDetails');
    console.log(JSON.stringify(transitList));
  }
  async createEditableTrip(tripurl: string, retry: boolean = true) {
    console.log(
      'createEditableTrip call value : ' + environment.createEditableTrip
    );
    if (environment.createEditableTrip) {
      console.log('Creating....');
      const url = 'trip/createEditableTrip/' + tripurl;
      this.generalService.getMethod(url).subscribe(
        (value: any) => {
          //this.generateAdditionalDetails(tripurl);
          console.log('EDITABLE TRIP');
          console.log(value);
          if (value['result'] == 'yes') {
            
            this.updateLoadingText(
              'Your trip is ready. Get ready to pack your bags.',
              'move_top'
            );
            setTimeout(() => {
              this.loadingItinerary = false;
            }, 3000);
            this.resetTripVariables();
            this.reRenderItinerary(value);
          } else {
            this.updateLoadingText(
              'Your trip is ready. Get ready to pack your bags.',
              'move_top'
            );
            setTimeout(() => {
              this.loadingItinerary = false;
            }, 3000);
          }
        },
        (error: any) => {
          if (retry) {
            this.createEditableTrip(tripurl, false);
          }
          this.updateLoadingText(
            'Your trip is ready. Get ready to pack your bags.',
            'move_top'
          );
          setTimeout(() => {
            this.loadingItinerary = false;
          }, 3000);
          console.log('there is an error in this 929');
          console.log(error);
        }
      );
    } else {
      this.loadingItinerary = false;
    }
  }

  scrollToDay(day: number) {
    const elementId = 'dayNumber' + day;
    console.log('elementId+');
    const element = this.dayElements.find(
      (el) => el.nativeElement.id === elementId
    );
    if (element) {
      element.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }

  daysDropDownToggle() {
    //this.daysDropDown = !this.daysDropDown;
  }
  parseChunk(chunk: string): any[] {
    try {
      console.log('parsing chunk....');
      // Ensure all objects are separated by a newline or space for easier splitting
      chunk = chunk.replace(/}{/g, '}\n{');

      // Split the chunk by newlines and remove any empty lines
      const chunkArray = chunk.split('\n').filter((line) => line.trim() !== '');

      // Parse each line as a JSON object and collect them into an array
      const jsonArray = chunkArray.map((line) => JSON.parse(line));
      console.log('converting to array.........');
      console.log(jsonArray);
      return jsonArray;
    } catch (error) {
      // Return an empty array if there is a parsing error
      return [];
    }
  }

  private getDismissReason(reason: any): string {
    switch (reason) {
      case ModalDismissReasons.ESC:
        return 'no';
      case ModalDismissReasons.BACKDROP_CLICK:
        return 'no';
      default:
        return `${reason}`;
    }
  }
  updateTripStatus(content: TemplateRef<any>) {
    console.log(this.trip['_id']);
    console.log("this.trip['id']================================");
    console.log(this.tripStatus);
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          const resultResp = { reason: '', action: '', message: '' };
          if (result == 'publish') {
            let trip_status =
              this.tripStatus == 'public' ? 'private' : 'public';
            const url = 'trip/' + this.trip['id'] + '/displayStatus';
            const req = {
              requestMessage: 'getTrip',
              requestId: '108',
              apiKey: '',
              requestData: [
                {
                  tripId: this.trip['id'],
                  displayStatus: trip_status,
                },
              ],
            };
            console.log(url);
            console.log(req);
            this.generalService.putMethod(url, req).subscribe(
              (value) => {
                console.log(value);

                if (value['result'] == 'yes') {
                  this.tripStatus =
                    this.tripStatus == 'public' ? 'private' : 'public';

                  const msg =
                    this.tripStatus == 'public' ? 'published' : 'unpublished';
                  resultResp['action'] = 'toast';
                  resultResp['message'] = 'Trip ' + msg + ' successfully';
                  resultResp['reason'] = 'success';
                  this.notificationStructure.type =
                    NotificationEvent.TripUpdate;
                  this.notificationStructure.result = true;
                  this.notificationStructure.message =
                    'Trip ' + msg + ' successfully';
                } else {
                  const msg =
                    this.tripStatus == 'public' ? 'published' : 'unpublished';
                  this.notificationStructure.type =
                    NotificationEvent.TripUpdate;
                  this.notificationStructure.result = true;
                  this.notificationStructure.message =
                    'Trip ' + msg + ' failed';
                }

                this.notification.emit(this.notificationStructure);
              },
              (error) => {
                console.log('there is an error in this 1042');
                const msg =
                  this.tripStatus == 'public' ? 'published' : 'unpublished';
                this.notificationStructure.type = NotificationEvent.TripUpdate;
                this.notificationStructure.result = true;
                this.notificationStructure.message = 'Trip ' + msg + ' failed';
              }
            );
          }
        },
        (reason) => {
          console.log(`Dismissed ${this.getDismissReason(reason)}`);
        }
      );
  }


  async share() {
    
    const domain = `${window.location.protocol}//${window.location.host}/`;
    let tripUrl = domain +'ai-trip/view/'+this.tripPrivateUrl;
    console.log('share url: ' + tripUrl);
    const tripContent = this.tripTitle + ' \n\n' + this.tripStory + '\n\n';
    let shareReq: any = {
      title: tripContent,
      text: tripContent,
      url: tripUrl,
    };
    console.log(shareReq);
    if (this.finalTripImages.length > 0) {
      const img =
        'https://process.filestackapi.com/AMWLTZ7TPGdKVmXWkeY9Az/' +
        this.finalTripImages[0]['path'];
      const response = await fetch(img);
      const blob = await response.blob();
      const file = new File([blob], 'image.jpg', { type: 'image/jpeg' });
      shareReq.files = [file];
    }
    if (navigator.share && this.isMobile) {
      navigator
        .share(shareReq)
        .then(() => {
          console.log('Thanks for sharing!');
        })
        .catch((error) => {
          console.error('Error sharing:', error);
        });
    } else {
      const copiedText =
        this.tripTitle + '\n' + this.tripStory + '\n' + tripUrl;
      this.copyToClipboard(copiedText);
    }
  }

  prepareTripDetails(tripDoc:any) {
    console.log("PREPARE TRIP DETAILS");
    console.log(tripDoc);
    this.tripDuration = tripDoc["trip_details"]["total_trip_duration"];
    if (tripDoc["trip_details"].hasOwnProperty("trip_itinerary_days")) {
      if (tripDoc["trip_details"]["trip_itinerary_days"]) {
        this.tripDuration = tripDoc["trip_details"]["trip_itinerary_days"];
      }
    }

    if(tripDoc['start_date'] && tripDoc['end_date']){
      this.months.push(this.monthRef[tripDoc['start_date'][1]]);
      if(tripDoc['start_date'][1]!=tripDoc['end_date'][1]){
        this.months.push(this.monthRef[tripDoc['end_date'][1]]);
      }
    }

    this.tripStartDate = tripDoc['start_date'][2]+" "+this.monthRef[tripDoc['start_date'][1]]+", "+tripDoc['start_date'][0];

    if(tripDoc.hasOwnProperty('tags')){
      this.tags = tripDoc.tags;
    }
    this.categories = (tripDoc.hasOwnProperty('populated_categories'))? tripDoc['populated_categories'] : [];
    const attribute:any=[];
    if(tripDoc.hasOwnProperty('attributes')){
      //attributes
      tripDoc.attributes.forEach((element:any) => {
        const attr=element;
        attr['alt-name']=element['name'].replace(/-/g, ' ');
        attribute.push(attr);
      });
      this.attributes=attribute;
    }
  }
  
  toggleTextbox(event: Event) {
    event.stopPropagation();
    if (!this.isActive) {
      event.preventDefault(); // Prevent form submission when activating
      this.isActive = true;
      // Wait for the animation to complete before focusing on the input
      setTimeout(() => this.textInput?.nativeElement?.focus(), 300);
    }
  }

  removeSelectedPlace(){
    this.selectedPlace='';
  }
  updatePlace(placeEdit:any){
    if(placeEdit['editType']=='custom'){
      const editPrompt =placeEdit['value'];
      console.log(editPrompt);
      //const editReq={"user_prompt":editPrompt};
      const editReq={user_prompt:editPrompt,day:placeEdit['detail']['day'],place:placeEdit['detail']['name'],edit_level:"place","action":"custom"}
      console.log(editReq);
      this.editTrip(editReq);
    }else if(placeEdit['editType']=='trip'){
      this.resetTripVariables();
      this.reRenderItinerary(placeEdit['value']);
    }
  }

  // {user_prompt:"",place:"",edit_level:"trip or place","action":"custom or arrival or duration"}
  editTrip(editReq:any){
    this.modalService
      .open(this.editTripConfirm, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          if (result == 'yes') {
            // console.log(editPrompt);
            // const userInput= editPrompt;
            // // const editReq={"user_prompt":userInput};
            // // console.log("BEFORE =====");
            // // console.log(editReq);
            this.generate(this.tripurl, 'edit',editReq);
          }
        },
        (reason) => {
          console.log(`Dismissed ${this.getDismissReason(reason)}`);
        }
      );
  }
  editTripForm(form:any){
    
    if(form.form.value.userInput){
      const userInput=form.form.value.userInput;
      console.log("userInput>>>>>");
      console.log(userInput);
      if(this.isActive && userInput.length >= 10){
        form.resetForm();
        this.isActive=false;
        //const editReq={"user_prompt":editPrompt};
        const editReq={user_prompt:userInput,place:"",edit_level:"trip","action":"custom"}
        console.log("editReq>>>>>");
        console.log(editReq);
        this.editTrip(editReq);
        this.editInputText='';
      }
      
    }
    
  }

  getTripHistory(id:string){
    console.log("TRIP ID ");
    console.log(id);
    const url='trip-history/list/'+id;
    this.generalService.getMethod(url,'v2').subscribe(
      (value) => {
        this.tripHistory=value['resultData'][0]['data'];
      },(error)=>{
      });
  }

  setPreviousTrip(prevTrip:any){
    let obj=JSON.parse(JSON.stringify(this.obj));
    obj.resultData[0]['trip']['trip_details']['ai_trip']=prevTrip['ai_trip'];
    this.prevUserRequest=prevTrip['user_request'];
    this.resetTripVariables();
    this.reRenderItinerary(obj);
  }
  deletetrip(content: TemplateRef<any>) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (result) => {
          const resultResp = { reason: '', action: '', message: '' };
          if (result == 'delete') {
            console.log('DELETED');
            // console.log(this.logindata);
            const url = 'trip/' + this.trip['id'];
            // console.log(JSON.stringify(req));

            this.generalService.deleteMethod(url).subscribe(
              (value: any) => {
                // console.log(value);
                const msg = value.resultData[0].message;
                if (value.result === 'yes') {
                  this.notificationStructure.type =
                    NotificationEvent.TripDeleted;
                  this.notificationStructure.result = true;
                  this.notificationStructure.message =
                    'Trip deleted successfull';

                  this.notification.emit(this.notificationStructure);
                } else {
                  this.notificationStructure.type =
                    NotificationEvent.TripDeleted;
                  this.notificationStructure.result = false;
                  this.notificationStructure.message = 'Trip deletion failed';

                  this.notification.emit(this.notificationStructure);
                }
              },
              (error) => {
                console.log('there is an error in this 1221');
              }
            );
          }
        },
        (reason) => {
          console.log(`Dismissed ${this.getDismissReason(reason)}`);
        }
      );
  }


  copyToClipboard(url: string) {
    const textarea = document.createElement('textarea');
    textarea.value = `${url}`;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    alert('Link copied to clipboard');
  }
}
