//Font Family Mixin
@mixin  font {
    font-family:var(--font-family);
    font-style:normal;
}
//Font Size & Font Height Mixin
@mixin font-height($font-size, $line-height) {
    font-size: $font-size;
    line-height: $line-height;
}

@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
       -moz-border-radius: $radius;
        -ms-border-radius: $radius;
            border-radius: $radius;
}

/* Border-Radius */
@mixin border-radius($radius) {
	 border-radius: $radius;
	-webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
}

/* Opacity */
@mixin opacity($op) {
    -khtml-opacity: $op;
    -moz-opacity: $op;
    opacity: $op;
}

/* Width Height */
@mixin width-height ($width,$height) {
    width: $width;
    height: $height;
}

/* User Select Property  None*/
@mixin no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Mixin Button */
@mixin button($background, $color, $border) {
    background : $background;
    color : $color;
    border :$border;
}

/* Input Placeholder */
@mixin placeholder-color($color) {
  &::-webkit-input-placeholder { /* WebKit browsers */
      color: $color;
  }
  &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
      color: $color;
  }
  &::-moz-placeholder { /* Mozilla Firefox 19+ */
      color: $color;
  }
  &:-ms-input-placeholder { /* Internet Explorer 10+ */
      color: $color;
  }
}

/* Position */

@mixin position($position, $top: null, $right: null, $bottom: null, $left: null) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

@mixin truncate {
  overflow: hidden;
  text-overflow: ellipsis;
 white-space: nowrap;;
  word-wrap: normal;
}

// Pseudo underline for heading 
@mixin pseudo-underline($background , $height : 0.4rem)  {
    content: "" ;
    position: absolute;
    height :$height;
    background: $background;
}




