* {
  outline: none;
}
html {
  margin: 0px;
  padding: 0px;
  font-size: 10px;
  font-weight: $font-weight400;
}
body {
  font-size: 1.4rem;
  @include font;
  width: 100%;
  background-color: var(--white);
  color: var(--body-text-color);
  margin: 0px;
  padding: 0px;
  -webkit-text-size-adjust: 100%;
  font-weight: $font-weight400;
  @media screen and (max-width: 575px) {
    font-size: 1.3rem;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--heading-text-color);
}

button span {
  color: unset;
}
a {
  color: var(--body-text-color);
  cursor: pointer;
  text-decoration: none;
  &:hover,
  focus {
    cursor: pointer;
    box-sizing: none;
  }
}

.banner-heading {
  padding: 0rem;
  margin: 0 0 2rem 0;
  font-weight: $font-weight700;
  @include font-height($banner-font-size, $banner-font-size);
  @include media-breakpoint-down(md) {
    @include font-height(
      $banner-font-size - 2.4rem,
      $banner-font-size - 1.4rem
    );
    margin: 0 0 1.5rem 0;
  }
  @include media-breakpoint-only(xs) {
    @include font-height($banner-font-size - 2.5rem, $banner-font-size - 2rem);
    margin: 0 0 0.5rem 0;
  }
}

.sub-banner-heading {
  @include font-height($sub-font-size, $sub-font-size + 1rem);
  padding: 0rem;
  margin: 0 0 1rem 0;
  font-weight: $font-weight500;
  letter-spacing: $letter-spacing;
  @include media-breakpoint-down(md) {
    @include font-height($sub-font-size - 0.7rem, $sub-font-size);
    margin: 0 0 0.5rem 0;
  }
  @include media-breakpoint-only(xs) {
    @include font-height($sub-font-size - 1.1rem, $sub-font-size);
    margin: 0 0 0rem 0;
  }
}

.main-heading {
  @include font-height($main-font-size, $main-font-size + 1rem);
  padding: 0rem;
  margin: 0 0 2.5rem 0;
  font-weight: $font-weight500;
}

.title-heading {
  text-transform: capitalize;
  position: relative;
  @include font-height($title-font-size, $title-font-size + 1rem);
  padding: 0rem;
  margin: 0 0 3rem 0;
  font-weight: $font-weight600;
  &:after {
    background: var(--secondary-color);
    content: "";
    position: absolute;
    width: 7.5rem;
    height: 0.4rem;
    left: 0%;
    bottom: -1.4rem;
    transform: translateX(0%);
    @media only screen and (max-width: 575px) {
      width: 6.5rem;
      height: 0.3rem;
    }
  }
  @media only screen and (max-width: 575px) {
    @include font-height($title-font-size - 0.4rem, $title-font-size + 0.5rem);
  }
}
.modal-scrap-replace {
  @media only screen and (min-width: 1401px) {
    max-width: 70%;
  }
  @media only screen and (max-width: 1400px) {
    max-width: 90%;
  }
  @media only screen and (max-width: 598px) {
    max-width: 98%;
  }
}

.content-heading {
  @include font-height($content-font-size + 0.2rem, $content-font-size + 1.1rem);
  padding: 0rem;
  margin: 0rem;
  font-weight: $font-weight600;
  color:var(--black);
}
// NOT USEING
.body-heading {
  @include font-height($body-heading-size, $body-heading-size + 1rem);
  font-weight: $font-weight400;
  @media only screen and (max-width: 575px) {
    @include font-height($body-heading-size, $body-heading-size + 1rem);
  }
}
.content-body {
  @include font-height($body-heading-size + 0.1rem, $body-heading-size + 1.1rem);
  font-weight: $font-weight500;
  @media only screen and (max-width: 575px) {
    @include font-height($body-heading-size + 0.1rem, $body-heading-size + 1.1rem);
  }
}
.small-heading {
  // content-heading2 to rename later
  @include font-height($small-font-size, $small-font-size + 0.8rem);
  color: var(--body-text-color);
  font-weight: $font-weight500;
  @media only screen and (max-width: 575px) {
    @include font-height($small-font-size, $small-font-size + 0.8rem);
  }
}

.extra-small-heading {
  @include font-height(
    $extra-small-font-size + 0.2,
    $extra-small-font-size + 0.6rem
  );
  padding: 0rem;
  margin: 0rem;
  font-weight: $font-weight500;
}
.title-heading-without-underline {
  text-transform: capitalize;
  position: relative;
  @include font-height($title-font-size, $title-font-size + 1rem);
  padding: 0rem;
  margin: 0 0 0.4rem 0;
  font-weight: $font-weight400;
  @media only screen and (max-width: 575px) {
    @include font-height($title-font-size - 0.4rem, $title-font-size + 0.5rem);
  }
}
.underlined-heading {
  @include font-height($main-font-size, $main-font-size);
  font-weight: $font-weight600;
  color:var(--black);
  &.underline {
    margin-bottom: 2rem;
    position: relative;
    &:before {
      background-color: var(--secondary-color);
      content: "";
      position: absolute;
      width: 9.5rem;
      height: 0.4rem;
      left: 50%;
      bottom: -1.5rem;
      transform: translateX(-50%);
    }
    &.left {
      &:before {
        left: 0% !important;
        transform: translateX(0%) !important;
      }
    }
    @media screen and (max-width: 767px) {
      &.underline {
        &:before {
          width: 8.5rem;
          height: 0.3rem;
          left: 50%;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    @include font-height($main-font-size - 2rem, $main-font-size - 1.5rem);
  }
}

/*Container Styling */
.tb-container {
  max-width: 90%;
  width: 100%;
  margin: auto;
  @media only screen and (max-width: 991px) {
    max-width: 90%;
  }
  @media only screen and (max-width: 767px) {
    max-width: 100%;
    padding: 0 2rem;
  }
}

/*row spacing */

.section-margin {
  margin: 6rem auto;
  @media only screen and (max-width: 767px) {
    margin: 3rem auto;
  }
}

/*Font weight classes */
.font-weight-400 {
  font-weight: 400;
}
.font-weight-500 {
  font-weight: 500;
}
.font-weight-600 {
  font-weight: 600;
}
/*Modal css */
.modal-body {
  padding: 2rem !important;
}
.close-modal-default {
  position: relative;
  float: right;
  font-size: 2.8rem;
  font-weight: 600;
  text-align: right;
  padding: 1rem 2rem;
  background: var(--white);
  border: none;
  border-radius: 0.6rem;
  color: var(--body-text-color);
}
.modal-content {
  border: none !important;
}

.line-break {
  word-break: break-word;
}
.tooltip {
  padding: 0.3rem 1rem;
  font-size: 1.2rem;
}
.turndevice {
  display: none;
}
.turnWrapper {
  display: none;
}
.turnCard {
  display: none;
}

@media screen and (min-width: 320px) and (max-width: 1080px) and (orientation: landscape) {
  .turndevice {
    display: block;
    z-index: 100;
    position: fixed;

    width: 100vw;

    height: 100vh;

    background: var(--primary-color);

    z-index: 100000;
  }
  .turnWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    height: 100%;
  }
  .turnCard {
    max-width: 60%;
    border-radius: 30px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    background: rgba(255, 255, 255, 0.2);

    display: flex;
    min-height: 60vh;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .header_logolandscape {
    height: 6rem;
    width: auto;
  }
}
.owl-dt-timer {
  height: auto !important;
}
.owl-dt-container {
  font-size: 1.4rem !important;
}
.spacer-1 {
  height: $spacer;
}
.spacer-2 {
  height: $spacer * 2;
}
.spacer-3 {
  height: $spacer * 3;
}
.spacer-4 {
  height: $spacer * 4;
}
.spacer-5 {
  height: $spacer * 5;
}
