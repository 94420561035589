button {
    @include font-height($button-font-size, $button-font-size +0.7rem);
    cursor: pointer;
    text-decoration: none;
    &:focus {
        outline: none;
        box-sizing: none;
        box-shadow: none;
    }
}

%default-button {
    display: inline-block;
    text-transform: uppercase;
    padding: 0.8rem 2rem;
    border-radius: 0.6rem;
    font-weight: $button-font-weight400;  
    margin:0rem 0.3rem;
    transition: 0.5s ease-in-out;
    min-width: 5rem;
    font-size: $extra-small-font-size+0.2;
    line-height:normal;
    @media screen  and (max-width:575px){
        padding: 0.6rem 1.5rem;
    }
}

.primary-button {
    @extend %default-button;
    @include button(var(--secondary-color), var(--white), 2px solid var(--secondary-color));
    &:hover {
        @include button(var(--white),var(--secondary-color), 2px solid var(--secondary-color)) ;
        color:var(--secondary-color) !important;
    }
    &:disabled{
     
        opacity: 0.5;
        &:hover {
            @include button(var(--secondary-color), var(--white), 2px solid var(--secondary-color));
            opacity: 0.5;
            color:var(--white) !important;
        }
        span {
            font-size: 1.2rem !important;
            color: var(--white);
            &:hover {
                font-size: 1.1rem !important;
                color: var(--white);
                }
        }
    }
   
   
}

.secondary-button {
    @extend %default-button;
    @include button(var(--white),var(--secondary-color),  2px solid var(--secondary-color));
    &:hover {
    @include button(var(--secondary-color), var(--white), 2px solid var(--secondary-color));
    }
}

.tertiary-button {
   @extend %default-button; 
   @include button(transparent, $body-color,  2px solid transparent);
    &:hover {
        @include button($tertiary-button-color, var(--white),   2px solid transparent);
    }
}
.primary-state-button {
    @extend %default-button;
    font-size: 1.2rem;
    @include button(var(--primary-color), var(--white), 2px solid var(--primary-color));
    &:hover {
        font-size: 1.2rem;
        @include button(var(--white), var(--primary-color),   2px solid var(--primary-color));
    }
}