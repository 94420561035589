//@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
/*Font Family */
$font-family: var(--font-family);

/* Font sizes */
$default-font-size: 1rem;

/*Font weight */

$font-weight300: 300;
$font-weight400: 400;
$font-weight500: 500;
$font-weight600: 600;
$font-weight700: 700;
$font-weight800: 800;

/* Default Letter Spacing */

$letter-spacing: 0.08rem;

/*Media Queries */

$extra-large-screen: 120rem;
$large-screen: 99.2rem;
$tablet-landscape: 99.1rem;
$tablet-potrait: 76.7rem;
$phone: 57.5rem;

/* Defaults */
$custom-border-radius: 10px;

/*body color */
$body-color: var(--body-text-color);

/* Typography Font Sizes */
$banner-font-size: $default-font-size * 5;
$sub-font-size: $default-font-size * 2.5;
$sub-font-size: $default-font-size * 2.5;
$main-font-size: $default-font-size * 4;
$title-font-size: $default-font-size * 2.2;
$content-font-size: $default-font-size * 1.6;
$body-heading-size: $default-font-size * 1.5;
$small-font-size: $default-font-size * 1.4;
$extra-small-font-size: $default-font-size * 1;

/*Button Colors*/
$primary-button-color: var(--secondary-color);
$secondary-button-color: var(--secondary-color);
$tertiary-button-color: var(--secondary-color);

/* Button Font Size*/
$button-font-size: $default-font-size * 1.3;

/* Button Font Weight */
$button-font-weight300: $font-weight300;
$button-font-weight400: $font-weight400;
$button-font-weight500: $font-weight500;
$button-font-weight600: $font-weight600;
$button-font-weight700: $font-weight700;
$button-font-weight800: $font-weight800;

/* Badges Font Size & Color  */
$badge-font-size: $default-font-size - 0.1rem;
$primary-badge-color1: var(--primary-color);
$primary-badge-color2: var(--secondary-color);
$secondary-badge-color1: var(--primary-light);
$secondary-badge-color2: var(--secondary-light);
$warning-badge-color: var(--warning);

/* Input Elements */
$input-font-size: $default-font-size * 1.4;
$input-height: 3.4rem;
$input-border: 1px solid var(--grey300);
$input-text-color: var(--body-text-color);
$input-placeholder-color: var(--grey400);
$input-radio-color: var(--grey100);
$input-checked-radio-color: var(--primary-color);
$input-color-white: var(--white);

/* Input Font Weight */
$input-font-weight300: $font-weight300;
$input-font-weight400: $font-weight400;
$input-font-weight500: $font-weight500;
$input-font-weight600: $font-weight600;
$input-font-weight700: $font-weight700;
$input-font-weight800: $font-weight800;

/* Header */
$header-font-size: $body-heading-size;
$header-nav-bg: var(--white);
$header-search-bar-bg: var(--grey100);
$header-text-color: var(--body-text-color);
$header-logo-height: 4rem;

/* Footer */

$footer-content-bg: var(--grey100);
$footer-bottom-bar-bg: var(--grey100);

/* Widgets border radius */
$trip-widget-radius: $custom-border-radius;
$destination-widget-radius: $custom-border-radius;
$experience-widget-radius: $custom-border-radius;
$category-widget-radius: $custom-border-radius;

/* SPACER */
$spacer: 10px;
