import { fromEvent as observableFromEvent, Observable } from 'rxjs';
import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { CommonModule } from '@angular/common';
@Component({
  standalone: true,
  selector: 'app-image',
  imports: [CommonModule],
  templateUrl: './image.component.html',
})
export class ImageComponent implements OnInit {
  @ViewChild('imageDisplay', { static: false }) imageDisp!: ElementRef;
  _image: string = '';
  @Input() options: any;
  @Input() set image(value: string) {
    this._image = value;
    this.getDimension();
  }
  get image() {
    return this._image;
  }
  @Input() ratio: string = '';
  @Input() altTag: string = '';
  @Input() lazyLoad: boolean = false;
  @Input() placeId: string = '';
  @Input() imgObj: any;
  @Input() resize: boolean = true;
  @Input() crop: boolean = true;

  imagePath: string = '';
  imagePathWebp: string = '';
  config = { width: 0, height: 0, offset: 50 };
  width: Number = 10;
  height: Number = 10;
  offsets = 0;
  defaultImage = environment.defaultImages.all;

  blurImageWebp: string = '';
  blurImage: string = '';
  errorPath: string = '';
  errorPathWebp: string = '';
  offset: any;
  scrollObservable: any;
  isVisible = true;
  isLoaded = false;
  constructor(private hostElement: ElementRef) {
    // this.cd.detectChanges();
  }

  ngOnInit() {
    this.scrollObservable = observableFromEvent(window, 'scroll');

    if (this.options !== undefined) {
      if (this.options['width'] !== undefined) {
        this.config.width = this.options['width'];
      }
      if (this.options['height'] !== undefined) {
        this.config.height = this.options['height'];
      }
    }
    if (this.ratio === undefined) {
      this.ratio = '16:9';
    }
    if (this.altTag === undefined) {
      this.altTag = '';
    }
    if (this.lazyLoad === undefined) {
      this.lazyLoad = true;
    }
    if (this.resize !== undefined) {
      this.resize = this.resize;
    } else {
      this.resize = true;
    }
    if (this.crop !== undefined) {
      this.crop = this.crop;
    } else {
      this.crop = true;
    }
    if (this.options) {
      if (this.options.hasOwnProperty('fallbackImage')) {
        if (this.options['fallbackImage']) {
          this.defaultImage = this.options['fallbackImage'];
        }
      }
    }
    this.getDimension();
    /*if (this.image === undefined || this.image === "") {
      this.image = this.defaultImage;
    }*/
  }
  onLoaded() {
    this.imagePath = this.errorPath;
    this.imagePathWebp = this.errorPathWebp;
    // make somthing based on 'isFallback'
  }
  onViewPortChange(event: any) {
    if (event) {
      if (!this.isVisible) {
        this.isVisible = true;
      }
    }
  }
  imageLoaded() {
    this.isLoaded = true;
  }
  getDimension() {
    let width: any;
    let height: any;
    console.log('this.ratio>>>>>>>');
    console.log(this.ratio);
    setTimeout(async () => {
      width = this.imageDisp.nativeElement.clientWidth;
      if (width === 0 || width === null) {
        width = 10;
      }
      this.config.width !== 0 ? (width = this.config.width) : '';
      // check and set default image

      switch (this.ratio) {
        case '16:9':
          height = (parseInt(width, 10) * 9) / 16;
          break;
        case '4:3':
          height = (parseInt(width, 10) * 3) / 4;
          break;
          case '21:9':
            height = (parseInt(width, 10) * 9) / 21;
            break;
        case '1:1':
          height = (parseInt(width, 10) * 1) / 1;
          break;
      }
      console.log('before height = ' + height);
      console.log('width = ' + width);
      this.config.height !== 0 ? (height = this.config.height) : '';
      height = parseInt(height, 10);
      this.width = width;
      this.height = height;
      let googleImage = false;
      if (this.image === '') {
        this.image = this.defaultImage;
        /* DON'T DELETE CODE
        if (this.placeId !== undefined) {
          if (this.placeId !== "") {
            googleImage = true;
            console.log('google place id got it')
            console.log(this.placeId);
            const googleImageLink = await this.getGooglePlaceDetail(this.placeId);
            if (googleImageLink !== false) {
              this.image = <string>googleImageLink;
            } else {
              this.image = this.defaultImage;
            }
            console.log('after got result');
            console.log(this.image);
            //  this.image = 'thSvfXSRIeHoEPEkhb0w';
          }
        }
        */
      }
      // if (!googleImage && this.image === '') {
      //   this.image = this.defaultImage;
      // }

      let source = 'filestack';
      if (this.imgObj) {
        if (this.imgObj.hasOwnProperty('source')) {
          if (this.imgObj['source'] === 'google') {
            source = this.imgObj['source'];
          }
        }
      }
      if (source === 'google') {
        /*
        this.imagePath = this.image
          ? this.image
          : "https://maps.googleapis.com/maps/api/place/photo?photoreference=" +
            this.options["photo_reference"] +
            "&sensor=false&maxheight=" +
            height +
            "&maxwidth=" +
            width +
            "&key=" +
            GOOGLE_MAP_KEY;
        this.imagePathWebp = this.imagePath;
        */
      } else {
        const blurWidth = Math.ceil(width * 0.0125);
        const blurHeight = Math.ceil(height * 0.0125);
        let crop = '';
        if (this.crop) {
          crop = ',f:crop';
        }
        let baseImage =
          'https://process.filestackapi.com/AMWLTZ7TPGdKVmXWkeY9Az/resize=h:' +
          height +
          ',w:' +
          width +
          ',a:center' +
          crop +
          '/';
        if (!this.resize) {
          baseImage =
            'https://process.filestackapi.com/AMWLTZ7TPGdKVmXWkeY9Az/';
        }
        console.log('this.image');
        console.log(this.image);
        console.log('BASE IMAGE');
        console.log(baseImage);

        this.imagePath = baseImage + this.image;
        this.imagePathWebp = baseImage + 'output=format:webp/' + this.image;
        const blurImagePath =
          'https://process.filestackapi.com/AMWLTZ7TPGdKVmXWkeY9Az/resize=h:' +
          blurHeight +
          ',w:' +
          blurWidth +
          ',a:center' +
          crop +
          '/compress/';
        this.blurImageWebp = blurImagePath + 'output=format:webp/' + this.image;
        this.blurImage = blurImagePath + this.image;
        this.errorPath = baseImage + this.defaultImage;
        this.errorPathWebp =
          baseImage + 'output=format:webp/' + this.defaultImage;
      }
    }, 100);
  }
}
