


                .scrap_name {
                    font-size: 20px;
                    color: var(--body-text-color) !important;
                    h4 {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: var(--body-text-color);
                    }
                    .scrap_edit_icon {
                        svg {
                            line-height: 30px;
                            color:   var(--body-text-color) ;
                        }
                    }
                }

                .traveler-scrap-detail {
                    font-size: 14px;
                    .angular-input-stars li {
                        font-size: 10px!important;
                    }
                    .star_category_share {
                        width: 50%;
                        .category_title {
                            color:   var(--body-text-color) ;
                        }
                    }
                }

                .scrap_inner_page {
                    .tb-scrap-img-action {
                        background: var(--white);
                        color: var(--body-text-color);
                        text-align: center;
                        width: 100%;
                        font-size: 10px;
                        padding: 8px 5px;
                        text-transform: uppercase;
                        font-weight: 500;
                    }
                    .tb-scrap-img-action {
                        border: 1px solid   var(----grey300) ;
                    }
                    .additional_inner_info {
                        color:  var(--body-text-color) ;
                        font-size: 12px;
                        text-align: justify;
                        // width: 400px;
                        word-wrap: break-word;
                        @include media-breakpoint-only(xs) {
                            width: unset;
                        }
                        @include media-breakpoint-only(md) {
                            width: unset;
                        }
                    }
                }

                .filter_inner_disp {
                    position: relative;
                    float: left;
                    margin-right: 20px;
                    color:   var(----grey300) ;
                }

                .scrap_content {
                    color:  var(--body-text-color) ;
                    text-align: justify;
                    word-wrap: break-word;
                }

                .tb-scrap-innercolortext {
                    color:   var(--body-text-color) ;
                }

                .sub_heading {
                    font-size: 14px;
                    color: var(--body-text-color);
                    margin-top: 10px;
                }

                .traveler-traveling-days {
                    position: relative;
                    float: left;
                   margin-right: 10px;
                    color:  var(--grey200) ;
                }
                .list-comma:last-child::after{
                    content: '' !important;
                    }
                .traveler-traveling-days::after {
                    content: '';
                    clear: both;
                }

                .traveler-traveling-timings {
                    position: relative;
                    margin-right: 20px;
                    color:  var(--body-text-color) ;
                    font-size: 13px;
                }

                .activepopularity {
                    color:#5c5c5c;
                }

                .tb-scrap-img-action .scrap_trip_add {
                    color:  var(--secondary-light) ;
                }

                .tb-scrap-img-action .scrap_trip_add.add_to_trip {
                    color: var(--body-text-color);
                }

                .sepration {
                    border-bottom: 1px solid  var(--grey100) ;
                }

                .innerPageCarousel .carousel-inner {
                    position: unset!important;
                }

                .img-caption-scrap {
                    font-size: 12px!important;
                    color: var(--body-text-color);
                }

                .innerPageCarousel .carousel-item {
                    height: unset!important;
                    min-height: unset!important;
                }

                .remark-icon {
                    font-size: 12px;
                }

                .rating-new-scrap-inner {
                    .rating-new-scrap-fill {
                        height: 16px;
                        width: 16px;
                        border-radius: 5px;
                        margin-right: 3px;
                    }
                    .rating-new-scrap-fill-outer {
                        height: 16px;
                        width: 16px;
                        border-radius: 5px;
                        margin-right: 3px;
                        background:  var(--light-cream)  ;
                    }
                    .rating-new-left-corner {
                        border-top-left-radius: 5px;
                        border-bottom-left-radius: 5px;
                    }
                    .rating-new-right-corner {
                        border-top-right-radius: 5px;
                        border-bottom-right-radius: 5px;
                        margin-right: 0px!important;
                    }
                    .rating-new-scrap-fill.active-green {
                        background: var(--success);
                    }
                    .rating-new-scrap-fill.active-orange {
                        background:   var(--primary-color) ;
                    }
                    .rating-new-scrap-fill.active-red {
                        background: #dc3545;
                    }
                }

                // new create scrap
                .heading-create-scrap {
                    font-size: 16px;
                    color: var(--body-text-color);
                    margin-top: 10px;
                }

                .subheading-create-scrap {
                    font-size: 13px;
                    color:   var(--body-text-color) ;
                    a {
                        color:   var(--body-text-color) ;
                    }
                }
                .excepspan {
                    display: inline-block;
                     width: 48px;
                }

                .descriptioninner {
                    ul li {
                        list-style-type: disc !important;
                    }
                    ul {
                        margin-left: 16px;
                    }
                }

                .scrap-inner-padding {
                    padding: 0px 15px!important;
                    @include media-breakpoint-only(xs) {
                        padding: 0px!important;
                    }
                }

                .details-section {
                    .heading-create-scrap {
                        margin-bottom: 5px;
                    }
                }

                .google-review {
                    .swiper-pagination-bullet {
                        &:focus {
                            outline: unset!important;
                        }
                    }
                    .google-review-outer {
                        .angular-input-stars li {
                            font-size: 10px;
                            color:   var(--body-text-color) !important;
                        }
                        .reviewer-name {
                            font-size: 14px;
                        }
                        .review-days {
                            font-size: 12px;
                            color:  var(--grey200) ;
                            margin-top: 3px;
                        }
                        .review-description {
                            font-size: 12px;
                        }
                    }
                    .user-review-pic {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        background-color:  var(--grey100) ;
                    }
                    .swiper-slide {
                        width: 25%;
                        @include media-breakpoint-down(sm) {
                            width: 70%;
                        }
                    }
                }

                .phone-inner {
                    height: 24px;
                    line-height: 24px!important;
                    width: 24px;
                    border-radius: 50%;
                    text-align: center;
                    background-color: var(--body-text-color);
                    i {
                        color: var(--white);
                        font-size: 12px;
                    }
                }

                .rotate_phone {
                    .phone-inner {
                        transform: scaleX(-1);
                    }
                }

                .inner-tags {
                    border-radius: 10px;
                    border: 1px solid   var(----grey300) ;
                    margin-bottom: 5px;
                }

                .remark_icon_width {
                    width: 35px;
                    text-align: center;
                }

                .entryfee-remarks {
                    font-size: 11px;
                    color:  var(--grey200) ;
                }

                // expinner css
                .expinner-outer {
                    .expinner-map-height {
                        height: calc(100vh - 146px);
                        @include media-breakpoint-only(xs) {
                            height: calc(100vh - 272px);
                        }
                        @include media-breakpoint-only(sm) {
                            height: calc(100vh - 100px);
                        }
                    }
                }

                .expinner-right-outer {
                    img {
                        width: 100%;
                    }
                    .scrap_name {
                        @include media-breakpoint-down(sm) {
                            font-size: 16px;
                            height: 25px;
                            width: 85%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                           white-space: nowrap;;
                        }
                    }
                }

                .expblog-mob-scroll {
                    height: calc(100vh - 146px);
                    overflow-y: scroll;
                    position: relative;
                    &.mapView {
                        @include media-breakpoint-down(sm) {
                            display: flex;
                            overflow-y: scroll;
                        }
                        .exp-mob-width {
                            button:focus {
                                outline: unset;
                            }
                            @include media-breakpoint-down(sm) {
                                width: 82%;
                                margin-right: 20px;
                            }
                        }
                        .width-righr-scrap {
                            @include media-breakpoint-down(sm) {
                                min-width: 290px;
                            }
                        }
                    }
                    @include media-breakpoint-down(sm) {
                        height: unset;
                        overflow-y: unset;
                        position: unset;
                    }
                    .exinner-heading {
                        color: var(--body-text-color);
                        font-size: 16px;
                    }
                    .exinner-description {
                        color:   var(--body-text-color) ;
                        font-size: 13px;
                        word-wrap: break-word;
                        @include media-breakpoint-down(sm) {
                            font-size: 12px;
                        }
                    }
                    .exp-moreinfo {
                        color: black;
                        font-size: 13px;
                        background: unset!important;
                        border: unset!important;
                        @include media-breakpoint-down(sm) {
                            font-size: 12px;
                        }
                    }
                }

                .expnew-heading {
                    height: 80px;
                    line-height: 80px;
                    .back-btn {
                        width: 25%;
                        a {
                            color: var(--body-text-color);
                        }
                        @include media-breakpoint-down(sm) {
                            width: unset;
                            height: auto;
                            line-height: unset;
                        }
                    }
                    .expnew-heading-desk-mob {

                        text-align: center;
                        flex-grow: 1;
                        h1 {
                            font-size: 20px;
                            font-weight: 300;
                            line-height: 80px;
                            color:   var(--body-text-color) ;
                            @include media-breakpoint-down(md) {
                                font-size: 16px;
                                line-height: unset;
                                text-align: center;
                            }
                        }
                    }
                    @include media-breakpoint-down(md) {
                        height: auto;
                        line-height: unset;
                        padding: 10px 0px;
                    }
                    .w-10 {
                        @include media-breakpoint-down(sm) {
                            width: 10%;
                        }
                        @include media-breakpoint-only(md) {
                            width: 25%;
                        }
                    }
                }
.viator_section {
  ul{
    margin-left:15px;
  }
  ul li{
    list-style-type:disc;
  }
  i{
    width:20px;
  }
}



