//flexcss
.flexrow {
  flex-direction: row;
}
.flexcol {
  flex-direction: column;
}
.flexwidth_50 {
  flex-basis: 50%;

  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 48%;
    width: 27px;
    height: 44px;
    margin-top: -22px;
    z-index: 10;
    cursor: pointer;
    background-size: 29px 30px;
    background-position: center;
    background-repeat: no-repeat;
  }
  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    background-image: url(/assets/images/review-prev.png) !important;
    left: 10px;
    right: auto;
  }
  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    background-image: url(/assets/images/review-next.png) !important;
    right: 10px;
    left: auto;
  }
  .swiper-slide {
    source {
      border-radius: 6px;
    }
  }
}
.flexwidth_20 {
  padding-right: 25px;
  flex-basis: 20%;
}
.flexwidth_30 {
  flex-basis: 30%;
}
.flexwidth_70 {
  flex-basis: 70%;
}
.flexwidth_80 {
  flex-basis: 80%;
}
.flex-5 {
  flex-basis: 5%;
}
.flex-55 {
  flex-basis: 55%;
}
.flex-65 {
  flex-basis: 65%;
}
.flex-100 {
  flex-basis: 100%;
}

//margin & padding
.margin-row {
  margin: 40px 0px;
}
.padding30 {
  padding: 25px;
}
.p_left30 {
  padding-left: 30px;
}
.headingbtm {
  margin-bottom: 25px;
  text-transform: capitalize;
}
.marginright15 {
  margin-right: 15px;
}
.marginleft15 {
  margin-left: 15px;
}
.marginbtm5 {
  margin-bottom: 5px;
}
.valign {
  align-self: center;
}
.margin-row-btm {
  margin-bottom: 40px;
}
.margin-row-top {
  margin-top: 20px;
  margin-bottom: 20px;
}
.margin-row-btm1 {
  margin-bottom: 70px;
}
.reviewpadding {
  padding: 0px 60px;
}
.reviewcard {
  padding: 30px;
  border: 1px solid var(--grey200);
  margin: 60px 0px;
  border-radius: 6px;
  .swiper-button-next,
  .swiper-button-prev {
    position: absolute;
    top: 50%;
    width: 27px;
    height: 44px;
    margin-top: -22px;
    z-index: 10;
    cursor: pointer;
    background-size: 29px 30px;
    background-position: center;
    background-repeat: no-repeat;
  }
  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    background-image: url(/assets/images/review-prev.png);
    left: 10px;
    right: auto;
  }
  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    background-image: url(/assets/images/review-next.png);
    right: 10px;
    left: auto;
  }
}
.entry_fee_swiper {
  width: 450px;
}

.scrap-list {
  list-style-type: disc;
  margin-left: 20px;
}
// icons
.gen_icon {
  font-size: 1.5rem;
  color: var(--body-text-color);
}
.scrap-star {
  font-size: 1.5rem;
  color: var(--primary-color);
  margin-right: 3px;
  margin: 8px 3px 10px 3px;
}
.lht25 {
  line-height: 25px;
}
.lht30 {
  line-height: 30px;
}
.linkcolor {
  color: var(--blue);
}
.curv-radius {
  background: var(--grey100);
  padding: 3px 8px;
  border-radius: 6px;
  margin-top: 0px !important;
  display: inline-block;
  margin-bottom: 10px !important;
}
.fees_acc .activeAccord {
  transform: rotate(180deg) !important;
}
//card layout
.bordermid {
  border-right: 1px solid var(--grey200);
}
.cardbg {
  border: 1px solid var(--grey200);
  min-height: 150px;
  border-radius: 6px;
}

.phoneicon {
  font-size: 34px;
  color: #5c5c5c;
  margin-right: 3px;
  transform: rotate(90deg);
}
.locationicon {
  color: #5c5c5c;
  font-size: 34px;
  margin-right: 3px;
}
.acc-holder {
  background-color: #9d9b9b12;
  text-transform: initial !important;
  div {
    text-transform: lowercase !important;
    &::first-letter {
      content: "";
      text-transform: capitalize !important;
    }
  }

  p {
    text-transform: lowercase !important;
    &::first-letter {
      content: "";
      text-transform: capitalize !important;
    }
  }
}
.acc-header {
  font-weight: 400;
  padding: 16px;
  margin-bottom: 0;

  border-radius: 6px !important;
}
.copyright-container {
  width: 100%;
}
.copyright-width {
  max-width: 300px;
}

.scrap-top-section {
  .swiper-container {
    position: relative;
    .swiper-pagination {
      position: absolute;
      width: 100%;
      text-align: center;
      left: 50% !important;
      transform: translateX(-50%) !important;
      z-index: 5;
    }
    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      display: inline-block;
      border-radius: 100%;
      background: var(--white);
      opacity: 0.2;
      margin: 0px 6px;
      position: relative;
    }

    //   @media only screen and (max-width:575px) {
    //     .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
    //         transform: scale(1);

    //     }
    //     .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
    //         transform: scale(1);
    //     }
    //     .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
    //         transform: scale(1);
    //     }
    //     .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
    //         transform: scale(1);
    //     }
    //   }
  }
}
.flex_11 {
  flex-grow: 1;
}
.d-flex1 {
  display: flex;
}
.mye_overflow {
  overflow-y: auto;
  text-align: left !important;
  max-height: 62px;
  padding: 0px 50px;
}
.poiimg {
  width: 300px;
  position: relative;
  min-width: 50%;
  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    right: 10px;
    left: auto;
  }
}
.entry_fee_heading {
  padding: 0px 3px;
  &.active_border {
    color: var(--primary-color);
    border-bottom: 2px solid var(--primary-color)-color !important;
  }
}
.entry_fee_details {
  padding-left: 45px;
  text-align: left;
  overflow-y: auto;
  height: 105px;
}
.entry_overflow {
  overflow-x: auto;
  white-space: nowrap;
}
.arrow-down {
  font-size: 16px;
  font-weight: 600;
}

@media only screen and (max-width: 1024px) {
  .entry_fee_swiper {
    width: 100%;
  }
}
@media only screen and (max-width: 1024px) {
  .margin-row {
    margin: 40px 0px;
  }
  .myd-block {
    display: block !important;
  }
  .margin-row-top {
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .flexrow {
    flex-direction: column;
  }

  .p_left30 {
    padding-left: 0px;
  }

  .flexwidth_50 {
    flex-basis: 100%;
  }

  .bordermid {
    padding-bottom: 20px;
    border-right: none;
    border-bottom: 1px solid var(--grey200);
    margin-bottom: 20px;
  }
  .m-imgbtm {
    margin-bottom: 40px;
  }
  .margin-row-btm1 {
    margin-bottom: 45px;
  }
  .margin-row-btm {
    margin-bottom: 20px;
  }

  .marginleft15 {
    margin-left: 0px;
  }
  .cardbg {
    .headingbtm {
      margin-top: 10px;
      margin-bottom: 15px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .d-flex1 {
    display: block;
    .mob-btm {
      margin-right: 0px;
    }
  }
  .arrow-down {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
  }
  .mob_100 {
    max-width: 100%;
  }
  .mye_overflow {
    overflow-y: auto;
    text-align: left !important;
    max-height: 100%;
    padding: 0px 50px;
  }
  .mob-btm {
    margin-bottom: 40px;
  }
  .reviewcard {
    padding: 20px 0px;
    border: 1px solid var(--grey200);
    margin: 60px 0px;
    border-radius: 6px;
    .swiper-button-next,
    .swiper-button-prev {
      position: absolute;
      top: 50%;
      width: 27px;
      height: 44px;
      margin-top: -23px;
      z-index: 10;
      cursor: pointer;
      background-size: 27px 24px;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  .reviewpadding {
    padding: 20px 40px;
  }
  .cardbg {
    min-height: auto;
    border-radius: 6px;
  }
  .curv-radius {
    background: var(--grey100);
    padding: 3px 8px;
    border-radius: 6px;
    margin-top: 0px !important;
    display: inline-block;
    margin-bottom: 10px !important;
  }
  .scrap-star {
    font-size: 16px;
    color: var(--primary-color);
    margin-right: 3px;
  }
  .entry_fee_details {
    text-align: left;
    overflow-y: auto;
    height: auto;
  }

  .headingbtm {
    margin-bottom: 15px;
    text-transform: capitalize;
  }
}

.recommendation_month_week {
  .months_week {
    text-transform: capitalize;
    &::after {
      content: ", ";
    }
    &:last-child::after {
      content: "";
    }
  }
}

.more-table {
  table {
    border-collapse: separate;
    border-spacing: 0;
    color: var(--body-text-color);
  }
  table tr td {
    border-right: 1px solid var(--grey200);
    border-bottom: 1px solid var(--grey200);
    padding: 15px;
  }
  table tr td:first-child,
  table tr td:first-child {
    border-left: 1px solid var(--grey200);
  }
  table tr td {
    border-top: 1px solid var(--grey200);
    text-align: left;
  }
  /* top-left border-radius */
  table tr:first-child td:first-child {
    border-top-left-radius: 6px;
  }
  /* top-right border-radius */
  table tr:first-child td:last-child {
    border-top-right-radius: 6px;
  }

  /* bottom-left border-radius */
  table tr:last-child td:first-child {
    border-bottom-left-radius: 6px;
  }

  /* bottom-right border-radius */
  table tr:last-child td:last-child {
    border-bottom-right-radius: 6px;
  }
}
@media only screen and (max-width: 991px) {
  .copyright-container {
    padding-right: 0%;
    max-width: 85%;
    width: 100%;
    padding-left: 0%;
    margin-left: 0px;
  }
}
@media only screen and (max-width: 575px) {
  .scrap-top-section {
    .swiper-pagination-bullets-dynamic {
      z-index: 5 !important;
      bottom: 40px !important;
      overflow: hidden;
      white-space: nowrap;
      z-index: 5 !important;
    }

    .container {
      max-width: 100% !important;
      .scrap-topcontent {
        max-width: 85%;
        margin: 0px auto;
        margin-bottom: 20px;
      }
      .margin-row-top {
        margin-top: 10px;
        margin-bottom: 20px;
      }
      .exp-newbg {
        background: var(--white);
        margin: 40px auto 0px auto;
        border-radius: 6px;
        padding: 0px;
      }
      .exp_bannerimg {
        border-radius: 0px;
      }
    }
  }

  .copyright-container {
    padding-right: 7.5%;
    max-width: 85%;
    width: 100%;
    padding-left: 0%;
    margin-left: 0px;
  }
  .copyright-width {
    max-width: 189px;
  }
}
@media only screen and (max-width: 575px) {
  .tripdetail {
    .scrap-top-section {
      .swiper-pagination-bullets-dynamic {
        z-index: 5 !important;
        bottom: 30px !important;
        overflow: hidden;
        white-space: nowrap;
        z-index: 5 !important;
      }
    }
  }
}
