.tb-support {
  height: 60px;
  line-height: 60px;
  font-size: 18px;
  border-bottom: 1px solid var(--grey200);
  @include media-breakpoint-only(xs) {
    font-size: 15px;
  }
}

.tb-support span {
  margin-right: 7px;
  color: var(--body-text-color);
  @include media-breakpoint-only(xs) {
    margin-right: 2px;
  }
}

.bag-new {
  // background-image: url(/assets/images/build-trip.webp);
  background-size: cover;
  background-position: 32% 40%;
  border-radius: 0px 6px 6px 0px;
}

.new_input_home {
  padding: 0rem 2.2rem 3rem 2.2rem;
  .input-field {
    background-color: var(--white) !important;
    height: 3.4rem;
    border-radius: 0.3rem;
    display: flex;
    width: 100%;
    align-items: center;
    font-size: 1.5rem;
    color: var(--body-text-color);
    font-weight: 400 !important;
    @media screen and (max-width: 575px) {
      font-size: 1.2rem;
    }
  }

  @include media-breakpoint-only(xs) {
    padding: 1.2rem 1.2rem 3rem 1.2rem;
  }
  .submit-btn-new {
    font-size: 1.5rem;
    padding: 0rem 1.8rem;
    margin: 0.5rem 0.6rem;
  }
}

.create-new-trip {
  input {
    font-size: 13px !important;
  }
  .input-field {
    padding: 7px 20px;
    font-size: 13px !important;
    height: 40px !important;
  }
  .new_input_home {
    background-color: rgba(216, 200, 198, 0.26);
  }
}

.collapse_header {
  float: left;
  width: 100%;
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

.collapse_header i {
  transition: all 0.3s ease;
}

.collapse_header.active i {
  transform: rotate(180deg);
}

.collapse_header[aria-expanded="true"] .collapseOpen:before {
  content: "\F056";
}

.tb-searchbarwrap-home {
  width: 100%;
  height: 50px;
  .input-field {
    @include media-breakpoint-only(xs) {
      font-size: 9px;
    }
  }
  @include media-breakpoint-only(xs) {
    width: 100%;
    height: 35px;
  }
}

.tb-bar-searchButton-mob {
  color: var(--secondary-light) !important;
  background-color: var(--white) !important;
  border-color: none !important;
  @include media-breakpoint-only(xs) {
    color: var(--body-text-color) !important;
  }
}

.multiple-dest {
  display: inline-block;
  height: 25px;
  font-size: 12px;
  font-weight: 500;
  color: var(--body-text-color);
  line-height: 25px;
  padding: 0 12px;
  /* border: 2px solid #5c5c5c; */
  border-radius: 16px;
  background-color: var(--grey200);
  margin-right: 3px;
  margin-bottom: 10px;
  opacity: 1;
  animation: chipAnimate 0.3s forwards;
  i {
    cursor: pointer;
  }
}

// terms & policy
.tb-privacy-headg {
  text-decoration: underline;
}

.tb-content {
  font-size: 15px;
  margin-bottom: 20px;
  text-align: justify;
  color: var(--body-text-color);
}

.tb-type-align {
  margin-top: 2px;
  margin-left: 17px;
}

.tb-type-headg {
  padding-left: 10px;
}

.tb-descrptn-details {
  padding-left: 10px;
  text-align: justify;
  margin-top: 8px;
  margin-bottom: 8px;
  color: var(--body-text-color);
}

.tb-subtype-align {
  margin-top: 2px;
  margin-left: 25px;
  color: var(--body-text-color);
}

.tb-typelisit-align {
  padding-left: 10px;
}

.slider-search {
  position: absolute;
  z-index: 10;
  top: 260px;
  width: 100%;
}

.tb-slidercontent-margin {
  text-align: center;
  margin-bottom: 0px;
  margin-top: 215px;
  .home-45 {
    width: 45% !important;
    @include media-breakpoint-only(xs) {
      width: 72% !important;
    }
    @include media-breakpoint-only(sm) {
      width: 70% !important;
    }
    @include media-breakpoint-only(md) {
      width: 68% !important;
    }
  }
  input {
    font-size: 13px !important;
  }
  @include media-breakpoint-only(xs) {
    margin-top: 188px;
  }
  @include media-breakpoint-only(sm) {
    margin-top: 100px;
  }
  @include media-breakpoint-only(md) {
    margin-top: 225px;
  }
}

.demo_text .slider_top {
  text-align: center;
  font-size: 35px;
  color: var(--white);
  text-shadow: 0px 3px 9px rgba(0, 0, 0, 0.7);
  text-transform: capitalize;
  font-weight: 500;
  position: relative;
  @include media-breakpoint-only(xs) {
    font-size: 22px;
    padding: 0px;
  }
  @include media-breakpoint-only(sm) {
    padding: 0px;
    font-size: 24px;
  }
}

.search-no-padding {
  @include media-breakpoint-only(xs) {
    padding: 0px !important;
  }
}

.input-home-height {
  height: 50px;
  @include media-breakpoint-only(xs) {
    height: 35px;
  }
}

.mob-input-home-new {
  @include media-breakpoint-only(xs) {
    height: 40px !important;
    font-weight: 300 !important;
  }
}

.headline-home {
  margin: 80px 0px;
  @include media-breakpoint-only(xs) {
    margin: 50px 0px 40px 0px;
  }
}

.headline-home-destination {
  margin: 50px 0px 80px 0px;
  @include media-breakpoint-only(xs) {
    margin: 50px 0px 40px 0px;
  }
}

.swiper-wrapper {
  // box-sizing: border-box;
  // position: relative;
  // width: 100%;
  // height: 100%;
  // z-index: 1;
  // display: flex;
  // transition-property: transform;
  box-sizing: border-box;
}

.home_page .swiper-slide222 {
  text-align: center;
  font-size: 18px;
  background: var(--white);
  width: 25%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  @include media-breakpoint-only(xs) {
    width: 60% !important;
    font-size: 14px;
  }
  @include media-breakpoint-only(sm) {
    width: 40% !important;
  }
  @include media-breakpoint-up(lg) {
    width: 20% !important;
  }
}

.home_page .swiper-container-horizontal > .swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 0px !important;
  @include media-breakpoint-down(sm) {
    display: none;
  }
}
.swiper-pagination-bullet-active {
  opacity: 1 !important;
  background: var(--white);
}
.swiper-pagination-bullet-active .swiper-slide .exphold-new {
  width: 100% !important;
  margin: 0px !important;
}

.counts-new-home {
  font-weight: 300 !important;
  font-size: 14px;
}

.more_home {
  font-size: 13px;
}

.near-destination {
  @include media-breakpoint-down(sm) {
    &:nth-child(even) {
      padding-left: 7px !important;
    }
    &:nth-child(odd) {
      padding-right: 7px !important;
    }
  }
}

.near-destination .distance-km {
  font-size: 13px !important;
}

.margin-exp-trip {
  margin: 0px 0px 30px 0px !important;
  @include media-breakpoint-only(xs) {
    margin: 0px 0px 15px 0px !important;
  }
}

.trip-name-image {
  bottom: 0;
}

.home_widget {
  @include media-breakpoint-only(xs) {
    .top_destination:nth-child(odd) {
      padding-left: 0px;
      padding-right: 5px !important;
    }
    .top_destination:nth-child(even) {
      padding-right: 0px !important;
      padding-left: 5px !important;
    }
  }
}
.headline-home3 {
  margin: 80px 0px;
  @include media-breakpoint-only(xs) {
    margin: 20px 0px 40px 0px;
  }
}
.ready-trips-widget-new {
  min-height: 100%;
}
.trip_widget {
  @include media-breakpoint-up(md) {
    .col-lg-3 {
      flex: 0 0 25%;
      max-width: 25%;
    }
  }
  @include media-breakpoint-only(md) {
    .col-lg-3 {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
  @include media-breakpoint-only(xs) {
    .ready-trips {
      padding-left: 0px !important;
      padding-right: 0px !important;
      margin: 0px 0px 20px 0px;
    }
  }
}

.profile_widget {
  .user-profile:nth-child(odd) {
    padding-right: 7px !important;
  }
  .user-profile:nth-child(even) {
    padding-left: 8px !important;
  }
}

.w-56 {
  width: 56% !important;
}

.w-57 {
  width: 57% !important;
}

.empty-destination {
  font-size: 14px;
  @include media-breakpoint-down(sm) {
    font-size: 12px;
  }
}

.swiper-button-prev-custom,
.swiper-container-rtl .swiper-button-next-custom {
  left: 10px;
  right: auto;
  padding-right: 2px;
}

.swiper-button-next-custom,
.swiper-container-rtl .swiper-button-prev-custom {
  right: 10px;
  left: auto;
}

.swiper-button-next-custom,
.swiper-button-prev-custom {
  position: absolute;
  top: 49%;
  margin-top: -22px;
  z-index: 10;
  cursor: pointer;
  color: black;
  font-size: 16px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  text-align: center;
  text-shadow: 0 0 12px var(--white);
  &:focus {
    outline: unset;
  }
}

.swiper-container:hover {
  .swiper-button-next-custom,
  .swiper-button-prev-custom {
    background-color: rgb(255, 255, 255);
    box-shadow: var(--card-shadow) 0px 0.222222em 0.888889em;
  }
}

.swiper-button-next-custom.swiper-button-disabled,
.swiper-button-prev-custom.swiper-button-disabled {
  opacity: 0.35;
  cursor: auto;
  pointer-events: auto !important;
  display: none !important;
}

.newcls {
  border-color: red !important;
}
.slider-image3 {
  background-image: url("https://process.filestackapi.com/AMWLTZ7TPGdKVmXWkeY9Az/resize=h:925,w:1888,a:center,f:crop/compress/jIaNESuSqy9gHbOQam4w");
  //  background-image: url('/assets/images/slides/h-3.webp');
  @include media-breakpoint-only(xs) {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(
        -180deg,
        #dc422500,
        #2b2625c4,
        var(--body-text-color) 000d1
      );
      opacity: 0.6;
    }

    background-image: url("https://process.filestackapi.com/AMWLTZ7TPGdKVmXWkeY9Az/resize=h:1800,w:1200,a:center,f:crop/compress/jUbMfCafSIalL3j2QMVI");
    // background-image : url('/assets/images/slides/mbh-3.webp');
  }
}

.slider-image2 {
  background-image: url("https://process.filestackapi.com/AMWLTZ7TPGdKVmXWkeY9Az/resize=h:925,w:1888,a:center,f:crop/compress/ZsZYihflRxyymjlz8auH");

  @include media-breakpoint-only(xs) {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(
        -180deg,
        #dc422500,
        #2b26250d,
        var(--body-text-color) 000
      );
      opacity: 0.6;
    }
    background-image: url("https://process.filestackapi.com/AMWLTZ7TPGdKVmXWkeY9Az/resize=h:1800,w:1200,a:center,f:crop/compress/kOpiInhXRdWUS7rv93Bs");
    // background-image : url('/assets/images/slides/mob-1.webp');
  }
}
.slider-image1 {
  background-image: url("https://process.filestackapi.com/AMWLTZ7TPGdKVmXWkeY9Az/resize=h:925,w:1700,a:center,f:crop/compress/bumwUUSQyylCLLaSeRsg");
  //   background-image: url('/assets/images/slides/h-1.webp');
  @include media-breakpoint-only(xs) {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: linear-gradient(-180deg, #dc422500, #2b26250d, #000000);
      opacity: 0.6;
    }

    background-image: url("https://process.filestackapi.com/AMWLTZ7TPGdKVmXWkeY9Az/resize=h:1800,w:1200,a:center,f:crop/compress/RARqiieFQVmSazXwr8dM");
    // background-image : url('/assets/images/slides/mob-home2.webp');
  }
}

.home-bootstrap-slider {
  position: relative;
  height: 70vh;
  background-color: var(--body-text-color);
}
.swiper-container.home-bootstrap-slider {
  position: relative;
  height: 70vh;
  background-color: var(--body-text-color);
  .swiper-pagination {
    position: absolute;
    width: 100%;
    text-align: center;
    bottom: 15px !important;
    z-index: 10;
  }
  .swiper-pagination-bullet {
    width: 10px !important;
    height: 10px !important;
    margin-right: 7px;
    display: inline-block;
    border-radius: 100%;
    opacity: 0.4;
    background: var(--white) fff !important;
  }

  @include media-breakpoint-only(xs) {
    height: 480px;
  }
  @include media-breakpoint-only(sm) {
    height: 290px;
  }
  @include media-breakpoint-only(md) {
    height: 480px;
  }
}

.swiper-slide {
  height: 100%;
  background-size: cover;
  background-position: center bottom;
}

// new slider
.home-bootstrap-slider {
  height: 530px;
  @include media-breakpoint-only(xs) {
    height: 480px;
    margin-top: -92px;
    .swiper-slide {
      background-repeat: no-repeat !important;
      height: 100%;
      background-size: cover;
      background-position: center bottom;
    }
  }
  @include media-breakpoint-only(sm) {
    height: 290px;
    margin-top: -92px;
  }
  @include media-breakpoint-only(md) {
    height: 480px;
    margin-top: -92px;
  }
  .carousel-indicators {
    z-index: 8;
    li {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      margin-right: 6px;
      margin-left: 6px;
    }
  }
  .carousel-item {
    background-size: cover;
    background-position: center;
    background-color: var(--body-text-color);
    /*
  -webkit-animation: zoom 10s linear forwards;
  animation: zoom 10s linear forwards;
  */
  }
  // .slider-image1 {
  //     background-image: url('/assets/images/slides/coverimg1.jpeg');
  //     // background-position: top !important;
  //     @include media-breakpoint-only(xs) {
  //         background-image: url('/assets/images/slides/m1.jpg');
  //     }
  // }
  // .slider-image2 {
  //     background-image:url('/assets/images/slides/coverimg2.jpg') ;
  //     background-position: bottom !important;
  //     @include media-breakpoint-only(xs) {
  //         background-image: url('/assets/images/slides/m2.jpg');
  //         // background-size: 180%;
  //     }
  // }
  // .slider-image3 {
  //     background-image: url('/assets/images/slides/d3.png');
  //     background-position: top !important;
  //     @include media-breakpoint-only(xs) {
  //         background-image: url('/assets/images/slides/m5.jpg');
  //     }
  // }
  .carousel-caption {
    right: 0 !important;
    left: 0 !important;
    bottom: 0 !important;
    top: 30% !important;
    width: 100%;
    text-align: left !important;
    padding: 0px 40px !important;
    @include media-breakpoint-only(xs) {
      top: 69% !important;
      text-align: center !important;
      padding: 20px !important;
    }
    @include media-breakpoint-only(sm) {
      top: 40% !important;
      text-align: center !important;
      padding: 20px !important;
    }
    @include media-breakpoint-only(md) {
      top: 41% !important;
      text-align: center !important;
    }
  }

  .inputhome-absolute {
    position: absolute;
    top: 50%;
    width: 100%;
    @include media-breakpoint-only(xs) {
      top: 49%;
    }
    @include media-breakpoint-only(sm) {
      top: 46%;
    }
    @include media-breakpoint-only(md) {
      top: 53%;
    }
    .width-search-text {
      width: 36%;
      margin: 0 auto;
      input {
        font-size: 13px !important;
      }
      @include media-breakpoint-only(xs) {
        width: 62%;
      }
      @include media-breakpoint-only(md) {
        width: 60%;
      }
    }
  }
  .no-destination {
    width: 36%;
    margin: 0 auto;
    @include media-breakpoint-only(xs) {
      width: 62%;
    }
    @include media-breakpoint-only(md) {
      width: 60%;
    }
  }
}

@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(1, 1);
  }
  to {
    -webkit-transform: scale(1.1, 1.1);
  }
}

@keyframes zoom {
  from {
    transform: scale(1, 1);
  }
  to {
    transform: scale(1.1, 1.1);
  }
}

// home tabs
.home-tabs {
  ul {
    display: flex;
    margin-bottom: 0px;
    @include media-breakpoint-only(xs) {
      display: block;
    }
    li {
      width: 5%;
      margin: 0px 5px;
      @include media-breakpoint-only(xs) {
        width: 100%;
        margin: 0px 0px 10px 0px;
      }
      .country-list-link {
        display: block;
        line-height: 96px;
        height: 90px;
        text-align: center;
        cursor: pointer;
      }
      a {
        text-transform: uppercase;
        color: var(--white) !important;
        font-weight: bold;
        text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.5);
      }
    }
  }
  li.flex-fill.continent-background1.active {
    box-shadow: 0 0.125rem 0.25rem var(--card-shadow) !important;
  }
}

.continent-background1 {
  background-image: url(/assets/images/continents/asia1.jpg);
  background-size: cover;
  background-position: center;
}

.continent-background2 {
  background-image: url(/assets/images/continents/africa1.jpg);
  background-size: cover;
  background-position: center;
}

.continent-background3 {
  background-image: url(/assets/images/continents/NA.jpg);
  background-size: cover;
  background-position: center;
}

.continent-background4 {
  background-image: url(/assets/images/continents/SA.jpg);
  background-size: cover;
  background-position: center;
}

.continent-background5 {
  background-image: url(/assets/images/continents/antarctica.jpg);
  background-size: cover;
  background-position: center;
}

.continent-background6 {
  background-image: url(/assets/images/continents/europe.jpg);
  background-size: cover;
  background-position: center;
}

.continent-background7 {
  background-image: url(/assets/images/continents/australia.jpg);
  background-size: cover;
  background-position: center;
}

.tab-content-display {
  border: 1px solid #d0d0d0;
  border-top: unset;
  margin: 0px 5px;
  @include media-breakpoint-only(xs) {
    margin: unset;
    border: 1px solid #d0d0d0 !important;
  }
}

.video-home {
  height: 530px;
  position: relative;
  @include media-breakpoint-only(xs) {
    height: 480px;
  }
  .video-caption {
    position: absolute;
    top: 35%;
    z-index: 9;
    color: var(--white);
    width: 100%;
    padding: 0px 40px !important;
    @include media-breakpoint-only(xs) {
      top: 45% !important;
      text-align: center !important;
      padding: 0px !important;
    }
    @include media-breakpoint-only(sm) {
      top: 29% !important;
    }
    @include media-breakpoint-only(md) {
      top: 36% !important;
    }
  }
}

#video-background {
  /*  making the video fullscreen  */
  position: relative;
  right: 0;
  bottom: 0;
  width: 100%;
  @include media-breakpoint-only(xs) {
    width: unset;
  }
}

.nav-opacity {
  transition: 300ms all linear;
  .input-group {
    border-bottom: 0px solid var(--body-text-color) !important;
  }
}

.new_input_home {
  .dropdown_list {
    background: var(--white);
    position: absolute;
    width: 100%;
    z-index: 11;
    top: 33px !important;
    box-shadow: 0px 2px 5px var(--card-shadow);
  }
  .form-check {
    padding-left: 0px;
  }
  .message_box_clear {
    position: absolute;
    top: 7px !important;
    right: 0px;
    cursor: pointer;
    color: #00000057 !important;
  }
  .selectdiv {
    width: 100%;
    @include media-breakpoint-only(xs) {
      padding-left: 0px;
      &:after {
        font: 20px "Consolas", monospace;
      }
    }
    select {
      border: 1px solid var(--grey200);
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      height: auto !important;
      padding: 0.375rem 0.75rem;
      font-size: 0.875rem;
      font-weight: 300;
      line-height: 1.5;
      /* padding: 5px 30px 5px 10px; */
      /* font-size: 14px; */
      /* line-height: 18px; */
      color: var(--body-text-color);
      width: 100%;
      background-image: none;
      @include media-breakpoint-only(xs) {
        font-size: 14px !important;
      }
    }
  }

  .control {
    font-size: 14px;
    position: relative;
    display: block;
    margin-bottom: 0px;
    padding-left: 40px;
    cursor: pointer;
    color: var(--body-text-color);
  }
  .control__indicator {
    position: absolute;
    top: 0px;
    left: 0%;
    width: 23px;
    height: 23px;
    background: #e6e6e6;
  }
  .control--checkbox .control__indicator:after {
    top: 4px;
    left: 8px;
    width: 7px;
    height: 12px;
    transform: rotate(49deg);
    border: solid var(--white);
    border-width: 0 2px 2px 0;
  }
}

.trip-profile-theme-outer {
  .background-img-theme {
    width: 100%;
    height: 46px;
    background-position: center;
    background-repeat: no-repeat;
  }
  .text-col {
    color: var(--body-text-color);
    text-transform: uppercase;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    @include media-breakpoint-down(sm) {
      font-size: 10px;
    }
  }
  input[type="radio"],
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
  [type="radio"] + .background-img-theme {
    cursor: pointer;
  }
  [type="radio"] + .adventure-travel {
    background-image: url(/assets/images/adventure-black.png);
  }
  [type="radio"]:checked + .adventure-travel {
    background-image: url(/assets/images/adventure-red.png);
    & + .text-col {
      color: var(--primary-color);
    }
  }
  [type="radio"] + .culture-travel {
    background-image: url(/assets/images/culture-black.png);
  }
  [type="radio"]:checked + .culture-travel {
    background-image: url(/assets/images/culture-red.png);
    & + .text-col {
      color: var(--primary-color);
    }
  }
  [type="radio"] + .tourist-travel {
    background-image: url(/assets/images/travel-black.png);
  }
  [type="radio"]:checked + .tourist-travel {
    background-image: url(/assets/images/travel-red.png);
    & + .text-col {
      color: var(--primary-color);
    }
  }
  [type="radio"] + .activity-travel {
    background-image: url(/assets/images/activity-black.png);
  }
  [type="radio"]:checked + .activity-travel {
    background-image: url(/assets/images/activity-red.png);
    & + .text-col {
      color: var(--primary-color);
    }
  }
  [type="radio"] + .exotic-travel {
    background-image: url(/assets/images/exotic-black.png);
  }
  [type="radio"]:checked + .exotic-travel {
    background-image: url(/assets/images/exotic-red.png);
    & + .text-col {
      color: var(--primary-color);
    }
  }
  [type="radio"] + .romantic-travel {
    background-image: url(/assets/images/romantic-black.png);
  }
  [type="radio"]:checked + .romantic-travel {
    background-image: url(/assets/images/romantic-red.png);
    & + .text-col {
      color: var(--primary-color);
    }
  }
  [type="radio"] + .custom-trip {
    background-image: url(/assets/images/custom-black.png);
  }
  [type="radio"]:checked + .custom-trip {
    background-image: url(/assets/images/custom-red.png);
    & + .text-col {
      color: var(--primary-color);
    }
  }
  .trip-profile-theme {
    width: 20%;
    @include media-breakpoint-down(sm) {
      width: 33.333%;
    }
  }
}

.trip-profile-travel-outer {
  .background-img-travel {
    width: 100%;
    height: 46px;
    background-position: center;
    background-repeat: no-repeat;
  }
  .text-col {
    color: var(--body-text-color);
    text-transform: uppercase;
    font-size: 12px;
    text-align: center;
    cursor: pointer;
    @include media-breakpoint-down(sm) {
      font-size: 10px;
    }
  }
  input[type="radio"],
  input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    width: 0;
    height: 0;
  }
  [type="radio"] + .background-img-travel {
    cursor: pointer;
  }
  [type="radio"] + .background-img-travel1 {
    background-image: url(/assets/images/solo-black.png);
  }
  [type="radio"]:checked + .background-img-travel1 {
    background-image: url(/assets/images/solo-red.png);
    & + .text-col {
      color: var(--primary-color);
    }
  }
  [type="radio"] + .background-img-travel2 {
    background-image: url(/assets/images/couple-black.png);
  }
  [type="radio"]:checked + .background-img-travel2 {
    background-image: url(/assets/images/couple-red.png);
    & + .text-col {
      color: var(--primary-color);
    }
  }
  [type="radio"] + .background-img-travel3 {
    background-image: url(/assets/images/family-black.png);
  }
  [type="radio"]:checked + .background-img-travel3 {
    background-image: url(/assets/images/family-red.png);
    & + .text-col {
      color: var(--primary-color);
    }
  }
  [type="radio"] + .background-img-travel4 {
    background-image: url(/assets/images/group-black.png);
  }
  [type="radio"]:checked + .background-img-travel4 {
    background-image: url(/assets/images/family-red.png);
    & + .text-col {
      color: var(--primary-color);
    }
  }
  .trip-profile-travel {
    width: 20%;
    @include media-breakpoint-down(sm) {
      width: 25%;
    }
  }
}

.trip_name .current_trip {
  margin-right: 13px;
  line-height: 25px;
}
.mymodalclose {
  position: absolute;
  right: 20px;
  top: 14px;
  z-index: 10;
  font-size: 28px;
  box-shadow: none;
  border-radius: 14px;
  padding: 0px;
  border: none;
  background: none;
  /* transition: all 200ms; */
  font-size: 20px;
  font-weight: 600;
}

.mymodalclose:focus {
  outline: none !important;
  border: none !important;
}

.pac-item-query {
  color: #495057;
  font-size: 13px !important;
  font-weight: 300 !important;

  font-weight: 300;
  src: local("Pacifico Regular"), local("Pacifico-Regular"),
    url(https://fonts.gstatic.com/s/pacifico/v12/FwZY7-Qmy14u9lezJ-6H6MmBp0u-.woff2)
      format("woff2");
  font-display: swap;
}
.pac-item {
  color: #495057;
  padding-left: 20px;
  font-size: 13px;
  line-height: 38px;
  font-weight: 300 !important;

  font-weight: 300;
  src: local("Pacifico Regular"), local("Pacifico-Regular"),
    url(https://fonts.gstatic.com/s/pacifico/v12/FwZY7-Qmy14u9lezJ-6H6MmBp0u-.woff2)
      format("woff2");
  font-display: swap;
}
.pac-icon {
  margin-top: 10px;
}
.pac-matched {
  font-weight: 500 !important;
}

.imgnew-hold {
  width: 100%;
}
@media only screen and (max-width: 575px) {
  .p_mob20 {
    padding: 0px 20px;
  }
}

@media only screen and (max-width: 991px) {
  .pb-mob {
    padding-bottom: 12px !important;
  }
}
.scrap-top-section {
  .swiper-slide {
    width: 100% !important;
    height: 100%;
    background-size: cover;
    background-position: center bottom;
  }
}
.margin-bottom-10 {
  margin-bottom: 10px !important;
}
.home-desktopbg {
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(180deg, #dc422500, #2b2625c4, #00000000);
    opacity: 0.2;
    height: inherit;
  }

  height: 85.8vh;
  max-width: auto;
  width: auto;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-image: url(https://process.filestackapi.com/AMWLTZ7TPGdKVmXWkeY9Az/compress/55cGQZQnQuCZoZvbf8tA);
}

.w-65 {
  width: 55%;
  @media only screen and (max-width: 991px) {
    width: 100%;
  }
}
.banner-text-shadow {
  text-shadow: 0px 0px 5px rgb(0, 0, 0 / 0.3);
}
@media only screen and (max-width: 991px) {
  .home-desktopbg {
    height: 60vh;
    max-width: 100%;
    position: relative;
    z-index: 5;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url(https://process.filestackapi.com/AMWLTZ7TPGdKVmXWkeY9Az/JlgkqcNXQsi4ZnzaWeMP);
  }
}
.tb-search-suggested-places {
  color: var(--white) !important;

  a {
    color: var(--white) !important;
    &::after {
      content: ", ";
    }
    &:last-child::after {
      content: "";
    }
  }
}
.build-trip-img-icon {
  width: 20px;
  height: auto;
}
.build-dropdown {
  ul {
    left: 0px;
    top: 38px;
    background: var(--white);
    width: 100%;
    border-radius: 6px;
    z-index: 99;
    padding: 10px 0px;
    white-space: nowrap;
    li {
      padding: 10px 20px !important;
      display: block;
      color: var(--body-text-color) !important;
      font-size: 15px !important;
      line-height: 22px;
      @media screen {
        display: flex;

        font-size: 1.2rem !important;
        line-height: 2.2rem;
        align-items: center;
        width: 100%;
      }
      &:hover {
        background: var(--grey100);
      }
    }
  }
}
.build-trip-container {
  max-width: 85% !important;
  margin: 0px auto;
  @media only screen and (max-width: 991px) {
    max-width: 100% !important;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    margin-left: 0px;
    margin-right: 0px;
    background: var(--grey100);
  }
}
.arrow-right-wrapper {
  background: var(--secondary-color);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 27px;
  text-align: center;
  font-size: 16px;
  margin: 0px;
  border: 1.5px solid var(--secondary-color);
}
.build-trip-height {
  height: 500px;
  align-items: center;
  display: flex;
  @media only screen and (max-width: 991px) {
    height: auto;
  }
}
.widget-padding {
  padding: 0.5rem 0rem 2rem 0rem;
}
